import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import EventCard from 'page/EventsTickets/components/EventCard'
import TitleGradient from 'components/landingComponents/TitleGradient'
import Seemore from 'components/landingComponents/SeeMore'
import { TabButton } from 'components/ButtonsTab'
import { useNavigate } from 'react-router-dom'
import { filterName } from 'utils/filter'
import { GetCookie } from 'utils/cookieLombok'
import { useUserInfoAtom } from 'store/authAtom'
import { Button } from '@mui/material'

function FeaturedSection({ events = [], recommends = [], programmes = [] }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const isLogin = Boolean(useUserInfoAtom((state) => state.profileInfo))
    const [type, setType] = useState('event')

    const renderBtnVals = useMemo(() => {
        const btnVals = [
            { text: t('landing.recommendedForYou'), val: 'SPECIAL_FOR_YOU' },
            { text: t('landing.events'), val: 'event' },
            { text: t('landing.programmes'), val: 'PROGRAMMES' },
        ]
        return isLogin ? btnVals : btnVals.slice(1)
    }, [isLogin, language])

    const renderList = useMemo(() => {
        if (type === 'event') return events
        if (type === 'SPECIAL_FOR_YOU')
            return recommends?.map((i) => {
                return i?.dataUuidType === 1
                    ? {
                          ...i,
                          cardImage: i?.eventCardImage,
                          label: i?.eventLabel,
                          uuid: i?.eventIdentityUuid,
                          local: i?.venueMainBuilding,
                      }
                    : {
                          ...i,
                          cardImage: i?.programmeCardImage,
                          label: i?.programmeLabel,
                          uuid: i?.programmeId,
                          eventName: i?.programmeName,
                          local:
                              i.venues?.length > 0
                                  ? filterName(
                                        {
                                            nameEn: i.venues[0].enName,
                                            nameCn: i.venues[0].scName,
                                            nameZh: i.venues[0].tcName,
                                        },
                                        language,
                                    )
                                  : '',
                          dateRange: [
                              { eventStartDateTime: i?.programmeStartDate, eventEndDateTime: i?.programmeEndDate },
                          ],
                          path: '/programmesDetail',
                      }
            })
        if (type === 'PROGRAMMES')
            return programmes?.map((card) => ({
                ...card,
                uuid: card.programmeId,
                eventName: card.programmeName,
                local:
                    card.venues?.length > 0
                        ? filterName(
                              {
                                  nameEn: card.venues[0].enName,
                                  nameCn: card.venues[0].scName,
                                  nameZh: card.venues[0].tcName,
                              },
                              language,
                          )
                        : '',
                dateRange: [
                    card.startDate &&
                        card.endDate && {
                            eventStartDateTime: card.startDate,
                            eventEndDateTime: card.endDate,
                        },
                ].filter((i) => i),
            }))
    }, [type, language, events, recommends, programmes])

    useEffect(() => {
        if (isLogin) setType('SPECIAL_FOR_YOU')
        if (!isLogin) setType('event')
    }, [isLogin])

    return (
        <div className='bg-container landing-pt justify-center'>
            <TitleGradient text={t('landing.featuredAttractions')} />
            <div className='flex justify-between gap-2 pb-[32px] md:pb-[60px]'>
                {renderBtnVals.length > 1 && (
                    <div className='flex flex-wrap gap-[10px]'>
                        {renderBtnVals.map((btnVal, index) => {
                            return (
                                <TabButton
                                    key={index}
                                    onClick={() => {
                                        setType(btnVal.val)
                                    }}
                                    selected={type === btnVal.val}
                                >
                                    {btnVal.text}
                                </TabButton>
                            )
                        })}
                    </div>
                )}
                {type !== 'SPECIAL_FOR_YOU' && (
                    <div className='hidden sm:block'>
                        <Seemore
                            handleClick={() => {
                                if (type === 'event') navigate('/eventsTickets')
                                if (type === 'PROGRAMMES') navigate('/programmes')
                            }}
                        />
                    </div>
                )}
            </div>
            <div className='grid gap-x-[20px] gap-y-6 grid-cols-2 md:gap-x-[24px] md:gap-y-[40px] lg:grid-cols-3'>
                {renderList &&
                    renderList?.length > 0 &&
                    renderList.map((card, index) => {
                        if (type === 'SPECIAL_FOR_YOU')
                            return (
                                <EventCard key={index} listType='grid' isLanding={true} card={card} path={card?.path} />
                            )
                        if (type === 'event')
                            return <EventCard key={index} listType='grid' isLanding={true} card={card} />
                        if (type === 'PROGRAMMES')
                            return (
                                <EventCard
                                    key={index}
                                    listType='grid'
                                    isLanding={true}
                                    card={card}
                                    path='/programmesDetail'
                                />
                            )
                        return <div />
                    })}
            </div>
            {type !== 'SPECIAL_FOR_YOU' && (
                <div className='flex sm:hidden mt-6 justify-center'>
                    <Button
                        type='button'
                        variant='outlined'
                        className='w-[270px] font-semibold text-[18px]'
                        size='large'
                        onClick={() => {
                            if (type === 'event') navigate('/eventsTickets')
                            if (type === 'PROGRAMMES') navigate('/programmes')
                        }}
                    >
                        {t('landing.seeMore')}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default FeaturedSection
