import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Select } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { updateUrl } from 'utils/util'
import { setDayjsLocale } from 'utils/util'
function SelectComponent({ color }) {
    const { i18n } = useTranslation()

    const lngs = [
        { title: 'EN', value: 'en_US' },
        { title: '繁體', value: 'zh_HK' },
        { title: '简体', value: 'zh_CN' },
    ]
    const handleChange = (e) => {
        setDayjsLocale(e.target.value)
        i18n.changeLanguage(e.target.value)
        updateUrl()
    }

    return (
        <Select
            labelId='demo-simple-select-autowidth-label'
            id='demo-simple-select-autowidth'
            value={i18n.language}
            onChange={handleChange}
            autoWidth
            sx={{
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '0px',
                    },
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '.MuiSvgIcon-root': { color: color },
                '.MuiSelect-select': { padding: 0 },
                fontSize: 14,
                color: color,
            }}
        >
            {lngs.map((lan, index) => {
                return (
                    <MenuItem key={index} value={lan.value}>
                        {lan.title}
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default SelectComponent
