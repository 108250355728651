import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useMediaQuery } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Fade from '@mui/material/Fade'

import { filterName } from 'utils/filter'

function FooterLabel({ menuFirst, isPoint }) {
    const [isExpand, setIsExpand] = useState(false)
    const isDestop = useMediaQuery('(min-width:1024px)')
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <div
            onClick={() => {
                !isPoint && !isDestop && setIsExpand(!isExpand)
            }}
            className={
                ' border-b-[1px] border-secondary-grey border-solid py-5 lg:pt-0 lg:pb-4 lg:border-0 ' +
                (isDestop ? ' ' : 'active:opacity-50')
            }
        >
            {menuFirst && (
                <div
                    onClick={() => {
                        if (menuFirst.webLinkUrl) {
                            window.location.href = menuFirst.webLinkUrl
                        }
                    }}
                    className={
                        'text-body2 leading-[32px] text-secondary-midnightBlue flex justify-between items-center gap-[10px] ' +
                        (isPoint && ' ') +
                        ((menuFirst.webLinkUrl || !isDestop) && ' cursor-pointer')
                    }
                >
                    {filterName(menuFirst, language)}
                    {isPoint && <div className='icon-arrow_right text-xs' />}
                    {!isPoint &&
                        !isDestop &&
                        (isExpand ? (
                            <div className='icon-arrow_up text-xs' />
                        ) : (
                            <div className='icon-arrow_down text-xs' />
                        ))}
                </div>
            )}
            <Fade in={isExpand || isDestop}>
                <div>
                    {menuFirst.secondMenu.length > 0 &&
                        (isDestop || isExpand) &&
                        menuFirst.secondMenu.map((m, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        if (m.newBrowser) {
                                            window.open(m.appLinkUrl)
                                            return
                                        }
                                        window.location.href = m.appLinkUrl
                                    }}
                                    className={
                                        'text-sm cursor-pointer text-secondary-midnightBlue font-medium ' +
                                        (index !== menuFirst.secondMenu.length - 1 && 'pb-2 ') +
                                        (index === 0 && 'pt-2')
                                    }
                                    key={index}
                                >
                                    {filterName(m, language)}
                                </div>
                            )
                        })}
                </div>
            </Fade>
        </div>
    )
}

export default FooterLabel
