import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import { filterName } from 'utils/filter'
import Card from 'page/FacilitiesBooking/components/Card'

function BookingSwiper({ items = [], setSwiper, setSlideIndex }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <Swiper
            spaceBetween={26}
            modules={[Navigation]}
            onActiveIndexChange={(swiper) => {
                setSlideIndex(swiper.realIndex)
            }}
            onSwiper={(swiper) => {
                setSwiper(swiper)
            }}
            breakpoints={{
                0: { slidesPerView: 2 },
                1024: {
                    slidesPerView: 3,
                },
            }}
            className='mySwiper'
        >
            {items.map((card, index) => {
                return (
                    <SwiperSlide key={index}>
                        <Card
                            card={{
                                ...card,
                                cardImage: filterName(
                                    {
                                        nameEn: card.cardImageEn,
                                        nameZh: card.cardImageTc,
                                        nameCn: card.cardImageCn,
                                    },
                                    language,
                                ),
                                facilityName: filterName(card, language),
                                label: filterName(
                                    { nameEn: card.labelEn, nameZh: card.labelTc, nameCn: card.labelCn },
                                    language,
                                ),
                            }}
                            listType='grid'
                        />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default BookingSwiper
