import React from 'react'
import { useNavigate } from 'react-router-dom'
function Label({ label, link, setIsFocus, index, isThreeMenu = true, labelStyle = '' }) {
    const navigate = useNavigate()
    const pb =
        isThreeMenu && isThreeMenu.length > 0
            ? ' pb-5 font-boldFamily text-[26px] leading-[26px]'
            : '  text-[22px] leading-[22px] font-semiboldFamily pb-1'
    return (
        <div
            onClick={() => {
                link && navigate(link)
                setIsFocus(false)
            }}
            className={'text-white ' + (link && 'cursor-pointer hover:underline ') + pb + labelStyle}
        >
            {label}
        </div>
    )
}

export default Label
