import React from 'react'
import { Button } from '@mui/material'
function WebBtn({ url, text }) {
    return (
        <Button
            onClick={() => url && window.open(url)}
            className=' text-base mt-3 leading-4 py-2 min-w-[115px] h-8'
            variant='secondary'
            size='large'
        >
            {text}
        </Button>
    )
}

export default WebBtn
