import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'
import { indexOf } from 'ramda'

import { event } from 'event/event'
import { decryptAes, IASMError, APIWhitelist } from 'utils/filter'
import { GetCookie, SetCookie, RemoveCookie } from 'utils/cookieLombok'
import { myProfile } from 'service/crmService'
import { ErrorCode } from './staticData'
export const BASE_URL = '/api'

const ktspUrl = process.env.NODE_ENV === 'development' ? BASE_URL : process.env.REACT_APP_BASE_URL + BASE_URL
const removeCookieFun = () => {
    RemoveCookie('ktspSsoAccessToken')
    RemoveCookie('session')
    RemoveCookie('sessionKey')
    RemoveCookie('ktspSsoRefreshToken')
}
export const http = axios.create({
    baseURL: ktspUrl,
    timeout: 30000,
    headers: {},
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true }) /// 传递数组 paramsSerializer序列化 ids:[1,2,3] -> ids=1&ids=2&id=3
    },
})
let isUpdate = false
http.interceptors.request.use(async (config) => {
    const accessToken = GetCookie('ktspSsoAccessToken')
    const refreshToken = GetCookie('ktspSsoRefreshToken')
    const session = GetCookie('session')
    const sessionKey = GetCookie('sessionKey')
    let headers = {
        'Content-Type': 'application/json',
        Session: session ? decryptAes(session) : null,
        sessionKey: sessionKey ? decryptAes(sessionKey) : null,
    }
    if (accessToken && refreshToken && session) {
        headers = {
            ...headers,
            Authorization: decryptAes(accessToken),
            Session: decryptAes(session),
            sessionKey: decryptAes(sessionKey),
        }
    }
    if (!accessToken && refreshToken && session && sessionKey && !isUpdate) {
        isUpdate = true
        try {
            const response = await myProfile.updateAccessToken(decryptAes(refreshToken))
            SetCookie('ktspSsoAccessToken', response.accessToken, response.accessTokenExpired)
            SetCookie('ktspSsoRefreshToken', response.refreshToken, response.refreshTokenExpired)
            SetCookie('session', decryptAes(session), response.refreshTokenExpired)
            SetCookie('sessionKey', decryptAes(sessionKey), response.refreshTokenExpired)
            headers = {
                ...headers,
                Authorization: response.accessToken,
            }
            isUpdate = false
        } catch (error) {
            console.log('refreshToken error')
            console.log(error)
            removeCookieFun()
            event.emit('logoutHandle')
        }
    }
    config.headers = {
        ...headers,
    }
    return config
})

http.interceptors.response.use(({ data, status, ...rest }) => {
    const apiUrl = rest.request.responseURL.split(ktspUrl).pop();
    if (data instanceof Blob) {
        return data
    }
    if (data.errorCode === '60028') {
        removeCookieFun()
        event.emit('logoutHandle')
    }
    if (status === 200 && data.errorCode === '00000') {
        return data.result
    } else if (status === 200 && indexOf(data.errorCode, IASMError) !== -1) {
        return data
    } else if (indexOf(data.errorCode, ErrorCode) !== -1) {
        throw i18n.t(`ErrorCode.${data.errorCode}`)
    } else if (indexOf(apiUrl, APIWhitelist)!== -1) {
        return data
    } else {
        throw i18n.t(`ErrorCode.99999`)
    }
})
