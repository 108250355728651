import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Backdrop } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { split, indexOf } from 'ramda'
import dayjs from 'dayjs'

import { useSimpleBackdropStore } from 'store/authAtom'
import { configDataStore } from 'store/authAtom'
import iamsmartImg from 'resources/images/iamsmart.png'
import tickGreen from 'resources/images/tickGreen.svg'
import { useModalStore, useIASMStore } from 'store/authAtom'
import { iamsmart } from 'service/iasmMartService'
import { myProfile } from 'service/crmService'
import { iasmLangObj } from 'utils/filter'
import { IASMError } from 'utils/filter'

import { Box } from 'page/UserSettings/components/CommonBox'
import DeleteTip from 'page/UserSettings/EditMyProfile/components/DeleteTip'
import Loading from 'components/Loading'
import { updateUrlLang } from 'utils/util'

const AccountBox = ({ title, about, rightBox, fun, isIASMBtn }) => {
    return (
        <Box onClick={fun}>
            <div
                className={
                    'flex cursor-pointer justify-between items-center gap-5' +
                    (isIASMBtn ? ' flex-col md:flex-row' : '')
                }
            >
                <div className='flex-1 self-start'>
                    <div className='text-xl pb-2 text-secondary-midnightBlue font-semiboldFamily'>{title}</div>
                    <div className='text-xs font-medium'>{about}</div>
                </div>
                {rightBox}
            </div>
        </Box>
    )
}

const StatusButton = styled(({ isIASM, ...rest }) => <Button {...rest} />)`
    &:hover {
        background-color: ${(props) => (props.isIASM ? tw`bg-[#2B73661A]` : tw`bg-[#2B7366]`)};
    }

    ${(props) =>
        props.isIASM
            ? tw`bg-[#2B73661A] text-[#2B7366] px-2 py-3 rounded-[8px] leading-[18px] text-base`
            : tw`bg-[#2B7366] px-2 py-3 rounded-[8px] text-white text-base leading-[18px]`}
`

export default function AccountBoxs() {
    const setTokenInfo = useIASMStore((state) => state.setTokenInfo)
    const { configDataValue } = configDataStore()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    // const queryClient = useQueryClient()

    const { enqueueSnackbar } = useSnackbar()
    // const { setModalComponent, onClose, onOpen } = useModalStore()

    const { data: profileData, refetch } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })

    const {
        data: tokenData,
        isFtching: tokenDataIsPending,
        isError,
    } = useQuery({
        queryKey: ['iasmGetToken'],
        queryFn: () =>
            iamsmart.getToken({
                code: searchParams.get('code'),
                grantType: 'authorization_code',
            }),
        enabled: !!searchParams.get('code'),
    })

    // const {
    //     data: initiateRequestData,
    //     isFetching: initiateRequestDataIsPending,
    //     isSuccess: initiateRequestDataIsSuccess,
    // } = useQuery({
    //     queryKey: ['iasmInitiateRequest'],
    //     queryFn: () =>
    //         iamsmart.initiateRequest({
    //             accessToken: tokenData?.accessToken,
    //             lang: language,
    //             openId: tokenData?.openID,
    //         }),
    //     enabled: !!tokenData && !!searchParams.get('code') && !!noBound,
    // })

    // const {
    //     data: IASMProfileData,
    //     isFetching: iASMProfileDataIsPending,
    //     isSuccess,
    // } = useQuery({
    //     queryKey: ['iamSmartGetProfile'],
    //     queryFn: () =>
    //         iamsmart.getProfile({
    //             buinessId: initiateRequestData?.businessID,
    //             lang: language,
    //         }),
    //     retry: true,
    //     select: (v) => v?.content || v?.errorCode, /////
    //     enabled: !!initiateRequestData?.businessID && !!searchParams.get('code') && !!noBound,
    // })

    const checkProfileExistMutation = useMutation({
        mutationFn: myProfile.checkProfileExist,
        onSuccess: (v) => {
            navigate('/userSettings/consentPage')
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    // const userBindMutation = useMutation({
    //     mutationFn: myProfile.userBind,
    //     onSuccess: (v) => {
    //         setSearchParams(undefined)
    //         enqueueSnackbar(t('AccountBoxs.BindingSuccessful'), { variant: 'success' })
    //         // onOpenSimpleBackdrop()
    //         updateSSOProfile.mutate({
    //             ...profileData,
    //             activityInterest: undefined,
    //             dayOfBirth: dayjs(IASMProfileData?.birthDate)?.format('DD') ?? profileData?.dayOfBirth,
    //             province: profileData?.province ?? '',
    //             district: profileData?.district ?? '',
    //             campaignCode: profileData?.campaignCode ?? '',
    //             referralCode: profileData?.referralCode ?? '',
    //             eNewIndicator:
    //                 profileData?.eNewIndicator === 'false'
    //                     ? false
    //                     : profileData?.eNewIndicator === 'true'
    //                     ? true
    //                     : false,

    //             openIdUUID: tokenData?.openID,

    //             lastname: split(',', IASMProfileData?.enName?.UnstructuredName ?? '')?.[1] ?? profileData?.lastname,
    //             firstname: split(',', IASMProfileData?.enName?.UnstructuredName ?? '')?.[0] ?? profileData?.firstname,
    //             monthOfBirth: dayjs(IASMProfileData?.birthDate)?.format('MM') ?? profileData?.monthOfBirth,
    //             yearOfBirth: dayjs(IASMProfileData?.birthDate)?.format('YYYY') ?? profileData?.yearOfBirth,
    //             chineseName: IASMProfileData?.chName?.ChineseName ?? profileData?.chineseName,
    //         })
    //     },
    //     onError: (e) => {
    //         enqueueSnackbar(e, { variant: 'error' })
    //     },
    // })

    // const updateSSOProfile = useMutation({
    //     mutationFn: myProfile.updateSSOProfile,
    //     onSuccess: () => {
    //         // onCloseSimpleBackdrop()
    //         // enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
    //         queryClient.removeQueries(['iasmGetToken'])
    //         queryClient.removeQueries(['iasmInitiateRequest'])
    //         queryClient.removeQueries(['iamSmartGetProfile'])
    //         refetch()
    //         navigate('/userSettings/myProfile', {
    //             replace: true,
    //         })
    //     },
    //     onError: () => {
    //         // onCloseSimpleBackdrop()
    //         enqueueSnackbar(t('myProfile.saveTip'), { variant: 'error' })
    //     },
    // })

    const handleBind = () => {
        document.cookie = `lang=${language}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
        window.location.href = `${process.env.REACT_APP_ISMDOMAINS}/api/v1/auth/getQR?clientID=${process.env.REACT_APP_ISM_CLIENTID}&responseType=code&source=PC_Browser&redirectURI=${process.env.REACT_APP_WEBSITE}/userSettings/myProfile&scope=eidapi_auth%20eidapi_formFilling&lang=${iasmLangObj[language]}`
    }

    useEffect(() => {
        if (!!profileData && tokenData?.openID && !!searchParams.get('code')) {
            checkProfileExistMutation.mutate({
                countryCode: '',
                emailId: '',
                lang: language,
                mobileNo: '',
                openIdUUID: tokenData?.openID,
            })
        }
    }, [profileData, tokenData?.openID, searchParams.get('code')])

    // useEffect(() => {
    //     if (
    //         !!tokenData &&
    //         !!profileData &&
    //         !!initiateRequestData &&
    //         !!IASMProfileData &&
    //         !(indexOf(IASMProfileData, IASMError) !== -1) &&
    //         !!searchParams.get('code')
    //     ) {
    //         userBindMutation.mutate({
    //             openIdUUID: tokenData?.openID,
    //         })
    //     }
    // }, [tokenData, profileData, initiateRequestData, IASMProfileData, searchParams.get('code')])

    // Cancel profile authorization
    // useEffect(() => {
    //     if (isSuccess && indexOf(IASMProfileData, IASMError) !== -1) {
    //         setSearchParams(undefined)
    //         queryClient.removeQueries(['iamSmartGetProfile'])
    //         if (IASMProfileData === 'D40000' || IASMProfileData === 'D40001' || IASMProfileData === 'D50001') {
    //             enqueueSnackbar(t('myProfile.canceIamsmart'), { variant: 'error' })
    //         }
    //         if (IASMProfileData === 'D40002' || IASMProfileData === 'D50002') {
    //             enqueueSnackbar(t('myProfile.rejectIamsmart'), { variant: 'error' })
    //         }
    //         if (IASMProfileData === 'D40003' || IASMProfileData === 'D50003' || IASMProfileData === 'D40004') {
    //             enqueueSnackbar(t('myProfile.expiredIamsmart'), { variant: 'error' })
    //         }
    //         navigate('/userSettings/myProfile')
    //     }
    // }, [isSuccess, IASMProfileData])

    useEffect(() => {
        if (!!tokenData) {
            setTokenInfo(tokenData)
        }
        if (isError) {
            setTokenInfo(null)
        }
    }, [tokenData, isError])
    // Prompt user for authorization
    // useEffect(() => {
    //     if (initiateRequestDataIsSuccess && initiateRequestData) {
    //         enqueueSnackbar(t('AccountBoxs.Authorize'), { variant: 'warning' })
    //     }
    // }, [initiateRequestDataIsSuccess, initiateRequestData])

    if (
        tokenDataIsPending ||
        checkProfileExistMutation.isPending
        // initiateRequestDataIsPending ||
        // iASMProfileDataIsPending ||
        // userBindMutation.isPending ||
        // updateSSOProfile.isPending
    ) {
        return (
            <Backdrop open={true}>
                <Loading />
            </Backdrop>
        )
    }

    return (
        <>
            <AccountBox
                fun={!!profileData?.openIdUUID ? null : handleBind}
                title={t('myProfile.iAmStartAccount')}
                about={t('myProfile.iAmStartAccountAbout')}
                rightBox={
                    <StatusButton
                        disabled={!!profileData?.openIdUUID}
                        className='px-5 font-semiboldFamily whitespace-nowrap normal-case self-start cursor-pointer disabled:text-[#2B7366] disabled:border-[2px] disabled:border-solid disabled:border-[rgba(43,115,102,0.20)]'
                        isIASM={!!profileData?.openIdUUID}
                        startIcon={
                            <img
                                alt=''
                                className='w-[15px] h-5 object-contain'
                                src={!!profileData?.openIdUUID ? tickGreen : iamsmartImg}
                            />
                        }
                    >
                        {!!profileData?.openIdUUID ? t('myProfile.boundInformation') : t('myProfile.smart')}
                    </StatusButton>
                }
                isIASMBtn={true}
            />
            {/* {configDataValue && configDataValue?.cchSwitch ? ( */}
            {/*    <AccountBox*/}
            {/*        fun={() => {*/}
            {/*            window.open(updateUrlLang(process.env.REACT_APP_VBS_URL + 'myProfile/personal?num=6'))*/}
            {/*        }}*/}
            {/*        title={t('myProfile.emergencyContact')}*/}
            {/*        about={t('myProfile.addPlayer')}*/}
            {/*        rightBox={<div className='self-start icon-arrow_right' />}*/}
            {/*    />*/}
            {/*) : null}*/}
            {/* <AccountBox
                fun={() => {
                    setModalComponent(<DeleteTip setModalComponent={setModalComponent} onClose={onClose} />)
                    onOpen()
                }}
                title={t('myProfile.deleteAccount')}
                about={t('myProfile.deleteAccountAbout')}
                rightBox={<ChevronRightIcon className='self-start' />}
            /> */}
        </>
    )
}
