import React from 'react'

import { imageViewer } from 'store/authAtom'
function ImageMap({ venueImage }) {
    const { showImage, setImage } = imageViewer()
    return (
        <div
            onClick={() => {
                setImage(venueImage)
                showImage()
            }}
            className='w-full cursor-pointer mb-2 h-fit md:max-w-[715px]'
        >
            <img alt='loading' loading='lazy' src={venueImage} className='w-full h-full' />
        </div>
    )
}

export default ImageMap
