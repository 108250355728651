import React from 'react'
import { useTranslation } from 'react-i18next'

import Banner from 'components/shopDine/Banner'
import contactUsBanner from 'resources/images/contactUsBannerUser.jpg'
import EnquiryForm from 'page/ConnectWithKTSP/ContactUs/components/EnquiryForm'
import ContactMethod from 'page/ConnectWithKTSP/ContactUs/components/ContactMethod'
function ContactUs() {
    const { t } = useTranslation()
    const banner = {
        image: contactUsBanner,
        title: t('contactUs.mainTitle'),
        about: t('contactUs.bannerAbout'),
    }
    return (
        <div className='bg-white'>
            <Banner banner={banner} contactUs=' mb-1' />
            <EnquiryForm />
            <ContactMethod />
        </div>
    )
}

export default ContactUs
