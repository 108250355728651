import React from 'react'
import { pipe, __, modulo, equals, T } from 'ramda'
import { useTranslation } from 'react-i18next'

import ConnectCard from 'components/ConnectCard'
import NavigationPoint from 'components/NavigationPoint'

export default function WhyKTSP({ getWhyKtspList = [] }) {
    const isEven = pipe(modulo(__, 2), equals(0))
    const { t } = useTranslation()
    return (
        <div className='bg-container pt-6'>
            <NavigationPoint
                firstNav={t('navigationBar.connectWithKTSP')}
                lastNav={t('connectWithKtsp.workWithUs')}
                firstNavLink={'/connectWithKTSP/aboutKTSP'}
            />
            <div className='flex'>
                <div className='text-secondary-midnightBlue w-full'>
                    <div className='pt-6 pb-4 text-title'>{t('workWithUs.whyKtsp')}</div>
                    <div className='text-sm font-medium line-clamp-5 whitespace-pre-line'>{t('workWithUs.whyKtspAbout')}</div>
                </div>
            </div>

            {getWhyKtspList?.map((item, i) => (
                <ConnectCard
                    key={i}
                    isReverse={!isEven(i)}
                    image={item.webVisual}
                    title={item.title}
                    about={item.description}
                />
            ))}
        </div>
    )
}
