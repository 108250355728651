import * as React from 'react'
import { IconButton, CardMedia, Card, CardActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import bookmarkHit from 'resources/images/bookmarkHit.png'
import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { dateRangeFormat } from 'utils/filter'
import { cn } from 'utils/cn'
const BookmarkIcon = tw.div`text-secondary-midnightBlue text-xl px-[2px]`
function RestaurantCard({ card, listType }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
    } = useTranslation()

    const locationRef = React.useRef(null)
    const [isMultiline, setIsMultiline] = React.useState(false)

    React.useEffect(() => {
        const checkMultiline = () => {
            const textElement = locationRef.current
            const lineHeight = 17

            if (textElement) {
                setIsMultiline(textElement.scrollHeight > lineHeight)
            }
        }

        checkMultiline()

        // 窗口调整大小时重新检查
        window.addEventListener('resize', checkMultiline)
        return () => {
            window.removeEventListener('resize', checkMultiline)
        }
    }, [card?.location])

    return (
        <Card
            onClick={() => navigate('/restaurantsDetail?id=' + card?.identityUuid)}
            className={
                'relative w-full shadow-none cursor-pointer bg-transparent group rounded-none' +
                (listType !== 'grid' ? ' mt-[35px]' : '')
            }
            square={true}
        >
            <div className={listType !== 'grid' ? 'flex gap-4 md:gap-10' : ''}>
                <div
                    className={cn(
                        CARD_BOX,
                        'aspect-square w-full h-full',
                        listType === 'gird' && 'mb-2',
                        listType === 'list' && 'w-[150px] md:w-[180px] lg:w-[290px]',
                    )}
                >
                    {/* image */}
                    {card?.cardImage && (
                        <CardMedia
                            component='img'
                            className='object-cover w-full h-full group-hover:scale-110 transition-all duration-300'
                            image={card.cardImage}
                            alt='loading'
                        />
                    )}
                    {/* label */}
                    {card?.label && (
                        <div className='absolute font-semiboldFamily text-white top-0 left-0 py-[6px] px-[15px] bg-primaryRed-ktRed uppercase text-xs md:text-sm'>
                            {card.label}
                        </div>
                    )}
                    <RedLine />
                </div>
                <div className={listType !== 'grid' ? 'flex-1 relative' : 'pt-3'}>
                    {/* <div className={'flex-1' + (listType !== 'grid' ? ' flex flex-col' : ' pt-3')}> */}
                    {/* <div className='flex justify-between'>
                            <div className={'text-secondary-midnightBlue pb-2 text-h5 break-words'}>{card.name}</div>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                className={
                                    'p-0 self-start text-secondary-midnightBlue ' +
                                    (listType !== 'grid' && ' absolute top-0 right-0')
                                }
                            >
                                <BookmarkIcon className='icon-bookmark' />
                            </IconButton>
                        </div> */}
                    {card.name && (
                        <div className={'text-secondary-midnightBlue mb-2 text-h5 [overflow-wrap:anywhere] line-clamp-2'}>{card.name}</div>
                    )}
                    {/* about */}
                    {card?.about && listType === 'list' && <DescriptionLabel description={card.about} />}
                    {/* location */}
                    <div
                        className={cn(
                            'flex gap-1 items-start font-medium text-secondary-midnightBlue text-body3 mb-1',
                            isMultiline ? 'items-start' : 'items-center',
                        )}
                    >
                        <div className='icon-location text-[16px] leading-[20px] [overflow-wrap:anywhere]' />
                        <div className='text-ellipsis whitespace-nowrap overflow-hidden' ref={locationRef}>
                            {card?.location}
                        </div>
                    </div>
                    {/* dollar */}
                    {/* {card?.acceptKDollar === 1 && (
                            <div className='flex gap-1 items-center font-medium text-secondary-midnightBlue text-body3'>
                                <div className='icon-k_dollar text-base' />
                                <div className='line-clamp-2'>Accept K Dollar</div>
                            </div>
                        )} */}
                    {/* </div> */}
                </div>
            </div>
        </Card>
    )
}

export default RestaurantCard
