import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { ConvertDateRangeHelper, ConvertTimeRangeHelper } from 'utils/util'
import Loading from 'components/Loading'
import { ticketServices } from 'service/ticketsService'
import TicketCard from 'page/UserSettings/TicketDetail/components/TicketCard'
import RecordCard from 'page/UserSettings/TicketDetail/components/RecordCard'
function TicketDetail() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const type = searchParams.get('type')
    const sessionId = searchParams.get('sessionId')
    const image = searchParams.get('image')
    const [ticketData, setTicketData] = useState()
    const filteredData = useMemo(() => {
        return ticketData?.hktTicketsRespDTOS && ticketData?.hktTicketsRespDTOS.length > 0
            ? ticketData.hktTicketsRespDTOS.filter((item) => item.session.id + '' === sessionId)
            : []
    }, [ticketData])
    const getTicketDetail = useMutation({
        mutationFn: ticketServices.myTickets,
        onSuccess: (res) => {
            setTicketData(res)
        },
        onError: (e) => {
            console.log(e)
        },
    })
    const getTicketDetailMethod = () => {
        getTicketDetail.mutate({ eventId: parseInt(id), orderGroupId: '', page: 1, pageSize: 50, period: type })
    }
    const handleDate = (date = '') => {
        const dayjsObj = dayjs(date)
        const hour = dayjsObj.hour()
        return `${dayjsObj.format('HH:mm')}${hour > 12 ? 'pm' : 'am'}`
    }
    const handleDay = (startDate = '', endDate = '') => {
        const startDayjsObj = dayjs(startDate)
        const endDayObj = dayjs(endDate)
        if (startDayjsObj.format('DD MMM YYYY') === endDayObj.format('DD MMM YYYY')) {
            return `${startDayjsObj.format('DD MMM YYYY')} | ${handleDate(startDate)} - ${handleDate(endDate)}`
        } else
            return `${startDayjsObj.format('DD MMM YYYY')} | ${handleDate(startDate)} - ${endDayObj.format(
                'DD MMM YYYY',
            )} | ${handleDate(endDate)}`
    }
    useEffect(() => {
        getTicketDetailMethod()
    }, [])
    return (
        <div className='bg-[#F5F5F5] w-full h-full space-y-6 mt-6 px-5 md:px-10 lg:px-[80px] md:mt-[64px]'>
            {getTicketDetail.isPending ? (
                <Loading />
            ) : (
                filteredData.length > 0 && (
                    <>
                        <RecordCard
                            image={image}
                            filteredData={filteredData}
                            item={ticketData}
                            time={`${ConvertDateRangeHelper(
                                [filteredData[0].session.starts, filteredData[0].session.ends],
                                false,
                                language,
                            )} | ${ConvertTimeRangeHelper(
                                [filteredData[0].session.starts, filteredData[0].session.ends],
                                language,
                            )}`}
                        />
                        {filteredData.length > 0 &&
                            filteredData.map((item, index) => {
                                return (
                                    <TicketCard
                                        ticketData={ticketData}
                                        time={`${ConvertDateRangeHelper(
                                            [filteredData[0].session.starts, filteredData[0].session.ends],
                                            false,
                                            language,
                                        )} | ${ConvertTimeRangeHelper(
                                            [filteredData[0].session.starts, filteredData[0].session.ends],
                                            language,
                                        )}`}
                                        length={filteredData.length}
                                        index={index}
                                        item={item}
                                        key={index}
                                    />
                                )
                            })}
                    </>
                )
            )}
        </div>
    )
}

export default TicketDetail
