import React, { useEffect, useState, useMemo } from 'react'
import tw from 'twin.macro'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'
import { indexOf, split } from 'ramda'
import { useOrientation } from 'react-use'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { myProfile } from 'service/crmService'
import tap from 'resources/images/tap.png'
import { iamsmart } from 'service/iasmMartService'
import { useIASMStore } from 'store/authAtom'
import { IASMError } from 'utils/filter'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-6`

export default function InstructionPage() {
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const queryClient = useQueryClient()
    const [isGetProfile, setIsGetProfile] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])
    const tokenInfo = useIASMStore((state) => state.tokenInfo)
    const { data: profileData, refetch } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: () => myProfile.getMyProfile(language),
    })
    const {
        data: IASMProfileData,
        // isFetching: iASMProfileDataIsPending,
        isSuccess,
    } = useQuery({
        queryKey: ['iamSmartGetProfile'],
        queryFn: () =>
            iamsmart.getProfile({
                buinessId: id,
                lang: language,
            }),
        retry: true,
        select: (v) => v?.content || v?.errorCode, /////
        enabled: !!id,
    })

    const updateSSOProfile = useMutation({
        mutationFn: myProfile.updateSSOProfile,
        onSuccess: () => {
            enqueueSnackbar(t('AccountBoxs.BindingSuccessful'), { variant: 'success' })
            queryClient.removeQueries(['iasmGetToken'])
            queryClient.removeQueries(['iasmInitiateRequest'])
            queryClient.removeQueries(['iamSmartGetProfile'])
            refetch()
            // setIsGetProfile(true)
            navigate('/userSettings/myProfile', {
                replace: true,
            })
        },
        onError: () => {
            // onCloseSimpleBackdrop()
            enqueueSnackbar(t('myProfile.saveTip'), { variant: 'error' })
        },
    })
    const userBindMutation = useMutation({
        mutationFn: myProfile.userBind,
        onSuccess: (v) => {
            // onOpenSimpleBackdrop()
            updateSSOProfile.mutate({
                ...profileData,
                activityInterest: undefined,
                dayOfBirth: dayjs(IASMProfileData?.birthDate)?.format('DD') ?? profileData?.dayOfBirth,
                province: profileData?.province ?? '',
                district: profileData?.district ?? '',
                campaignCode: profileData?.campaignCode ?? '',
                referralCode: profileData?.referralCode ?? '',
                eNewIndicator:
                    profileData?.eNewIndicator === 'false'
                        ? false
                        : profileData?.eNewIndicator === 'true'
                        ? true
                        : false,

                openIdUUID: tokenInfo?.openID,

                lastname: split(',', IASMProfileData?.enName?.UnstructuredName ?? '')?.[1] ?? profileData?.lastname,
                firstname: split(',', IASMProfileData?.enName?.UnstructuredName ?? '')?.[0] ?? profileData?.firstname,
                monthOfBirth: dayjs(IASMProfileData?.birthDate)?.format('MM') ?? profileData?.monthOfBirth,
                yearOfBirth: dayjs(IASMProfileData?.birthDate)?.format('YYYY') ?? profileData?.yearOfBirth,
                chineseName: IASMProfileData?.chName?.ChineseName ?? profileData?.chineseName,
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })
    useEffect(() => {
        if (isSuccess && indexOf(IASMProfileData, IASMError) !== -1) {
            queryClient.removeQueries(['iamSmartGetProfile'])
            if (IASMProfileData === 'D40000' || IASMProfileData === 'D40001' || IASMProfileData === 'D50001') {
                enqueueSnackbar(t('myProfile.canceIamsmart'), { variant: 'error' })
            }
            if (IASMProfileData === 'D40002' || IASMProfileData === 'D50002') {
                enqueueSnackbar(t('myProfile.rejectIamsmart'), { variant: 'error' })
            }
            if (IASMProfileData === 'D40003' || IASMProfileData === 'D50003' || IASMProfileData === 'D40004') {
                enqueueSnackbar(t('myProfile.expiredIamsmart'), { variant: 'error' })
            }
            navigate('/userSettings/myProfile')
        }
    }, [isSuccess, IASMProfileData])
    useEffect(() => {
        if (
            !!tokenInfo &&
            !!profileData &&
            !!id &&
            !!IASMProfileData &&
            !(indexOf(IASMProfileData, IASMError) !== -1)
        ) {
            userBindMutation.mutate({
                openIdUUID: tokenInfo?.openID,
            })
        }
    }, [tokenInfo, profileData, id, IASMProfileData])

    return (
        <div className='bg-website-gradation h-full py-[60px]'>
            <Wrap
                className={`site-grid site-grid--support-medium w-[90%] mx-auto md:w-[462px] min-h-[297px] ${
                    isHorizontal ? 'isHorizontal' : ''
                }`}
            >
                <Typography variant='h5'>{t('myProfile.instructionTitle')}</Typography>

                <div className='py-3 px-5 bg-[#F9F9F9] text-black text-xs leading-4 font-[400]'>
                    <div>{t('myProfile.openIntro')}</div>
                    <div className='size-12 ml-4 my-1'>
                        <img alt='' src={tap} className='w-full h-full object-contain' />
                    </div>
                    <div>{t('myProfile.tapFill')}</div>
                    <div>{t('myProfile.tapAgree')}</div>
                </div>

                {/* <Button
                    variant='contained'
                    className='rounded-full normal-case mt-8'
                    fullWidth
                    disabled={!isGetProfile}
                    type='submit'
                    onClick={() => navigate('/userSettings')}
                >
                    {t('myProfile.continue')}
                </Button> */}
            </Wrap>
        </div>
    )
}
