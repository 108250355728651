import React, { useState } from 'react'
import tw from 'twin.macro'
import { Button, Chip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { map } from 'ramda'
import { useTranslation } from 'react-i18next'

import { useModalStore } from 'store/authAtom'
import AddInierest from 'page/UserSettings/EditMyProfile/components/AddInierest'

import { user } from 'service/userService'

const Wrap = tw.div`pt-5 border-secondary-grey border-solid border-t-[1px] text-secondary-midnightBlue`

export default function MyInterest({ profileData }) {
    const [isExpand, setIsExpand] = useState(false)
    const { setModalComponent, onOpen } = useModalStore()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { data: interestExist } = useQuery({
        queryKey: ['getInterest', profileData?.activityInterest, language],
        queryFn: () =>
            user.getInterest({
                lang: language,
                code: !!profileData?.activityInterest ? map((v) => v.interestCode, profileData?.activityInterest) : [],
            }),
        placeholderData: [],
    })

    return (
        <Wrap>
            <div
                onClick={() => setIsExpand(!isExpand)}
                className='flex cursor-pointer text-xl text-[#283266]  justify-between items-center active:opacity-50'
            >
                <div className='font-semiboldFamily'> {t('myProfile.myInterest')}</div>
                <div className={'text-base ' + (isExpand ? 'icon-arrow_up' : 'icon-arrow_down')} />
            </div>
            {isExpand && (
                <div className='text-base font-medium '>
                    <div className='pt-5'>{t('myProfile.whatDoUInterest')}</div>
                    {/* <Interest>Sport</Interest> */}
                    <div className='pt-4 flex gap-3 flex-wrap'>
                        {interestExist?.map((item, i) => (
                            <Chip
                                className='px-[20px] py-[10px] w-fit min-h-[34px] rounded-full text-body3'
                                label={item.name}
                                key={i}
                            />
                        ))}
                    </div>
                    <Button
                        onClick={() => {
                            onOpen()
                            setModalComponent(<AddInierest interestExist={profileData?.activityInterest ?? []} />)
                        }}
                        className='bg-white text-secondary-midnightBlue text-base leading-8 normal-case px-4 border-secondary-midnightBlue border-[1px] border-solid mt-6 md:mt-10'
                        variant='contained'
                        startIcon={<div className='icon-plus text-base w-4 leading-4 font-medium pr-2' />}
                        size='small'
                    >
                        {t('myProfile.add')}
                    </Button>
                </div>
            )}
        </Wrap>
    )
}
