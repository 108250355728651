import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import tw from 'twin.macro'

import ImageMap from 'components/detailComponents/ImageMap'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import MapBtn from 'components/detailComponents/MapBtn'

const Wrap = tw.div`z-10 relative max-w-[750px]`

export default function TopIntro({ items }) {
    const [isExpand, setIsExpand] = useState(false)
    const { t } = useTranslation()

    const formatDate = ({ Date }) => {
        return dayjs(Date).format('DD MMMM YYYY dddd')
    }

    return (
        <Wrap>
            <div className='pb-[32px] pt-8'>
                <NavigationPoint firstNav={t('visitList.venue')} lastNav={items.venueName} firstNavLink={'/venue'} />
                <div className='w-full py-8 text-4xl text-secondary-midnightBlue font-semiboldFamily'>
                    {items.venueName}
                </div>
                {(items?.specialRemark || items?.specialRemarkDescription) && (
                    <ExclusiveOffer
                      title={items.specialRemark}
                      about={items.specialRemarkDescription}
                      text={t('venue.showMore')}
                    />
                )}
                {items?.openHours.length > 0 && (
                    <div className='flex gap-2 pt-8 pb-6'>
                        <div className='w-1/5 text-base font-semiboldFamily'>{t('visitorTip.openingHour')}</div>
                        <div className='flex-1 text-base font-medium'>
                            {items.openHours.map((item, index) => {
                                return <div key={index}>{item.openHour}</div>
                            })}
                        </div>
                    </div>
                )}
                {items?.localhost && (
                    <div className='flex gap-2 pb-6'>
                        <div className='w-1/5 text-base font-semiboldFamily'>{t('venue.location')}</div>
                        <div className='flex-1 '>
                            <div className='text-base font-medium'>{items?.localhost}</div>
                            {(items?.localMap || items?.googleMapUrl) && (
                                <div
                                    className='flex items-center cursor-pointer leading-6 pt-[2px] gap-4'
                                    onClick={() => setIsExpand(!isExpand)}
                                >
                                    <div className='text-sm font-semiboldFamily  underline text-[#0000EE]'>
                                        {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                                    </div>
                                    <div className='text-secondary-midnightBlue'>
                                        {isExpand ? (
                                            <div className='icon-arrow_up' />
                                        ) : (
                                            <div className='icon-arrow_down' />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {isExpand && (
                    <div className='space-y-2'>
                        {items?.localMap && <ImageMap venueImage={items.localMap} />}
                        {items?.googleMapUrl && <MapBtn venueLocationUrl={items.googleMapUrl} />}
                    </div>
                )}
            </div>
        </Wrap>
    )
}
