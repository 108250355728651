import React, { useEffect, useRef, useState } from 'react'
import RemoveIcon from '@mui/icons-material/Remove'
import { Tab, Box, Tabs, Button, tabsClasses, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import 'swiper/css'

import SwiperCard from 'page/ConnectWithKTSP/HeritageMilestone/components/SwiperCard'
import dayjs from 'dayjs'
import { cn } from 'utils/cn'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper/modules'

function HeritageCard({
    isScroll,
    swiperRef,
    data,
    currentIndex,
    setCurrentIndex,
    titleMenuIndex,
    setTitleMenuIndex,
    historyType,
    setHistoryType,
}) {
    const { t } = useTranslation()
    const [isTitleScroll, setIsTitleScroll] = useState(false)
    const isPC = useMediaQuery('(min-width:768px)')
    const translateY =
        data.historyTitleMenu.length > 3 && titleMenuIndex <= data.historyTitleMenu.length - 1 ? titleMenuIndex * 88 : 0
    const timeTranslateY = currentIndex > 0 ? currentIndex * (isPC ? 157 : 112) : 0

    const isSameTime = (time, index) => {
        return (
            (currentIndex < index &&
                index > 0 &&
                time.detailedTimeline === data.timeOfhistories[index - 1].detailedTimeline &&
                ' hidden ') +
            (currentIndex > index &&
                index > 0 &&
                time.detailedTimeline === data.timeOfhistories[index + 1].detailedTimeline &&
                ' hidden ')
        )
    }

    // const swiperRef = useRef(null)

    const commonTimelineStyles =
        'absolute text-[22px] leading-[26px] right-[24px] text-right top-[50%] text-white -translate-y-[50%] w-[106px] md:w-[160px] break-words'

    return (
        <div>
            <div className='block pt-8 mb-8 md:mb-0 md:hidden'>
                <Tabs
                    indicatorColor='secondary'
                    value={titleMenuIndex}
                    scrollButtons={false}
                    variant='scrollable'
                    sx={{
                        [`& .${tabsClasses.indicator}`]: {
                            backgroundColor: '#fff', // 将indicatorColor设置为白色
                        },
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    {data.historyTitleMenu.length > 0 &&
                        data.historyTitleMenu.map((tab, index) => {
                            return (
                                <Tab
                                    className={'' + (index === 0 && 'pl-6 ')}
                                    key={index}
                                    label={
                                        <Box
                                            sx={{ fontSize: 16, fontWeight: 600, color: '#fff', textTransform: 'none' }}
                                        >
                                            {tab.title}
                                        </Box>
                                    }
                                    onClick={async () => {
                                        if (isTitleScroll) {
                                            return
                                        }
                                        setIsTitleScroll(true)
                                        historyType !== tab.type && setHistoryType(tab.type)
                                        setTitleMenuIndex(index)
                                        setCurrentIndex(tab.startIndex)
                                        await swiperRef.current.slideTo(tab.startIndex)
                                        setTimeout(() => setIsTitleScroll(false), 600)
                                    }}
                                />
                            )
                        })}
                </Tabs>
            </div>
            {data.historyTitleMenu.length > 0 && (
                <div className='flex justify-between bg-container'>
                    <div className='mt-8 w-36 h-[356px] overflow-y-clip hidden pt-[103px] md:block'>
                        <div
                            style={{
                                transform: ` translate(0, -${translateY}px)`,
                                transition: 'transform .4s ease-in-out',
                            }}
                            className='text-white font-semiboldFamily'
                        >
                            {data.historyTitleMenu.length > 0 &&
                                data.historyTitleMenu.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={async () => {
                                                if (isTitleScroll) {
                                                    return
                                                }
                                                setIsTitleScroll(true)
                                                historyType !== item.type && setHistoryType(item.type)
                                                setTitleMenuIndex(index)
                                                setCurrentIndex(item.startIndex)
                                                swiperRef.current.slideTo(item.startIndex)
                                                setTimeout(() => setIsTitleScroll(false), 600)
                                            }}
                                            className={
                                                'py-5 text-base h-[88px] ' +
                                                (titleMenuIndex !== index && 'opacity-50') +
                                                (isTitleScroll ? ' cursor-default' : ' cursor-pointer ')
                                            }
                                        >
                                            <div className='relative'>
                                                {titleMenuIndex === index &&
                                                    (item.type === 1 ? t('aboutUs.heriage') : t('aboutUs.milestones'))}
                                                {titleMenuIndex === index && (
                                                    <RemoveIcon className='absolute text-white left-0 -translate-x-[30px]' />
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    '' +
                                                    (titleMenuIndex === index &&
                                                        'text-[22px] line-clamp-2 leading-[26px]')
                                                }
                                            >
                                                {item.title}
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div className='overflow-y-hidden md:overflow-y-visible pl-[120px] pr-4 md:pr-8 md:pl-[186px] md:pt-[103px]'>
                        <div className='w-[2px] min-h-[1100px] md:-translate-y-[180px] relative'>
                            <div className='absolute top-0 left-0 right-0 h-[180px] bg-gradient-to-b from-transparent to-white z-30'></div>
                            <div className='absolute top-[180px] left-0 right-0 h-[1100px] bg-white z-30'></div>
                            <div
                                style={{
                                    transform: `translate(0, -${timeTranslateY}px)`,
                                    transition: 'transform .4s ease-in-out',
                                }}
                                className='w-[2px] flex flex-col items-center pt-[103px] md:pt-[180px]'
                            >
                                {data.timeOfhistories.length > 0 &&
                                    data.timeOfhistories.map((time, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (isTitleScroll) {
                                                        return
                                                    }
                                                    setIsTitleScroll(true)
                                                    setCurrentIndex(index)
                                                    setTitleMenuIndex(time.titleMenuIndex)
                                                    swiperRef.current.slideTo(index)
                                                    setTimeout(() => setIsTitleScroll(false), 600)
                                                    historyType !== time.type && setHistoryType(time.type)
                                                }}
                                                key={index}
                                                className={
                                                    'bg-white relative my-[52px] md:my-[74px] ' +
                                                    (currentIndex === index
                                                        ? 'w-[18px] h-[18px] rounded-[9px]'
                                                        : 'w-[9px] h-[9px] rounded-[4.5px]') +
                                                    (isTitleScroll ? ' cursor-default' : ' cursor-pointer ')
                                                }
                                            >
                                                {
                                                    <div
                                                        className={cn(
                                                            commonTimelineStyles,
                                                            currentIndex !== index && 'right-5',
                                                            currentIndex === index
                                                                ? isIncludeChinese(time.detailedTimeline)
                                                                    ? 'text-[28px] leading-[28px] md:text-[38px] md:leading-[44px]'
                                                                    : 'text-[28px] leading-[28px] md:text-[42px] md:leading-[50px]'
                                                                : 'opacity-50',
                                                        )}
                                                        style={{
                                                            opacity: currentIndex === index ? 1 : 0.5,
                                                        }}
                                                    >
                                                        <div className={isSameTime(time, index)}>
                                                            {time.type === 2
                                                                ? dayjs(time.detailedTimeline).format('YYYY')
                                                                : time.detailedTimeline}
                                                        </div>
                                                        {time.type === 2 && (
                                                            <div
                                                                className={
                                                                    'text-[22px] leading-[26px] text-white text-right ' +
                                                                    (currentIndex !== index && 'opacity-50 ') +
                                                                    isSameTime(time, index)
                                                                }
                                                            >
                                                                {dayjs(time.detailedTimeline).format('MM')}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    {data.timeOfhistories.length > 0 && (
                        <Swiper
                            direction={'vertical'}
                            slidesPerView={1}
                            spaceBetween={30}
                            mousewheel={true}
                            modules={[Mousewheel]}
                            className='h-screen w-full'
                            ref={swiperRef}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper
                            }}
                            onActiveIndexChange={(swiper) => {
                                if (isScroll || isTitleScroll) {
                                    return
                                }
                                setCurrentIndex(swiper.realIndex)
                                setTitleMenuIndex(data.timeOfhistories[swiper.realIndex].titleMenuIndex)
                                setHistoryType(data.timeOfhistories[swiper.realIndex].type)
                            }}
                            cssMode={true}
                        >
                            {data.timeOfhistories.map((content, index) => (
                                <SwiperSlide key={index}>
                                    <CurrentContent item={content} now={currentIndex} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                </div>
            )}
        </div>
    )
}

function CurrentContent({ item, now }) {
    return (
        <div className=' text-white space-y-6 overflow-y-auto overflow-x-hidden w-full h-[50vh] mt-[15px] md:h-[600px] flex-1'>
            <div className='text-4xl font-semiboldFamily text-wrap break-words'>{item.contentTitle}</div>
            <div className='textarea-content'>{item.contentDescription}</div>
            {item.milestoneImageRespDTOS && item.milestoneImageRespDTOS.length > 0 && (
                <SwiperCard yearArr={item.milestoneImageRespDTOS} now={now} />
            )}
        </div>
    )
}

const isIncludeChinese = (str) => /[\u4e00-\u9fa5]/.test(str)

export default HeritageCard
