import * as React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { IconButton } from '@mui/material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { dateFormat } from 'utils/util'
import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import dayjs from 'dayjs'
import { cn } from 'utils/cn'
import { dateRangeFormat } from 'utils/filter'
function CardNewsRoom({ card, isProductsList }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <Card
            onClick={() => navigate('/newsRoomDetail?id=' + card.identityUuid)}
            className={'relative w-full shadow-none cursor-pointer bg-transparent group rounded-none'}
        >
            <div className={isProductsList ? 'flex gap-4 lg:gap-10' : ''}>
                {/* {card.category && (
                    <div className='absolute text-white top-0 left-0 py-[6px] px-[15px] bg-primaryRed-ktRed text-xs md:text-sm]'>
                        {card.category}
                    </div>
                )} */}
                <div
                    className={cn(
                        CARD_BOX,
                        'aspect-4/3 h-full',
                        isProductsList ? 'w-[40%] md:w-[27%]' : 'w-full h-full',
                        // listType === 'calendar' && 'xl:w-[250px] xl:h-[210px]',
                    )}
                >
                    <CardMedia
                        component='img'
                        className='h-full w-full object-cover'
                        image={card.cardImage}
                        alt='Loading'
                    />
                    <RedLine />
                </div>
                <div className={isProductsList ? 'w-[54%] md:w-[70%] space-y-2' : ''}>
                    {card?.newsTitle && (
                        <div
                            className={cn(
                                'text-secondary-midnightBlue w-full break-words text-h5 ',
                                isProductsList ? 'pt-0' : 'pt-3',
                            )}
                        >
                            {card.newsTitle}
                        </div>
                    )}
                    {card.description && isProductsList && <DescriptionLabel description={card.description} />}
                    {card.releaseDate && (
                        <div className='flex gap-1 items-center font-medium mt-[10px] text-sm'>
                            {/* <IconButton className='p-0 '> */}
                            <div className='icon-date text-base text-secondary-midnightBlue' />
                            {/* </IconButton> */}
                            <div className='line-clamp-2'>{dateFormat(card.releaseDate, language)}</div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    )
}

export default CardNewsRoom
