import React, { useState, useMemo, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'
import { useOrientation } from 'react-use'

import iamsmartImg from 'resources/images/iamsmart.png'
import redDot from 'resources/images/redDot.png'
import iamsmartLogo from 'resources/images/iamsmartLogo.png'
import { iamsmart } from 'service/iasmMartService'
import { useIASMStore } from 'store/authAtom'

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#2B7366',
    '&:hover': {
        backgroundColor: '#2B7366',
    },
}))
const Wrap = tw.div`py-[42px] my-6 bg-white shadow-lg space-y-6 px-5 md:px-10 lg:px-[60px]`

export default function ConsentPage() {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [tokenInfo, setTokenInfo] = useState(useIASMStore((state) => state.tokenInfo))

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const initiateRequestMutation = useMutation({
        mutationFn: iamsmart.initiateRequest,
        onSuccess: (v) => {
            navigate('/userSettings/instructionPage?id=' + v?.businessID)
            window.localStorage.removeItem('tokenInfo')
            // enqueueSnackbar(t('NoRecordFound.Authorize'), { variant: 'success' })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    // const {
    //     data: initiateRequestData,
    //     isFetching: initiateRequestDataIsPending,
    //     isSuccess: initiateRequestDataIsSuccess,
    // } = useQuery({
    //     queryKey: ['iasmInitiateRequest'],
    //     queryFn: () =>
    //         iamsmart.initiateRequest({
    //             accessToken: tokenInfo?.accessToken,
    //             lang: language,
    //             openId: tokenInfo?.openID,
    //         }),
    //     enabled: !!tokenInfo && !!noBound,
    // })
    const handleNewUser = () => {
        initiateRequestMutation.mutate({
            accessToken: tokenInfo?.accessToken,
            lang: language,
            openId: tokenInfo?.openID,
        })
    }
    const datas = [t('SignUpPage.EnglishNameHint'), t('SignUpPage.ChineseNameHint'), t('SignUpPage.DateOfBirthDisplay')]
    useEffect(() => {
        if (tokenInfo) {
            window.localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
        } else if (!tokenInfo && window.localStorage.getItem('tokenInfo')) {
            setTokenInfo(JSON.parse(window.localStorage.getItem('tokenInfo')))
        } else {
            navigate('/userSettings')
        }
    }, [])
    return (
        <div className='bg-website-gradation overflow-auto  h-full py-[60px]'>
            <Wrap className={`w-[90%] mx-auto md:w-[462px] min-h-[297px] ${isHorizontal ? 'isHorizontal' : ''}`}>
                <Typography variant='h5'>{t('myProfile.howDoesItWork')}</Typography>
                <div className='my-6 w-[184px] h-[93px] mx-auto'>
                    <img alt='iamsmart' src={iamsmartLogo} className='w-full h-full object-contain' />
                </div>
                <div className='py-3 px-5 bg-[#F9F9F9] text-black text-xs leading-4 font-[400]'>
                    <div>{t('myProfile.content')}</div>
                    <div className='py-4'>{t('myProfile.bomContent')}</div>
                    {datas.map((data, index) => {
                        return (
                            <div className='text-sm font-[500] flex gap-[10px]' key={index}>
                                <img alt='' src={redDot} className='object-contain' />
                                <span>{data}</span>
                            </div>
                        )
                    })}
                    <div className='pt-4'>{t('myProfile.pleaseNote')}</div>
                </div>
                <ColorButton
                    variant='contained'
                    size='large'
                    className='rounded-full text-lg normal-case h-fit mt-8 mb-3'
                    fullWidth
                    color='success'
                    onClick={handleNewUser}
                    startIcon={<img alt='' className='w-5 h-5 object-contain mr-1' src={iamsmartImg} />}
                >
                    {t('myProfile.smart')}
                </ColorButton>
            </Wrap>
        </div>
    )
}
