import React from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'

function NavigationPoint({ firstNav, lastNav, firstNavLink, color }) {
    const navigate = useNavigate()
    return (
        <span
            className={cn(
                'text-secondary-midnightBlue text-sm font-semiboldFamily flex flex-wrap w-full',
                color && color,
            )}
        >
            <span
                onClick={() => {
                    firstNavLink && navigate(firstNavLink)
                }}
                className='cursor-pointer underline active:opacity-50 flex'
            >
                {firstNav}
            </span>

            <span className='w-6 grid place-items-center'>
                <span className='icon-arrow_right' />
            </span>

            <span className='[overflow-wrap:anywhere]'>{lastNav}</span>
        </span>
    )
}

export default NavigationPoint
