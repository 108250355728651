import React from 'react'
import { useTranslation } from 'react-i18next'

import SimpleSwiper from 'components/swiperComponent/SimpleSwiper'
import Content from 'components/Content'

export default function MissionVision({ description, swiperList }) {
    const { t } = useTranslation()

    return (
        <div className='w-full md:bg-messionViseionBg bg-no-repeat bg-[length:100%_1180px] text-secondary-midnightBlue'>
            <div className='bg-container pb-10 lg:min-h-[700px]'>
                <div className='text-h3 pb-8 pt-[100px] md:pt-[222px]'>{t('aboutUs.missionVision')}</div>
                <Content
                    data={description}
                    className='text-[21px] md:text-[24px] leading-[150%] font-normal w-full max-w-[942px]'
                />
            </div>
            {swiperList && swiperList.length > 0 && (
                <SimpleSwiper
                    title={t('aboutUs.ourValues')}
                    titleClassName='text-h3'
                    list={
                        swiperList?.map((item) => ({
                            ...item,
                            title: item.webTitle,
                            image: item.webVisual,
                            description: item.description,
                        })) || []
                    }
                />
            )}
        </div>
    )
}
