import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import Sticky from 'react-stickynode'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

const Wrap = tw.div`bg-slate-100 w-[200px] lg:w-[300px]`
const TopBox = tw.div` bg-white  text-secondary-midnightBlue border-b-2 border-solid border-secondary-grey px-5 pt-8 pb-6 md:px-[30px] lg:px-[60px]`
const BomBox = tw.div` bg-white text-secondary-midnightBlue text-base pb-4 pl-5 md:pl-[30px] lg:pl-[60px]`

const Label = ({ item, index, keyLabel, fun }) => {
    return (
        <div
            onClick={() => {
                fun()
            }}
            className={
                'mt-6 cursor-pointer flex gap-[30px] max-w-full break-words border-solid border-primaryRed-ktRed ' +
                (keyLabel === item ? 'text-primaryRed-ktRed font-semiboldFamily border-r-[3px]' : '')
            }
        >
            <div className='pr-5 w-full break-words md:pr-[30px] lg:pr-[60px]'>{item.title}</div>
        </div>
    )
}
function Aside({ datas, isHidden, keyLabel, setKeyLabel, onClose }) {
    const { t } = useTranslation()
    const nav = useNavigate()

    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')

    useEffect(() => {
        if (id && datas) {
            const activeItem = [...(datas.careerList || []), ...(datas.jobList || [])].find(
                (item) => item.identityUuid === id,
            )
            if (activeItem) {
                setKeyLabel(activeItem)
            }
        }
    }, [id, datas, setKeyLabel])

    return (
        <Wrap className={isHidden ? ' hidden lg:block' : ''}>
            <Sticky
                top='#main-header'
                bottomBoundary='#work-with-us-content'
                activeClass={isHidden ? 'bg-slate-100' : 'bg-white'}
                innerClass='z-40'
            >
                <div className='bg-white'>
                    <TopBox className='font-semiboldFamily'>
                        <div className='text-[22px] leading-[26.4px]'>{t('workWithUs.teamIntroduction')}</div>
                    </TopBox>
                    <BomBox className='font-medium'>
                        {datas?.careerList &&
                            datas?.careerList.length > 0 &&
                            datas?.careerList.map((item, index) => {
                                return (
                                    <Label
                                        item={item}
                                        index={index}
                                        keyLabel={keyLabel}
                                        key={index}
                                        fun={() => {
                                            setKeyLabel(item)
                                            onClose && onClose()
                                            nav(`/workWithUsDetails?id=${item.identityUuid}`)
                                        }}
                                    />
                                )
                            })}
                    </BomBox>
                </div>
                <div className='mt-2 bg-white'>
                    <TopBox className='py-4 font-semiboldFamily'>
                        <div className='text-[22px] leading-[26.4px]'>{t('workWithUs.jobType')}</div>
                    </TopBox>
                    <BomBox>
                        {datas?.jobList &&
                            datas.jobList.length > 0 &&
                            datas.jobList.map((item, index) => {
                                return (
                                    <Label
                                        item={item}
                                        index={index + 4}
                                        keyLabel={keyLabel}
                                        key={index}
                                        fun={() => {
                                            setKeyLabel(item)
                                            onClose && onClose()
                                            nav(`/workWithUsDetails?id=${item.identityUuid}`)
                                        }}
                                    />
                                )
                            })}
                    </BomBox>
                </div>
            </Sticky>
        </Wrap>
    )
}

export default Aside
