import { http } from 'utils/http'

export const information = {
    getConnectWithKtsp: (payload) => http.post('/information/getConnectWithKtsp', payload), //
    getJobPositionList: (payload) => http.post('/information/getJobPositionList', payload), //work with us
    getMeetOurTeam: (payload) => http.post('/information/getMeetOurTeam', payload), //about us
    getMoreSports: (payload) => http.post('/information/getMoreSports', payload), //about us
    getMissionAndVision: (payload) => http.post('/information/getMissionAndVision', payload), //about us
    getNewsHeroBanners: (payload) => http.post('/information/getNewsHeroBanners', payload),
    getNewsList: (payload) => http.post('/information/getNewsList', payload),
    getOurValues: (payload) => http.post('/information/getOurValues', payload), //about us
    getSeeMore: (payload) => http.post('/information/getSeeMore', payload), //about us
    getTeamIntroductionList: (payload) => http.post('/information/getTeamIntroductionList', payload), //work with us
    getWhyKtspList: (payload) => http.post('/information/getWhyKtspList', payload), //work with us
    getWorkWithKtspContentList: (payload) => http.post('/information/getWorkWithKtspContentList', payload), //work with us
    getWorkWithKtspJobList: (payload) => http.post('/information/getWorkWithKtspJobList', payload), //work with us
    getFootFaqTabList: (payload) => http.post('/information/getFootFaqTabList?lang=' + payload),
    getFootFaqList: (payload) => http.post('/information/getFootFaqList', payload),
    getFootFaqSearch: (payload) => http.post('/information/faqSearch', payload),
    getAdditionalInformation: (payload) => http.post('/information/getAdditionalInformation', payload),
    seeMoreContactUs: (payload) => http.post('/information/seeMoreContactUs', payload),
    getOurPartners: (payload) => http.post('/information/getOurPartners', payload),
    downloadTransport: (payload) => http.post('/information/downloadTransport', payload),
    getTimeLine: (payload) => http.post('/information/getTimeLine', payload),
    getWorkWithKtsp: (payload) => http.post('/information/getWorkWithKtsp', payload),
    aboutKtsp: (payload) => http.post('/information/aboutKtsp', payload),
    workWithUs: (payload) => http.post('/information/workWithUs', payload),
    getOpenHour: (payload) => http.post('/information/getOpenHour', payload),
}
export const newsroomService = {
    getHeroBanners: (payload) => http.post('/newsroom/getHeroBanners', payload),
    getFilterInfo: (payload) => http.post('/newsroom/getFilterInfo', payload),
    getList: (payload) => http.post('/newsroom/getList', payload),
    getDetail: (payload) => http.post('/newsroom/getDetail', payload),
}
