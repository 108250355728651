import React, { useState } from 'react'
import { Tab, Box, Tabs, Chip } from '@mui/material'
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'

import ViewMore from 'components/detailComponents/ViewMore'
import Content from 'components/Content'

const Wrap = tw.div`pt-8 sm:w-[90%] lg:w-[70%]`

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
`

export default function TabsAbout({ items }) {
    const { t } = useTranslation()
    const [isExpand, setIsExpand] = useState(false)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const tabsTitles = [t('venue.overview'), t('venue.whatToExpect')]
    // const amenities = [
    //     {
    //         icon: <HeadphonesOutlinedIcon className='text-2xl text-secondary-midnightBlue' />,
    //         about: 'Audio Visual',
    //     },
    // ]
    // const generatedAmenities = Array.from({ length: 8 }, () => amenities[0])

    return (
        <Wrap>
            <Tabs
                textColor='secondary'
                indicatorColor='secondary'
                value={0}
                className='border-solid border-b-[1px] border-secondary-darkGery'
                onChange={handleChange}
                aria-label='basic tabs example'
            >
                {tabsTitles.map((tabTitle, index) => {
                    return (
                        <Tab
                            key={index}
                            className={index === 0 ? 'pl-0' : ''}
                            label={<Box sx={{ fontSize: 16, fontWeight: 600, textTransform: 'none' }}>{tabTitle}</Box>}
                            onClick={() => {
                                setValue(index)
                                index === 1 && setIsExpand(!isExpand)
                            }}
                        />
                    )
                })}
            </Tabs>
            <div className='pt-8 text-secondary-midnightBlue'>
                {/* <div className='pb-4 text-2xl font-semiboldFamily'>More about Main Stadium</div> */}
                <Content data={items.description} className='text-base font-medium' />
                {isExpand && (
                    <div>
                        <div className='py-6 text-xl font-semiboldFamily'>{t('venue.amenities')}</div>
                        <div className='grid grid-cols-4 gap-6'>
                            {items?.amenitiesList?.map((item) => {
                                return (
                                    <div className='flex gap-[10px] items-center'>
                                        {item?.iconUrl}
                                        <div className='text-sm font-medium'>{item?.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                <ViewMore setIsExpand={setIsExpand} isExpand={isExpand} />
            </div>
            <Box className='mt-12 space-x-3'>
                {items?.tagList?.map((item) => (
                    <ChipWrap key={item.identityUuid} label={item.name} variant='outlined' />
                ))}
            </Box>
        </Wrap>
    )
}
