import React, { useEffect, useMemo, useState } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import dayjs from 'dayjs'
import { useInfiniteQuery, useQueryClient, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import TitleSearchComponent from 'components/shopDine/allProductsComponents/TitleSearchComponent'
import PaginationButton from 'components/PaginationButton'
import { filterName, newIdArray, formatStartDateEndDate, datesSelect } from 'utils/filter'
import { eventServices } from 'service/eventService'
import EventCard from 'page/EventsTickets/components/EventCard'
import FilterModal from 'components/shopDine/allProductsComponents/FilterModal'
import Tag from 'components/shopDine/allProductsComponents/Tag'
import TypeMenu from 'components/shopDine/allProductsComponents/TypeMenu'
import TypeDate from 'components/TypeDate'
import { useDisclosure } from 'utils/useDisclosure'
import Calendar from 'page/EventsTickets/components/Calender'
import Loading from 'components/Loading'
function AllPromotion({ name, isFilter, firstTypeName, secondTypeName }) {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const queryClient = useQueryClient()
    const [typesSelected, setTypesSelected] = useState([])
    const [venuesSelected, setVenuesSelected] = useState([])
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [listType, setListType] = useState(true)
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const dates = datesSelect()
    const [dateType, setDateType] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const formatStartDate = startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : null
    const formatEndDate = endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : null
    const formatDate = useMemo(() => {
        return formatStartDateEndDate(startDate ?? endDate, endDate ?? startDate, language)
    }, [startDate, endDate, language])
    const selectedCategories = useMemo(() => {
        return [...typesSelected, ...venuesSelected]
    }, [typesSelected, venuesSelected])
    const { data: types } = useQuery({
        queryKey: ['getTypesList'],
        queryFn: () => eventServices.getEventFilterList({ lang: language, type: 'type' }),
    })
    const { data: venues } = useQuery({
        queryKey: ['getVenuessList'],
        queryFn: () => eventServices.getEventFilterList({ lang: language, type: 'venue' }),
    })
    const getEventConditions = {
        endDate: formatEndDate ? formatEndDate + ' 23:59:59' : null,
        startDate: formatStartDate ? formatStartDate + ' 00:00:00' : null,
        lang: language,
        range: dateType && dateType?.val !== 'flexibleDate' ? dateType.val : null,
        searchText: searchVal.trim(),
        type: newIdArray(typesSelected),
        venue: newIdArray(venuesSelected),
        maxRecord: 20,
    }
    const { data, fetchNextPage, refetch, isFetching, isPending } = useInfiniteQuery({
        queryKey: ['eventsList', startDate, endDate, language, dateType, typesSelected, venuesSelected], // key
        queryFn: ({ pageParam = 0 }) => eventServices.getEventList({ ...getEventConditions, startRow: pageParam }),
        getNextPageParam: (lastPage, pages) => {
            return pages.length * 20
        },
    })

    const hasFilter = useMemo(() => {
        return typesSelected.length > 0 || venuesSelected.length > 0 || dateType || startDate || endDate
    }, [typesSelected, venuesSelected, dateType, startDate, endDate])
    useEffect(() => {
        refetch()
    }, [])

    return (
        <div className='bg-white pt-16 bg-container'>
            <FilterModal
                firstTypeName={firstTypeName}
                secondTypeName={secondTypeName}
                venues={venues ?? []}
                types={types ?? []}
                typesSelected={typesSelected}
                setTypesSelected={setTypesSelected}
                venuesSelected={venuesSelected}
                setVenuesSelected={setVenuesSelected}
                open={isOpen}
                handleClose={onClose}
                dateType={dateType}
                setDateType={setDateType}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dates={dates}
            />
            <TitleSearchComponent
                name={name}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                onBlurFun={() => !isFetching && refetch()}
                setIsSearch={setIsSearch}
            />
            <div className='flex justify-between items-center'>
                <div className='pt-[24px] flex flex-wrap items-center gap-[10px]'>
                    <div className='hidden md:flex md:flex-wrap md:gap-[10px] md:items-center'>
                        <TypeMenu
                            name={firstTypeName}
                            num={typesSelected.length}
                            labels={types ?? []}
                            typesSelected={typesSelected}
                            setTypesSelected={setTypesSelected}
                        />
                        <TypeDate
                            dateType={dateType}
                            setDateType={setDateType}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            dates={dates}
                        />
                        <TypeMenu
                            name={secondTypeName}
                            num={venuesSelected.length}
                            labels={venues ?? []}
                            typesSelected={venuesSelected}
                            setTypesSelected={setVenuesSelected}
                        />
                    </div>
                    {isFilter && (
                        <div
                            onClick={() => onOpen()}
                            className='md:pl-[10px] md:border-secondary-midnightBlue md:border-solid md:border-l-[1px]'
                        >
                            <div className='w-[160px] py-[6px] pl-[16px] pr-[10px] flex justify-between items-center cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px]'>
                                <div className='text-base font-medium'>{t('eventPage.allFilter')}</div>
                                <div className='flex items-center'>
                                    <div className='icon-filter font-bold' />
                                </div>
                            </div>
                        </div>
                    )}
                    {hasFilter && (
                        <div
                            onClick={() => {
                                setTypesSelected([])
                                setVenuesSelected([])
                                dateType && setDateType(null)
                                startDate && setStartDate(null)
                                endDate && setEndDate(null)
                            }}
                            className='text-base font-semiboldFamily text-[#0A173D] cursor-pointer underline'
                        >
                            {t('eventPage.reset')}
                        </div>
                    )}
                </div>
                <div className='text-lg text-secondary-grey flex items-center gap-2 cursor-pointer'>
                    <div onClick={() => setListType(true)} className={listType && 'text-secondary-midnightBlue'}>
                        <GridOnOutlinedIcon />
                    </div>
                    <div onClick={() => setListType(false)} className={!listType ? 'text-secondary-midnightBlue' : ''}>
                        <FormatListBulletedIcon />
                    </div>
                    {/* <div
                        onClick={() => setListType('calendar')}
                        className={listType === 'calendar' ? 'text-secondary-midnightBlue' : ''}
                    >
                        <CalendarTodayOutlinedIcon />
                    </div> */}
                </div>
            </div>
            {hasFilter && (
                <>
                    <div className='flex gap-2 flex-wrap mt-6'>
                        {selectedCategories.length > 0 &&
                            selectedCategories.map((item, index) => {
                                return (
                                    <Tag
                                        key={index}
                                        text={filterName(item, language)}
                                        fun={() => {
                                            if (
                                                types.some(
                                                    (itemType) =>
                                                        itemType.id === item.id &&
                                                        itemType.nameEn === item.nameEn &&
                                                        itemType.nameZh === item.nameZh &&
                                                        itemType.nameCn === item.nameCn,
                                                )
                                            ) {
                                                const arr = typesSelected.filter((val) => val !== item)
                                                setTypesSelected([...arr])
                                            } else {
                                                const arr = venuesSelected.filter((val) => val !== item)
                                                setVenuesSelected([...arr])
                                            }
                                        }}
                                    />
                                )
                            })}
                        {dateType && dateType.val !== 'flexibleDate' && (
                            <Tag text={t(dateType.about)} fun={() => setDateType('')} />
                        )}
                        {formatDate && (
                            <Tag
                                text={formatDate}
                                fun={() => {
                                    setStartDate(null)
                                    setEndDate(null)
                                    setDateType('')
                                }}
                            />
                        )}
                    </div>
                    <div className='text-h4 mt-6 text-secondary-midnightBlue'>
                        {isSearch ? data?.pages[0]?.allDataSize ?? 0 : 0}&nbsp;
                        {t('eventPage.resultsFound')}
                    </div>
                </>
            )}

            {isPending && <Loading />}
            <div className='mt-4 md:flex-row md:mt-8 lg:mt-[60px]'>
                <div
                    className={
                        listType ? 'grid gap-x-5 gap-y-8 grid-cols-2 md:grid-cols-3 lg:gap-y-16 xl:grid-cols-4 ' : ''
                    }
                >
                    {isSearch &&
                        data &&
                        data?.pages.map((page) => {
                            return page.data.map((card, i) => {
                                return <EventCard index={i} key={card + i} listType={listType} card={card} />
                            })
                        })}
                </div>
                {/* {listType === 'calendar' && (
                    <div className='w-[30%]'>
                        <Calendar
                            markedDatesAll={[
                                '2024-05-11',
                                '2024-05-11',
                                '2024-05-20',
                                '2024-05-21',
                                '2024-06-21',
                                '2025-01-21',
                                '2024-08-09',
                            ]}
                        />
                    </div>
                )} */}
            </div>
            {isSearch && (
                <PaginationButton
                    isDisabled={
                        data?.pages.reduce((acc, page) => acc + page.data.length, 0) === data?.pages[0]?.allDataSize
                    }
                    fun={() => {
                        fetchNextPage()
                    }}
                    nowCardsLength={data?.pages.reduce((acc, page) => acc + page.data.length, 0) ?? 0}
                    allCardsLength={data?.pages[0]?.allDataSize ?? 0}
                />
            )}
        </div>
    )
}

export default AllPromotion
