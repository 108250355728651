import React from 'react'
import { useTranslation } from 'react-i18next'

import ImageMap from 'components/detailComponents/ImageMap'
import Content from 'components/Content'

function SeatingPlanSection({ items }) {
    const { t } = useTranslation()
    const { dynamicContent, seatingFloorPlan, seatingFloorPlanText } = items
    return (
        <div className=' text-secondary-midnightBlue'>
            {(seatingFloorPlan || seatingFloorPlanText) && (
                <div className='text-h4 pb-6'>{t('eventPage.seatingPlan')}</div>
            )}
            {seatingFloorPlan && <ImageMap venueImage={seatingFloorPlan} />}
            <Content className='mt-6 detail-page-text' data={seatingFloorPlanText} />
        </div>
    )
}

export default SeatingPlanSection
