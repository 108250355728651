import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { landing } from 'service/landingService'
import { useSpacialTrafficNews } from 'store/authAtom'
import BannerSwiper from 'components/landingComponents/BannerSwiper'
import MagicSwiper from 'components/landingComponents/MagicSwiper'
import FeaturedSection from 'components/landingComponents/FeaturedSection'
import ExploreShopDine from 'components/landingComponents/ExploreShopDine'
import DoublePhotoSwiper from 'components/landingComponents/DoublePhotoSwiper'
import ItinerariesSwiper from 'components/landingComponents/ItinerariesSwiper'
import ImmersiveExperiences from 'components/landingComponents/ImmersiveExperiences'
import CreateCheRishedMemories from 'components/landingComponents/CreateCheRishedMemories'
import AnUrbanOasis from 'components/landingComponents/AnUrbanOasis'
import DownApp from 'components/landingComponents/DownApp'
import Loading from 'components/Loading'
function Landing() {
    const { enqueueSnackbar } = useSnackbar()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { setData } = useSpacialTrafficNews()
    const { data, isPending } = useQuery({
        queryKey: ['landingPage', language],
        queryFn: () =>
            landing.getLandingPage({
                lang: language,
                platform: 2,
                size: 0,
                tagSize: 6,
                type: '',
            }),
    })
    useEffect(() => {
        if (data && data?.specialAnnouncement && data?.specialAnnouncement.length > 0) {
            setData(data?.specialAnnouncement[0])
        }
    }, [data])
    if (isPending)
        return (
            <div className='flex justify-center items-center h-screen'>
                <Loading />
            </div>
        )

    return (
        <div className='bg-white'>
            <section>
                {data?.heroBannerList && data?.heroBannerList.length > 0 && (
                    <BannerSwiper banners={data?.heroBannerList} />
                )}
            </section>
            <section>
                {data?.topAdvertisements && data?.topAdvertisements.length > 0 && (
                    <MagicSwiper items={data?.topAdvertisements} />
                )}
            </section>
            <section>
                {((data?.eventRespDTOList && data?.eventRespDTOList.length > 0) ||
                    (data?.landingRecommendListRespDTOList && data?.landingRecommendListRespDTOList.length > 0) ||
                    (data?.programmeListRespDTOList && data?.programmeListRespDTOList.length > 0)) && (
                    <FeaturedSection
                        events={data?.eventRespDTOList}
                        recommends={data?.landingRecommendListRespDTOList}
                        programmes={data?.programmeListRespDTOList}
                    />
                )}
            </section>
            <section>
                <ExploreShopDine />
            </section>
            <section>
                {data?.advertisements && data?.advertisements.length > 0 && (
                    <DoublePhotoSwiper items={data?.advertisements} />
                )}
            </section>
            {/* <section>
                <ItinerariesSwiper />
            </section> */}
            {/* <section>
                <ImmersiveExperiences />
            </section> */}
            <section>{data?.tag && data?.tag.length > 0 && <CreateCheRishedMemories tags={data?.tag} />}</section>
            <section>
                <AnUrbanOasis />
            </section>
            <section>
                <DownApp />
            </section>
        </div>
    )
}

export default Landing
