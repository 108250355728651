import React from 'react'
import { useTranslation } from 'react-i18next'

import EventsCards from 'page/Venue/components/EventsCards'
import ShopCard from 'page/ShopDine/components/ShopCard'
import RestaurantCard from 'page/Restaurants/components/RestaurantCard'

function ShopDineCards({ items = [] }) {
    const { t } = useTranslation()
    return (
        <div>
            {items.shopList.length > 0 && (
                <EventsCards seeMoreUrl='/shops' CardType={ShopCard} title='landing.shop' items={items.shopList} />
            )}
            {items.dineList.length > 0 && (
                <EventsCards
                    seeMoreUrl='/restaurants'
                    title='landing.dine'
                    CardType={RestaurantCard}
                    items={items.dineList}
                />
            )}
        </div>
    )
}

export default ShopDineCards
