import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Loading from 'components/Loading'
import { useDisclosure } from 'utils/useDisclosure'
import { visitorTipApi } from 'service/visitorTipService'
import MotalOpeningHour from 'page/VisitorTips/components/MotalOpeningHour'
import btn from 'resources/images/withIcon.png'
import map from 'resources/images/openingHour.png'
import PaginationButton from 'components/PaginationButton'
import Content from 'components/Content'
const MainTitle = tw.div`text-[28px] leading-[28px]`
const Labels = ({ mainTitle, title, time }) => {
    const { t } = useTranslation()
    const times = JSON.parse(time)
    return (
        <div>
            <div className='text-lg font-semiboldFamily'>{mainTitle}</div>
            <div className='flex justify-between flex-col md:flex-row'>
                <div className='text-base font-medium w-[40%]'>{t('visitorTip.openingHour')}</div>

                <div className='pt-2 md:pt-0 flex-1 self-start'>
                    {times.length > 0 &&
                        times.map((item, index) => {
                            return (
                                <div key={index} className='text-base font-medium whitespace-nowrap'>
                                    {item.label}
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
const Label = tw.div`py-5 flex justify-between cursor-pointer items-center active:opacity-50`
function MothodLabel({ item }) {
    const [isExpand, setExpand] = useState(false)
    return (
        <div className='border-solid border-b-[1px] border-secondary-grey '>
            <Label onClick={() => setExpand(!isExpand)}>
                <div>
                    <div className='font-semiboldFamily text-xl '>{item.title}</div>
                    {item.startDate && (
                        <div className='text-sm font-medium'>{dayjs(item.startDate).format('DD.MM.YYYY')}</div>
                    )}
                </div>
                {!isExpand ? <AddIcon /> : <RemoveIcon />}
            </Label>
            {isExpand && (
                <Content
                    data={item.description}
                    className='pb-5 text-sm font-medium'
                />
            )}
        </div>
    )
}
function OpeningHour() {
    const [items, setItems] = useState([])
    const [specialAnnouncementLables, setSpecialAnnouncementLables] = useState([])
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [page, setPage] = useState(1)
    const [hasMoreData, setHasMoreData] = useState(true)
    const { onClose, onOpen, isOpen } = useDisclosure()
    const getOpeningHour = useMutation({
        mutationFn: visitorTipApi.getOpeningHour,
        onSuccess: (res) => {
            setItems(res)
        },
        onError: (e) => {
            console.log(e)
        },
    })
    const getSpecialAnnouncement = useMutation({
        mutationFn: visitorTipApi.getSpecialAnnouncement,
        onSuccess: (res) => {
            setSpecialAnnouncementLables(res)
            res.length < 50 ? setHasMoreData(false) : setHasMoreData(true)
        },
        onError: (e) => {
            console.log(e)
        },
    })
    const getSpecialAnnouncementPagination = useMutation({
        mutationFn: visitorTipApi.getSpecialAnnouncement,
        onSuccess: (res) => {
            setSpecialAnnouncementLables(res)
            res.length < 50 ? setHasMoreData(false) : setHasMoreData(true)
            res.length >= 50 && setPage(page + 1)
        },
        onError: (e) => {
            console.log(e)
        },
    })
    const getSpecialAnnouncementPaginationMethod = (page) => {
        if (getSpecialAnnouncementPagination.isPending || getSpecialAnnouncement.isPending || !hasMoreData) {
            return
        }
        getSpecialAnnouncementPagination.mutate({ lang: language, limit: 10, offset: page, type: 0 })
    }
    useEffect(() => {
        getOpeningHour.mutate({ lang: language, limit: 10, offset: 0, type: 0 })
        getSpecialAnnouncement.mutate({ lang: language, limit: 50, offset: 0, type: 0 })
    }, [language])
    return (
        <div className='text-secondary-midnightBlue'>
            {items.length > 0 && <MotalOpeningHour items={items} onClose={onClose} isOpen={isOpen} image={map} />}
            {getOpeningHour.isPending ? (
                <Loading />
            ) : (
                <>
                    {items.length > 0 && (
                        <div className='flex w-full h-fit flex-col gap-11 lg:gap-2 lg:flex-row '>
                            <div className='relative w-full h-[251px] lg:w-1/2 md:h-[515px] lg:h-[344px]'>
                                <img alt='' src={map} className='w-full object-cover h-full' />
                                <img
                                    onClick={() => onOpen()}
                                    alt=''
                                    src={btn}
                                    className='w-[97px] h-8 object-contain cursor-pointer absolute bottom-3 right-3'
                                />
                            </div>
                            <div className='flex flex-col px-5 text-secondary-midnightBlue w-full lg:w-3/5'>
                                <div className='w-4/5'>
                                    {/* <MainTitle className='pb-3'>{t('visitorTip.kaiMainStadium')}</MainTitle> */}
                                    {/* <About className='pb-4'>{t('visitorTip.majorVenueAbout')}</About> */}
                                    {items.length > 0 && (
                                        <div className='space-y-8'>
                                            {items.map((item, index) => {
                                                return (
                                                    <Labels
                                                        key={index}
                                                        mainTitle={item.title}
                                                        time={item.description}
                                                    />
                                                )
                                            })}
                                            {/* <Labels mainTitle={t('visitorTip.kaiMainStadium')} time={items[0].description} />
                                    <Labels mainTitle={t('visitorTip.kaiArena')} time={items[1].description} />
                                    <Labels mainTitle={t('visitorTip.kaiMall')} time={items[2].description} />
                                    <Labels
                                        mainTitle={t('visitorTip.kaiYouthSportsGround')}
                                        time={items[3].description}
                                    /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {specialAnnouncementLables.length > 0 && (
                <div className='font-semiboldFamily mt-12 mb-7 text-start uppercase text-[28px] md:text-4xl'>
                    {t('visitorTip.specialAnnounment')}
                </div>
            )}
            {getSpecialAnnouncement.isPending ? (
                <Loading />
            ) : (
                <div>
                    {specialAnnouncementLables.length > 0 &&
                        specialAnnouncementLables.map((item, index) => {
                            return <MothodLabel key={index} item={item} />
                        })}
                    <PaginationButton
                        isDisabled={!hasMoreData}
                        fun={() => {
                            getSpecialAnnouncementPaginationMethod(page)
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default OpeningHour
