import React, { useState, useRef } from 'react'
import { Tab, Tabs, tabsClasses } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { filterName } from 'utils/filter'
import SearchInput from 'components/SearchInput'
function TabSearch({ searchVal, setSearchVal, onBlurFun, tabTitles, setTabVal, setIsSearch }) {
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const {
        i18n: { language },
        t,
    } = useTranslation()

    return (
        <div className='border-solid border-b-[1px] border-[#0A173D] flex flex-col-reverse gap-4 lg:flex-row lg:justify-between '>
            <Tabs
                textColor='secondary'
                indicatorColor='secondary'
                TabIndicatorProps={{
                    sx: {
                        height: '4px',
                        borderTopRightRadius: '4px',
                        backgroundColor: 'secondary',
                    },
                }}
                value={value}
                scrollButtons
                allowScrollButtonsMobile
                variant='scrollable'
                onChange={handleChange}
                className='w-full lg:max-w-[70%]'
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { display: 'none' },
                    },
                }}
                aria-label='scrollable auto tabs example'
            >
                {tabTitles?.length > 0 &&
                    tabTitles.map((tab, index) => {
                        return (
                            <Tab
                                key={index}
                                sx={{
                                    fontWeight: value === index ? 600 : 500,
                                    textTransform: 'none',
                                    alignItems: 'flex-start',
                                    paddingX: 0,
                                    marginRight: '50px',
                                    minWidth: '56px',
                                }}
                                label={
                                    <div className='text-base text-secondary-midnightBlue normal-case'>
                                        {filterName(tab, language)}
                                    </div>
                                }
                                onClick={() => {
                                    setTabVal(tab.vbsRefId)
                                }}
                            />
                        )
                    })}
            </Tabs>
            <SearchInput
                searchVal={searchVal}
                setIsSearch={setIsSearch}
                setSearchVal={setSearchVal}
                onBlurFun={onBlurFun}
                searchHitText={t('programme.search')}
            />
        </div>
    )
}

export default TabSearch
