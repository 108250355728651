import { TextField, Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import tw from 'twin.macro'
import { useSnackbar } from 'notistack'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'

import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
import VerifyEmail from 'page/UserSettings/EditMyProfile/components/VerifyEmail'

import { useModalStore, useUserInfoAtom } from 'store/authAtom'
import { myProfile } from 'service/crmService'
import { loginReg } from 'service/authService'
import { useValidationI18n } from 'utils/validationI18n'

const Form = tw.form``

export default function UpdateEmail() {
    const { enqueueSnackbar } = useSnackbar()
    const { validationSchema } = useValidationI18n()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { setModalComponent, onClose } = useModalStore()
    const profileInfo = useUserInfoAtom((state) => state.profileInfo)

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            yup.object({
                emailId: validationSchema.emailId,
            }),
        ),
    })

    const sendEmailMutation = useMutation({
        mutationFn: loginReg.sendEmail,
        onSuccess: (v) => {
            // enqueueSnackbar(t('myProfile.VerifyEmail'), { variant: 'success' })
            setModalComponent(<VerifyEmail newEmail={getValues().emailId} />)
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const checkProfileExistMutation = useMutation({
        mutationFn: myProfile.checkProfileExist,
        onSuccess: () => {
            const formData = getValues()
            sendEmailMutation.mutate({
                emailId: profileInfo.emailId,
                lang: profileInfo.languagePreference,
                newEmail: formData.emailId,
                nickName: profileInfo.nickname,
                plateType: 'web',
                type: 'update',
                sysLang: language,
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onSend = (formData) => {
        checkProfileExistMutation.mutate({
            emailId: formData.emailId,
            lang: language,
        })
    }

    return (
        <Box>
            <Typography variant='h6' className='font-bold mb-8' gutterBottom>
                {t('myProfile.updateEmailAddress')}
            </Typography>

            <Form onSubmit={handleSubmit(onSend)} autoComplete='off'>
                <TextField
                    {...register('emailId')}
                    error={!!errors?.emailId}
                    helperText={errors?.emailId?.message ?? null}
                    fullWidth
                    variant='standard'
                    color='secondary'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={t('myProfile.hintEmail')}
                    label={<Typography className='font-bold text-black'>{t('myProfile.newEmailAddress')}</Typography>}
                />

                <DoubleBtn
                    leftText={t('myProfile.updateEmailBack')}
                    leftFun={onClose}
                    rightText={t('myProfile.update')}
                />
            </Form>
        </Box>
    )
}
