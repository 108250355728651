import React from 'react'

import EventsCards from 'page/Venue/components/EventsCards'

function EventSection({ items }) {
    return (
        <div>
            {items.eventList.length > 0 && <EventsCards items={items.eventList} />}
            {items.programmeList.length > 0 && (
                <EventsCards
                    seeMoreUrl='/programmes'
                    title='bookingsProgrammes.programmes'
                    isProgramme={true}
                    items={items.programmeList}
                />
            )}
        </div>
    )
}

export default EventSection
