import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

function PaginationButton({ fun, allCardsLength = 0, nowCardsLength = 0, isDisabled }) {
    const { t } = useTranslation()
    if (nowCardsLength === 0) {
        return null
    }
    return (
        <div className='py-8 md:py-16 flex flex-col items-center'>
            {!isDisabled && (
                <Button className='md:w-[270px]' variant='secondary' onClick={fun}>
                    {t('eventPage.loadingBtn')}
                </Button>
            )}
            {nowCardsLength > 0 && allCardsLength > 0 && (
                <div className='pt-6 text-secondary-midGrey text-tag'>
                    {t('eventPage.showing') +
                        nowCardsLength +
                        t('eventPage.numComma') +
                        allCardsLength +
                        t('eventPage.num')}
                </div>
            )}
            {/* {isDisabled && allCardsLength !== 0 && (
                <div className='pt-4 text-secondary-darkGery text-sm font-medium'>{t('eventPage.noMore')}</div>
            )} */}
        </div>
    )
}

export default PaginationButton
