import React, { useState } from 'react'
import { KeyboardArrowRight } from '@mui/icons-material'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ad from 'resources/images/seeMoreImg.png'

const Label = tw.div`flex justify-between items-center cursor-pointer active:opacity-50 py-5 border-white border-b-[1px] border-solid `

export default function SeeMoreSection({ getSeeMore }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [imageData, setImageData] = useState(getSeeMore[0])
    const handleClick = (item) => {
        switch (item.title) {
            case '97000':
                navigate('/heritageMilestone')
                break
            case '97001':
                navigate('/newsRoom')
                break
            case '97002':
                navigate('/connectWithKTSP/workWithUs')
                break
            default:
                break
        }
    }
    const titleCw = (code) => {
        return code === '97000'
            ? t('connectWithKtsp.milestonesHeritage')
            : code === '97001'
            ? t('connectWithKtsp.newsroom')
            : t('connectWithKtsp.workWithUs')
    }

    return (
        <div className='flex flex-col-reverse text-white md:flex-row'>
            <div className='bg-website-gradation w-full py-12 md:py-[72px] md:w-[57.3%] md:pr-4 lg:pr-10 lg:h-[700px]'>
                <div className='px-5 md:pl-10 lg:pl-[60px] xl:pl-[150px]'>
                    <div className='text-h3 pb-[60px] md:pb-[120px]'>{t('aboutUs.seeMore')}</div>
                    {getSeeMore?.map((item, index) => {
                        return (
                            <Label
                                onMouseEnter={() => setImageData(item)}
                                key={index}
                                onClick={() => handleClick(item)}
                            >
                                <div className='text-h4'>{titleCw(item.title)}</div>
                                <KeyboardArrowRight />
                            </Label>
                        )
                    })}
                </div>
            </div>
            <div className='hidden md:block h-[281px] md:h-auto md:flex-1 lg:h-[700px]'>
                <img src={imageData.webVisual} alt={imageData.altText} className='w-full h-full object-cover' />
            </div>
        </div>
    )
}
