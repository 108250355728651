import Content from 'components/Content'
import React from 'react'
import { useTranslation } from 'react-i18next'

import tw from 'twin.macro'

const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`
function TabOverview({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <div className='bg-container py-[60px] text-secondary-midnightBlue'>
            <div className='max-w-[750px]'>
                {/* <div className='text-2xl font-semiboldFamily pb-4'>{t('eventPage.about')}</div> */}
                <Content data={items.description} />
                <div className='flex gap-2 flex-wrap pt-4'>
                    {items.tags.map((tag, index) => {
                        return (
                            <Tag className='font-medium' key={index}>
                                {tag}
                            </Tag>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TabOverview
