import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Autoplay } from 'swiper/modules'
import { useMediaQuery } from '@mui/material'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'
import ReactPlayer from 'react-player'
import { useMeasure } from 'react-use'

import { isImage, ensureHttps } from 'utils/filter'
import { imageViewer } from 'store/authAtom'
import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import bgBom from 'resources/images/bgTrapar.png'
const VideoConponent = ({ image }) => {
    return (
        <ReactPlayer
            config={{
                file: {
                    attributes: {
                        style: {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        },
                    },
                },
            }}
            muted
            playsinline={true}
            className=''
            playing={false}
            width='100%'
            height='100%'
            url={image}
        />
    )
}
const VideoConponentPlay = ({ image, slideIndex, index, goToNextSlide }) => {
    const [isPlay, setIsPlay] = useState(true)
    // useEffect(() => {
    //     setIsPlay(true)
    // }, [])
    return (
        <div
            onClick={() => {
                console.log(isPlay)
                setIsPlay(!isPlay)
            }}
        >
            <ReactPlayer
                config={{
                    file: {
                        attributes: {
                            style: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            },
                        },
                    },
                }}
                muted
                playsinline={true}
                playing={isPlay}
                width='100%'
                height='100%'
                // onEnded={() => goToNextSlide()}
                url={image}
            />
        </div>
    )
}
export default function BannerSwiper({ items = [] }) {
    const [isPlay, setIsPlay] = useState(true)
    const { showImage, setImage } = imageViewer()
    const [ref, { width }] = useMeasure()
    const [swiper, setSwiper] = useState(null)
    const isXl = useMediaQuery('(min-width:1440px)')
    const ispad = useMediaQuery('(min-width:1024px)')
    const [slideIndex, setSlideIndex] = useState(0)
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    const isLoopFix = items.length > 1 && items.length < 4
    const showSlides = isLoopFix ? [...items, ...items] : items
    const showSlidesIndex = isLoopFix ? slideIndex % items.length : slideIndex
    // useEffect(() => {
    //     const video = !isImage(items[showSlidesIndex]?.image)
    //     if (video && swiper) {
    //         setIsPlay(true)
    //         swiper?.autoplay?.stop()
    //     } else {
    //         swiper && swiper?.autoplay?.start()
    //     }
    // }, [showSlidesIndex])
    return (
        <div className='relative w-full flex'>
            <div className='w-full z-30 absolute bottom-0'>
                <img alt='' src={bgBom} className='w-full h-full object-cover' />
            </div>
            <div
                // style={{ width: isXl ? (window.innerWidth - 800) / 2 : ispad ? '16.7%' : '10%' }}
                className='overflow-hidden hidden md:block relative md:w-[10%] lg:min-w-[15%] lg:flex-1'
            >
                <div className='h-full aspect-video'>
                    {showSlides.length > 1 && slideIndex > 1 ? (
                        isImage(showSlides[slideIndex - 1].image) ? (
                            <img alt='' src={showSlides[slideIndex - 1].image} className='h-full w-full object-fill' />
                        ) : (
                            <VideoConponent image={showSlides[slideIndex - 1].image} />
                        )
                    ) : isImage(showSlides[0].image) ? (
                        <img alt='' src={showSlides[0].image} className=' h-full w-full object-fill' />
                    ) : (
                        <VideoConponent image={showSlides[0].image} />
                    )}
                </div>
                <div className='absolute top-0 left-0 w-full h-full backdrop-blur-sm' />
            </div>

            {/* <div className='w-1/6 h-[450px] relative '>
                <div className='bg-black z-50 absolute top-0 bottom-0 left-0 right-0 opacity-10 blur-lg'></div>
            </div> */}
            <Swiper
                loop
                modules={[Navigation, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                // autoplay={{
                //     delay: 5000,
                //     disableOnInteraction: false,
                // }}
                initialSlide={0}
                className='w-full aspect-video max-h-[450px] max-w-[800px]'
            >
                {showSlides.map((item, index) => {
                    return (
                        <SwiperSlide
                            onClick={() => {
                                if (!isImage(item.image)) {
                                    return
                                }
                                setImage(item.image)
                                showImage()
                            }}
                            className='cursor-pointer'
                            key={index}
                        >
                            <div ref={ref} className='w-full h-full'>
                                {isImage(item.image) ? (
                                    <img
                                        title={item.altText}
                                        alt=''
                                        src={item.image}
                                        className='w-full h-full object-cover'
                                    />
                                ) : (
                                    <VideoConponentPlay
                                        setIsPlay={setIsPlay}
                                        image={item.image}
                                        slideIndex={slideIndex}
                                        index={index}
                                        goToNextSlide={goToNextSlide}
                                        isPlay={isPlay}
                                    />
                                )}
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {items.length > 1 && (
                <div
                    style={{ width: width }}
                    className='flex justify-between items-end z-40 absolute bottom-0 left-1/2 -translate-x-[50%] px-[22px] pb-[26px]'
                >
                    <Steps
                        swiperLength={items}
                        slideIndex={showSlidesIndex}
                        stepTextColor={'text-white'}
                        stepActiveColor={'bg-white'}
                        isLanding={1}
                    />
                    <IconButtonChange
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#FFFFFF'}
                        borderColor={'border-white'}
                        length={items.length}
                    />
                </div>
            )}
            <div
                // style={{ width: isXl ? (window.innerWidth - 800) / 2 : ispad ? '16.7%' : '10%' }}
                className='hidden overflow-hidden md:block md:w-[10%] lg:min-w-[15%] lg:flex-1'
            >
                <div className='h-full aspect-video relative'>
                    {showSlides.length > 1 && slideIndex <= items.length - 2 ? (
                        isImage(showSlides[slideIndex + 1].image) ? (
                            <img alt='' src={showSlides[slideIndex + 1].image} className='h-full w-full object-fill' />
                        ) : (
                            <VideoConponent image={showSlides[slideIndex + 1].image} />
                        )
                    ) : isImage(showSlides[0].image) ? (
                        <img alt='' src={showSlides[0].image} className='h-full w-full object-fill' />
                    ) : (
                        <VideoConponent image={showSlides[0].image} />
                    )}
                    <div className='absolute top-0 left-0 w-full h-full backdrop-blur-sm' />
                </div>
            </div>
        </div>
    )
}
