import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ThingTonoteSection from 'page/EventsTickets/components/ThingTonoteSection'
import SeatingPlanSection from 'page/EventsTickets/components/SeatingPlanSection'
import { TabButton } from 'components/ButtonsTab'

function TabSeatingMapAndThingsToNote({ items }) {
    const { t } = useTranslation()
    const { thingsToNote, seatingFloorPlan, seatingFloorPlanText, hideThingsToNote } = items
    const [type, setType] = useState(seatingFloorPlan || seatingFloorPlanText ? 0 : 1)
    const btnVals = [
        (seatingFloorPlan || seatingFloorPlanText) && {
            text: t('eventPage.seatingPlan'),
            val: 0,
        },
        !hideThingsToNote && thingsToNote?.length > 0 && { text: t('eventPage.thingsToNote'), val: 1 },
    ].filter((i) => i)
    return (
        <div className='bg-container text-secondary-midnightBlue'>
            <div className='max-w-[750px] space-y-8 pt-8 md:space-y-[60px] md:pt-[60px]'>
                {btnVals.length > 0 && (
                    <div className='flex flex-wrap gap-3'>
                        {btnVals.map((btnVal, index) => {
                            return (
                                <TabButton
                                    key={index}
                                    onClick={() => {
                                        setType(index)
                                    }}
                                    selected={type === btnVal.val}
                                >
                                    {btnVal.text}
                                </TabButton>
                            )
                        })}
                    </div>
                )}
                {type === 0 ? <SeatingPlanSection items={items} /> : <ThingTonoteSection items={items} />}
            </div>
        </div>
    )
}

function TabSeatingMap({ items }) {
    const { t } = useTranslation()
    return (
        <div className='bg-container text-secondary-midnightBlue'>
            <div className='max-w-[750px] space-y-8 pt-8 md:space-y-[60px] md:pt-[60px]'>
                <TabButton onClick={() => {}} selected={true}>
                    {t('eventPage.seatingPlan')}
                </TabButton>
                <SeatingPlanSection items={items} />
            </div>
        </div>
    )
}

function TabThingsToNote({ items }) {
    const { t } = useTranslation()
    return (
        <div className='bg-container text-secondary-midnightBlue'>
            <div className='max-w-[750px] space-y-8 pt-8 md:space-y-[60px] md:pt-[60px]'>
                <TabButton onClick={() => {}} selected={true}>
                    {t('eventPage.thingsToNote')}
                </TabButton>
                <ThingTonoteSection items={items} />
            </div>
        </div>
    )
}

export { TabSeatingMapAndThingsToNote, TabSeatingMap, TabThingsToNote }
