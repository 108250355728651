import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Menu from '@mui/material/Menu'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CheckBoxFiled from 'components/shopDine/allProductsComponents/CheckBoxFiled'

function TypeMenu({
    name,
    labels = [],
    typesSelected,
    setTypesSelected,
    num = [],
    isNoFilter,
    allItem = {
        id: 'all',
        nameEn: 'All',
        nameCn: '全部',
        nameZh: '全部',
    },
    resetCw = '',
    confirmCw = '',
}) {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [values, setValues] = useState(typesSelected)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setValues(typesSelected)
    }
    useEffect(() => {
        setValues(typesSelected)
    }, [typesSelected])
    if (labels?.length === 0) {
        return null
    }

    const handleSelected = (items) => {
        const isAllSelected = items?.at(-1)?.id === 'all'
        if (isAllSelected) {
            setValues([allItem])
        } else {
            setValues(items.filter((item) => item.id !== 'all'))
        }
    }

    return (
        <>
            <div
                onClick={handleClick}
                className='px-[10px] pl-4 py-[6px] flex justify-between items-center gap-2 cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px] min-w-[160px]'
            >
                <div className='text-base font-medium'>{name}</div>
                <div className='flex items-center gap-1'>
                    <div
                        className={
                            'w-[18px] h-[18px] flex justify-center items-center rounded-[9px] text-white text-xs font-bold' +
                            (num > 0 ? ' bg-secondary-midnightBlue' : ' bg-white')
                        }
                    >
                        {num}
                    </div>
                    <div className='icon-arrow_down text-sm' />
                </div>
            </div>
            <Menu
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: 0,
                        borderTopRightRadius: 40,
                        py: 3,
                        pr: 2,
                        pl: 3,
                        mt: 1,
                        width: 310,
                    },
                }}
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/*<CheckBoxFiled*/}
                {/*    className='mb-3 leading-[1]'*/}
                {/*    typesSelected={values.length === 0 ? [allItem] : values}*/}
                {/*    setTypesSelected={handleSelected}*/}
                {/*    label={allItem}*/}
                {/*    key={0}*/}
                {/*/>*/}
                {labels.length > 0 &&
                    labels.map((label, index) => {
                        return (
                            <CheckBoxFiled
                                className='mb-3 leading-[1]'
                                isNoFilter={isNoFilter}
                                typesSelected={values}
                                setTypesSelected={handleSelected}
                                label={label}
                                key={index}
                            />
                        )
                    })}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        fontWeight: 600,
                        alignItems: 'center',
                        mr: 1,
                        mt: 5,
                    }}
                >
                    <Box
                        onClick={() => {
                            setValues([])
                        }}
                        sx={{ color: '#0A173D', cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        {resetCw || t('eventPage.reset')}
                    </Box>
                    <Button
                        variant='primaryRed'
                        size='small'
                        onClick={() => {
                            setTypesSelected(values)
                            handleClose()
                        }}
                        sx={{
                            textTransform: 'none',
                            px: 2,
                            fontWeight: '600',
                        }}
                    >
                        {confirmCw || t('common.comfirm')}
                    </Button>
                </Box>
            </Menu>
        </>
    )
}

export default TypeMenu
