import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'
import { useMeasure } from 'react-use'

import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'

function DoublePhotoSwiper({ items = [] }) {
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const isXl = useMediaQuery('(min-width:1440px)')
    const isPad = useMediaQuery('(min-width:768px)')
    const [ref, { width }] = useMeasure()
    const num = 0
    const {
        i18n: { language },
    } = useTranslation()
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    const isLoopFix = items.length > 2 && items.length < 4
    const showSlides = isLoopFix ? [...items, ...items] : items
    const showSlidesIndex = isLoopFix ? slideIndex % items.length : slideIndex
    return (
        <div>
            {
                <div className={'px-5 md:px-10 lg:pr-0 lg:pl-[60px] xl:pl-[150px] landing-pt'}>
                    <Swiper
                        loop
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        onSwiper={(swiper) => {
                            setSwiper(swiper)
                        }}
                        onActiveIndexChange={(swiper) => {
                            setSlideIndex(swiper.realIndex)
                        }}
                        initialSlide={0}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 24,
                            },
                            1024: {
                                slidesPerView: 2.1,
                                spaceBetween: 24,
                            },
                            1440: {
                                slidesPerView: 2.25,
                                spaceBetween: 24,
                            },
                        }}
                    >
                        {showSlides.map((s, index) => {
                            return (
                                <SwiperSlide className='aspect-video' key={index}>
                                    <img
                                        ref={ref}
                                        onClick={() => window.open(s.webUrl)}
                                        className='w-full h-full cursor-pointer object-cover active:opacity-70'
                                        alt={s.altText}
                                        src={s.image}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            }
            {(items.length > 2 || !isPad) && (
                <div
                    style={{ width: isXl ? width * 2 + 174 : '100%' }}
                    className='pt-[32px] px-5 md:px-10 lg:px-[60px] xl:pl-[150px] xl:pr-0 flex justify-between items-center'
                >
                    <Steps
                        swiperLength={items}
                        slideIndex={showSlidesIndex + num}
                        stepTextColor={'text-secondary-midnightBlue'}
                        stepActiveColor={'bg-secondary-midnightBlue'}
                    />
                    <IconButtonChange
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                    />
                </div>
            )}
        </div>
    )
}

export default DoublePhotoSwiper
