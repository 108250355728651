import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { information } from 'service/informationService'
import TabsChange from 'page/ConnectWithKTSP/FAQ/conponents/TabsChange'
import Banner from 'components/shopDine/Banner'
import contactUsBanner from 'resources/images/contactUsBanner.png'
import Loading from 'components/Loading'
function FAQ() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [tabsList, setTabsList] = useState([])
    const banner = {
        image: contactUsBanner,
        title: t('FAQ.title'),
        about: t('FAQ.bannerAbout'),
    }
    const getFootFaqTabList = useMutation({
        mutationFn: information.getFootFaqTabList,
        onSuccess: setTabsList,
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        getFootFaqTabList.mutate(language)
    }, [language])
    return (
        <div>
            <Banner banner={banner} />
            {tabsList.length > 0 && <TabsChange tabsList={tabsList} />}
        </div>
    )
}

export default FAQ
