import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
// import { Container } from './styles';

function Loading() {
    return (
        <div className='flex justify-center items-center h-[300px] flex-1'>
            <CircularProgress />
        </div>
    )
}

export default Loading
