import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import SwiperEventCards from 'components/detailComponents/SwiperEventCards'
import { TabButton } from 'components/ButtonsTab'

function RelatedCards({ relatedRef, items = [] }) {
    const { t } = useTranslation()
    const [type, setType] = useState('event')
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const xlScreen = useMediaQuery('(min-width:1440px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    const btnVals = [
        // { text: t('landing.recommendedForYou'), val: 'SPECIAL_FOR_YOU' },
        { text: t('landing.events'), val: 'event' },
        // { text: t('landing.programmes'), val: 'PROGRAMMES' },
    ]
    return (
        <section ref={relatedRef} className='py-4 my-1 bg-[#E9ECF3] md:py-12'>
            <div className='bg-container'>
                <div className='text-h3 pb-4 md:pb-8 text-secondary-midnightBlue font-semiboldFamily'>
                    {t('promotion.related')}
                </div>
                <div className='flex justify-between items-center pb-4 md:pb-8'>
                    <div className='flex flex-wrap gap-[10px]'>
                        {btnVals.map((btnVal, index) => {
                            return (
                                <TabButton
                                    key={index}
                                    onClick={() => {
                                        console.log(btnVal.val)
                                    }}
                                    selected={type === btnVal.val}
                                >
                                    {btnVal.text}
                                </TabButton>
                            )
                        })}
                    </div>
                    <div
                        className={
                            'block ' +
                            (items.length <= 4 && ' xl:hidden ') +
                            (items.length <= 3 && ' lg:hidden ') +
                            (items.length <= 2 && ' hidden')
                        }
                    >
                        <IconButtonChange
                            slideIndex={slideIndex}
                            num={xlScreen ? 4 : lgScreen ? 3 : 2}
                            length={items.length}
                            goToNextSlide={goToNextSlide}
                            goToPrevSlide={goToPrevSlide}
                            buttonColor={'#231448'}
                            borderColor={'border-secondary-midnightBlue'}
                        />
                    </div>
                </div>
                <SwiperEventCards setSlideIndex={setSlideIndex} items={items} setSwiper={setSwiper} />
            </div>
        </section>
    )
}

export default RelatedCards
