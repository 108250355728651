import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

import EventSection from 'page/Venue/components/EventSection'
import BookingCards from 'page/Venue/components/BookingCards'
import ShopDineCards from 'page/Venue/components/ShopDineCards'
import TransportationSection from 'page/Venue/components/TransportationSection'
import { TabButton } from 'components/ButtonsTab'

const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`

export default function EventType({ items }) {
    const { t } = useTranslation()
    const btnVals = [
        (items.eventList.length > 0 || items.programmeList.length > 0) && { text: t('venue.events'), val: 'events' },
        items.facilityList.length > 0 && { text: t('bookingsProgrammes.facility'), val: 'bookings' },
        (items.shopList.length > 0 || items.dineList.length > 0) && {
            text: t('landing.exploreShopDine'),
            val: 'shopDine',
        },
        { text: t('venue.transportationBtn'), val: 'transportation' },
    ].filter((i) => i)
    const [type, setType] = useState(btnVals[0].val)
    return (
        <div className='bg-container'>
            <div className='flex flex-wrap gap-[10px] pb-8 xl:pb-16'>
                {btnVals.map((btnVal, index) => {
                    return (
                        <TabButton
                            key={index}
                            onClick={() => {
                                setType(btnVal.val)
                                console.log(btnVal.val)
                            }}
                            selected={type === btnVal.val}
                        >
                            {btnVal.text}
                        </TabButton>
                    )
                })}
            </div>
            {type === 'events' && <EventSection items={items} />}
            {type === 'bookings' && <BookingCards items={items} />}
            {type === 'shopDine' && <ShopDineCards items={items} />}
            {type === 'transportation' && <TransportationSection items={items} />}
        </div>
    )
}
