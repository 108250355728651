import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'

import { programmesService } from 'service/vbsService'
import Banner from 'components/shopDine/Banner'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import AllProgrammers from 'page/Programmes/componets/AllProgrammers'
import bannerTop from 'resources/images/programmeBanner.jpg'
import Loading from 'components/Loading'
function Programmes() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const banner = {
        image: bannerTop,
        title: t('programme.title'),
        about: t('programme.bannerAbout'),
    }
    const { data: bannerSwiperImgs, isFetching } = useQuery({
        queryKey: ['getProgrammeBanner', language],
        queryFn: () =>
            programmesService.getProgrammeBanner({
                lang: language,
                size: 5,
            }),
        placeholderData: {},
    })
    const { data: fiterLists, filterIsFetching } = useQuery({
        queryKey: ['getProgrammeFilter', language],
        queryFn: () =>
            programmesService.getProgrammeFilter({
                lang: language,
            }),
        placeholderData: null,
    })
    return (
        <div>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                {bannerSwiperImgs && bannerSwiperImgs.length > 0 && (
                    <BannerSwiper type='programmes' bannerSwiperImgs={bannerSwiperImgs} />
                )}
            </section>
            <section>
                {filterIsFetching && <Loading />}
                {fiterLists && (
                    <AllProgrammers
                        fiterLists={fiterLists}
                        firstTypeName={t('eventPage.type')}
                        secondTypeName={t('eventPage.venue')}
                        isFilter={true}
                    />
                )}
            </section>
        </div>
    )
}

export default Programmes
