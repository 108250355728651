import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Banner from 'components/shopDine/Banner'
import shopTop from 'resources/images/shopTop.png'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import FeaturedShop from 'components/shopDine/FeaturedShop'
import { useTranslation } from 'react-i18next'
import AllProduct from './components/AllProduct'
import { shopDineService } from 'service/restaurantService'

function ShopDine() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: shopTop,
        title: t('shop.shopsTitle'),
        about: t('shop.shopAbout'),
    }

    const { data: bannerFeaturedList } = useQuery({
        queryKey: ['getShopBannerFeaturedList', language],
        queryFn: () =>
            shopDineService.getShopDineBannerFeaturedList({
                lang: language,
                bannerSize: 5,
                featuredSize: 10,
                type: 'shop',
            }),
    })

    return (
        <div className='bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                {bannerFeaturedList && bannerFeaturedList.heroBanner && bannerFeaturedList.heroBanner.length > 0 && (
                    <BannerSwiper bannerSwiperImgs={bannerFeaturedList.heroBanner} type='shop' />
                )}
            </section>
            <section>
                {bannerFeaturedList && bannerFeaturedList.featured && bannerFeaturedList.featured.length > 0 && (
                    <FeaturedShop name={t("shop.featureShop")} featuredList={bannerFeaturedList.featured} path='/shopsDetail' />
                )}
            </section>
            <section>
                <AllProduct
                    // typesSelected={typesSelected}
                    // setTypesSelected={setTypesSelected}
                    firstTypeName={t('shop.type')}
                    secondTypeName={t('shop.location')}
                    isFilter={true}
                    // venuesSelected={venuesSelected}
                    // setVenuesSelected={setVenuesSelected}
                    // types={types}
                    // venues={venues}
                    // cards={cards}
                    // setCards={setCards}
                    name={t("shop.allShop")}
                />
            </section>
        </div>
    )
}

export default ShopDine
