import React, { useMemo, useState, useEffect } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import dayjs from 'dayjs'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import TabSearch from 'page/Programmes/componets/TabSearch'
import PaginationButton from 'components/PaginationButton'
import { filterName, newIdArray, formatStartDateEndDate, datesSelect } from 'utils/filter'
import { programmesService } from 'service/vbsService'
import EventCard from 'page/EventsTickets/components/EventCard'
import FilterModal from 'components/shopDine/allProductsComponents/FilterModal'
import Tag from 'components/shopDine/allProductsComponents/Tag'
import TypeMenu from 'components/shopDine/allProductsComponents/TypeMenu'
import TypeDate from 'components/TypeDate'
import { useDisclosure } from 'utils/useDisclosure'
import Loading from 'components/Loading'

const newVbsIdArray = (arr) => {
    return arr.length > 0 ? arr.map((item) => item.vbsRefId) : []
}

function AllProgrammers({ fiterLists, isFilter, firstTypeName, secondTypeName }) {
    const queryClient = useQueryClient()
    const { activityTypes = [], sportsTypes = [], venues, ageGroup } = fiterLists
    const tabsList = [
        {
            id: '',
            nameEn: 'All',
            nameCn: '全部',
            nameZh: '全部',
        },
        ...sportsTypes,
    ]
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [typesSelected, setTypesSelected] = useState([])
    const [venuesSelected, setVenuesSelected] = useState([])
    const [ageGroupSelected, setAgeGroupSelected] = useState([])
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [listType, setListType] = useState(true)
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const dates = datesSelect()
    const [dateType, setDateType] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const formatStartDate = startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : null
    const formatEndDate = endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : null

    const formatDate = useMemo(() => {
        return formatStartDateEndDate(startDate ?? endDate, endDate ?? startDate, language)
    }, [startDate, endDate, language])
    const [tabVal, setTabVal] = useState('')

    const selectedCategories = useMemo(() => {
        return [...typesSelected, ...venuesSelected, ...ageGroupSelected]
    }, [typesSelected, venuesSelected, ageGroupSelected])

    const filterType = (item, arr) => {
        return arr.some(
            (itemType) =>
                itemType.id === item.id &&
                itemType.nameEn === item.nameEn &&
                itemType.nameZh === item.nameZh &&
                itemType.nameCn === item.nameCn,
        )
    }

    const getEventConditions = {
        dateTo: formatEndDate ? formatEndDate + ' 23:59:59' : '',
        dateFrom: formatStartDate ? formatStartDate + ' 00:00:00' : '',
        lang: language,
        age:
            ageGroupSelected.length > 0
                ? ageGroupSelected.map((item) => ({
                      id: item.id,
                      ageFrom: item.ageFrom,
                      ageTo: item.ageTo,
                  }))
                : [],
        dateRange: dateType && dateType.val ? dateType.val : 'anyTime',
        searchKey: searchVal.trim(),
        activityTypes: newVbsIdArray(typesSelected),
        sportsType: tabVal,
        venues: newVbsIdArray(venuesSelected),
        maxRecord: listType ? 20 : 10,
    }
    const { data, fetchNextPage, refetch, isFetching, isPending } = useInfiniteQuery({
        queryKey: [
            'getProgrammeList',
            startDate,
            endDate,
            dateType,
            typesSelected,
            venuesSelected,
            tabVal,
            ageGroupSelected,
            language,
            listType,
        ], // key
        queryFn: ({ pageParam = 0 }) =>
            programmesService.getProgrammeList({ ...getEventConditions, startRow: pageParam }),
        getNextPageParam: (lastPage, pages) => {
            return pages.length * (listType ? 20 : 10)
        },
    })
    const hasFilter = useMemo(() => {
        return (
            typesSelected.length > 0 ||
            venuesSelected.length > 0 ||
            ageGroupSelected.length > 0 ||
            dateType ||
            startDate ||
            endDate
        )
    }, [typesSelected, venuesSelected, ageGroupSelected, dateType, startDate, endDate])
    useEffect(() => {
        refetch()
    }, [])
    return (
        <div className='bg-white pt-16 bg-container'>
            <div className='text-h3 pb-4 text-secondary-midnightBlue md:pb-6'>{t('programme.listTitle')}</div>
            <FilterModal
                ageGroups={ageGroup}
                ageGroupSelected={ageGroupSelected}
                setAgeGroupSelected={setAgeGroupSelected}
                thirdName={t('programme.ageGroup')}
                firstTypeName={firstTypeName}
                secondTypeName={secondTypeName}
                venues={venues}
                types={activityTypes}
                typesSelected={typesSelected}
                setTypesSelected={setTypesSelected}
                venuesSelected={venuesSelected}
                setVenuesSelected={setVenuesSelected}
                open={isOpen}
                handleClose={onClose}
                dateType={dateType}
                setDateType={setDateType}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dates={dates}
            />
            <TabSearch
                tabVal={tabVal}
                setTabVal={setTabVal}
                tabTitles={tabsList}
                setIsSearch={setIsSearch}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                onBlurFun={() => {
                    !isFetching && refetch()
                }}
            />
            <div className='flex justify-between items-center'>
                <div className='py-[24px] flex flex-wrap items-center gap-4'>
                    <div className='hidden lg:flex lg:flex-wrap lg:gap-4 lg:items-center'>
                        <TypeMenu
                            name={firstTypeName}
                            num={typesSelected.length}
                            labels={activityTypes}
                            typesSelected={typesSelected}
                            setTypesSelected={setTypesSelected}
                        />
                        <TypeDate
                            dateType={dateType}
                            setDateType={setDateType}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            dates={dates}
                        />
                        <TypeMenu
                            name={secondTypeName}
                            num={venuesSelected.length}
                            labels={venues}
                            typesSelected={venuesSelected}
                            setTypesSelected={setVenuesSelected}
                            allItem={{
                                id: 'all',
                                nameEn: 'All locations',
                                nameCn: '所有地点',
                                nameZh: '所有地點',
                            }}
                        />
                        <TypeMenu
                            name={t('programme.ageGroup')}
                            num={ageGroupSelected.length}
                            labels={ageGroup}
                            typesSelected={ageGroupSelected}
                            setTypesSelected={setAgeGroupSelected}
                        />
                    </div>
                    {isFilter && (
                        <div
                            onClick={() => onOpen()}
                            className='lg:px-4 lg:border-secondary-midnightBlue lg:border-solid lg:border-l-[1px]'
                        >
                            <div className='w-[160px] py-[6px] pl-[16px] pr-[10px] flex justify-between items-center cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px]'>
                                <div className='text-base font-medium'>{t('eventPage.allFilter')}</div>
                                <div className='flex items-center'>
                                    <div className='icon-filter font-bold' />
                                </div>
                            </div>
                        </div>
                    )}
                    {hasFilter && (
                        <div
                            onClick={() => {
                                setTypesSelected([])
                                setVenuesSelected([])
                                setAgeGroupSelected([])
                                dateType && setDateType(null)
                                startDate && setStartDate(null)
                                endDate && setEndDate(null)
                            }}
                            className='text-base font-semiboldFamily text-[#0A173D] cursor-pointer underline'
                        >
                            {t('eventPage.reset')}
                        </div>
                    )}
                </div>
                <div className='text-lg text-secondary-grey flex items-center gap-2 cursor-pointer'>
                    <div onClick={() => setListType(true)} className={listType ? 'text-secondary-midnightBlue' : ''}>
                        <GridOnOutlinedIcon />
                    </div>
                    <div onClick={() => setListType(false)} className={!listType ? 'text-secondary-midnightBlue' : ''}>
                        <FormatListBulletedIcon />
                    </div>
                </div>
            </div>
            <div
                className={
                    'flex gap-2 flex-wrap' +
                    (selectedCategories.length > 0 || (dateType && dateType.val !== 'flexibleDate') || formatDate
                        ? ' pb-6'
                        : '')
                }
            >
                {selectedCategories.length > 0 &&
                    selectedCategories.map((item, index) => {
                        return (
                            <Tag
                                key={index}
                                text={filterName(item, language)}
                                fun={() => {
                                    if (filterType(item, activityTypes)) {
                                        const arr = typesSelected.filter((val) => val !== item)
                                        setTypesSelected([...arr])
                                    } else if (filterType(item, ageGroup)) {
                                        const arr = ageGroupSelected.filter((val) => val !== item)
                                        setAgeGroupSelected([...arr])
                                    } else {
                                        const arr = venuesSelected.filter((val) => val !== item)
                                        setVenuesSelected([...arr])
                                    }
                                }}
                            />
                        )
                    })}
                {dateType && dateType.val !== 'flexibleDate' && (
                    <Tag text={t(dateType.about)} fun={() => setDateType('')} />
                )}
                {formatDate && (
                    <Tag
                        text={formatDate}
                        fun={() => {
                            setStartDate(null)
                            setEndDate(null)
                            setDateType('')
                        }}
                    />
                )}
            </div>
            {hasFilter && (
                <div className='text-h4 text-secondary-midnightBlue pb-8'>
                    {isSearch ? data?.pages[0]?.allDataSize ?? 0 : 0}&nbsp;
                    {t('eventPage.resultsFound')}
                </div>
            )}
            <div className='mt-8 md:mt-12'>
                <div
                    className={
                        !listType
                            ? 'flex-1'
                            : 'grid gap-x-[10px] flex-1 gap-y-8 grid-cols-2 md:gap-x-[20px] lg:grid-cols-3 lg:gap-y-16 xl:grid-cols-4'
                    }
                >
                    {isSearch &&
                        data &&
                        data?.pages.map((page, index) => {
                            return (
                                page.data &&
                                page.data.length > 0 &&
                                page.data.map((card, i) => {
                                    return (
                                        <EventCard
                                            path='/programmesDetail'
                                            index={index}
                                            key={card + i}
                                            listType={listType}
                                            card={{
                                                ...card,
                                                uuid: card.programmeId,
                                                eventName: card.programmeName,
                                                label: card.label,
                                                local:
                                                    card.venues?.length > 0
                                                        ? filterName(
                                                              {
                                                                  nameEn: card.venues[0].enName,
                                                                  nameCn: card.venues[0].scName,
                                                                  nameZh: card.venues[0].tcName,
                                                              },
                                                              language,
                                                          )
                                                        : '',
                                                cardImage: card.cardImage,
                                                dateRange: [
                                                    card.startDate &&
                                                        card.endDate && {
                                                            eventStartDateTime: card.startDate,
                                                            eventEndDateTime: card.endDate,
                                                        },
                                                ].filter((i) => i),
                                            }}
                                        />
                                    )
                                })
                            )
                        })}
                </div>
            </div>
            {isPending && <Loading />}
            {isSearch && (
                <PaginationButton
                    isDisabled={
                        data?.pages.reduce((acc, page) => acc + page.data?.length, 0) === data?.pages[0]?.allDataSize ||
                        data?.pages[0]?.allDataSize === 0
                    }
                    fun={() => {
                        fetchNextPage()
                    }}
                    nowCardsLength={data?.pages.reduce((acc, page) => acc + page.data?.length, 0) ?? 0}
                    allCardsLength={data?.pages[0]?.allDataSize ?? 0}
                />
            )}
        </div>
    )
}

export default AllProgrammers
