import React from 'react'
import { IconButton, useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { cn } from 'utils/cn'

function IconButtonChange({
    className,
    goToPrevSlide,
    goToNextSlide,
    buttonColor,
    borderColor,
    slideIndex,
    length,
    num = 0,
    width = 'md:h-[45px] md:w-[45px] lg:h-[60px] lg:w-[60px] ',
    landingBanner = '',
}) {

    const isFirst = slideIndex === 0
    const isLast = slideIndex + num === length

    const iconColorFirst = isFirst ? ' border-secondary-grey' : borderColor
    const iconColorLast = isLast ? ' border-secondary-grey' : borderColor
    const iconClassName =
        'rounded-full aspect-square border-solid border-[1.5px] w-[30px] h-[30px] ' + width + landingBanner

    const ispad = useMediaQuery('(min-width:768px)')
    const fontSize = ispad ? 'middle' : 'small'

    return (
        <div
            className={cn(
                `flex gap-${width === 'md:h-[45px] md:w-[45px] lg:h-[60px] lg:w-[60px] ' ? 5 : 3}`,
                landingBanner ?? 'flex-col',
                className,
                isFirst && isLast && 'opacity-0',
            )}
        >
            <IconButton
                onClick={goToPrevSlide}
                className={iconClassName + iconColorFirst}
                aria-label='ArrowBack'
                size='middle'
                disabled={isFirst}
            >
                <ArrowBackIcon sx={{ color: isFirst ? '#D4D6D9' : buttonColor }} fontSize={fontSize} />
            </IconButton>
            <IconButton
                onClick={goToNextSlide}
                className={iconClassName + iconColorLast}
                aria-label='ArrowForward'
                size='middle'
                disabled={isLast}
            >
                <ArrowForwardIcon
                    sx={{
                        color: isLast ? '#D4D6D9' : buttonColor,
                    }}
                    fontSize={fontSize}
                />
            </IconButton>
        </div>
    )
}

export default IconButtonChange
