import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { landing } from 'service/landingService'
import { useSpacialTrafficNews } from 'store/authAtom'
import Banner from 'components/shopDine/Banner'
import b from 'resources/images/transportBanner.jpg'
import GuideDetail from 'page/Transportation/components/GuideDetail'
function Transportation() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { onOpenSpacial, setData, onCloseSpacial } = useSpacialTrafficNews()
    const banner = {
        image: b,
        title: t('transport.Title'),
        about: t('transport.bannerAbout'),
    }
    const getSpecialAnnouncement = useMutation({
        mutationFn: landing.getTransportationAnnouncement,
        onSuccess: (res) => {
            setData(res)
            res.announcementTitle || res.announcementMessage ? onOpenSpacial() : onCloseSpacial()
        },
    })
    useEffect(() => {
        getSpecialAnnouncement.mutate({ lang: language, type: '', id: 0, published: 0 })
    }, [language])
    return (
        <div className=' bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                <GuideDetail />
            </section>
        </div>
    )
}

export default Transportation
