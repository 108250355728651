import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import tw from 'twin.macro'
import { Typography } from '@mui/material'

import { venueServices } from 'service/venueService'
import Banner from 'components/shopDine/Banner'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import bannerTop from 'resources/images/facilitiesBookingBanner.jpg'
import TabsChange from 'page/FacilitiesBooking/components/TabsChange'
import Loading from 'components/Loading'
import { information } from 'service/informationService'
import { facilitiesService } from 'service/vbsService'
import { filterName } from 'utils/filter'
import Content from 'components/Content'

const Wrap = tw.div``
const AlertWrap = tw.div`mt-8 md:mt-16`
const AlertContent = tw.div`space-y-2 px-5 py-4 bg-[#E9ECF3]`

export default function FacilitiesBooking() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: bannerTop,
        title: t('facilitiesBooking.bannerTitle'),
        about: t('facilitiesBooking.bannerAbout'),
    }

    const { data: bannerSwiperImgs } = useQuery({
        queryKey: ['getSportBanner', language],
        queryFn: () =>
            facilitiesService.getSportBanner({
                lang: language,
                size: 5,
            }),
    })

    const { data: tabsTitles, isFetching } = useQuery({
        queryKey: ['getSportFilter'],
        queryFn: () => facilitiesService.getSportFilter({ lang: language }),
    })

    const { data: remarks } = useQuery({
        queryKey: ['getSportRemark'],
        queryFn: () => facilitiesService.getSportRemark(),
    })

    return (
        <Wrap>
            <Banner banner={banner} />
            {bannerSwiperImgs?.length > 0 && <BannerSwiper type='sports' bannerSwiperImgs={bannerSwiperImgs} />}

            {remarks && (
                <AlertWrap className='bg-container'>
                    <AlertContent>
                        <Typography variant='h6' className='text-[#283266]'>
                            {filterName(
                                {
                                    nameEn: remarks.remarkTitleEn,
                                    nameZh: remarks.remarkTitleTc,
                                    nameCn: remarks.remarkTitleCn,
                                },
                                language,
                            )}
                        </Typography>
                        <Typography variant='subtitle2' className='text-[#283266]'>
                            <Content
                                data={filterName(
                                    {
                                        nameEn: remarks.remarkMessageEn,
                                        nameZh: remarks.remarkMessageTc,
                                        nameCn: remarks.remarkMessageCn,
                                    },
                                    language,
                                )}
                            />
                        </Typography>
                    </AlertContent>
                </AlertWrap>
            )}
            {isFetching && <Loading />}
            <TabsChange tabsTitles={tabsTitles?.sportsTypes} />
        </Wrap>
    )
}
