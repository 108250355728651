import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import PaginationButton from 'components/PaginationButton'
import { ticketServices } from 'service/ticketsService'
import MyRecordCard from 'page/UserSettings/MyRecord/components/MyRecordCard'
import NoDatas from 'page/UserSettings/MyRecord/components/NoDatas'
import Loading from 'components/Loading'

function Tickets({ value, name }) {
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [hasMoreData, setHasMoreData] = useState(true)
    const [cards, setCards] = useState([])
    const getEventsList = useMutation({
        mutationFn: ticketServices.getEventsList,
        onSuccess: (res) => {
            res && res.length < 10 ? setHasMoreData(false) : setHasMoreData(true)
            setCards(res)
            setPage(1)
            // res.length > 0 && setTotal(res[0].count)
        },
        onError: (e) => {
            console.log(e)
            setHasMoreData(false)
        },
    })
    const getEventsListPagination = useMutation({
        mutationFn: ticketServices.getEventsList,
        onSuccess: (res) => {
            setCards([...cards, ...res])
            if (res.length < 10) {
                console.log(2222)
                setHasMoreData(false)
            } else {
                setHasMoreData(true)
                setPage(page + 1)
            }
        },
        onError: (e) => {
            console.log(e)
            setHasMoreData(false)
        },
    })
    const getEventListMethod = (pageNow) => {
        if (getEventsListPagination.isPending || !hasMoreData) {
            return
        }
        getEventsListPagination.mutate({ page: pageNow + 1, pageSize: 10, period: name.val })
    }
    useEffect(() => {
        setCards([])
        getEventsList.mutate({ page: 1, pageSize: 10, period: name.val })
    }, [value])
    return (
        <>
            {cards && cards.length > 0 ? (
                <div className='mt-10 space-y-6 mb-6 md:mb-[60px] md:space-y-[60px]'>
                    {cards.map((card, index) => {
                        return (
                            <div key={index} className='space-y-6 md:space-y-[60px]'>
                                {card.dataResultItems.length > 0 &&
                                    card.dataResultItems.map((item, i) => {
                                        return <MyRecordCard type={name.val} item={item} card={card} key={i} />
                                    })}
                            </div>
                        )
                    })}
                    {getEventsListPagination.isPending && <Loading />}
                    {hasMoreData && (
                        <PaginationButton
                            isDisabled={!hasMoreData}
                            fun={() => {
                                getEventListMethod(page)
                            }}
                            // nowCardsLength={total}
                            // allCardsLength={total}
                        />
                    )}
                </div>
            ) : getEventsList.isPending ? (
                <Loading />
            ) : (
                <NoDatas text={t('myProfile.noTickets')} btnText={t('myProfile.exporeEvent')} />
            )}
        </>
    )
}

export default Tickets
