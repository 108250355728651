import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { newsroomService } from 'service/informationService'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import TopIntro from 'page/ConnectWithKTSP/NewsRoom/components/TopIntro'
import AboutTabs from 'page/ConnectWithKTSP/NewsRoom/components/AboutTabs'
import AllCard from 'page/ConnectWithKTSP/NewsRoom/components/AllCard'
import Loading from 'components/Loading'
import { TabContainer } from 'components/TabContainer'
import TabOverview from '../components/TabOverview'
function Detail() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const [items, setItems] = useState(null)
    const getNewsroomDetail = useMutation({
        mutationFn: newsroomService.getDetail,
        onSuccess: setItems,
        onError: () => {},
    })
    useEffect(() => {
        getNewsroomDetail.mutate({ lang: language, identityUuid: id, size: 5 })
    }, [language, id])
    if (getNewsroomDetail.isPending) {
        return <Loading />
    }

    return (
        <div className='bg-white'>
            {items ? (
                <>
                    {items.banners.length > 0 && <BannerSwiper items={items.banners} />}
                    <TopIntro items={items} />
                    <TabContainer
                        components={[
                            (items.tags.length > 0 || items.description) && {
                                key: 'about',
                                title: t('eventPage.about'),
                                component: () => <TabOverview items={items} />,
                            },
                            items?.moreNews &&
                                items?.moreNews?.length > 0 && {
                                    key: 'moreNews',
                                    title: t('aboutUs.moreNewsTitle'),
                                    component: () => <AllCard items={items.moreNews} />,
                                },
                        ].filter((i) => i)}
                    />
                </>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default Detail
