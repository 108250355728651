import React from 'react'
import tw from 'twin.macro'

const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`
function Tags({ items }) {
    return (
        <div className='flex gap-2 flex-wrap max-w-[752px]'>
            {items.tags &&
                items.tags.length > 0 &&
                items.tags.map((tag, index) => {
                    return (
                        <Tag className='font-medium' key={index}>
                            {tag.name}
                        </Tag>
                    )
                })}
        </div>
    )
}

export default Tags
