import React from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'

import { imageViewer } from 'store/authAtom'
function ImageViewer() {
    const { isOpen, hiddenImage, imageSrc } = imageViewer()
    return (
        <Modal
            open={isOpen}
            onClose={hiddenImage}
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
        >
            <Box className='absolute left-1/2 -translate-x-1/2 w-[95%] rounded-[10px] bg-black flex justify-center items-center h-auto top-1/2 -translate-y-1/2 max-h-[100vh] md:h-[774px] md:w-[80%]'>
                <div className='mx-auto w-full h-full md:w-4/5'>
                    <CloseIcon
                        onClick={hiddenImage}
                        className='text-white text-2xl absolute cursor-pointer top-4 right-4 hidden md:block'
                    />
                    <img alt='' src={imageSrc} className='w-full h-full object-contain' />
                </div>
            </Box>
        </Modal>
    )
}

export default ImageViewer
