import { useEffect, useState } from 'react'
import { Button, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import tw, { styled } from 'twin.macro'
import { map, includes, filter } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSimpleBackdropStore } from 'store/authAtom'
import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
import Loading from 'components/Loading'
import { useModalStore } from 'store/authAtom'
import { user } from 'service/userService'
import { myProfile } from 'service/crmService'

const ButtonStyle = styled(({ isSelect, ...rest }) => <Button {...rest} />)`
    ${(props) =>
        props.isSelect
            ? tw`bg-[#D4D6D9] text-[#333333] group-hover:text-white hover:bg-secondary-grey text-sm leading-[100%] font-medium normal-case py-[5.5px] min-h-[34px] w-fit px-3`
            : tw`bg-[#E9ECF3] text-[#4A4B59] group-hover:text-white hover:bg-[#E9ECF3] text-sm leading-[100%] font-medium normal-case py-[5.5px] min-h-[34px] w-fit px-3`}
`

export default function AddInierest({ interestExist }) {
    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()
    const { onClose } = useModalStore()
    const queryClient = useQueryClient()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [interestList, setInterestList] = useState([])

    const { data, isPending } = useQuery({
        queryKey: ['getAllInterest', language],
        queryFn: () =>
            user.getAllInterest({
                lang: language,
            }),
        select: (data) => {
            let interestExistCodeList = map((v) => v.interestCode, interestExist)
            return map(
                (item) => ({
                    ...item,
                    isSelect: includes(item.code, interestExistCodeList) ? true : false,
                }),
                data,
            )
        },
    })

    const updateInterest = useMutation({
        mutationFn: myProfile.updateInterest,
        onSuccess: () => {
            onCloseSimpleBackdrop()
            queryClient.invalidateQueries({
                queryKey: ['getMyProfile'],
            })

            onClose()
            navigate('/userSettings/myProfile')
        },
        onError: (e) => {
            onCloseSimpleBackdrop()
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onSelect = (item) => {
        setInterestList((list) => {
            return map((v) => {
                if (v.code === item.code) {
                    return {
                        ...v,
                        isSelect: !v.isSelect,
                    }
                } else {
                    return {
                        ...v,
                    }
                }
            }, list)
        })
    }

    const handleSave = () => {
        onOpenSimpleBackdrop()
        let selectedInterestList = filter((v) => v.isSelect === true, interestList)
        updateInterest.mutate({
            activityInterest: map((v) => ({ interestCode: v.code }), selectedInterestList),
            lang: 'en_US',
        })
    }

    useEffect(() => {
        setInterestList(data)
    }, [data])

    if (isPending) {
        return <Loading />
    }

    return (
        <Box>
            <Box sx={{ fontSize: 22, fontWeight: 600, paddingBottom: '32px', color: '#231448' }}>
                {t('myProfile.addInterest')}
            </Box>

            <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                {interestList?.map((item, i) => (
                    <ButtonStyle
                        key={i}
                        sx={{
                            '.MuiButton-endIcon': {
                                marginLeft: '0',
                                marginRight: '0',
                                paddingLeft: '4px',
                                paddingRight: '4px',
                            },
                        }}
                        isSelect={item.isSelect}
                        variant='contained'
                        endIcon={
                            !item.isSelect ? (
                                <div className='icon-plus text-sm leading-[100%]' />
                            ) : (
                                <div className='icon-close text-sm leading-[100%]' />
                            )
                        }
                        size='small'
                        onClick={() => onSelect(item)}
                    >
                        {item.name}
                    </ButtonStyle>
                ))}
            </Box>
            <Box sx={{ paddingX: { xs: 0, md: '26px' } }}>
                <DoubleBtn
                    leftText={t('myProfile.addINTEREST')}
                    leftFun={onClose}
                    rightText={t('myProfile.save')}
                    rightFun={handleSave}
                    rightDisable={updateInterest.isPending}
                />
            </Box>
        </Box>
    )
}
