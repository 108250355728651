import { Calendar } from '.'
import { isSameDay } from 'date-fns'
import { cn } from 'utils/cn'

export function RangeCalendar({ className, date, setDate }) {
    const isRange = date?.from && date?.to && !isSameDay(date.from, date.to)

    const onSelect = (selected) => {
        if (selected && typeof selected === 'object') {
            setDate(selected)
        }
    }

    return (
        <div className={cn('', className)}>
            <Calendar mode='range' numberOfMonths={2} selected={date} onSelect={onSelect} isRange={isRange} />
        </div>
    )
}
