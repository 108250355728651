import React, { useEffect, useMemo, useState } from 'react'
import Menu from '@mui/material/Menu'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Select({ name, setName, type, value, setValue }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const labels = useMemo(() => {
        return [
            { name: t('myProfile.upcoming'), val: 'upcoming' },
            { name: t('myProfile.past'), val: 'past' },
            type !== 'myTicket' && { name: t('myProfile.cancelled'), val: 'canceled' },
            type !== 'myTicket' && { name: t('myProfile.balloting'), val: 'BALLOT' },
        ].filter((i) => i)
    }, [type, language])
    useEffect(() => {
        if (type === 'myTicket' && (value === 'canceled' || value === 'BALLOT')) {
            setValue(labels[0].val)
            setName(labels[0])
        }
    }, [type, value])
    return (
        <>
            <div
                onClick={handleClick}
                className='self-center flex justify-between items-center cursor-pointer pl-4 py-2 rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px] w-[116px] pr-[6px] md:pr-[10px] md:w-[160px]'
            >
                <div className='text-base font-medium text-ellipsis whitespace-nowrap overflow-hidden w-full'>
                    {labels?.find((item) => item.val === name.val)?.name || ''}
                </div>
                <div className='icon-arrow_down' />
            </div>
            <Menu
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: 0,
                        borderTopRightRadius: 40,
                        mt: 1,
                        py: '15px',
                        width: 160,
                        fontFamily: language === 'en_US' ? 'Barlow' : 'MicrosoftJhengHei',
                    },
                }}
                className='flex flex-col gap-[15px]'
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {labels.map((label, index) => {
                    return (
                        <Box
                            className='cursor-pointer py-[8px] px-[20px] hover:bg-secondary-grey/20'
                            onClick={() => {
                                setValue(label.val)
                                setName(label)
                                handleClose()
                            }}
                            key={index}
                            sx={{ width: '100%' }}
                        >
                            {label.name}
                            {/* <FormControlLabel
                                sx={{
                                    width: '100%',
                                    pl: 4,
                                    justifyContent: 'space-between',
                                    flexDirection: 'row-reverse',
                                }}
                                label={<Box sx={{ fontSize: 14, fontWeight: 500 }}>{label.name}</Box>}
                                control={
                                    <Checkbox
                                        color='secondary'
                                        checked={label.val === value}
                                        onChange={() => {
                                            setValue(label.val)
                                            setName(label)
                                            handleClose()
                                        }}
                                    />
                                }
                            /> */}
                        </Box>
                    )
                })}
            </Menu>
        </>
    )
}

export default Select
