import { http } from 'utils/http'

export const visitorTipApi = {
    getAccessibilityInformation: (payload) => http.post('/public/vtAccessibility/information', payload),
    getAccessibilityServices: (payload) => http.post('/vt/getAccessibilityServices', payload),
    getGuestServices: (payload) => http.post('/vt/getGuestServices', payload),
    getOpeningHour: (payload) => http.post('/vt/getOpeningHour', payload),
    getSpecialAnnouncement: (payload) => http.post('/vt/getSpecialAnnouncement', payload),
    getSeeMore: (payload) => http.post('/vt/getSeeMore', payload),
}
