import * as React from 'react'
import { IconButton, CardMedia, Card, CardActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import bookmarkHit from 'resources/images/bookmarkHit.png'
import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { dateRangeFormat } from 'utils/filter'
import Content from 'components/Content'
import ProductModal from './ProductModal'
import { useDisclosure } from 'utils/useDisclosure'
const BookmarkIcon = tw.div`text-secondary-midnightBlue text-xl px-[2px]`
export default function ProductCard({ setCard, onOpen, card, isLanding = false }) {
    const {
        i18n: { language },
    } = useTranslation()

    return (
        <Card
            onClick={() => {
                setCard(card)
                onOpen()
            }}
            className='relative w-full shadow-none cursor-pointer bg-transparent group rounded-none'
        >
            <div className='aspect-[1/1]'>
                {/* image */}
                {card?.content && (
                    <CardMedia
                        component='img'
                        className='object-cover w-full h-full group-hover:scale-110 transition-all duration-300'
                        image={card.content}
                        alt='loading'
                    />
                )}

                {/* <RedLine /> */}
            </div>
            <div className={'pt-3'}>
                {/* description */}
                {card?.description && (
                    <div className='text-secondary-midnightBlue text-h5 break-words'>
                        {card.description}
                        {/* <Content data={card.promotionTitle} className='line-clamp-3' /> */}
                    </div>
                )}
            </div>
        </Card>
    )
}
