import React, { useState } from 'react'
import { Button, useMediaQuery } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'

import { dateRangeFormat } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
import BuyNowBtn from 'page/EventsTickets/components/BuyNowBtn'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
const Title = tw.div`w-[116px] text-sm md:text-base`
function TopIntro({ items }) {
    const {
        name,
        category,
        location,
        subLocal,
        specialRemarkDescription,
        specialRemarkTitle,
        mainBuilding,
        locationMap: venueImage,
        venueLocationUrl,
        contactNumber: enquiries,
        website,
        // acceptKDollar,
        merchantLogo,
        openHours,
    } = items
    const [isExpand, setIsExpand] = useState(false)
    const { t } = useTranslation()

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[750px] md:pt-10 md:pb-[60px]'>
                <NavigationPoint firstNav={t('shop.shopsTitle')} lastNav={name} firstNavLink={'/shops'} />
                <div className='flex items-center py-8'>
                    {merchantLogo && (
                        <div className='w-20 h-20 mr-3.5'>
                            <img src={merchantLogo} alt='logo' className='w-full h-full rounded-full' />
                        </div>
                    )}
                    {name && <div className='flex-1 text-h3 text-secondary-midnightBlue break-all'>{name}</div>}
                </div>
                {specialRemarkTitle && specialRemarkDescription && (
                    <ExclusiveOffer title={specialRemarkTitle} about={specialRemarkDescription} modalShowAll />
                )}

                <div className='flex gap-[10px]'>
                    <Title className='text-body2'>{t('shop.location')}</Title>
                    <div className='flex-1 text-body1 overflow-hidden break-words'>
                        {/* {mainBuilding && <div>{mainBuilding}</div>} */}
                        {location && <div>{location}</div>}
                        {/* {subLocal && <div>{subLocal}</div>} */}
                        {(venueImage || venueLocationUrl) && (
                            <div
                                className='flex items-center cursor-pointer gap-4'
                                onClick={() => setIsExpand(!isExpand)}
                            >
                                <div className='text-sm font-semiboldFamily underline text-[#0000EE]'>
                                    {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                                </div>
                                <div className='text-secondary-midnightBlue'>
                                    {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isExpand && (
                    <>
                        {venueImage && <ImageMap venueImage={venueImage} />}
                        {venueLocationUrl && (
                            <MapBtn venueLocationUrl={venueLocationUrl} btnCw={t('shop.goToGoogleMap')} />
                        )}
                    </>
                )}

                {openHours && openHours.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.openingHour')}</Title>
                        <div className='flex-1 text-body1 overflow-hidden break-words flex flex-col'>
                            {openHours.map((i, index) => {
                                return <div key={index}>{i}</div>
                            })}
                        </div>
                    </div>
                )}
                {enquiries && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.Enquiries')}</Title>
                        <div className='text-body1'>{enquiries}</div>
                    </div>
                )}
                {website && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.website')}</Title>
                        <div
                            onClick={() => window.open(website)}
                            className='flex-1 pr-2 text-body1 break-words overflow-hidden underline text-[#0000EE] cursor-pointer'
                        >
                            {website}
                        </div>
                    </div>
                )}

                {/* {acceptKDollar === 1 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('restaurant.accept')}</Title>
                        <div className='text-body1'>K Dollar</div>
                    </div>
                )} */}

                {category && category.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.type')}</Title>
                        <div className='flex flex-1 flex-wrap'>
                            {category.map((item, index) => {
                                return (
                                    <div key={index} className='text-body1'>
                                        {item + (index !== category.length - 1 ? ',' : '')}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopIntro
