import React, { useState } from 'react'
import { Button, useMediaQuery } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'

import { dateRangeFormat } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
import BuyNowBtn from 'page/EventsTickets/components/BuyNowBtn'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
const Title = tw.div`w-[116px] text-sm md:text-base`
function TopIntro({ items }) {
    const {
        eventName,
        dateRange,
        officialLink,
        eventType,
        local,
        subLocal,
        specialRemarkDescription,
        specialRemarkTitle,
        mainBuilding,
        venueImage,
        venueLocationUrl,
        ctaButtonStatus,
        displayDate,
        displayTime,
    } = items
    const [isExpand, setIsExpand] = useState(false)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')
    const formatDate = ({ Date }) => {
        return dayjs(Date).format('DD MMMM YYYY dddd')
    }
    const datesFormat = dateRange && dateRangeFormat(dateRange, language)

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[800px] md:pt-10 md:pb-[60px]'>
                <NavigationPoint firstNav={t('navigationBar.eventsTickets')} lastNav={eventName} firstNavLink={'/eventsTickets'} />
                <div className='text-h3 w-full text-secondary-midnightBlue py-8'>{eventName}</div>
                {specialRemarkTitle && specialRemarkDescription && (
                    <ExclusiveOffer title={specialRemarkTitle} about={specialRemarkDescription} />
                )}
                {displayDate && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('eventPage.date')}</Title>
                        <div className='text-body1 textarea-content'>{displayDate}</div>
                    </div>
                )}
                {displayTime && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('eventPage.time')}</Title>
                        <div className='textarea-content'>
                            {/*{dateRange.map((item, index) => {*/}
                            {/*    return (*/}
                            {/*        <div key={index} className='flex-1 text-body1'>*/}
                            {/*            {dayjs(item.eventStartDateTime).format('HH:mm') +*/}
                            {/*                ' - ' +*/}
                            {/*                dayjs(item.eventEndDateTime).format('HH:mm dddd')}*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*})}*/}
                            {displayTime}
                        </div>
                    </div>
                )}

                <div className='flex gap-[10px]'>
                    <Title className='text-body2 shrink-0'>{t('venue.location')}</Title>
                    <div className='flex-1 text-body1 overflow-hidden break-words'>
                        {mainBuilding && <div>{mainBuilding}</div>}
                        {local && <div>{local}</div>}
                        {subLocal && <div>{subLocal}</div>}
                        {(venueImage || venueLocationUrl) && (
                            <div
                                className='flex items-center cursor-pointer gap-4'
                                onClick={() => setIsExpand(!isExpand)}
                            >
                                <div className='text-sm font-semiboldFamily underline text-[#0000EE]'>
                                    {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                                </div>
                                <div className='text-secondary-midnightBlue'>
                                    {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isExpand && (
                    <>
                        {venueImage && <ImageMap venueImage={venueImage} />}
                        {venueLocationUrl && <MapBtn venueLocationUrl={venueLocationUrl} />}
                    </>
                )}
                {officialLink && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('eventPage.officialLink')}</Title>
                        <div
                            onClick={() => window.open(officialLink)}
                            className='flex-1 pr-2 text-body1 break-words overflow-hidden underline cursor-pointer'
                        >
                            {officialLink}
                        </div>
                    </div>
                )}
                {eventType && eventType.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('eventPage.type')}</Title>
                        <div className='flex flex-1 flex-wrap'>
                            {eventType.map((item, index) => {
                                return (
                                    <div key={index} className='text-body1'>
                                        {item + (index !== eventType.length - 1 ? ',' : '')}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
            <>
                {isPC ? (
                    <BuyNowBtn items={items} times={dateRange} datesFormat={datesFormat} />
                ) : (
                    createPortal(
                        <BuyNowBtn items={items} times={dateRange} datesFormat={datesFormat} />,
                        document.getElementById('main-container'),
                    )
                )}
            </>
        </div>
    )
}

export default TopIntro
