import React, { useState, useEffect, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'

import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'

const SwiperCard = React.memo(function SwiperCard({ yearArr, now }) {
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)

    const goToNextSlide = useCallback(() => {
        swiper?.slideNext()
    }, [swiper])

    const goToPrevSlide = useCallback(() => {
        swiper?.slidePrev()
    }, [swiper])

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(0, 0)
        }
    }, [now, swiper])

    return (
        <div>
            <Swiper
                modules={[Navigation]}
                loop={true}
                spaceBetween={50}
                slidesPerView={1}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                initialSlide={0}
            >
                {yearArr.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className=' text-white'>
                                <div className='flex gap-4 flex-col lg:flex-row'>
                                    <div className='w-[290px] max-w-full h-[163px]'>
                                        <img
                                            alt={item.altText}
                                            src={item.image}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                    <div className='flex-1 text-sm font-medium'>{item.description}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {yearArr.length > 1 && (
                <div className='pt-8 flex justify-between pr-5 flex-col-reverse gap-4 md:items-center md:flex-row'>
                    <Steps
                        swiperLength={yearArr}
                        slideIndex={slideIndex}
                        stepTextColor={'text-white'}
                        stepActiveColor={'bg-white'}
                    />
                    <IconButtonChange
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#FFFFFF'}
                        borderColor={'border-white'}
                    />
                </div>
            )}
        </div>
    )
})

export default SwiperCard
