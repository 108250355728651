import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { loginReg } from 'service/authService'
import { useModalStore, useUserInfoAtom } from 'store/authAtom'
import { displayEmail } from 'utils/filter'

import UpdateEmail from 'page/UserSettings/EditMyProfile/components/UpdateEmail'
import DoubleBtn from 'page/UserSettings/components/DoubleBtn'

export default function VerifyEmail({ newEmail }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const profileInfo = useUserInfoAtom((state) => state.profileInfo)
    const { setModalComponent, onClose, onOpen } = useModalStore()

    const [count, setCount] = useState(60)

    const sendEmailMutation = useMutation({
        mutationFn: loginReg.sendEmail,
        onSuccess: () => {
            setCount(60)
            // enqueueSnackbar(t('myProfile.VerifyEmail'), { variant: 'success' })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onResend = () => {
        sendEmailMutation.mutate({
            emailId: profileInfo.emailId,
            lang: profileInfo.languagePreference,
            newEmail,
            nickName: profileInfo.nickname,
            plateType: 'web',
            type: 'update',
            sysLang: language,
        })
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                setCount(count - 1)
            }
        }, 1000)
    }, [count])

    return (
        <Box sx={{ color: '#231448' }}>
            <Box sx={{ fontSize: 22, fontWeight: 600, paddingBottom: 4 }}>{t('myProfile.verifyEmailAddress')}</Box>
            <Box sx={{ fontSize: 14, fontWeight: 500, paddingBottom: 4 }}>
                {t('myProfile.verifyEmailAddressAbout', {
                    emailId: displayEmail(newEmail),
                })}
            </Box>
            <Box component='span' sx={{ fontSize: 14, fontWeight: 500, paddingBottom: 4 }}>
                <Trans
                    i18nKey='myProfile.resendEmail'
                    components={[
                        <Box
                            component='span'
                            onClick={() => {
                                setModalComponent(<UpdateEmail />)
                            }}
                            sx={{ color: '#EC0032', textDecoration: 'underline', cursor: 'pointer' }}
                        />,
                    ]}
                />
            </Box>
            <Box sx={{ fontSize: 14, fontWeight: 500, textAlign: 'center' }}>{'(' + count + ')'}</Box>
            <DoubleBtn
                leftText={t('myProfile.cancelVerifyEmail')}
                leftFun={onClose}
                rightText={t('myProfile.resend')}
                rightFun={onResend}
                rightDisable={count !== 0}
            />
        </Box>
    )
}
