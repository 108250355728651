import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import { filterName } from 'utils/filter'
import EventCard from 'page/EventsTickets/components/EventCard'

function SwiperCards({ items = [], setSwiper, setSlideIndex }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <Swiper
            spaceBetween={24}
            loop={items.length > 2}
            modules={[Navigation]}
            onSwiper={(swiper) => {
                setSwiper(swiper)
            }}
            onActiveIndexChange={(swiper) => {
                setSlideIndex(swiper.realIndex)
            }}
            breakpoints={{
                0: { slidesPerView: 2 },
                1024: {
                    slidesPerView: 3,
                },
                1440: {
                    slidesPerView: 4,
                },
            }}
            className='mySwiper'
        >
            {items.map((card, index) => {
                return (
                    <SwiperSlide key={index}>
                        <EventCard
                            path='/programmesDetail'
                            card={{
                                ...card,
                                uuid: card.programmeId,
                                eventName: card.programmeName,
                                label: card.label,
                                local:
                                    card.venues?.length > 0
                                        ? filterName(
                                              {
                                                  nameEn: card.venues[0].enName,
                                                  nameCn: card.venues[0].scName,
                                                  nameZh: card.venues[0].tcName,
                                              },
                                              language,
                                          )
                                        : '',
                                cardImage: card.cardImage,
                                dateRange: [
                                    card.startDate &&
                                        card.endDate && {
                                            eventStartDateTime: card.startDate,
                                            eventEndDateTime: card.endDate,
                                        },
                                ].filter((i) => i),
                            }}
                            listType='grid'
                        />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default SwiperCards
