import { http } from 'utils/http'

/**
 * User API
 */
export const user = {
    getCountryCodeList: (payload) => http.post('/user/getCountryCodeList', payload),
    forgotPassword: (payload) => http.post('/user/forgotPassword', payload),
    resetPassword: (payload) => http.post('/user/resetPassword', payload),
    getInterest: (payload) => http.post('/user/getInterest', payload),
    getAllInterest: (payload) => http.post('/loginReg/getInterest', payload),
    logout: (payload) => http.post('/loginReg/logout?refreshToken=' + payload),
}
