import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'

import { formatStartDateEndDate } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
import NumSpace from 'page/CarPark/components/NumSpace'
import { cx } from 'class-variance-authority'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const Title = tw.div`w-[116px] text-sm md:text-base`
function TopIntro({ items }) {
    const {
        carParkName,
        specialRemarkDescription,
        specialRemarkTitle,
        venue,
        venueImage,
        venueLocationUrl,
        openHours,
        amenities,
        isCarParkOff,
        vacancy,
        lastUpdatedTime,
    } = items
    const [isExpand, setIsExpand] = useState(false)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')

    const maoUrl = (longitude, latitude) => {
        return language === 'zh_CN'
            ? `https://uri.amap.com/marker?position=${longitude},${latitude}`
            : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    }
    const numSpacesData = { time: lastUpdatedTime, num: vacancy, isCarParkOn: !isCarParkOff, carParkName }

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[800px] md:pt-8 md:pb-[48px]'>
                <NavigationPoint firstNav={t('carPark.title')} lastNav={carParkName} firstNavLink={'/carPark'} />
                <div className='text-h3 w-full text-secondary-midnightBlue py-4'>{carParkName}</div>
                {specialRemarkTitle && specialRemarkDescription && (
                    <ExclusiveOffer title={specialRemarkTitle} about={specialRemarkDescription} />
                )}
                {openHours && openHours.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('eventPage.time')}</Title>
                        <div>
                            {openHours.map((time, index) => {
                                return (
                                    <div key={index} className='flex-1 text-body1'>
                                        {time}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}

                <div className='flex gap-[10px]'>
                    <Title className='text-body2'>{t('venue.location')}</Title>
                    <div className='flex-1 text-body1 overflow-hidden break-words'>
                        {venue && <div>{venue}</div>}
                        {(venueImage || venueLocationUrl) && (
                            <div
                                className='flex items-center cursor-pointer gap-4'
                                onClick={() => setIsExpand(!isExpand)}
                            >
                                <div className='text-sm font-semiboldFamily underline text-[#0000EE]'>
                                    {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                                </div>
                                <div className='text-secondary-midnightBlue'>
                                    {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isExpand && (
                    <>
                        {venueImage && <ImageMap venueImage={venueImage} />}
                        {venueLocationUrl && <MapBtn venueLocationUrl={venueLocationUrl} />}
                    </>
                )}

                {amenities && amenities.length > 0 && (
                    <div className='bg-secondary-blueThin text-secondary-balck p-5 space-y-3 w-full md:max-w-[715px]'>
                        {/* <Title className='text-body2'>{t('carPark.facilities')}</Title> */}
                        <div className='flex gap-3 gap-y-5 flex-wrap md:gap-5'>
                            {amenities.map((facility, index) => {
                                return (
                                    <div key={index} className='flex items-center gap-1'>
                                        <div className='icon-tickCarPark'></div>
                                        <div className='text-body1'>{facility.name}</div>
                                    </div>
                                )
                            })}
                            {/* </div> */}
                        </div>
                    </div>
                )}
            </div>

            <>
                {isPC ? (
                    <NumSpace items={numSpacesData} />
                ) : (
                    createPortal(<NumSpace items={numSpacesData} />, document.getElementById('main-container'))
                )}
            </>
        </div>
    )
}

export default TopIntro
