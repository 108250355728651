import React from 'react'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'

function SearchInput({ searchVal, setSearchVal, onBlurFun, setIsSearch, searchHitText = false }) {
    const { t } = useTranslation()
    return (
        <FormControl
            sx={{
                bgcolor: 'white',
                '.MuiInputBase-root': { bgcolor: 'white', pl: 0 },
                '.MuiInputBase-input': { padding: 0, pl: 1 },
            }}
            variant='filled'
        >
            <FilledInput
                type={'text'}
                value={searchVal}
                color='secondary'
                onChange={(e) => {
                    setSearchVal(e.target.value)
                }}
                className='m-0 h-[36px]'
                onBlur={async () => {
                    await onBlurFun()
                    setIsSearch && setIsSearch(true)
                }}
                onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                        await onBlurFun()
                        setIsSearch && setIsSearch(true)
                    }
                }}
                placeholder={searchHitText ? searchHitText : t('eventPage.searchAbout')}
                startAdornment={
                    <InputAdornment position='end'>
                        <div
                            onClick={async () => {
                                await onBlurFun()
                                setIsSearch && setIsSearch(true)
                            }}
                            className='icon-explore_hit cursor-pointer font-medium text-secondary-midnightBlue'
                        />
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default SearchInput
