import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import tw from 'twin.macro'

import { useSpacialTrafficNews } from 'store/authAtom'
import { carParkService } from 'service/carParkService'
import Banner from 'components/shopDine/Banner'
import bannerTop from 'resources/images/parkingBanner.jpg'
import CarParkCard from 'page/CarPark/components/CarParkCard'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import { useEffect } from 'react'
import { dateFormat3 } from 'utils/util'
const Wrap = tw.div``

export default function CarPark() {
    const { setData } = useSpacialTrafficNews()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: bannerTop,
        title: t('carPark.title'),
        about: t('carPark.bannerAbout'),
    }

    const { data: carParkData, isPending: cardsPending } = useQuery({
        queryKey: ['getCarParkList', language],
        queryFn: () =>
            carParkService.getList({
                lang: language,
                isWeb: true,
            }),
        placeholderData: {
            list: [],
            lastUpdatedTime: '',
        },
    })
    const { data: promotionList, isPending: promotionListPending } = useQuery({
        queryKey: ['getListPromotion', language],
        queryFn: () =>
            carParkService.getListPromotion({
                lang: language,
                size: 5,
            }),
        placeholderData: [],
    })
    const { data: newsData } = useQuery({
        queryKey: ['getSpecialNews', language],
        queryFn: () =>
            carParkService.getSpecialNews({
                lang: language,
            }),
        placeholderData: null,
    })
    useEffect(() => {
        if (newsData) {
            setData({
                announcementTitle: newsData?.title ?? '',
                announcementMessage: newsData?.message ?? '',
                publishDate: newsData?.lastUpdatedTime ?? '',
            })
        }
    }, [newsData])
    return (
        <Wrap>
            <Banner banner={banner} />
            <div className='bg-container'>
                {carParkData?.lastUpdatedTime && (
                    <div className='font-regular text-right pt-8 text-[12px] md:text-[14px] leading-[120%]'>
                        {t('carPark.lastUpdatedOn') + dateFormat3(language, carParkData.lastUpdatedTime)}
                    </div>
                )}
                <div className='grid gap-x-[10px] gap-y-4 pt-8 grid-cols-1 sm:grid-cols-2 md:gap-x-6 md:gap-y-6 lg:grid-cols-3 lg:gap-y-10'>
                    {carParkData.list.length > 0 &&
                        carParkData.list.map((card, index) => {
                            return <CarParkCard key={index} card={card} />
                        })}
                </div>
            </div>
            {promotionList && promotionList?.length > 0 && <PromotionCards items={promotionList} bgColor='#fff' />}
        </Wrap>
    )
}
