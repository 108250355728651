import React from 'react'
import { useTranslation } from 'react-i18next'

import TextContainer from 'page/Transportation/components/TextContainer'
import guide from 'resources/images/mtrBanner.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import BusCommonComponent from 'page/Transportation/components/BusCommonComponent'
import busIntro from 'resources/images/busIntro.png'
function Minibus() {
    const { t } = useTranslation()
    return (
        <WrapBox
            image={guide}
            component={
                <TextContainer
                    title={t('transport.byMinibus')}
                    link={'https://www.kmb.hk/'}
                    isWeb={t('transport.busWeb')}
                    endWord={t('transport.busAboutEnd')}
                    about={t('transport.miniBusAbout')}
                />
            }
            bomComponent={
                <BusCommonComponent
                    isBus={true}
                    topFun={() => {}}
                    busItems={[
                        {
                            isBus: true,
                            topFun: () => {},
                            image: busIntro,
                            wording: {
                                routes: [{ busRoute: '213X', busRouteAbout: t('transport.routeIntro') }],
                                busStopBtn: t('transport.kowloonCityFerry'),
                                walkDistDetail: t('transport.kowloonCityFerryDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: busIntro,
                            wording: {
                                routes: [{ busRoute: '24', busRouteAbout: t('transport.routeBomIntro') }],
                                busStopBtn: t('transport.HKSocietyForTheBlind'),
                                walkDistDetail: t('transport.HKSocietyForTheBlindDistance'),
                            },
                        },
                    ]}
                />
            }
        />
    )
}

export default Minibus
