import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { information } from 'service/informationService'
import TCPrivacyDisclaimerComponent from 'page/ConnectWithKTSP/TC/components/TCPrivacyDisclaimerComponent'
function Disclaimer() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [content, setContent] = useState()
    const getDisclaimer = useMutation({
        mutationFn: information.getAdditionalInformation,
        onSuccess: (res) => {
            setContent(res[0].content)
        },
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        getDisclaimer.mutate({ lang: language, type: [4] })
    }, [language])
    return (
        <TCPrivacyDisclaimerComponent
            isPending={getDisclaimer.isPending}
            title={t('footer.disclaimer')}
            content={content}
        />
    )
}

export default Disclaimer
