import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import Content from 'components/Content'

const Label = tw.div`pt-[18px] pb-6 pr-5 flex justify-between cursor-pointer items-center text-secondary-midnightBlue text-2xl active:opacity-50`
function ThingsLabel({ item }) {
    const [isExpand, setExpand] = useState(false)

    return (
        <div className='border-solid border-secondary-grey border-b-[1px]'>
            <Label className='text-body2' onClick={() => setExpand(!isExpand)}>
                <div>{item.title}</div>
                {isExpand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            </Label>
            {isExpand && <Content data={item.content} className='text-body1 pb-6' />}
        </div>
    )
}

function ThingTonoteSection({ items }) {
    const { t } = useTranslation()
    const { hideThingsToNote, thingsToNote } = items
    if (hideThingsToNote || thingsToNote.length === 0) {
        return null
    }
    return (
        <div className='text-secondary-midnightBlue'>
            <div className='text-h4 pb-3'>{t('eventPage.thingsToNote')}</div>
            {!hideThingsToNote && thingsToNote && thingsToNote.length > 0 && (
                <div>
                    {thingsToNote.map((item, index) => {
                        return <ThingsLabel item={item} index={item} key={index} />
                    })}
                </div>
            )}
        </div>
    )
}

export default ThingTonoteSection
