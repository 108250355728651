import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { landing } from 'service/landingService'
import TitleGradient from 'components/landingComponents/TitleGradient'
import Loading from 'components/Loading'

function CreateCheRishedMemories({ tags = [] }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [identityId, setIdentityId] = useState(tags[0].id)
    const { data, isPending } = useQuery({
        queryKey: ['getCherishedMemoriesImage', language, identityId],
        queryFn: () =>
            landing.getCherishedMemoriesImage({
                indetityId: identityId,
                lang: language,
                size: 6,
            }),
    })

    return (
        <>
            {
                <div className='pt-8 md:pt-[120px]'>
                    <div className='w-full flex justify-center items-center text-center flex-col bg-container '>
                        <TitleGradient text={t('landing.createCherishedMemories')} />
                        <div className='flex justify-center flex-wrap bg-blue font-medium text-secondary-midnightBlue gap-1 text-base leading-[120%] md:text-[30px] md:gap-2 '>
                            <div className=''>{t('landing.shareAbout')}</div>
                            <div className='pb-5 md:pb-6 lg:pb-10 xl:pb-[60px]'>
                                {tags.map((indetity, index) => {
                                    return (
                                        <span
                                            className='font-semiboldFamily cursor-pointer active:opacity-50'
                                            onClick={() => setIdentityId(indetity.id)}
                                            key={index}
                                        >
                                            #{indetity.hashTag + (index !== tags.length - 1 ? ', ' : '')}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        {isPending ? (
                            <Loading />
                        ) : Array.isArray(data) && data.length > 0 ? (
                            data.map((img, index) => {
                                return (
                                    <div className='w-1/2 md:flex-1' key={index}>
                                        <img
                                            alt='loading'
                                            className='w-full object-cover aspect-square'
                                            src={img.image}
                                        />
                                    </div>
                                )
                            })
                        ) : null}
                    </div>
                </div>
            }
        </>
    )
}

export default CreateCheRishedMemories
