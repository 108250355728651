import React from 'react'
import RowList from './RowList'

// import { Container } from './styles';

function OfficialSection({ title, brandAbouts, id, targetRef }) {
    return (
        <div className='flex justify-center w-full'>
            <div className='w-full xl:w-[65%] text-secondary-midnightBlue pb-5 mt-20'>
                <div className='text-h2 uppercase pb-8 text-center'>{title}</div>
                <RowList items={brandAbouts} targetRef={targetRef} id={id} />
            </div>
        </div>
    )
}

export default OfficialSection
