import { useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
const DateComponent = ({ datesFormat, times }) => {
    const {
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')
    const formatStartDateEndDate = (startDate, endDate, language) => {
        const formatModelStart = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
        const formatModelEnd = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
        const formatModelEndSameMonth = language === 'en_US' ? 'D MMM YYYY' : 'D日'
        const formatModelStartSameMonth = language === 'en_US' ? 'D' : 'YYYY年M月D'
        const formatModelEndSameYear = language === 'en_US' ? 'D MMM YYYY' : 'M月D日'
        const formatModelStartSameYear = language === 'en_US' ? 'D MMM' : 'YYYY年M月D日'
        const formatStartDate = startDate ? dayjs(startDate).format(formatModelStart) : null
        const formatEndDate = endDate ? dayjs(endDate).format(formatModelEnd) : null
        const isSameMonth = dayjs(startDate).format('YYYY M') === dayjs(endDate).format('YYYY M')
        const isSameDay = dayjs(startDate).format('YYYY M D') === dayjs(endDate).format('YYYY M D')
        const isSameYear =
            dayjs(startDate).format('YYYY') === dayjs(endDate).format('YYYY') &&
            dayjs(startDate).format('YYYY M') !== dayjs(endDate).format('YYYY M')
        const gap = language === 'en_US' ? ' - ' : '至'
        return startDate && endDate && isSameMonth && !isSameDay ? (
            dayjs(startDate).format(formatModelStartSameMonth) + gap + dayjs(endDate).format(formatModelEndSameMonth)
        ) : startDate && endDate && isSameYear && !isSameMonth ? (
            language === 'en_US' ? (
                dayjs(startDate).format(formatModelStartSameYear) + gap + dayjs(endDate).format(formatModelEndSameYear)
            ) : (
                <div>
                    <div>{dayjs(startDate).format(formatModelStart) + gap + dayjs(endDate).format('M')}</div>
                    <div>{dayjs(endDate).format('月D日')}</div>
                </div>
            )
        ) : startDate && endDate && isSameDay ? (
            formatStartDate
        ) : startDate && endDate ? (
            <div>
                <div>
                    {dayjs(startDate).format(formatModelStart) + gap}
                    {language === 'en_US' ? dayjs(endDate).format('DD MMM') : ''}
                </div>
                <div>
                    {language === 'en_US' ? dayjs(endDate).format('YYYY') : dayjs(endDate).format('YYYY年M月D日')}
                </div>
            </div>
        ) : null
    }
    const dateRangeFormat = (dateRange = [], language) => {
        const formatModelStart = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
        return dateRange.length > 1 &&
            dayjs(dateRange[0].eventStartDateTime).format('D MMM YYYY') !==
                dayjs(dateRange[dateRange.length - 1].eventEndDateTime).format('D MMM YYYY')
            ? formatStartDateEndDate(
                  dateRange[0].eventStartDateTime,
                  dateRange[dateRange.length - 1].eventEndDateTime,
                  language,
              )
            : dateRange.length > 1 &&
              dayjs(dateRange[0].eventStartDateTime).format('D MMM YYYY') ===
                  dayjs(dateRange[dateRange.length - 1].eventEndDateTime).format('D MMM YYYY')
            ? dayjs(dateRange[0].eventStartDateTime).format(formatModelStart)
            : dateRange.length === 1
            ? formatStartDateEndDate(dateRange[0].eventStartDateTime, dateRange[0].eventEndDateTime, language)
            : null
    }
    return (
        <div className='flex-1 font-boldFamily text-[24px] md:text-[32px] leading-[120%] textarea-content'>
            {datesFormat && (isPC ? <div>{dateRangeFormat(times, language)}</div> : <div>{datesFormat}</div>)}
        </div>
    )
}
export default DateComponent
