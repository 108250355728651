import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import PromotionCard from 'page/Promotions/components/PromotionCard'
function SwiperPromotionCards({ items, setSwiper, setSlideIndex }) {
    return (
        <Swiper
            spaceBetween={26}
            // loop={items.length > 2}
            modules={[Navigation]}
            onSwiper={(swiper) => {
                setSwiper(swiper)
            }}
            onActiveIndexChange={(swiper) => {
                setSlideIndex(swiper.realIndex)
            }}
            breakpoints={{
                0: { slidesPerView: 2 },
                1024: {
                    slidesPerView: 3,
                },
            }}
            className='mySwiper'
        >
            {items.map((card, index) => {
                return (
                    <SwiperSlide key={index}>
                        <PromotionCard card={card} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default SwiperPromotionCards
