import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

import { isImage } from 'utils/filter'
import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import { Button } from '@mui/material'

function BannerSwiper({ banners = [] }) {
    const navigate = useNavigate()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const [isPlay, setIsPlay] = useState(true)
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    useEffect(() => {
        if (banners.length > 0) {
            const video = !isImage(banners[slideIndex].visual)
            if (video && swiper) {
                setIsPlay(true)
                swiper.autoplay.stop()
                // setTimeout(() => {
                //     goToNextSlide()
                // }, 2000)
            } else {
                swiper && swiper?.autoplay.start()
            }
        }
    }, [slideIndex])
    return (
        <div>
            <Swiper
                loop={true}
                modules={[Navigation, Autoplay]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                spaceBetween={50}
                slidesPerView={1}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                initialSlide={0}
            >
                {banners.length > 0 &&
                    banners.map((img, index) => {
                        return (
                            <SwiperSlide key={index} className='swiper-slide aspect-square lg:aspect-video'>
                                {isImage(img.visual) ? (
                                    <div className='w-full h-full'>
                                        <img
                                            className='z-10 relative w-full h-full object-cover object-right lg:object-center textarea-content'
                                            alt={img.altText}
                                            src={img.visual}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className='w-full h-full bg-black'
                                        onClick={() => {
                                            setIsPlay(!isPlay)
                                        }}
                                    >
                                        <ReactPlayer
                                            config={{
                                                file: {
                                                    attributes: {
                                                        style: {
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            ...(window.innerWidth >= 1024
                                                                ? { objectPosition: 'right' }
                                                                : {}),
                                                        },
                                                    },
                                                },
                                            }}
                                            playsinline={true}
                                            playing={slideIndex === index && isPlay}
                                            muted
                                            controls={false}
                                            width='100%'
                                            onEnded={() => goToNextSlide()}
                                            height='100%'
                                            url={img.visual}
                                        />
                                    </div>
                                )}
                            </SwiperSlide>
                        )
                    })}
                {
                    <div
                        onClick={() => {
                            banners[slideIndex].webUrl && window.open(banners[slideIndex].webUrl)
                        }}
                        className='flex justify-between text-white font-medium w-full px-[20px] relative pt-5 md:px-[60px] md:pt-8 lg:z-10 lg:left-0 lg:absolute lg:pl-[60px] lg:pr-0 lg:top-0 lg:bottom-0 lg:pt-[64px] lg:w-[44.2%] xl:pt-[180px] xl:pl-[150px]'
                    >
                        <div className='absolute -z-10 left-0 w-full bg-website-gradation top-0 bottom-0 lg:opacity-80'></div>
                        <div className='cursor-pointer w-[73%] lg:pb-[43px] lg:flex lg:flex-col lg:justify-between lg:w-full lg:pr-8 xl:pb-[60px] xl:pr-[6.6%]'>
                            <div>
                                {banners[slideIndex].titleFirst && (
                                    <div className='text-sm mb-2 break-words leading-[120%] md:text-xl xl:mb-3'>
                                        {banners[slideIndex].titleFirst}
                                    </div>
                                )}
                                {banners[slideIndex].titleMain && (
                                    <div className='break-words text-2xl leading-[128%] xl:text-[42px] overflow-hidden mb-4 xl:mb-6'>
                                        {banners[slideIndex].titleMain}
                                    </div>
                                )}
                                {banners[slideIndex].description && (
                                    <div className='overflow-hidden textarea-content text-body1 hidden mb-4 md:block lg:mb-6 xl:mb-8'>
                                        {banners[slideIndex].description}
                                    </div>
                                )}
                                {banners[slideIndex].buttonTitle && (
                                    <Button
                                        onClick={() => {
                                            const { buttonAction, actionUuid } = banners[slideIndex]
                                            buttonAction === 1
                                                ? window.open(banners[slideIndex].externalLink)
                                                : buttonAction === 2
                                                ? navigate('/eventsTicketsDetail?id=' + actionUuid)
                                                : navigate('/promotionsDetail?id=' + actionUuid)
                                        }}
                                        className='normal-case border-[1px] text-button1 px-5 h-fit py-[7px] min-w-[240px] group'
                                        variant='primary'
                                        size='large'
                                    >
                                        <span className='bg-website-gradation inline-block text-transparent hover:text-white bg-clip-text group-hover:text-white'>
                                            {banners[slideIndex].buttonTitle}
                                        </span>
                                    </Button>
                                )}
                            </div>

                            <div className='flex justify-between items-center mt-4 z-20 mb-[20px] md:mt-6 md:mb-[42px] lg:mb-0 lg:mt-0 w-full '>
                                <Steps
                                    swiperLength={banners}
                                    slideIndex={slideIndex}
                                    stepTextColor={'text-white'}
                                    stepActiveColor={'bg-white'}
                                    isLanding={1}
                                />
                                {banners.length > 1 && (
                                    <div className='hidden lg:block'>
                                        <IconButtonChange
                                            goToNextSlide={goToNextSlide}
                                            goToPrevSlide={goToPrevSlide}
                                            buttonColor={'#FFFFFF'}
                                            borderColor={'border-white'}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {banners.length > 1 && (
                            <div className='block lg:hidden'>
                                <IconButtonChange
                                    goToNextSlide={goToNextSlide}
                                    goToPrevSlide={goToPrevSlide}
                                    buttonColor={'#FFFFFF'}
                                    borderColor={'border-white'}
                                    landingBanner=' flex-col w-[52.93px] h-[52.93px] md:w-[60px] md:h-[60px] '
                                />
                            </div>
                        )}
                    </div>
                }
            </Swiper>
        </div>
    )
}

export default BannerSwiper
