import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import tw from 'twin.macro'

import { venueServices } from 'service/venueService'
import Banner from 'components/shopDine/Banner'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import bannerTop from 'resources/images/venueBanner.jpg'
import AllVenue from 'page/Venue/components/AllVenue'
import Loading from 'components/Loading'

const Wrap = tw.div``

export default function Venue() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: bannerTop,
        title: t('venue.title'),
        about: t('venue.bannerAbout'),
    }

    const { data: bannerSwiperImgs } = useQuery({
        queryKey: ['getVneueHeroBanners', language],
        queryFn: () =>
            venueServices.getHeroBannerList({
                lang: language,
                searchKey: '',
            }),
    })

    return (
        <Wrap>
            <Banner banner={banner} />
            {bannerSwiperImgs?.length > 0 && <BannerSwiper type='venue' bannerSwiperImgs={bannerSwiperImgs} />}
            <AllVenue />
        </Wrap>
    )
}
