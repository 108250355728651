import React from 'react'
import { Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

import { useModalStore } from 'store/authAtom'

const style = {
    position: 'relative',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    padding: 10,
}
const LittleBox = {
    width: { xs: '100vw', md: 704 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: { xs: '60px', md: '80px' },
    borderBottomRightRadius: { xs: 0, md: 0 },
    borderBottomLeftRadius: { xs: 0, md: 0 },
    borderTopLeftRadius: { xs: 0, md: 0 },
    borderWidth: 0,
}
export default function MainModal() {
    const { modalComponent, isOpen, onClose, colorCloseIcon, closeFn } = useModalStore()
    return (
        <Modal
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
            open={isOpen}
            onClose={() => {
                closeFn && closeFn()
                onClose()
            }}
            slotProps={{
                root: { className: 'shadow-card' },
                backdrop: {
                    className: 'backdrop-blur-[10px] bg-[#333] bg-opacity-20',
                },
            }}
        >
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={style}>
                    <Box sx={LittleBox} className='overflow-hidden'>
                        <Box
                            sx={{
                                overflow: 'auto',
                                minHeight: '30vh',
                                maxHeight: '80vh',
                                p: { xs: '20px', md: '32px' },
                            }}
                        >
                            {modalComponent}
                        </Box>
                        <Box
                            className='icon-close'
                            sx={{
                                zIndex: 50,
                                position: 'absolute',
                                top: 60,
                                right: { xs: 80, md: 80 },
                                cursor: 'pointer',
                                p: { xs: '0  8px 8px 0', md: 0 },
                                color: colorCloseIcon,
                            }}
                            onClick={() => {
                                closeFn && closeFn()
                                onClose()
                            }}
                        ></Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
