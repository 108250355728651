import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Box } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { useSimpleBackdropStore } from 'store/authAtom'
import { useModalStore } from 'store/authAtom'
import { loginReg } from 'service/authService'
import { myProfile } from 'service/crmService'

import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
import UpdatePhone from 'page/UserSettings/EditMyProfile/components/UpdatePhone'

export default function VerifyPhone({ countryCodeList, countryCode, mobileNo }) {
    const [otp, setOtp] = useState('')
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [count, setCount] = useState(60)
    const { setModalComponent, onClose } = useModalStore()

    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()

    const updateEmailAndPhoneMutation = useMutation({
        mutationFn: myProfile.updateEmailAndPhone,
        onSuccess: () => {
            onClose()
            onCloseSimpleBackdrop()
            queryClient.invalidateQueries({
                queryKey: ['getMyProfile'],
            })
            navigate('/userSettings/myProfile')
            enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
            onCloseSimpleBackdrop()
        },
    })

    const otpVerifiyMutation = useMutation({
        mutationFn: loginReg.otpVerifiy,
        onSuccess: () => {
            onOpenSimpleBackdrop()
            updateEmailAndPhoneMutation.mutate({
                countryCode,
                mobileNo,
                emailId: '',
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onVerify = () => {
        otpVerifiyMutation.mutate({
            countryCode,
            mobileNo,
            lang: language,
            verifyCode: otp,
        })
    }
    const sendOtpMutation = useMutation({
        mutationFn: loginReg.sendOtp,
        onSuccess: (v) => {
            // enqueueSnackbar(t('myProfile.verifyPhoneLast'), { variant: 'success' })
            setCount(60)
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })
    const resendOtp = () => {
        if (count > 0) {
            return
        }
        sendOtpMutation.mutate({
            countryCode: countryCode,
            lang: language,
            mobileNo: mobileNo,
            newCountryCode: '',
            newMobileNo: '',
            verifyType: 0,
        })
    }
    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                setCount(count - 1)
            }
        }, 1000)
    }, [count])
    return (
        <Box>
            <Box sx={{ fontSize: 22, fontWeight: 600, paddingBottom: 4 }}>{t('myProfile.verifyPhone')}</Box>
            <Box component='span' sx={{ fontSize: 14, fontWeight: 500, paddingBottom: 4 }}>
                {t('myProfile.verifyPhoneFirst') +
                    ' ' +
                    '+' +
                    countryCode +
                    ' ' +
                    mobileNo?.slice(0, 4) +
                    '*'.repeat(5) +
                    ' ' +
                    t('myProfile.verifyPhoneLast')}
                <Box
                    component='span'
                    onClick={() => {
                        setModalComponent(<UpdatePhone countryCodeList={countryCodeList} />)
                    }}
                    sx={{ color: '#EC0032', textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {t('myProfile.updatePhone')}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <MuiOtpInput
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        '& .MuiInputBase-root': {
                            borderRadius: '12px',
                            width: '50px',
                            height: '60px',
                        },
                    }}
                    length={4}
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    TextFieldsProps={{ placeholder: '-' }}
                />
            </Box>
            <Box sx={{ fontSize: 16, fontWeight: 500, paddingTop: '24px', display: 'flex', justifyContent: 'center' }}>
                {t('myProfile.noReceiveCode')}
                <Box
                    onClick={resendOtp}
                    sx={{
                        color: '#EC0032',
                        textDecoration: 'underline',
                        cursor: count === 0 ? 'pointer' : 'default',
                    }}
                >
                    {t('myProfile.resentPhone') + '(' + count + 's)'}
                </Box>
            </Box>
            <DoubleBtn
                leftText={t('myProfile.cancelVerifyPhone')}
                leftFun={onClose}
                rightText={t('myProfile.verify')}
                rightFun={onVerify}
                rightDisable={otp.length !== 4}
            />
        </Box>
    )
}
