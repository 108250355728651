import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

/**
 * Combines multiple class names into a single string.
 * 
 * @param {...import('clsx').ClassValue} inputs - The class names to be combined.
 * @returns {string} - The combined class names as a string.
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs))
}