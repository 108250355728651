import React, { useMemo } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

function ProductModal({ onClose, isOpen, item, items, setCard }) {
    const cardIndex = useMemo(() => {
        return items.findIndex((i) => i.content === item.content)
    }, [item])
    const slideNext = () => {
        setCard(cardIndex === items.length - 1 ? items[0] : items[cardIndex + 1])
    }
    const slidePre = () => {
        setCard(cardIndex === 0 ? items[items.length - 1] : items[cardIndex - 1])
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
            slotProps={{
                root: { className: 'shadow-card' },
                backdrop: {
                    className: 'pt-[82px] md:pt-[108px] backdrop-blur-[10px] bg-[#333] bg-opacity-20',
                },
            }}
        >
            <div className='h-full w-full flex flex-col'>
                <div className='h-[82px] md:h-[108px]' />
                <div className='flex-1 flex items-center justify-center gap-x-4'>
                    <div className='icon-arrow_left text-xl sm:text-4xl cursor-pointer' onClick={slidePre} />
                    <div className='md:inline-flex bg-white shadow-card rounded-tr-[100px] relative w-[85%] md:w-[85%] lg:w-[70%]'>
                        <IconButton
                            className='absolute -translate-y-full right-0 -top-2 p-0'
                            disableRipple
                            onClick={() => onClose()}
                            aria-label='close'
                            size='middle'
                        >
                            <CloseIcon sx={{ color: '#231448' }} />
                        </IconButton>
                        <div className='aspect-[4/3] rounded-tr-[100px] md:rounded-tr-none overflow-hidden w-full md:w-[60%]'>
                            <img
                                className='h-full w-full object-cover'
                                id='map'
                                alt='loading'
                                // src="https://dw07qic8h25rb.cloudfront.net/data/image/EventDetail/3d33e07c695846e6818ae45985f58ed4/jpg.jpg"
                                // src={'https://img1.baidu.com/it/u=3001934222,1791122926&fm=253&fmt=auto&app=120&f=PNG?w=500&h=733'}
                                src={item?.content}
                            />
                        </div>
                        <div className='py-6 px-5 text-body1 text-[#222] lg:mt-6'>
                            {item?.description}
                        </div>
                    </div>
                    <div className='icon-arrow_right text-xl sm:text-4xl cursor-pointer' onClick={slideNext} />
                </div>
            </div>
        </Modal>
    )
}

export default ProductModal
