import React from 'react'
import { useTranslation } from 'react-i18next'

import NavigationPoint from 'components/NavigationPoint'
import dayjs from 'dayjs'
import { dateFormat } from 'utils/util'
function TopIntro({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <div className='pt-10 pb-[60px] space-y-4 bg-container'>
            <div className='max-w-[750px]'>
                <NavigationPoint
                    firstNav={t('connectWithKtsp.newsroom')}
                    lastNav={items.newsTitle}
                    firstNavLink='/newsRoom'
                />
                <div className='text-4xl w-full max-w-[800px] text-secondary-midnightBlue font-semiboldFamily py-8'>
                    {items.newsTitle}
                </div>
                {items.releaseDate && (
                    <div className='flex gap-2'>
                        <div className='w-1/5 text-base font-semiboldFamily'>{t('eventPage.date')}</div>
                        <div className='flex-1 text-base font-medium'>{dateFormat(items.releaseDate, language)}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopIntro
