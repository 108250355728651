import React from 'react'

function TextContainer({ title, about, isWeb, endWord, link }) {
    return (
        <div className='space-y-2'>
            <div className='text-4xl font-semiboldFamily'>{title}</div>
            <div className='text-base font-medium'>
                <div className='whitespace-pre-wrap'>{about}</div>
                {isWeb && link && (
                    // <a className='text-[#0000EE]' href={link} rel='noreferrer' target='_blank'>
                    //     {isWeb}
                    // </a>
                    <span>{isWeb}</span>
                )}
                {endWord && <span>{endWord}</span>}
            </div>
        </div>
    )
}

export default TextContainer
