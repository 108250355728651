import { Grow } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import MyInterest from 'page/UserSettings/components/MyInterest'
import FormUpdate from 'page/UserSettings/EditMyProfile/components/FormUpate'
import AccountBoxs from 'page/UserSettings/components/AccountBoxs'
import { Box, Wrap } from 'page/UserSettings/components/CommonBox'

import Loading from 'components/Loading'

import { myProfile } from 'service/crmService'
import { useUserInfoAtom } from 'store/authAtom'
import { user } from 'service/userService'
import { loginReg } from 'service/authService'

export default function EditMyProfile() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const setProfileInfo = useUserInfoAtom((state) => state.setProfileInfo)

    const { data: profileData, isFetching: profileIsFetching } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })

    const { data: countryCodeList, isFetching: countryCodeListFetching } = useQuery({
        queryKey: ['getCountryCodeList', language],
        queryFn: () =>
            user.getCountryCodeList({
                lang: language,
            }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
    })
    const { data: getTitle, isFetching: getTitleFetching } = useQuery({
        queryKey: ['getTitle', language],
        queryFn: () =>
            loginReg.getTitle({
                lang: language,
            }),
        placeholderData: [],
    })

    useEffect(() => {
        setProfileInfo(profileData)
    }, [profileData])

    return (
        <Wrap>
            <div className='text-4xl font-semiboldFamily uppercase'>{t('myProfile.myProfile')}</div>
            {(profileIsFetching || countryCodeListFetching || getTitleFetching) && <Loading />}
            <Grow
                in={!profileIsFetching && !countryCodeListFetching && !getTitleFetching}
                style={{ transformOrigin: '0 0 0' }}
                {...(!profileIsFetching && !countryCodeListFetching && !getTitleFetching ? { timeout: 1000 } : {})}
            >
                <Box>
                    {!profileIsFetching && !countryCodeListFetching && !getTitleFetching && (
                        <FormUpdate profileData={profileData} getTitle={getTitle} countryCodeList={countryCodeList} />
                    )}
                    {!profileIsFetching && <MyInterest profileData={profileData} />}
                </Box>
            </Grow>
            <AccountBoxs />
        </Wrap>
    )
}
