import React from 'react'
import { Button, useMediaQuery } from '@mui/material'

import { cn } from 'utils/cn'
import Content from 'components/Content'
import DateComponent from 'components/detailComponents/DateComponent'
function BuyNowBtn({ items, datesFormat, times }) {
    const {
        bottonRemarkTitle,
        bottonRemarkDescription,
        ctaButtonWording,
        ctaButtonUrl,
        ctaButtonStatus,
        displayDate,
    } = items
    const isPC = useMediaQuery('(min-width: 1024px)')
    if (!ctaButtonWording) {
        return null
    }
    return (
        <div
            className={cn(
                'sticky bottom-0 left-0 right-0 bg-white z-40 pt-4 flex-1 lg:static lg:pt-[96px]',
                isPC ? '' : 'shadow-card',
            )}
        >
            <div className='flex flex-col-reverse px-5 gap-4 md:px-[60px] lg:gap-6 lg:min-h-[317px] lg:flex-col lg:border-[#0A173D] lg:pl-8 lg:pr-0 lg:border-solid lg:border-l-[1px] '>
                <div className='flex gap-4 flex-col  pb-4 md:flex-row md:items-center md:justify-between lg:items-start lg:gap-6 lg:pb-0 lg:flex-col'>
                    <div className='flex-1 font-boldFamily text-[24px] md:text-[32px] leading-[120%] textarea-content'>
                        {displayDate}
                    </div>
                    <Button
                      disabled={ctaButtonStatus !== 1}
                      onClick={() => {
                          if (ctaButtonUrl) {
                              window.location.href = ctaButtonUrl
                          }
                      }}
                      className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                      variant={ctaButtonStatus === 1 ? 'primaryRed' : 'unselected'}
                      fullWidth
                      size='large'
                    >
                        {ctaButtonWording}
                    </Button>
                </div>
                {(bottonRemarkTitle || bottonRemarkDescription) && (
                  <div className='px-5 text-secondary-midnightBlue bg-[#E9ECF3] w-full py-3 lg:py-4 lg:w-[332px]'>
                        {bottonRemarkTitle && (
                            <div className='flex gap-2 items-center pb-2 lg:pb-3'>
                                <div className='icon-ticket text-2xl leading-6' />
                                <div className='text-body1 font-semiboldFamily'>{bottonRemarkTitle}</div>
                            </div>
                        )}
                        {bottonRemarkDescription && (
                            <Content className='text-xs font-medium textarea-content' data={bottonRemarkDescription} />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default BuyNowBtn
