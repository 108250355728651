import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import SwiperEventCards from 'components/detailComponents/SwiperEventCards'
import SwiperCards from 'page/Programmes/componets/SwiperCards'
import Seemore from 'components/landingComponents/SeeMore'
import EventCard from 'page/EventsTickets/components/EventCard'
function EventsCards({
    items = [],
    seeMoreUrl = '/eventsTickets',
    isProgramme = false,
    title = 'venue.events',
    CardType = EventCard,
}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const xlScreen = useMediaQuery('(min-width:1440px)')
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div className='pb-8 md:pb-16'>
            <div className='text-h3 flex justify-between items-center font-semiboldFamily text-secondary-midnightBlue pb-4 md:pb-8'>
                <div>{t(title)}</div>
                <Seemore title={t('facilitiesBooking.seeMore')} handleClick={() => navigate(seeMoreUrl)} />
            </div>
            <div
                className={
                    'flex justify-end pb-4 md:pb-8 ' +
                    (items.length <= 4 && ' xl:hidden ') +
                    (items.length <= 3 && ' lg:hidden ') +
                    (items.length <= 2 && ' hidden ')
                }
            >
                <IconButtonChange
                    slideIndex={slideIndex}
                    num={xlScreen ? 4 : lgScreen ? 3 : 2}
                    length={items.length}
                    goToNextSlide={goToNextSlide}
                    goToPrevSlide={goToPrevSlide}
                    buttonColor={'#231448'}
                    borderColor={'border-secondary-midnightBlue'}
                />
            </div>
            {isProgramme ? (
                <SwiperCards setSlideIndex={setSlideIndex} items={items} setSwiper={setSwiper} />
            ) : (
                <SwiperEventCards
                    setSlideIndex={setSlideIndex}
                    items={items}
                    CardType={CardType}
                    setSwiper={setSwiper}
                />
            )}
        </div>
    )
}

export default EventsCards
