import { cn } from 'utils/cn'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import { Button } from '@mui/material'

const PromotionRegister = ({ className, items }) => {
    const { actionButtonTitle, actionButtonUrl } = items

    const {
        t,
        i18n: { language },
    } = useTranslation()

    const isPC = useMedia('(min-width: 1024px)')

    return (
        <>
            {actionButtonTitle ? (
                isPC ? (
                    <div className={cn('mt-16', className)}>
                        <div className='pl-6  h-fit min-h-[200px] border-solid border-l-[1px]  border-[#0A173D]'>
                            <Button className='w-full lg:w-[332px]' variant='primaryRed' onClick={() => window.open(actionButtonUrl)}>
                                {actionButtonTitle}
                            </Button>
                        </div>
                    </div>
                ) : (
                    createPortal(
                        <div className='sticky bottom-0 z-40 px-5 py-3 bg-white h-fit shadow-above'>
                            <div className='flex items-end justify-end h-full'>
                                <Button
                                    className='w-full sm:w-[270px]'
                                    variant='primaryRed'
                                    onClick={() => window.open(actionButtonUrl)}
                                >
                                    {/* wait for the user to provide the correct cw */}
                                    {actionButtonTitle}
                                </Button>
                            </div>
                        </div>,
                        document.getElementById('main-container'),
                    )
                )
            ) : null}
        </>
    )
}

export default PromotionRegister
