import * as React from 'react'
import { CardMedia, Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { cn } from 'utils/cn'
import bookmarkHit from 'resources/images/bookmarkHit.png'
import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
const BookmarkIcon = tw.div`text-secondary-midnightBlue text-xl px-[2px]`
function CarParkCard({ card }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const { isCarParkOff } = card
    return (
        <Card
            onClick={() => navigate('/carParkDetail?id=' + card.identityUuid)}
            className={
                'relative w-full shadow-none cursor-pointer text-secondary-midnightBlue bg-transparent group rounded-none'
            }
            square={true}
        >
            {card.vacancy === 0 + '' && (
                <div className='absolute font-semiboldFamily text-white top-0 z-30 left-0 py-[6px] px-[15px] bg-primaryRed-ktRed uppercase text-xs md:text-sm'>
                    {t('carPark.full')}
                </div>
            )}
            <div className={CARD_BOX + ' w-full aspect-[4/3]'}>
                {card.cardImage && (
                    <CardMedia
                        sx={{
                            objectFit: 'fill',
                        }}
                        component='img'
                        className={cn('w-full h-full', !isCarParkOff && card.vacancy !== 0 + '' && 'scale-image')}
                        image={card.cardImage}
                        alt='loading'
                    />
                )}
                {(isCarParkOff || card.vacancy === 0 + '') && (
                    <div className='absolute bottom-0 top-0 left-0 right-0 bg-white opacity-50 z-30'></div>
                )}
                {!isCarParkOff && card.vacancy !== 0 + '' && <RedLine />}
            </div>
            <div className='flex gap-1 justify-between pt-2 md:pt-3 md:mx-[5px]'>
                <div
                    className={cn(
                        'flex gap-1',
                        !isCarParkOff ? 'max-w-[64%] sm:max-w-[54%] md:gap-[10px] md:max-w-[60%]' : 'w-full',
                    )}
                >
                    {card.isEVCharger && <div className='icon-ev-charger-converted-1 text-[16px] pt-[2px] md:pt-[1px] md:text-[24px]'></div>}
                    <div
                        className={cn(
                            'font-semiboldFamily break-words text-[16px] md:text-[28px]',
                            card.isEVCharger ? 'w-[85%] md:w-[90%]' : 'w-full',
                        )}
                    >
                        {card.carParkName}
                    </div>
                </div>
                {!isCarParkOff && (
                    <div>
                        <div className='text-h3 text-center'>{card.vacancy}</div>
                        <div className='text-body1 text-center'>{t('carPark.spaces')}</div>
                    </div>
                )}
            </div>
            {isCarParkOff && (
                <div className='mt-[9.6px] py-2 px-3 bg-primaryRed-lightRed font-regular text-secondary-midnightBlue text-xs leading-[18px] md: mx-[5px]'>
                    {t('carPark.offTip')}
                </div>
            )}
        </Card>
    )
}

export default CarParkCard
