import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate, Outlet } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { GetCookie } from 'utils/cookieLombok'
import { useModalStore } from 'store/authAtom'
import Aside from 'components/Aside'
const Wrap = tw.div`w-full flex overflow-x-auto bg-[#F5F5F5] h-[calc(100vh-81.47px)]  md:h-[calc(100vh-108px)] `
export default function Main() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const userSettingMatch = useMatch('/userSettings/ticketDetail')
    const consentPageMatch = useMatch('/userSettings/consentPage')
    const instructionPageMatch = useMatch('/userSettings/instructionPage')
    useEffect(() => {
        if (!GetCookie('ktspSsoRefreshToken')) {
            navigate('/')
            // enqueueSnackbar(t('navigationBar.loginAgain'), { variant: 'error' })
        }
    }, [document.cookie])
    return (
        <Wrap>
            {!userSettingMatch && !consentPageMatch && !instructionPageMatch && <Aside />}
            <div className='h-full overflow-y-auto overflow-x-auto flex-1'>
                <Outlet />
            </div>
        </Wrap>
    )
}
