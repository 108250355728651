import React from 'react'
import { useTranslation } from 'react-i18next'

import guide from 'resources/images/mtrBanner.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import TaxiFerrryCrossCommon from 'page/Transportation/components/TaxiFerrryCrossCommon'
function CrossBoundary() {
    const { t } = useTranslation()
    return (
        <WrapBox
            image={guide}
            component={
                <TaxiFerrryCrossCommon
                    firstTitle={t('transport.byCrossBoundary')}
                    // firstAbout={t('transport.crossBoundaryAbout')}
                    routeAbouts={
                        [
                            // t('transport.routeAbout'),
                            // t('transport.routeWestKowloon'),
                            // t('transport.routeChinaFerry'),
                            // t('transport.routeLuWu'),
                            // t('transport.routeShenzhenBay'),
                            // t('transport.routeLokMaChau'),
                            // t('transport.routeManKam'),
                            // t('transport.routeHeungYuenWa'),
                        ]
                    }
                />
            }
        />
    )
}

export default CrossBoundary
