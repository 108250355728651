import { Box, Chip, SvgIcon, Button } from '@mui/material'
import ViewMore from 'components/detailComponents/ViewMore'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'twin.macro'

import { useModalStore } from 'store/authAtom'
import AmenitiesSeeAll from './AmenitiesSeeAll'
import Content from 'components/Content'
import DescriptionComponent from 'components/detailComponents/DescriptionComponent'

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
    background: transparent;
`

export default function Overview({ items }) {
    const { t } = useTranslation()
    const { setModalComponent, onOpen } = useModalStore()

    return (
        <div className='pt-8 text-secondary-midnightBlue bg-container'>
            <div className='max-w-[750px]'>
                {/* <Content ref={descriptionRef} data={items.description} className={'text-base font-medium'} /> */}
                {/* <Content data={items.description} className={'text-base font-medium'} /> */}
                <DescriptionComponent description={items.description} />

                {items?.amenitiesBindVenueRespDTO && items?.amenitiesBindVenueRespDTO.length > 0 && (
                    <>
                        <div className='py-6 text-xl font-semiboldFamily'>{t('venue.amenities')}</div>
                        <div className='space-y-4 mb-6'>
                            <div className='text-[16px] font-semiboldFamily'>
                                {items?.amenitiesBindVenueRespDTO[0]?.['amenities']?.['name']}
                            </div>
                            <div className='grid gap-y-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                                {items?.amenitiesBindVenueRespDTO[0]?.['amenities']?.['amenities'].map(
                                    (item, index) => (
                                        <div className='flex px-5' key={index}>
                                            <div className='icon-tickCarPark leading-6 font-bold mr-2.5' />
                                            <div className='text-sm leading-6 font-medium'>{item?.amenitiesName}</div>
                                        </div>
                                    ),
                                )}
                            </div>

                            <Button
                                onClick={() => {
                                    setModalComponent(
                                        <AmenitiesSeeAll
                                            amenitiesBindVenueRespDTO={items?.amenitiesBindVenueRespDTO}
                                        />,
                                    )
                                    onOpen()
                                }}
                                size='small'
                                className='text-[#283266] text-lg p-0 font-regular'
                                endIcon={<div className='icon-arrow_right text-base font-semibold'></div>}
                            >
                                {t('venue.SeeAll')}
                            </Button>
                        </div>
                    </>
                )}

                <Box className='mt-12 space-x-3 pb-8 xl:pb-16'>
                    {items?.tagList?.map((item) => (
                        <ChipWrap key={item.identityUuid} label={item.name} variant='outlined' />
                    ))}
                </Box>
            </div>
        </div>
    )
}
