import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { useSimpleBackdropStore } from 'store/authAtom'
export default function SimpleBackdrop() {
    const { isOpen, onCloseSimpleBackdrop } = useSimpleBackdropStore()
    return (
        <div>
            <Backdrop onClick={onCloseSimpleBackdrop} sx={{ color: '#fff', zIndex: 9999 }} open={isOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
