import React from 'react'
import { useTranslation } from 'react-i18next'

import guide from 'resources/images/guideDetail.png'
import WrapBox from 'page/Transportation/components/WrapBox'
import TaxiFerrryCrossCommon from 'page/Transportation/components/TaxiFerrryCrossCommon'
function Car() {
    const { t } = useTranslation()
    return (
        <WrapBox
            image={guide}
            component={
                <TaxiFerrryCrossCommon firstTitle={t('transport.byCar')} firstAbout={t('transport.byCarAbout')} />
            }
        />
    )
}

export default Car
