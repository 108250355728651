import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactCookieConsent from 'react-cookie-consent'
import { useMediaQuery } from '@mui/material'
import { GetCookie, RemoveCookie, SetCookie } from '../utils/cookieLombok'
import { decryptAes } from '../utils/filter'
import { myProfile } from '../service/crmService'
import { cn } from 'utils/cn'
import { useNavigate } from 'react-router-dom'

const EssentialCookies = ['ktspSsoAccessToken', 'ktspSsoRefreshToken', 'session', 'sessionKey', 'CookieConsent']
const CookieConsent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 520px)')

    const buttonStyles = {
        width: isMobile ? '150px' : '220px',
        height: '48px',
        padding: isMobile ? '6px 0px' : '8px 0px',
        margin: '0',
        borderRadius: '9999px',
        textAlign: 'center',
        fontFamily: 'Barlow',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const onDecline = async () => {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (!EssentialCookies.includes(name)) {
                RemoveCookie(name)
            }
        }
    }

    useEffect(() => {
        const checkCookie = async () => {
            const CookieConsent = GetCookie('CookieConsent')
            if (CookieConsent === 'false') {
                try {
                    await onDecline()
                } catch (error) {
                    console.error(error)
                }
            }
        }
        checkCookie()
    }, [])

    return (
        <ReactCookieConsent
            containerClasses='bg-container 2xl:max-w-full py-5 bg-[#222]/90 gap-6 items-center justify-end'
            contentClasses='mx-0 my-auto flex flex-col gap-3'
            contentStyle={{ margin: 0 }}
            enableDeclineButton
            flipButtons
            buttonText={t('cookiesConsent.yes')}
            declineButtonText={t('cookiesConsent.no')}
            buttonWrapperClasses={cn('flex items-center justify-center gap-6', isMobile ? 'w-full' : '')}
            buttonStyle={{
                ...buttonStyles,
                background: 'linear-gradient(45deg, #8f0044, #bc003c, #e20030, #ff1636)',
                color: '#FEFFFF',
            }}
            declineButtonStyle={{
                ...buttonStyles,
                background: 'transparent',
                border: '1px solid #FFF',
                color: '#FFF',
            }}
            onDecline={onDecline}
            onAccept={onDecline}
            cookieSecurity
            sameSite={'None'}
        >
            <div className='text-[22px] font-semiboldFamily'>{t('cookiesConsent.title')}</div>
            <span className='text-body1'>
                <Trans
                    i18nKey='cookiesConsent.message'
                    components={[<span onClick={()=> navigate('/privacyPolicy')} className='underline cursor-pointer' />]}
                />
            </span>
        </ReactCookieConsent>
    )
}

export default CookieConsent
