import Content from 'components/Content'

export const CARD_BOX =
    'relative overflow-hidden rounded-tr-0  group-hover:rounded-tr-[60px] transition-all duration-500 ease-in-out'
export const RedLine = () => {
    return (
        <div className='absolute bottom-0 z-40 left-0 w-0 h-2 bg-primaryRed-ktRed transition-all duration-500 ease-in-out group-hover:w-full'></div>
    )
}
export const DescriptionLabel = ({ description }) => {
    return (
        <Content
            data={filterText(description)}
            className='text-secondary-midnightBlue bg-transparent text-body3 mb-2 hidden md:line-clamp-2 '
        />
    )
}

// 过滤html字符串中的img跟a标签及其内容，同时去掉元素标签只保留文字
function filterText(text) {
    // 去除img标签及其内容
    text = text.replace(/<img[^>]*>/g, '');
    // 去除a标签及其内容
    text = text.replace(/<a[^>]*>.*?<\/a>/g, '');
    // 去除所有其他HTML标签，只保留文字
    return text.replace(/<\/?[^>]+(>|$)/g, '');
}