import React, { useState } from 'react'
import FilledInput from '@mui/material/FilledInput'
import { Box, InputAdornment, CardMedia, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import i from 'resources/images/che2.png'

function UniversalSearth({ close }) {
    const [searchVal, setSearchVal] = useState('')
    const [keyWords, setKeyWords] = useState(['dsafd', 'fdsa'])
    const types = ['#Western Gourmet', '#Coffee Hub', '#MUSEA Cocktail Hour', '#KTSP MUSEA MUST-TRY']
    const events = [
        {
            image: i,
            title: 'Event & tickets',
        },
        {
            image: i,
            title: 'Venue',
        },
        {
            image: i,
            title: 'Experience tour',
        },
        {
            image: i,
            title: 'Programme',
        },
        {
            image: i,
            title: 'Restaurant',
        },
        {
            image: i,
            title: 'Promotion',
        },
    ]
    const searchKeyWord = (newVal) => {
        if (!newVal) {
            return
        }
        if (keyWords.includes(newVal)) {
            const arr = keyWords.filter((val) => val !== newVal)
            setKeyWords([newVal, ...arr])
        } else {
            setKeyWords([newVal, ...keyWords])
        }
    }
    return (
        <Box
            sx={{
                position: 'relative',
                paddingTop: '85px',
                display: 'flex',
                paddingX: '100px',
                background: '#FFFFFF',
            }}
        >
            <Box
                onClick={() => {
                    close()
                }}
                sx={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }}
            >
                <CloseIcon sx={{ fontSize: 32, color: '#283266' }} />
            </Box>
            <Box sx={{ fontSize: 32, fontWeight: 600, width: '30%' }}>SEARCH</Box>
            <Box sx={{ flex: 1 }}>
                <FilledInput
                    type={'text'}
                    value={searchVal}
                    fullWidth
                    color='secondary'
                    sx={{ bgcolor: '#ffffff', fontSize: 21, '.MuiInputBase-input': { paddingTop: '5px' } }}
                    onChange={(e) => setSearchVal(e.target.value)}
                    onBlur={(e) => searchKeyWord(e.target.value)}
                    placeholder='Search KTSP?'
                    endAdornment={
                        <InputAdornment onClick={() => searchKeyWord(searchVal)} position='end'>
                            <SearchIcon sx={{ fontSize: 42, color: '#283266', cursor: 'pointer' }} />
                        </InputAdornment>
                    }
                />
                <Stack mt={4} direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <Box sx={{ fontSize: '28px', lineHeight: '28px', fontWeight: '600' }}>Recent Search</Box>
                    <Box
                        onClick={() => setKeyWords([])}
                        sx={{
                            color: '#D6093B',
                            textDecoration: 'underline',
                            fontSize: '1rem',
                            lineHeight: '19px',
                            fontWeight: '600',
                            cursor: 'pointer',
                        }}
                    >
                        Clear All
                    </Box>
                </Stack>
                <Stack
                    sx={{ flexWrap: 'wrap' }}
                    mt={1}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={1}
                    rowGap={1}
                >
                    {keyWords.map((item) => {
                        return (
                            <Box
                                key={item}
                                onClick={() => {
                                    console.log(3333)
                                }}
                                sx={{
                                    paddingX: 2,
                                    paddingY: '5px',
                                    bgcolor: '#D4D6D9',
                                    borderRadius: 10,
                                    fontSize: '14px',
                                    display: 'flex',
                                    gap: 1,
                                    color: '#4A4B59',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <Box>{item}</Box>
                                <CloseIcon
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        const arr = keyWords.filter((val) => val !== item)
                                        setKeyWords(arr)
                                    }}
                                    fontSize='24px'
                                />
                            </Box>
                        )
                    })}
                </Stack>
                <Box sx={{ paddingTop: '60px', color: '#283266' }}>
                    <Box sx={{ fontSize: 28, fontWeight: 700, paddingBottom: '12px' }}>Popular</Box>
                    <Box sx={{ display: 'flex', gap: '12px', paddingBottom: 10 }}>
                        {types.map((type, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        border: '1px solid #283266',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        paddingX: 1,
                                        paddingY: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {type}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{ fontSize: 28, fontWeight: 700 }}>Quick Link</Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 33.33%)', gap: 2, paddingBottom: '100px' }}>
                    {events.map((event, index) => {
                        return (
                            <Box key={index} sx={{ position: 'relative', cursor: 'pointer' }}>
                                <CardMedia
                                    component='img'
                                    sx={{ height: '120px', width: '100%', objectFit: 'fill' }}
                                    image={event.image}
                                    alt='green iguana'
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: '20px',
                                        left: '20px',
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: '#FFFFFF',
                                        width: '80%',
                                    }}
                                >
                                    {event.title}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default UniversalSearth
