import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Box, IconButton } from '@mui/material'

const Wrap = tw.div`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white shadow-card rounded-tr-[100px] h-fit w-[90%] md:w-fit lg:w-[90%] lg:flex xl:w-[1200px]`
const Label = tw.div``
const TextBox = tw.div`flex-1 py-6 px-5 `
const Img = tw.img`h-full w-full cursor-pointer object-cover rounded-tr-[100px] md:object-contain lg:rounded-tr-[0] `
const Title = tw.div`text-xl`
const ImgBox = tw.div`relative w-full h-[251px] md:h-[344px] lg:w-2/3 lg:h-full `
const MainStadium = tw.div`absolute top-[35%] cursor-pointer bottom-[13%] left-[5%] right-[55%] z-50 md:bottom-[20%]`
const TopStadium = tw.div`absolute top-[20%] cursor-pointer bottom-[60%] left-[45%] right-[15%] z-50`
const RightStadium = tw.div`absolute top-[40%] cursor-pointer bottom-[40%] left-[75%] right-0 z-50`
const RightBomStadium = tw.div`absolute cursor-pointer left-[80%] right-0 z-50 bottom-[8%]  top-[70%]  md:top-[65%]  md:bottom-[20%]`
function MotalOpeningHour({ onClose, isOpen, image, items }) {
    const { t } = useTranslation()
    const [index, setIndex] = useState(0)
    const times = JSON.parse(items[index].description)
    const findItemsIndex = (id) => {
        return items.findIndex((item) => item.uuid === id)
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
            slotProps={{
                root: { className: 'shadow-card' },
                backdrop: {
                    className: 'mt-[82px] md:mt-[108px] backdrop-blur-[10px] bg-transparent bg-opacity-20',
                },
            }}
        >
            <Wrap>
                <IconButton
                    className='absolute -translate-y-full right-0 p-0'
                    disableRipple
                    onClick={onClose}
                    aria-label='close'
                    size='middle'
                >
                    <CloseIcon sx={{ color: '#231448' }} />
                </IconButton>
                <ImgBox>
                    <Img id='map' alt='' className=' relative cursor-pointer' src={image} />
                    {items[findItemsIndex('27bc0f9c-5506-3eaa-bdbd-151981850848')] && (
                        <TopStadium onClick={() => setIndex(findItemsIndex('27bc0f9c-5506-3eaa-bdbd-151981850848'))} />
                    )}
                    {items[findItemsIndex('371f9266-bcd3-3f23-9121-60d31bca35ea')] && (
                        <MainStadium onClick={() => setIndex(findItemsIndex('371f9266-bcd3-3f23-9121-60d31bca35ea'))} />
                    )}
                    {items[findItemsIndex('896be6e9-8529-3801-a229-361da39f9868')] && (
                        <RightStadium
                            onClick={() => setIndex(findItemsIndex('896be6e9-8529-3801-a229-361da39f9868'))}
                        />
                    )}
                    {items[findItemsIndex('7549d77f-971f-3839-b5c2-f755574650bc')] && (
                        <RightBomStadium
                            onClick={() => setIndex(findItemsIndex('7549d77f-971f-3839-b5c2-f755574650bc'))}
                        />
                    )}
                </ImgBox>
                <TextBox>
                    <Title className='font-semiboldFamily'>{items[index].title}</Title>
                    <Label>
                        <Title className='font-semiboldFamily'>{t('visitorTip.openingHour')}</Title>
                        <div>
                            {times.map((item, index) => {
                                return (
                                    <div key={index} className='text-base font-medium'>
                                        {item.label}
                                    </div>
                                )
                            })}
                        </div>
                    </Label>
                </TextBox>
            </Wrap>
        </Modal>
    )
}

export default MotalOpeningHour
