import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import CheckBoxFiled from 'components/shopDine/allProductsComponents/CheckBoxFiled'
import { TabButton } from 'components/ButtonsTab'
import { RangeCalendar } from 'components/Calendar/range'

const style = {
    bgcolor: 'background.paper',
    borderRadius: '0px 100px 0px 0px',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.42)',
    borderWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}
const Label = ({ text }) => {
    return <Box sx={{ fontSize: 18, fontWeight: 600, color: '#231448', fontFamily: 'Barlow', pb: 1 }}>{text}</Box>
}
const ArrSelect = ({ name, arr, typesSelected, setTypesSelected }) => {
    if (arr?.length === 0) {
        return null
    }
    return (
        <>
            <Label text={name} />
            <Box
                sx={{
                    display: 'grid',
                    mb: 4,
                    gridTemplateColumns: { md: 'repeat(3, 1fr)', xs: 'repeat(2, 1fr)' },
                }}
            >
                {arr.length > 0 &&
                    arr.map((item, index) => {
                        return (
                            <CheckBoxFiled
                                className='mb-2'
                                isModal={true}
                                typesSelected={typesSelected}
                                setTypesSelected={setTypesSelected}
                                label={item}
                                key={index}
                            />
                        )
                    })}
            </Box>
        </>
    )
}
function FilterModal({
    open,
    handleClose,
    firstTypeName,
    secondTypeName,
    venues,
    types,
    typesSelected,
    setTypesSelected,
    venuesSelected,
    setVenuesSelected,
    dateType,
    setDateType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dates,
    ageGroups = [],
    ageGroupSelected,
    setAgeGroupSelected,
    thirdName,
    resetCw = '',
    confirmCw = '',
}) {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [dateTypeTem, setDateTypeTem] = useState(dateType)
    const [startDateTem, setStartDateTem] = useState(startDate ? dayjs(startDate) : null)
    const [endDateTem, setEndDateTem] = useState(endDate ? dayjs(endDate) : null)
    const [typesSelectedTem, setTypesSelectedTem] = useState(typesSelected)
    const [venuesSelectedTem, setVenuesSelectedTem] = useState(venuesSelected)
    const [ageGroupsSelectedTem, setAgeGroupsSelectedTem] = useState(ageGroupSelected ?? [])
    const handleCloseMotal = () => {
        setDateTypeTem(dateType)
        setStartDateTem(startDate)
        setEndDateTem(endDate)
        setVenuesSelectedTem(venuesSelected)
        setTypesSelectedTem(typesSelected)
        setAgeGroupsSelectedTem(ageGroupSelected ?? [])
        handleClose()
    }
    useEffect(() => {
        setDateTypeTem(dateType)
        setTypesSelectedTem(typesSelected)
        setVenuesSelectedTem(venuesSelected)
        setStartDateTem(startDate)
        setEndDateTem(endDate)
        setAgeGroupsSelectedTem(ageGroupSelected ?? [])
    }, [dateType, typesSelected, venuesSelected, startDate, endDate, ageGroupSelected])
    return (
        <Modal
            open={open}
            onClose={handleCloseMotal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
            slotProps={{
                root: { className: 'shadow-card' },
                backdrop: {
                    className: 'backdrop-blur-[10px] bg-[#333] bg-opacity-20',
                },
            }}
        >
            <Box className='h-[100vh] w-[100vw] flex justify-center items-center'>
                <Box className='relative md:w-[800px] w-[90%] max-h-[85vh] flex flex-col'>
                    <Box>
                        <IconButton
                            className='float-right p-0'
                            onClick={handleCloseMotal}
                            aria-label='close'
                            size='middle'
                        >
                            <CloseIcon sx={{ color: '#231448' }} />
                        </IconButton>
                    </Box>
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                px: 4,
                                pt: 6,
                                pb: 2,
                            }}
                        >
                            <Box sx={{ fontSize: 24, fontWeight: 700, color: '#231448', fontFamily: 'Barlow' }}>
                                {t('eventPage.allFilter')}
                            </Box>
                        </Box>
                        <div className='overflow-y-scroll flex-1 px-8'>
                            <ArrSelect
                                name={firstTypeName}
                                arr={types}
                                typesSelected={typesSelectedTem}
                                setTypesSelected={setTypesSelectedTem}
                            />
                            <ArrSelect
                                name={secondTypeName}
                                arr={venues}
                                typesSelected={venuesSelectedTem}
                                setTypesSelected={setVenuesSelectedTem}
                            />
                            {dates && dates.length > 0 && (
                                <>
                                    <Label text={t('eventPage.date')} />
                                    <Box sx={{ display: 'flex', width: '80%', gap: 2, flexWrap: 'wrap', mb: 4 }}>
                                        {dates.map((item, index) => {
                                            return (
                                                <TabButton
                                                    key={index}
                                                    onClick={() => {
                                                        setDateTypeTem(item)
                                                        setEndDateTem(null)
                                                        setStartDate(null)
                                                    }}
                                                    className='min-w-fit w-fit max-w-fit'
                                                    selected={dateTypeTem?.val === item?.val}
                                                >
                                                    {t(item.about)}
                                                </TabButton>
                                            )
                                        })}
                                    </Box>
                                </>
                            )}
                            {dateTypeTem?.val === 'flexibleDate' && (
                                <Box
                                    sx={{
                                        display: { md: 'flex', xs: 'block' },
                                        justifyContent: 'space-between',
                                        pt: 2,
                                    }}
                                >
                                    <RangeCalendar
                                        date={{ from: startDateTem, to: endDateTem }}
                                        setDate={({ from, to }) => {
                                            setStartDateTem(from)
                                            setEndDateTem(to)
                                        }}
                                    />
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        maxDate={endDateTem}
                                        value={startDateTem}
                                        onChange={(newValue) => setStartDateTem(newValue)}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        minDate={startDateTem}
                                        value={endDateTem}
                                        onChange={(newValue) => setEndDateTem(newValue)}
                                    />
                                </LocalizationProvider> */}
                                </Box>
                            )}
                            {ageGroups.length > 0 && (
                                <ArrSelect
                                    name={thirdName}
                                    arr={ageGroups}
                                    typesSelected={ageGroupsSelectedTem}
                                    setTypesSelected={setAgeGroupsSelectedTem}
                                />
                            )}
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                px: 4,
                                py: 2,
                                borderTop: '1px solid #EFEFEF',
                                boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.42)',
                            }}
                        >
                            <Box
                                onClick={() => {
                                    setDateTypeTem(null)
                                    setStartDateTem(null)
                                    setEndDateTem(null)
                                    setTypesSelectedTem([])
                                    setVenuesSelectedTem([])
                                    setAgeGroupsSelectedTem([])
                                }}
                                sx={{
                                    color: '#0A173D',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {resetCw || t('eventPage.clearAll')}
                            </Box>
                            <Button
                                onClick={() => {
                                    setDateType(dateTypeTem)
                                    setEndDate(endDateTem)
                                    setStartDate(startDateTem)
                                    setTypesSelected(typesSelectedTem)
                                    setVenuesSelected(venuesSelectedTem)
                                    setAgeGroupSelected && setAgeGroupSelected(ageGroupsSelectedTem)
                                    handleClose()
                                }}
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    borderRadius: 8,
                                    padding: '3 4',
                                    textTransform: 'none',
                                }}
                                variant='primaryRed'
                            >
                                {confirmCw || t('common.comfirm')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default FilterModal
