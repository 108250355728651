import React from 'react'
import { useTranslation } from 'react-i18next'
import visa from 'resources/images/visa.png'
import Content from 'components/Content'

function Payment({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <div className='pt-8 bg-container md:pt-[60px]'>
            {/* <div className='max-w-[750px]' dangerouslySetInnerHTML={{ __html: items.paymentDescription }}></div> */}
            {items.paymentDescription && (
                <Content isNeedCk={false} data={items.paymentDescription} className={' max-w-[750px]'} />
            )}
        </div>
    )
}

export default Payment
