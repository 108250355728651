import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useSpacialTrafficNews, useModalStore } from 'store/authAtom'
import tag from 'resources/images/spacialTag.svg'
import Content from './Content'
function SpacialTrafficNews() {
    const { t } = useTranslation()
    const { data, onCloseSpacial } = useSpacialTrafficNews()
    const { setModalComponent, onOpen } = useModalStore()

    if (!data) return null

    return (
        <div className='bg-primaryRed-lightRed py-5 max-w-[100vw]'>
            <div className='px-[24px] md:px-5 lg:px-[50px] 2xl:container 2xl:mx-auto relative flex gap-3'>
                <img alt='' src={tag} className='size-8 object-contain' />
                <div className='flex-1 space-y-1 text-sm font-medium text-wrap w-[calc(100%-100px)]'>
                    <div className='text-base font-semiboldFamily text-secondary-midnightBlue break-words'>
                        {data.announcementTitle}
                    </div>
                    <Content
                        className='text-secondary-midnightBlue line-clamp-1 text-wrap w-full break-words'
                        data={data.announcementMessage}
                    />
                    <div
                        onClick={() => {
                            setModalComponent(
                                <Box sx={{ color: '#283266' }}>
                                    <Box sx={{ fontSize: 16, fontWeight: 600, paddingBottom: 1 }}>
                                        {data.announcementTitle}
                                    </Box>
                                    <Content className='text-[14px] font-medium pb-1' data={data.announcementMessage} />
                                    {data.publishDate && (
                                        <>
                                            <Box
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                    display: 'flex',
                                                    paddingTop: { xs: '16px', md: '32px' },
                                                }}
                                            >
                                                <Box>{t('common.update') + ':'}</Box>
                                                <Box sx={{ fontWeight: 500 }}>{data.publishDate}</Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>,
                                '#fff',
                            )
                            onOpen()
                        }}
                        className='text-[#283266] font-semiboldFamily cursor-pointer underline'
                    >
                        {t('landing.seeMore')}
                    </div>
                </div>
                <CloseIcon
                    onClick={() => onCloseSpacial()}
                    className='text-black text-xl cursor-pointer active:opacity-50'
                />
            </div>
        </div>
    )
}

export default SpacialTrafficNews
