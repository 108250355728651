import React, { useState } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import { useTranslation } from 'react-i18next'

import { Box, Wrap } from 'page/UserSettings/components/CommonBox'
import c from 'resources/images/card1.png'
import FeaturedCard from 'components/landingComponents/FeaturedCard'
import EventCards from 'page/UserSettings/MyBookmark/components/EventCardsSection'
import ProgrammeSection from 'page/UserSettings/MyBookmark/components/ProgrammeSection'
import FacilitySection from 'page/UserSettings/MyBookmark/components/FacilitySection'
import VenueSection from 'page/UserSettings/MyBookmark/components/VenueSection'
import PromotionSection from 'page/UserSettings/MyBookmark/components/PromotionSection'
import { TabButton } from 'components/ButtonsTab'

function MyBookmark() {
    const [isProductsList, setIsProductsList] = useState(false)
    const [type, setType] = useState('all')
    const { t } = useTranslation()
    const btnVals = [
        { text: t('programme.all'), val: 'all' },
        { text: t('myProfile.event'), val: 'event' },
        { text: t('myProfile.programme'), val: 'programme' },
        // { text: t('myProfile.shop'), val: 'shop' },
        // { text: t('myProfile.dine'), val: 'dine' },
        { text: t('myProfile.facilities'), val: 'facilities' },
        { text: t('myProfile.venue'), val: 'venue' },
        { text: t('myProfile.promotion'), val: 'promotion' },
    ]
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    /**
     * Handles the start of a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragStart = (e) => {
        setIsDragging(true)
        setStartX(e.pageX - e.currentTarget.offsetLeft)
        setScrollLeft(e.currentTarget.scrollLeft)
    }

    /**
     * Handles the end of a drag event.
     */
    const handleDragEnd = () => {
        setIsDragging(false)
    }

    /**
     * Handles the movement during a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragMove = (e) => {
        if (!isDragging) return
        e.preventDefault()
        const x = e.pageX - e.currentTarget.offsetLeft
        const walk = (x - startX) * 3
        e.currentTarget.scrollLeft = scrollLeft - walk
    }
    return (
        <Wrap>
            <div className='flex justify-between items-center'>
                <div className='text-4xl font-semiboldFamily uppercase'>{t('myProfile.myBookmark')}</div>
                {type !== 'facilities' && (
                    <div className='text-lg text-secondary-grey flex items-center cursor-pointer gap-2'>
                        <div
                            onClick={() => setIsProductsList(false)}
                            className={!isProductsList ? 'text-secondary-midnightBlue' : ''}
                        >
                            <GridOnOutlinedIcon />
                        </div>
                        <div
                            onClick={() => setIsProductsList(true)}
                            className={isProductsList ? 'text-secondary-midnightBlue' : ''}
                        >
                            <FormatListBulletedIcon />
                        </div>
                    </div>
                )}
            </div>
            <Box>
                <div
                    onMouseDown={handleDragStart}
                    onMouseUp={handleDragEnd}
                    onMouseMove={handleDragMove}
                    onMouseLeave={handleDragEnd}
                    className='flex gap-3 pb-8 overflow-hidden md:pb-[60px]'
                >
                    {btnVals.map((btnVal, index) => {
                        return (
                            <TabButton
                                key={index}
                                onClick={() => {
                                    setType(btnVal.val)
                                    console.log(btnVal.val)
                                }}
                                selected={type === btnVal.val}
                            >
                                {btnVal.text}
                            </TabButton>
                        )
                    })}
                </div>
                {type === 'all' && <EventCards listType={isProductsList ? 'list' : 'grid'} />}
                {type === 'event' && <EventCards listType={isProductsList ? 'list' : 'grid'} />}
                {type === 'programme' && <ProgrammeSection listType={isProductsList ? 'list' : 'grid'} />}
                {type === 'facilities' && <FacilitySection />}
                {type === 'venue' && <VenueSection isProductsList={isProductsList} />}
                {type === 'promotion' && <PromotionSection isProductsList={isProductsList} />}
            </Box>
        </Wrap>
    )
}

export default MyBookmark
