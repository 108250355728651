import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import RowList from './RowList'

const Title = tw.div`text-[42px] leading-[50px] text-center pb-8`
function PremiumFoundingPartner({ items, targetRef, id }) {
    const { t } = useTranslation()
    return (
        <div className='flex justify-center mb-16'>
            <div className='xl:w-[65%] text-secondary-midnightBlue'>
                <div className='text-h2 pb-8 uppercase text-center'>{t('landing.premierFoundingPartner')}</div>
                <div className='flex justify-center'>
                    <img
                        ref={id === items.premiumfounding[0].uuid ? targetRef : null}
                        title={items.premiumfounding[0].altText}
                        alt=''
                        src={items.premiumfounding[0].webVisual}
                        className='w-[200px] object-contain'
                    />
                </div>
                <div className='pt-6 text-body1 text-center textarea-content'>
                    {items.premiumfounding[0].description}
                </div>
                {items.foundingPartner && items.foundingPartner.length > 0 && (
                    <>
                        <div className='text-h2 uppercase mt-20 pb-8 text-center'>{t('landing.foundingPanter')}</div>
                        <RowList items={items?.foundingPartner} targetRef={targetRef} id={id} />
                    </>
                )}
            </div>
        </div>
    )
}

export default PremiumFoundingPartner
