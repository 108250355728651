import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import SimpleSwiper from 'components/swiperComponent/SimpleSwiper'
import { fliterJobList } from 'utils/util'

const Wrap = tw.div`py-16`

export default function TeamIntroduction({ getTeamIntroductionList = [] }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)
    const [datas, setDatas] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        getTeamIntroductionList.length > 0 && setDatas(fliterJobList(getTeamIntroductionList))
    }, [getTeamIntroductionList])
    return (
        <Wrap>
            <div className='w-full bg-exploreBg bg-[length:100%_auto] bg-bottom bg-[#e9ecf3] bg-no-repeat xl:h-[1000px]'>
                {datas && datas?.careerList.length > 0 && (
                    <SimpleSwiper
                        title={t('workWithUs.teamIntroduction')}
                        swiper={swiper}
                        setSwiper={setSwiper}
                        list={datas?.careerList.map((item) => ({
                            ...item,
                            image: item.cardImage,
                            description: item.cardDescription,
                        }))}
                        onClick={(item) => {
                            item.identityUuid && navigate('/workWithUsDetails?id=' + item.identityUuid)
                        }}
                    />
                )}
                <div className='flex justify-center items-center pt-[65px] pb-[90px] md:pt-[130px] md:pb-[180px]'>
                    <div className=' text-center text-secondary-midnightBlue w-full md:w-[600px]'>
                        <div className='text-title'>{t('workWithUs.careerAreas')}</div>
                        <div className='text-sm font-semiboldFamily pt-4'>{t('workWithUs.careerAreasAbout')}</div>
                    </div>
                </div>
            </div>
        </Wrap>
    )
}