import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { useNavigate, useMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'ramda'
import { configDataStore } from 'store/authAtom'
import { myProfile } from 'service/crmService'
import { MyRecordIcon, Notification } from './Icons'
import { updateUrlLang } from 'utils/util'

const Wrap = tw.div`bg-white w-[300px] hidden lg:block`
const TopBox = tw.div`cursor-pointer text-secondary-midnightBlue border-b-[1px] border-solid border-secondary-grey px-[60px] pt-[45px] pb-6 `
const BomBox = tw.div`text-secondary-midnightBlue text-base pt-8`

const Label = ({ item, index, keyLabel, fun }) => {
    return (
        <div
            onClick={fun}
            className={
                'mb-6 py-1 cursor-pointer flex  items-center gap-[30px] font-semiboldFamily px-5 md:px-[60px] ' +
                (keyLabel === item.link
                    ? 'text-primaryRed-ktRed border-solid border-r-[3px] border-primaryRed-ktRed'
                    : 'text-secondary-darkGery')
            }
        >
            <span className='w-6'>{item.icon}</span>
            <div>{item.about}</div>
        </div>
    )
}
const IconBox = tw.div`text-2xl leading-6`

export default function Aside() {
    const { configDataValue } = configDataStore()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const bookmark = useMatch('/userSettings/myBookmark')
    const record = useMatch('/userSettings/myRecord')
    // const fitness = useMatch('/userSettings/fitnessPlanner')
    const setting = useMatch('/userSettings/setting')
    const [user, setUser] = useState({})
    // const [keyLabel, setKeyLabel] = useState(bookmark ? 0 : record ? 1 : setting ? 2 : 3)
    const [keyLabel, setKeyLabel] = useState(record ? 0 : setting ? 1 : null)
    const labels = [
        // { icon: <IconBox className='icon-bookmark' />, about: t('myProfile.myBookmark'), link: '/userSettings/myBookmark' },
        // {
        //     icon: <Notification className='size-6' />,
        //     about: t('myProfile.notification'),
        //     link: 'myProfile/personal?num=1',
        //     isExteralLink: true,
        // },
        ((configDataValue && configDataValue?.ttlSwitch) || (configDataValue && configDataValue?.cspSwitch)) && {
            icon: <MyRecordIcon className='size-6' />,
            about: t('myProfile.myRecord'),
            link: '/userSettings/myRecord',
        },
        // configDataValue &&
        //     configDataValue?.cchSwitch && {
        //         icon: <IconBox className='icon-teamup1 text-[22px]' />,
        //         about: t('myProfile.playerList'),
        //         link: 'myProfile/personal?num=5',
        //         isExteralLink: true,
        //     },
        // { icon: <IconBox className='icon-fitness' />, about: t('myProfile.fitnessPlanner'), link: '' },
        {
            icon: <IconBox className='icon-settings' />,
            about: t('myProfile.settings'),
            link: '/userSettings/setting',
        },
    ].filter((i) => i)

    const { data: profileData } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })

    useEffect(() => {
        setKeyLabel(record ? '/userSettings/myRecord' : setting ? '/userSettings/setting' : null)
    }, [location])

    useEffect(() => {
        if (!isEmpty(profileData)) {
            setUser(profileData)
        }
    }, [profileData])

    return (
        <Wrap>
            {user && (
                <TopBox className='font-semiboldFamily'>
                    {user?.firstname && <div className='text-[22px] leading-[26.4px]'>{user.firstname}</div>}
                    {user?.memberId && (
                        <div className='font-medium text-xs pt-1 line-clamp-2 w-full'>
                            {t('myProfile.userId') + ' ' + user.memberId}
                        </div>
                    )}
                    <div
                        onClick={() => {
                            navigate('/userSettings/myProfile')
                        }}
                        className='text-sm underline py-[10px]'
                    >
                        {t('myProfile.manageProfile')}
                    </div>
                </TopBox>
            )}
            <BomBox className='font-medium'>
                {labels.map((item, index) => {
                    return (
                        <Label
                            item={item}
                            index={index}
                            keyLabel={keyLabel}
                            key={index}
                            fun={() => {
                                !item?.isExteralLink && setKeyLabel(index)
                                item?.isExteralLink
                                    ? window.open(updateUrlLang(process.env.REACT_APP_VBS_URL + item.link))
                                    : navigate(item.link)
                            }}
                        />
                    )
                })}
            </BomBox>
        </Wrap>
    )
}
