import React from 'react'

function Card({ image, component, isReverse, alt }) {
    return (
        <div
            className={
                'flex gap-6 md:gap-[46px] w-full flex-col md:flex-row md:min-h-[210px] lg:min-h-[345px]' +
                (isReverse ? ' md:flex-row-reverse' : '')
            }
        >
            <div className='w-full h-[255px] md:w-[280px] md:h-[210px] lg:w-[459px] lg:h-[345px]'>
                <img alt={alt} src={image} className='w-full h-full object-cover' />
            </div>
            <div className='flex flex-col justify-center text-secondary-midnightBlue w-full overflow-hidden md:flex-1'>
                {component}
            </div>
        </div>
    )
}

export default Card
