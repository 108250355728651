import React from 'react'
import { useTranslation } from 'react-i18next'
import Tags from 'page/EventsTickets/components/Tags'
import DescriptionComponent from 'components/detailComponents/DescriptionComponent'
function About({ items }) {
    const { t } = useTranslation()
    return (
        <div className='bg-container text-secondary-midnightBlue pt-8 md:pt-12'>
            <div className='max-w-[750px]'>
                {/* <div className='text-h3 pb-4'>{t('eventPage.about')}</div> */}
                <div className='text-body1 pb-6 md:pb-12'>
                    <DescriptionComponent description={items.description} />
                </div>
                <div className='pb-6 md:pb-16'>
                    <Tags items={items} />
                </div>
            </div>
        </div>
    )
}

export default About
