import { useQuery, useMutation } from '@tanstack/react-query'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { information } from 'service/informationService'
import Loading from 'components/Loading'

import Banner from 'components/shopDine/Banner'
import workWithUsBanner from 'resources/images/workwithUsBanner.jpg'
import WhyKTSP from 'page/ConnectWithKTSP/WorkWithUs/component/WhyKTSP'
import TeamIntroduction from 'page/ConnectWithKTSP/WorkWithUs/component/TeamIntroduction'
import JobType from 'page/ConnectWithKTSP/WorkWithUs/component/JobType'
import { useEffect, useState } from 'react'
import { set } from 'js-cookie'

const Wrap = tw.div`bg-white pb-8 lg:pb-16`

export default function WorkWithUs() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [data, setData] = useState(null)
    const banner = {
        image: workWithUsBanner,
        title: t('workWithUs.title'),
        about: t('workWithUs.bannerAbout'),
    }

    const workWithUs = useMutation({
        mutationFn: information.workWithUs,
        onSuccess: setData,
        onError: () => {},
    })
    useEffect(() => {
        workWithUs.mutate({ lang: language })
    }, [language])

    return (
        <Wrap>
            <Banner banner={banner} />
            {data && (
                <>
                    <WhyKTSP getWhyKtspList={data?.['whyKtspList']} />
                    <TeamIntroduction getTeamIntroductionList={data?.['workWithKtspJobList']} />
                    <JobType
                        getJobPositionList={data?.['workWithKtspJobList']}
                        getWorkWithKtsp={data?.['workWithKtspList']}
                    />
                </>
            )}
        </Wrap>
    )
}
