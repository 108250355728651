import React from 'react'
import tw from 'twin.macro'
import { useRouteError } from 'react-router-dom'
import { Alert } from '@mui/material'
import Content from 'components/Content'

const Wrap = tw.div`w-full h-full flex flex-col items-center justify-center space-y-6`

export default function Error() {
    const error = useRouteError()

    console.log(error)

    return (
        <Wrap>
            <Alert variant='outlined' severity='error'>
                {error?.statusText || 'Error page'}
            </Alert>
            <Content data={error?.data} />
        </Wrap>
    )
}
