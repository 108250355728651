import { useRef, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'

import TopIntro from 'page/FacilitiesBooking/components/TopIntro'
import About from 'page/FacilitiesBooking/components/About'
import BookingFee from 'page/FacilitiesBooking/components/BookingFee'
import { facilitiesService } from 'service/vbsService'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import RecommendedSportsCards from 'page/FacilitiesBooking/components/RecommendedSportsCards'

import { filterName } from 'utils/filter'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import Loading from 'components/Loading'
import { TabContainer } from 'components/TabContainer'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()

    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const { data: sportDetail, isPending: isSportDetailPending } = useQuery({
        queryKey: ['getSportDetail', language, id],
        queryFn: () =>
            facilitiesService.getSportDetail({
                lang: language,
                facilityId: id,
                bannerSize: 5,
                promoSize: 5,
                recommeSize: 4,
            }),
    })

    const { data: facilitiesStatus, isPending: isFacilitiesStatusPending } = useQuery({
        queryKey: ['getFacilitiesStatus', sportDetail?.vbsFacilityId],
        queryFn: () =>
            facilitiesService.getFacilitiesStatus({
                lang: language,
                ids: sportDetail?.vbsFacilityId,
            }),
        enabled: !!sportDetail?.vbsFacilityId,
    })

    if (isSportDetailPending || isFacilitiesStatusPending) return <Loading />

    return (
        <>
            {sportDetail && (
                <>
                    {sportDetail?.banner?.length > 0 && <BannerSwiper items={sportDetail.banner} />}
                    <section className='bg-container'>
                        <TopIntro items={sportDetail} status={facilitiesStatus?.list?.[0]} />
                    </section>
                    <TabContainer
                        components={[
                            sportDetail?.description && {
                                key: 'about',
                                title: t('eventPage.about'),
                                component: () => <About items={sportDetail} />,
                            },
                            sportDetail?.bookingFee && {
                                key: 'bookingFee',
                                title: t('facilitiesBooking.bookingFee'),
                                component: () => <BookingFee items={sportDetail} />,
                            },
                            sportDetail?.promo &&
                                sportDetail?.promo?.length > 0 && {
                                    key: 'promotion',
                                    title: t('visitList.promotions'),
                                    component: () => <PromotionCards items={sportDetail.promo} />,
                                },
                            sportDetail?.recommendedSport?.length > 0 && {
                                key: 'recommend',
                                title: t('facilitiesBooking.recommendedSport'),
                                component: () => (
                                    <div className='text-secondary-midnightBlue'>
                                        <RecommendedSportsCards items={sportDetail.recommendedSport} />
                                    </div>
                                ),
                            },
                        ].filter((i) => i)}
                    />
                </>
            )}
        </>
    )
}
