import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Menu from '@mui/material/Menu'
import { Box, Button, Tab, Tabs } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TabButton } from './ButtonsTab'
import { cn } from 'utils/cn'
import { RangeCalendar } from './Calendar/range'

function TypeDate({ dateType, setDateType, startDate, setStartDate, endDate, setEndDate, dates }) {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [dateTypeTem, setDateTypeTem] = useState(dateType)
    const [startDateTem, setStartDateTem] = useState(startDate ? dayjs(startDate) : null)

    const [endDateTem, setEndDateTem] = useState(endDate ? dayjs(endDate) : null)
    const [value, setValue] = React.useState(startDate || endDate ? 1 : 0)
    const datesNew = dates.slice(0, 6)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setStartDateTem(startDate)
        setEndDateTem(endDate)
        setDateTypeTem(dateType)
        setAnchorEl(null)
    }
    useEffect(() => {
        setDateTypeTem(dateType)
        setValue(startDate || endDate ? 1 : 0)
        setStartDateTem(startDate)
        setEndDateTem(endDate)
    }, [startDate, dateType, endDate])
    return (
        <>
            <div
                onClick={handleClick}
                className='w-[160px] py-[6px] px-[10px] pl-4 flex justify-between items-center cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px]'
            >
                <div className='text-base font-medium'>{t('eventPage.date')}</div>
                <div className='flex items-center gap-1'>
                    {((dateType && dateType.val !== 'flexibleDate') || startDate || endDate) && (
                        <div className='w-[18px] h-[18px] flex justify-center items-center rounded-[9px] bg-secondary-midnightBlue text-white text-xs font-bold'>
                            1
                        </div>
                    )}
                    <div className='icon-arrow_down text-sm' />
                </div>
            </div>
            <Menu
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: 0,
                        borderTopRightRadius: 40,
                        mt: 1,
                        px: 3,
                        py: 3,
                        width: value === 0 ? 368 : 'fit-content',
                    },
                }}
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box className='font-semiboldFamily' sx={{ borderBottom: 1, borderColor: '#231448', marginBottom: 2 }}>
                    <Tabs
                        textColor='secondary'
                        indicatorColor='secondary'
                        value={value}
                        sx={{
                            '& .MuiBox-root': {
                                color: '#231448',
                            },
                            '& .Mui-selected': {
                                color: 'secondary',
                                fontFamily: 'Barlow',
                                fontWeight: 700,
                            },
                            '& .Mui-selected:hover': {
                                color: 'secondary',
                            },
                        }}
                        onChange={handleChange}
                        aria-label='basic tabs example'
                    >
                        <Tab
                            label={<Box sx={{ fontSize: 16, textTransform: 'none' }}>{t('eventPage.chooseDates')}</Box>}
                            onClick={() => setValue(0)}
                        />
                        <Tab
                            label={
                                <Box sx={{ fontSize: 16, textTransform: 'none' }}>{t('eventPage.flexibleDates')}</Box>
                            }
                            onClick={() => {
                                setValue(1)
                                setDateTypeTem(dates[dates.length - 1])
                            }}
                        />
                    </Tabs>
                </Box>
                {value === 0 ? (
                    <Box sx={{ display: 'flex', width: '100%', gap: 2, flexWrap: 'wrap' }}>
                        {datesNew.map((item, index) => {
                            return (
                                <TabButton
                                    key={index}
                                    onClick={() => setDateTypeTem(item)}
                                    className={cn('min-w-fit w-fit max-w-fit')}
                                    selected={dateTypeTem?.val === item?.val}
                                    size='small'
                                >
                                    {t(item.about)}
                                </TabButton>
                            )
                        })}
                    </Box>
                ) : (
                    <Box sx={{}}>
                        <RangeCalendar
                            date={{ from: startDateTem, to: endDateTem }}
                            setDate={({ from, to }) => {
                                setStartDateTem(from)
                                setEndDateTem(to)
                            }}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                maxDate={endDateTem}
                                value={startDateTem}
                                onChange={(newValue) => setStartDateTem(newValue)}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                minDate={startDateTem}
                                value={endDateTem}
                                onChange={(newValue) => setEndDateTem(newValue)}
                            />
                        </LocalizationProvider> */}
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        fontWeight: 600,
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <Box
                        onClick={() => {
                            setDateTypeTem(null)
                            setEndDateTem(null)
                            setStartDateTem(null)
                        }}
                        sx={{ color: '#0A173D', cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        {t('eventPage.clearAll')}
                    </Box>
                    <Button
                        size='small'
                        onClick={() => {
                            if (value === 0) {
                                setDateType(dateTypeTem)
                                setStartDate(null)
                                setEndDate(null)
                                setStartDateTem(null)
                                setEndDateTem(null)
                            } else {
                                setStartDate(startDateTem)
                                setEndDate(endDateTem)
                                if (startDateTem || endDateTem) {
                                    setDateType(dates[dates.length - 1])
                                } else {
                                    setDateType(null)
                                }
                            }
                            handleClose()
                        }}
                        sx={{
                            textTransform: 'none',
                            px: 2,
                            fontWeight: '600',
                        }}
                        variant='primaryRed'
                    >
                        {t('common.comfirm')}
                    </Button>
                </Box>
            </Menu>
        </>
    )
}

export default TypeDate
