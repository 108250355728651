import React, { useState, useEffect } from 'react'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import PaginationButton from 'components/PaginationButton'
import Loading from 'components/Loading'
import { visitorTipApi } from 'service/visitorTipService'
import Card from 'page/VisitorTips/components/Card'
import Content from 'components/Content'

function GuestServices() {
    const {
        i18n: { language },
    } = useTranslation()

    const { data, fetchNextPage, isPending, hasNextPage } = useInfiniteQuery({
        queryKey: ['guestServices', language],
        queryFn: ({ pageParam = 0 }) =>
            visitorTipApi.getGuestServices({ lang: language, type: 0, limit: 10, offset: pageParam }),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.length < 10) return undefined
            return pages.length * 10
        },
    })

    const list = data?.pages.flatMap((page) => page) || []

    return (
        <div className='space-y-6'>
            {isPending && <Loading />}
            {list?.length > 0 &&
                list.map((item, index) => {
                    return (
                        <Card
                            key={index}
                            image={item.image}
                            isReverse={index % 2 !== 0}
                            alt={item.altText}
                            component={
                                <div>
                                    <div className='text-[28px] leading-[28px] font-semiboldFamily'>{item.title}</div>
                                    <Content className='mt-5' data={item.description} />
                                </div>
                            }
                        />
                    )
                })}
            {hasNextPage && <PaginationButton isDisabled={false} fun={fetchNextPage} nowCardsLength={list?.length} />}
        </div>
    )
}

export default GuestServices
