import React, { useState } from 'react'
import Tags from 'page/EventsTickets/components/Tags'
import { useTranslation } from 'react-i18next'

function TagsSection({ items }) {
    const { t } = useTranslation()
    const { tags } = items
    // const { hideThingsToNote, thingsToNote } = items
    return (
        <div className='text-secondary-midnightBlue bg-container'>
            {tags && tags.length > 0 && (
                <div className='pt-8 md:pt-[60px]'>
                    <Tags items={{ tags }} />
                </div>
            )}
        </div>
    )
}

export default TagsSection
