import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { information } from 'service/informationService'
import CommonLabels from 'page/ConnectWithKTSP/FAQ/conponents/CommonLabels'

const filter = (data) => {
    let result = {}

    data.forEach((item) => {
        if (!result[item.tagName]) {
            result[item.tagName] = {
                tagName: item.tagName,
                faqList: [],
            }
        }
        result[item.tagName].faqList = result[item.tagName].faqList.concat(item.faqList)
    })

    return Object.values(result)
}
const TypeComponent = ({ data }) => {
    return data && data?.faqList.length > 0 ? (
        <CommonLabels isAll={true} items={data.faqList} title={data.tagName ?? ''} />
    ) : null
}

export default function All({ searchVal }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [datas, setDatas] = useState([])
    const getFaqDatas = useMutation({
        mutationFn: information.getFootFaqSearch,
        onSuccess: (result) => {
            setDatas(filter(result))
        },
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        getFaqDatas.mutate({ keyword: searchVal.trim(), lang: language, tagId: 0 })
    }, [language])
    return (
        <div>
            {datas.length > 0 &&
                datas.map((item, index) => {
                    return <TypeComponent key={index} data={item} />
                })}
        </div>
    )
}
