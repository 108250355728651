import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useSimpleBackdropStore } from 'store/authAtom'
import { Box, Wrap } from 'page/UserSettings/components/CommonBox'
import { useSnackbar } from 'notistack'
import { myProfile } from 'service/crmService'
function Setting() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    const { data: profileData, isPending: profileIsPending } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })
    const updateSSOProfile = useMutation({
        mutationFn: myProfile.updateSSOProfile,
        onSuccess: () => {
            enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
            queryClient.invalidateQueries({
                queryKey: ['getMyProfile'],
            })
            onCloseSimpleBackdrop()
        },
        onError: () => {
            enqueueSnackbar(t('myProfile.saveTip'), { variant: 'error' })
            onCloseSimpleBackdrop()
        },
    })
    return (
        <Wrap>
            <div className='text-4xl font-semiboldFamily uppercase'>{t('myProfile.settings')}</div>
            {profileData && (
                <Box>
                    <div className='text-xl font-semiboldFamily text-secondary-midnightBlue pb-6'>
                        {t('myProfile.eNewsSubscription')}
                    </div>
                    <div className='flex justify-between items-center gap-2'>
                        <div className='text-sm text-secondary-midnightBlue flex-1 font-medium'>
                            <div>{t('myProfile.pushTip')}</div>
                        </div>
                        <Button
                            disabled={profileIsPending || profileData.eNewIndicator !== 'false'}
                            onClick={() => {
                                onOpenSimpleBackdrop()
                                updateSSOProfile.mutate({
                                    ...profileData,
                                    eNewIndicator: true,
                                    dayOfBirth: profileData?.dayOfBirth ?? '',
                                    openIdUUID: profileData?.openIdUUID ?? '',
                                    province: profileData?.province ?? '',
                                    district: profileData?.district ?? '',
                                    campaignCode: profileData?.campaignCode ?? '',
                                    referralCode: profileData?.referralCode ?? '',
                                })
                            }}
                            className={
                                'text-white normal-case text-base font-semiboldFamily leading-[19.2px] py-[8.5px] h-fit w-fit px-4' +
                                (profileData.eNewIndicator !== 'false' ? ' bg-secondary-gery' : ' bg-website-gradation')
                            }
                            variant='contained'
                            size='large'
                        >
                            {t('footer.subscribe')}
                        </Button>
                    </div>
                </Box>
            )}
        </Wrap>
    )
}

export default Setting
