import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, TextField, MenuItem } from '@mui/material'
import { useModalStore } from 'store/authAtom'

import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
const Label = ({ label }) => {
    return <Box sx={{ fontSize: 16, fontWeight: 600, color: '#231448' }}>{label}</Box>
}
function TicketDatasEdit() {
    const { onClose } = useModalStore()
    const { register, handleSubmit } = useForm()
    const onSubmit = (form) => {
        console.log('form')
        console.log(form)
        onClose()
    }
    const selectDatas = [
        { code: 222, about: 'sss' },
        { code: 111, about: 'ssees' },
        { code: 333, about: 'sfsafs' },
    ]
    return (
        <Box>
            <Box sx={{ fontSize: 22, fontWeight: 600, paddingBottom: 2 }}>Edit Ticket 1</Box>
            <Box sx={{ fontSize: 14, fontWeight: 500, paddingBottom: 4 }}>
                Complete this form to edit your ticket to edit your ticket details
            </Box>
            <Box sx={{ fontSize: 22, fontWeight: 600, paddingBottom: 2 }}>Ticket Information</Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register('email', { required: true })}
                    fullWidth
                    variant='standard'
                    color='secondary'
                    label={<Label label={'Email address'} />}
                    placeholder='email@gmail.com'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    sx={{ marginTop: 2 }}
                    {...register('fullName', { required: true })}
                    fullWidth
                    variant='standard'
                    color='secondary'
                    label={<Label label={'Full Name'} />}
                    placeholder='Full Name'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    select
                    variant='standard'
                    {...register('dateOfBirth', { required: true })}
                    color='secondary'
                    label={<Label label={'Date Of Birth'} />}
                    sx={{ width: '100%', marginTop: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    {selectDatas.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                            {option.about}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    variant='standard'
                    {...register('gender', { required: true })}
                    color='secondary'
                    label={<Label label={'Gender'} />}
                    sx={{ width: '100%', marginTop: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    {selectDatas.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                            {option.about}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ fontSize: 22, fontWeight: 600, paddingTop: 2 }}>Order From</Box>
                <TextField
                    sx={{ marginTop: 2 }}
                    {...register('orderFullName', { required: true })}
                    fullWidth
                    variant='standard'
                    color='secondary'
                    label={<Label label={'Full Name'} />}
                    placeholder='Full Name'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    select
                    variant='standard'
                    {...register('tip', { required: true })}
                    color='secondary'
                    label={<Label label={'Are all the ticket holders over the age of 18?'} />}
                    sx={{ width: '100%', marginTop: 2 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    {selectDatas.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                            {option.about}
                        </MenuItem>
                    ))}
                </TextField>
                <DoubleBtn
                    leftText='Back'
                    rightText='Save'
                    rightFun={() => {
                        onClose()
                    }}
                    // leftFun={() => {
                    //     onClose()
                    // }}
                    rightDisable={false}
                />
            </form>
        </Box>
    )
}

export default TicketDatasEdit
