import React, { useRef, useState, useEffect } from 'react'
import { IconButton, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import tw, { styled } from 'twin.macro'
import { length } from 'ramda'
import { useTranslation } from 'react-i18next'

const SwiperWrap = styled(Swiper)`
    & > .swiper-pagination-horizontal {
        border-radius: 10px;
        bottom: -55px;
        left: 0;
        top: auto;
        width: 200px;
        ${tw`relative bg-secondary-grey bg-opacity-[0.4]`}
        & > .swiper-pagination-progressbar-fill {
            ${tw`bg-white h-[5px]`}
        }
    }
`

export default function MeetOurTeam({ getMeetOurTeam }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)

    const ArrowForwardRef = useRef(null)
    const ArrowBackRef = useRef(null)

    useEffect(() => {
        if (swiper) {
            swiper.params.navigation.nextEl = ArrowForwardRef.current
            swiper.params.navigation.prevEl = ArrowBackRef.current

            swiper.navigation.init()
            swiper.navigation.update()
        }

        return () => {
            if (swiper && swiper.navigation) {
                swiper.navigation.destroy()
            }
        }
    }, [swiper])

    return (
        <div className='bg-meetOurTeamBg relative text-white bg-center z-20 bg-no-repeat bg-cover px-[50px] lg:min-h-[700px] xl:px-[150px]'>
            <div className='bg-[#000000] absolute top-0 bottom-0 left-0 right-0 -z-10 opacity-50'></div>
            <div className='font-semiboldFamily py-[60px] text-2xl md:text-4xl'>{t('aboutUs.moreOurTeam')}</div>
            <SwiperWrap
                spaceBetween={10}
                loop={true}
                onSwiper={setSwiper}
                onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
                modules={[Navigation, Pagination]}
                pagination={{
                    type: 'progressbar',
                }}
                navigation={{
                    nextEl: ArrowForwardRef.current,
                    prevEl: ArrowBackRef.current,
                }}
                className='mySwiper flex-1 xl:pl-[100px] pb-9'
            >
                {getMeetOurTeam?.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            {({ isActive }) => {
                                // console.log(index, '---->', isActive)
                                return (
                                    <div className='w-2/3'>
                                        <img
                                            alt={item.altText}
                                            src={item.webVisual}
                                            className='pb-2 w-[220px] h-[125px] object-contain'
                                        />
                                        <div className='text-xl font-bold'>{item.title}</div>
                                        <div className='py-2 text-base font-medium'>{item.webDescription}</div>
                                    </div>
                                )
                            }}
                        </SwiperSlide>
                    )
                })}
                {getMeetOurTeam.length > 0 && (
                    <div className='flex justify-between w-full mt-4'>
                        <Typography variant='subtitle1'>
                            {activeSlideIndex + 1} / {length(getMeetOurTeam)}
                        </Typography>
                        <div className='space-x-3'>
                            <IconButton
                                ref={ArrowBackRef}
                                className='rounded-1/2 border-solid border-white border-[1.5px] w-[50px] h-[50px]'
                            >
                                <ArrowBack className='text-white' />
                            </IconButton>
                            <IconButton
                                ref={ArrowForwardRef}
                                className='rounded-1/2 border-solid border-white border-[1.5px] w-[50px] h-[50px]'
                            >
                                <ArrowForward className='text-white' />
                            </IconButton>
                        </div>
                    </div>
                )}
            </SwiperWrap>
        </div>
    )
}
