import React, { useState, useRef } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, Drawer, useMediaQuery } from '@mui/material'

import AnUrbanCilcle from 'components/landingComponents/AnUrbanCilcle'
import anUrbanOasisBg1 from 'resources/images/anUrbanOasisBg.png'
import anUrbanOasisBg2 from 'resources/images/anUrbanOasisBg2.png'
import TitleGradient from 'components/landingComponents/TitleGradient'
function AnUrbanOasis() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const xxlScreen = useMediaQuery('(min-width:1667px)')
    const anUrbanOasisBg = xxlScreen ? anUrbanOasisBg2 : anUrbanOasisBg1
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    /**
     * Handles the start of a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragStart = (e) => {
        setIsDragging(true)
        setStartX(e.pageX - e.currentTarget.offsetLeft)
        setScrollLeft(e.currentTarget.scrollLeft)
    }

    /**
     * Handles the end of a drag event.
     */
    const handleDragEnd = () => {
        setIsDragging(false)
    }

    /**
     * Handles the movement during a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragMove = (e) => {
        if (!isDragging) return
        e.preventDefault()
        const x = e.pageX - e.currentTarget.offsetLeft
        const walk = (x - startX) * 3
        e.currentTarget.scrollLeft = scrollLeft - walk
    }

    const stadiums = [
        {
            position:
                'absolute right-[33%] top-[32%] lg:top-[36%] lg:right-[35.4%] xl:right-[35%] 2xl:right-[36%] 4xl:top-[35%]',
            text: t('visitorTip.kaiYouthSportsGround'),
        },
        {
            position:
                'absolute left-[21.7%] top-[47.3%] lg:top-[49%] lg:left-[23.4%] xl:top-[48%] xl:left-[22.4%] 2xl:left-[23.4%] 2xl:top-[49%] 3xl:left-[31.8%]',
            text: t('visitorTip.kaiMainStadium'),
        },
        {
            position:
                'absolute left-[1.22%] top-[48%] md:left-[2.22%] lg:top-[49%] lg:left-[4.62%] xl:top-[48.8%] xl:left-[4.5%] 2xl:left-[5.2%] 3xl:left-[18.2%]',
            text: t('visitorTip.diningCove'),
        },
        {
            position:
                'absolute top-[76%] left-[12%] md:left-[13.6%] lg:top-[78%] lg:left-[14.6%] xl:left-[14.4%] 2xl:left-[15.7%] 3xl:left-[25.7%]',
            text: t('visitorTip.easternPlaza'),
        },
        {
            position:
                'absolute top-[84%] left-[25.6%] md:top-[86.6%] lg:left-[28.6%] xl:left-[27.8%] 2xl:left-[28%] 3xl:left-[35.2%]',
            text: t('visitorTip.eastVillage'),
        },
        {
            position:
                'absolute top-[83%] right-[20%] md:right-[21.6%] lg:top-[84.5%] lg:right-[23.8%] xl:right-[23.8%] 2xl:right-[24.4%] 3xl:right-[27.8%]',
            text: t('visitorTip.playfield5'),
        },
        {
            position:
                'absolute right-[6%] top-[73%] md:right-[6.6%] lg:right-[7.8%] lg:top-[75.5%] xl:right-[8%] 2xl:right-[8.8%] 3xl:right-[16.8%]',
            text: t('visitorTip.northGarden'),
        },
        {
            position:
                'absolute right-[3%] top-[51%] md:right-[4.5%] md:top-[52%] lg:top-[54.8%] lg:right-[5.8%] xl:right-[6.8%] 2xl:right-[6.3%] 3xl:right-[14.5%]',
            text: t('visitorTip.kaiMall'),
        },
        {
            position:
                'absolute right-[13%] top-[41%] md:right-[15.5%] md:top-[42%] lg:right-[17%] lg:top-[45%] xl:right-[17%] 2xl:right-[18.4%] 3xl:right-[23%]',
            text: t('visitorTip.kaiTakSportsAvenue'),
        },
        {
            position:
                'absolute right-[37%] top-[47%] md:right-[39%] md:top-[48%] lg:right-[40%] lg:top-[50%] xl:right-[40%] 2xl:right-[41%] 3xl:right-[40.2%]',
            text: t('visitorTip.centralSquare'),
        },
        {
            position:
                'absolute right-[28.2%] top-[37%] md:right-[29.2%] md:top-[37.4%] lg:right-[30.4%] lg:top-[38.4%] xl:right-[30.8%] 2xl:right-[31.2%] 3xl:right-[33%]',
            text: t('visitorTip.healthAndWellnessCentre'),
        },
        {
            position:
                'absolute right-[18%] top-[53%] md:right-[20%] lg:right-[21%] lg:top-[54.6%] xl:right-[20.5%] xl:top-[55%] 2xl:right-[21.7%] 3xl:right-[25.4%]',
            text: t('visitorTip.kaiArena'),
        },
    ]
    return (
        <div className=' relative pt-[320px] md:pt-[370px] lg:pt-[358px] xl:pt-[330px]'>
            <div className='absolute z-30 w-full bg-gradient-to-b from-[70%] from-white to-transparent pb-[60px] sm:pb-[100px] md:pb-[40px] top-[60px] min-h-[411px] md:top-[120px] md:min-h-[446px]  lg:min-h-[365px] lg:top-[172px] lg:pb-[75px] xl:pb-[95px] 3xl:lg:top-[182px]'>
                <div className='bg-container flex flex-col gap-5 lg:flex-row lg:justify-between lg:items-start lg:gap-[90px]'>
                    <div className='w-full md:flex-1'>
                        <TitleGradient text={t('landing.anUrbanOasis')} />
                        <div className='text-body1 line-clamp-8 text-ellipsis overflow-hidden text-secondary-midnightBlue'>
                            {t('landing.anUrbanOasisAbout')}
                        </div>
                    </div>
                    <div className='flex pb-4 flex-col gap-4 md:gap-6'>
                        <Button
                            onClick={() => navigate('/venue')}
                            className='text-white text-lg px-5 h-fit py-2 min-w-[240px] w-fit'
                            variant='primaryRed'
                        >
                            {t('landing.seeAllVenues')}
                        </Button>
                        <Button
                            onClick={() => navigate('/transportation')}
                            className='bg-transparent hover:bg-gray-50 border-[1px] text-lg px-5 h-fit py-[7px] min-w-[240px] w-fit'
                            variant='secondary'
                        >
                            {t('landing.howToGetThere')}
                        </Button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    scrollbarWidth: 'none',
                    cursor: isDragging ? 'grabbing' : 'grab',
                }}
                className='w-full overflow-auto '
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDragMove}
                onMouseLeave={handleDragEnd}
            >
                <div className='relative h-[426px] w-[1022px] md:w-[1222px] md:h-[560px] lg:w-[1440px] lg:h-[780px] xl:cursor-default xl:w-full'>
                    <img alt='' src={anUrbanOasisBg} className='w-full h-full ' />
                    {stadiums.map((stadium, index) => {
                        return <AnUrbanCilcle key={index} position={stadium.position} text={stadium.text} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default AnUrbanOasis
