import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import BookingSwiper from 'page/Venue/components/BookingSwiper'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import Seemore from 'components/landingComponents/SeeMore'
function BookingCards({ items = [] }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div className='pb-8 md:pb-16'>
            <div className='text-h3 flex justify-between items-center font-semiboldFamily text-secondary-midnightBlue pb-4 md:pb-8'>
                <div>{t('bookingsProgrammes.facility')}</div>
                <Seemore title={t('facilitiesBooking.seeMore')} handleClick={() => navigate('/facilitiesBooking')} />
            </div>
            <div
                className={
                    'flex justify-end pb-4 md:pb-8 ' +
                    (items.facilityList.length <= 3 && ' lg:hidden ') +
                    (items.facilityList.length <= 2 && ' hidden ')
                }
            >
                <IconButtonChange
                    slideIndex={slideIndex}
                    num={lgScreen ? 3 : 2}
                    length={items.facilityList.length}
                    goToNextSlide={goToNextSlide}
                    goToPrevSlide={goToPrevSlide}
                    buttonColor={'#231448'}
                    borderColor={'border-secondary-midnightBlue'}
                />
            </div>
            <BookingSwiper setSlideIndex={setSlideIndex} items={items.facilityList} setSwiper={setSwiper} />
        </div>
    )
}

export default BookingCards
