import React from 'react'
import { Button } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { useTranslation } from 'react-i18next'
function MapBtn({ venueLocationUrl, btnCw = '' }) {
    const { t } = useTranslation()
    return (
        <Button
            onClick={() => {
                window.open(venueLocationUrl)
            }}
            variant='secondaryBlue'
            endIcon={<div className='icon-arrow_right text-base' />}
        >
            {btnCw || t('transport.goToGoogleMap')}
        </Button>
    )
}

export default MapBtn
