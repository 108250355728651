import { Box, Chip } from '@mui/material'
import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import tw, { styled } from 'twin.macro'

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
`

export default function About({ items }) {
    const { t } = useTranslation()

    const [isExpand, setIsExpand] = useState(false)
    const [isMoreThan, setIsMoreThan] = useState(false)

    const descriptionRef = useRef(null)

    //等ba回复确定是否需要将amenitiesList放在show more中
    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.style.display = 'block'
            const descriptionHeight = descriptionRef.current.offsetHeight
            const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10)
            const maxLines = 4
            setIsMoreThan(descriptionHeight > lineHeight * maxLines)
            descriptionRef.current.style.display = 'none'
        }
    }, [items.description])

    return (
        <div className='bg-container'>
            <div className='max-w-[750px]'>
                <div className='pt-8 text-secondary-midnightBlue md:pt-12'>
                    {/* <div className='text-h3 pb-4 md:pb-6'>{t('eventPage.about')}</div> */}
                    <Content ref={descriptionRef} data={items.description} className={'text-base font-medium pb-6'} />
                    <Content
                        data={items.description}
                        className={
                            'text-base font-medium' + (isMoreThan && !isExpand ? ' line-clamp-3' : '')
                        }
                    />
                    <div className='mt-6 mb-12'>
                        {isMoreThan && (
                          <ViewMore
                            setIsExpand={setIsExpand}
                            isExpand={isExpand}
                            more={t('facilitiesBooking.seeMore')}
                          />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
