import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
var LocalizedFormat = require('dayjs/plugin/localizedFormat')
// dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat)
dayjs.extend(LocalizedFormat)

export const setDayjsLocale = (language) => {
    window.localStorage.setItem('lang', language)
    require('dayjs/locale/en')
    dayjs.locale('en')
    if (language === 'zh_HK') {
        require('dayjs/locale/zh-hk')
        dayjs.locale('zh-hk')
    } else if (language === 'zh_CN') {
        require('dayjs/locale/zh-cn')
        dayjs.locale('zh-cn')
    }
}
export const registerBtnFun = (language, isSignUp = false) => {
    const path = isSignUp ? 'signUp' : 'signIn'
    window.location.href =
        process.env.NODE_ENV === 'development'
            ? `http://localhost:3001/login/${path}?lang=${language}&redirectUrl=http://localhost:3000`
            : `${process.env.REACT_APP_CIAM}/login/${path}?lang=${language}&redirectUrl=${process.env.REACT_APP_WEBSITE}`
}

export const fliterJobList = (getTeamIntroductionList = []) => {
    const careerList = getTeamIntroductionList.filter((item) => item.dataType === 1)
    const jobList = getTeamIntroductionList.filter((item) => item.dataType !== 1)
    return { careerList: careerList, jobList: jobList }
}

export function ConvertDateRangeHelper(dateRange, year, language) {
    // console.log(dateRange);

    let dateString = ''
    if (language === 'en_US') {
        if (dateRange?.length > 1) {
            if (dayjs(dateRange.at(0)).format('YYYY') === dayjs(dateRange.at(dateRange.length - 1)).format('YYYY')) {
                // eslint-disable-next-line no-lone-blocks
                {
                    if (
                        dayjs(dateRange.at(0)).format('MMM') === dayjs(dateRange.at(dateRange.length - 1)).format('MMM')
                    ) {
                        if (
                            dayjs(dateRange.at(0)).format('DDD') ===
                            dayjs(dateRange.at(dateRange.length - 1)).format('DDD')
                        ) {
                            return year
                                ? `${dayjs(dateRange.at(dateRange.length - 1)).format('DD MMM YYYY')}`
                                : `${dayjs(dateRange.at(dateRange.length - 1)).format('DD MMM')}`
                        }
                        return year
                            ? `${dayjs(dateRange.at(0)).format('DD')} - ${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('DD MMM YYYY')}`
                            : `${dayjs(dateRange.at(0)).format('DD')} - ${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('DD MMM')}`
                    } else
                        return year
                            ? `${dayjs(dateRange.at(0)).format('DD MMM')} - ${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('DD MMM YYYY')}`
                            : `${dayjs(dateRange.at(0)).format('DD MMM')} - ${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('DD MMM')}`
                }
            } else
                return `${dayjs(dateRange.at(0)).format('DD MMM YYYY')} - ${dayjs(
                    dateRange.at(dateRange.length - 1),
                ).format('DD MMM YYYY')}`
        }
        if (dateRange?.length === 1) {
            dateString = dayjs(dateRange.at(0)).format('DD MMM')
            console.log(dateString)
            return year ? dayjs(dateRange.at(0)).format('DD MMM YYYY') : dayjs(dateRange.at(0)).format('DD MMM')
        } else return ''
    } else {
        if (dateRange?.length > 1) {
            if (dayjs(dateRange.at(0)).format('YYYY') === dayjs(dateRange.at(dateRange.length - 1)).format('YYYY')) {
                // eslint-disable-next-line no-lone-blocks
                {
                    if (
                        dayjs(dateRange.at(0)).format('MMM') === dayjs(dateRange.at(dateRange.length - 1)).format('MMM')
                    ) {
                        if (
                            dayjs(dateRange.at(0)).format('DDD') ===
                            dayjs(dateRange.at(dateRange.length - 1)).format('DDD')
                        ) {
                            return year
                                ? `${dayjs(dateRange.at(dateRange.length - 1)).format('ll')}`
                                : `${dayjs(dateRange.at(dateRange.length - 1)).format('MMMDD日')}`
                        }
                        return year
                            ? `${dayjs(dateRange.at(dateRange.length - 1)).format('YYYY年MMMDD')}至${dayjs(
                                  dateRange.at(0),
                              ).format('D')}日`
                            : `${dayjs(dateRange.at(dateRange.length - 1)).format('MMMDD')}至${dayjs(
                                  dateRange.at(0),
                              ).format('D')}日`
                    } else
                        return year
                            ? `${dayjs(dateRange.at(0)).format('ll')}至${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('MMMDD')}日`
                            : `${dayjs(dateRange.at(0)).format('MMMDD')}至${dayjs(
                                  dateRange.at(dateRange.length - 1),
                              ).format('MMMDD')}日`
                }
            } else
                return `${dayjs(dateRange.at(0)).format('ll')}至${dayjs(dateRange.at(dateRange.length - 1)).format(
                    'll',
                )}`
        }
        if (dateRange?.length === 1) {
            dateString = dayjs(dateRange.at(0)).format('MMMDD日')
            console.log(dateString)
            return year ? dayjs(dateRange.at(0)).format('ll') : dayjs(dateRange.at(0)).format('MMMDD日')
        } else return ''
    }
}
export function ConvertTimeRangeHelper(dateRange, language) {
    // const language = useSelector((state) => state.common.language);
    // setDayjsLocale(language);
    if (language === 'en_US') {
        if (dateRange?.length > 1) {
            if (
                dayjs(dateRange.at(0)).format('DD MMM YYYY') ===
                dayjs(dateRange.at(dateRange.length - 1)).format('DD MMM YYYY')
            ) {
                // eslint-disable-next-line no-lone-blocks
                {
                    return `${dayjs(dateRange.at(0)).format('HH:mm ')} - ${dayjs(
                        dateRange.at(dateRange.length - 1),
                    ).format('HH:mm dddd')}`
                }
            } else
                return `${dayjs(dateRange.at(0)).format('HH:mm dddd')} - ${dayjs(
                    dateRange.at(dateRange.length - 1),
                ).format('HH:mm dddd')}`
        }
        if (dateRange?.length === 1) {
            return dayjs(dateRange.at(0)).format('HH:mm dddd')
        } else return ''
    } else {
        if (dateRange?.length > 1) {
            if (
                dayjs(dateRange.at(0)).format('DD MMM YYYY') ===
                dayjs(dateRange.at(dateRange.length - 1)).format('DD MMM YYYY')
            ) {
                // eslint-disable-next-line no-lone-blocks
                {
                    return `${dayjs(dateRange.at(0)).format('HH:mm')}至${dayjs(
                        dateRange.at(dateRange.length - 1),
                    ).format('HH:mm dddd')}`
                }
            } else
                return `${dayjs(dateRange.at(0)).format('HH:mm dddd')}至${dayjs(
                    dateRange.at(dateRange.length - 1),
                ).format('HH:mm dddd')}`
        }
        if (dateRange?.length === 1) {
            return dayjs(dateRange.at(0)).format('HH:mm dddd')
        } else return ''
    }
}

export const updateUrl = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const searchParams = new URLSearchParams(url.search)
    if (searchParams.get('lang')) {
        searchParams.delete('lang')
        const newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`
        window.history.replaceState({}, '', newUrl)
    }
}

export const updateUrlLang = (val) => {
    console.log(val)
    const url = new URL(val)
    let newUrl = val
    const searchParams = new URLSearchParams(url.search)
    if (!searchParams.get('lang')) {
        const localLang = window.localStorage.getItem('lang')
        searchParams.set('lang', localLang)
        newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`
    }
    return newUrl
}

export const dateFormat3 = (language, date) => {
    const format = language === 'en_US' ? 'D MMM YYYY HH:mm' : 'YYYY年M月D日 HH:mm'
    return dayjs(date).format(format)
}
export const dateFormat = (date, language) => {
    const format = language === 'en_US' ? 'D MMM YYYY' : 'YYYY年M月D日'
    return dayjs(date).format(format)
}
