import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import Banner from 'components/shopDine/Banner'
import proTop from 'resources/images/newsRoomBanner.png'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import AllNewsroom from 'page/ConnectWithKTSP/NewsRoom/components/AllNewsroom'
import NavigationPoint from 'components/NavigationPoint'
import { newsroomService } from 'service/informationService'

export default function NewsRoom() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: proTop,
        title: t('aboutUs.newsroomTitle'),
        about: t('aboutUs.newsroomBannerAbout'),
    }

    const { data: bannerSwiperImgs } = useQuery({
        queryKey: ['newsroomgetHeroBanners', language],
        queryFn: () =>
            newsroomService.getHeroBanners({
                lang: language,
                size: 5,
            }),
    })

    return (
        <div className='bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                <div className='py-6 bg-container'>
                    <NavigationPoint
                        firstNav={t('navigationBar.connectWithKTSP')}
                        lastNav={t('connectWithKtsp.newsroom')}
                        firstNavLink='/connectWithKTSP/aboutKTSP'
                    />
                </div>
            </section>
            <section>
                {bannerSwiperImgs?.length > 0 && <BannerSwiper type='newsroom' bannerSwiperImgs={bannerSwiperImgs} />}
            </section>
            <section>
                <AllNewsroom />
            </section>
        </div>
    )
}
