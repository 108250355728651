import { http } from 'utils/http'

export const myProfile = {
    getMyProfile: () => http.get('/user/getUserProfile'),
    changePassword: (payload) => http.post('/crm/changePassword', payload),
    updateSSOProfile: (payload) => http.post('/crm/updateSSOProfile', payload),
    deleteAccount: (payload) => http.post('/crm/deleteAccount', payload),
    deleteSSOProfile: (payload) => http.post('/crm/deleteSSOProfile', payload),
    updateAccessToken: (payload) => http.get('/sso/refreshUserToken?refreshUserToken=' + payload),
    updateInterest: (payload) => http.post('/crm/updateInterest', payload),
    updateEmailAndPhone: (payload) => http.post('/sso/updateEmailAndPhone', payload),
    userBind: (payload) => http.post('/crm/userBind', payload),
    checkProfileExist: (payload) => http.post('/crm/checkProfileExist', payload),
}
