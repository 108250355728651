import { useQuery } from '@tanstack/react-query'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { isEmpty, last } from 'ramda'
import { useEffect, useState } from 'react'

import { information } from 'service/informationService'

import Banner from 'page/ConnectWithKTSP/AboutKTSP/components/Banner'
import MissionVision from 'page/ConnectWithKTSP/AboutKTSP/components/MissionVision'
import MoreThanSports from 'page/ConnectWithKTSP/AboutKTSP/components/MoreThanSports'
import MeetOurTeam from 'page/ConnectWithKTSP/AboutKTSP/components/MeetOurTeam'
import SeeMoreSection from 'page/ConnectWithKTSP/AboutKTSP/components/SeeMoreSection'

const Wrap = tw.div`bg-white`

export default function AboutKTSP() {
    const {
        i18n: { language },
        t,
    } = useTranslation()

    const [data, setData] = useState({})

    const { data: currentData } = useQuery({
        queryKey: ['aboutKtsp', language],
        queryFn: () =>
            information.aboutKtsp({
                lang: language,
            }),
        select: (res) => {
            return {
                connectWithKtspList: res?.connectWithKtspList ?? {},
                missionAndVision: res?.missionAndVision ?? {},
                moreThanSportsList: res?.moreThanSportsList ?? [],
                ourValueList: res?.ourValueList ?? [],
                seeMoreList: res?.seeMoreList ?? [],
            }
        },
    })

    useEffect(() => {
        if (!!currentData) {
            setData(currentData)
        }
    }, [currentData])

    return (
        <Wrap>
            {!isEmpty(data) && (
                <>
                    {last(data?.connectWithKtspList)?.bannerVisual && (
                        <Banner
                            url={last(data?.connectWithKtspList)?.bannerVisual}
                            description={t('connectWithKtsp.title')}
                        />
                    )}

                    {!!data?.missionAndVision?.description && !isEmpty(data?.ourValueList) && (
                        <MissionVision
                            description={data?.missionAndVision?.description}
                            swiperList={data?.ourValueList}
                        />
                    )}
                    {!isEmpty(data?.moreThanSportsList) && <MoreThanSports getMoreSports={data?.moreThanSportsList} />}
                    {/* {data[1] && data[1].length > 0 && <MeetOurTeam getMeetOurTeam={data[1]} />} */}
                    {!isEmpty(data?.seeMoreList) && <SeeMoreSection getSeeMore={data?.seeMoreList} />}
                </>
            )}
        </Wrap>
    )
}
