import { http } from 'utils/http'

export const landing = {
    contactUs: (payload) => http.post('/landing/contactUs', payload),
    foundPartner: (payload) => http.post('/landing/foundPartner', payload),
    getAllEnjoyWith: (payload) => http.post('/landing/getAllEnjoyWith', payload),
    getAllFeaturedAttractions: (payload) => http.post('/landing/getAllFeaturedAttractions', payload),
    getAllHeroBannerAdvertisement: (payload) => http.post('/landing/getAllHeroBannerAdvertisement', payload),
    getAllHighLight: (payload) => http.post('/landing/getAllHighLight', payload),
    getAllImmersiveExperience: (payload) => http.post('/landing/getAllImmersiveExperience', payload),
    getAllShopAndDine: (payload) => http.post('/landing/getAllShopAndDine', payload),
    getCherishedMemories: (payload) => http.post('/landing/getCherishedMemories', payload),
    getCherishedMemoriesImage: (payload) => http.post('/landing/getCherishedMemoriesImage', payload),
    getHeroBannerDetail: (payload) => http.post('/landing/getHeroBannerDetail', payload),
    getSpecialAnnouncement: (payload) => http.post('/landing/getSpecialAnnouncement', payload),
    getTcInfo: (payload) => http.post('/landing/getTcInfo', payload),
    getTransportationAnnouncement: (payload) => http.post('/landing/getTransportationAnnouncement', payload),
    getTransportationDownloadGuide: (payload) => http.post('/landing/getTransportationDownloadGuide', payload),
    getWeather: (payload) => http.post('/landing/getWeather', payload),
    reminder: (payload) => http.post('/landing/reminder', payload),
    getAllShopDineAdvertisement: (payload) => http.post('/shopDine/getAllShopDineAdvertisement', payload),
    getLandingPage: (payload) => http.post('/landing/getLandingPage', payload),
}
