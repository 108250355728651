import Seemore from 'components/landingComponents/SeeMore'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CarParkList from 'page/Venue/components/CarParkList'

function TransportationSection({ items }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div className=' text-secondary-midnightBlue pb-8 md:pb-16'>
            <div className='flex justify-between items-center font-semiboldFamily text-secondary-midnightBlue pb-4 normal-case'>
                <div className='text-h3 pb-8 font-semiboldFamily'>{t('venue.transportation')}</div>
                <Seemore
                    title={t('venue.howToGetThere')}
                    handleClick={() => {
                        navigate('/transportation')
                    }}
                />
            </div>
            <div className='text-base font-medium'>{t('venue.transportationAbout')}</div>
            {/* <div className='text-base font-medium'>{t('venue.navigationService')}</div>
            <div className='text-base font-medium'>
                <Trans
                    i18nKey='venue.downloadApp'
                    components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a href='/visitorTips?val=KTSPAPPIntroduction' className='text-[#0000EE] underline' />,
                    ]}
                />
                <span className='text-[#0000EE] underline'>{t('venue.downloadAppLast')}</span>
            </div> */}
            {/*{items.carParkList.length > 0 && <CarParkList items={items.carParkList} />}*/}
        </div>
    )
}

export default TransportationSection
