import React, { useState } from 'react'
import tw from 'twin.macro'
import { TextField, Box, InputAdornment, IconButton, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { myProfile } from 'service/crmService'
import { useValidationI18n } from 'utils/validationI18n'
import { useModalStore } from 'store/authAtom'
import { useSimpleBackdropStore } from 'store/authAtom'
import { registerBtnFun } from 'utils/util'

import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
import ViewOff from 'resources/images/view_off.svg'
import View from 'resources/images/view.svg'

const Form = tw.form`flex flex-col space-y-5 mt-3`
const ImgIcon = tw.img``

export default function ChangePassword() {
    const { enqueueSnackbar } = useSnackbar()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { onClose } = useModalStore()
    const { validationSchema, requiredValidationsSchema } = useValidationI18n()

    const [showPassword, setShowPassword] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [focus, setFocus] = useState(false)

    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()

    const handleFocus = (e) => {
        e.stopPropagation()
        setFocus(true)
    }

    const handleBlur = (e) => {
        e.stopPropagation()
        setTimeout(() => {
            setFocus(false)
        }, 200)
    }

    const tips = [
        t('myProfile.Characters'),
        t('myProfile.Number'),
        t('myProfile.Uppercase'),
        t('myProfile.Lowercase'),
        t('myProfile.Symbol'),
    ]

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            yup.object({
                oldPassword: requiredValidationsSchema.oldPassword,
                newPassword: validationSchema.password,
                confirmPassword: validationSchema.confirmPassword,
            }),
        ),
    })

    const changePasswordPost = useMutation({
        mutationFn: myProfile.changePassword,
        onSuccess: () => {
            enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
            onClose()
            onCloseSimpleBackdrop()
            setTimeout(() => {
                registerBtnFun(language)
            }, 1500)
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
            onCloseSimpleBackdrop()
        },
    })

    const onSubmit = (formData) => {
        if (formData.newPassword === formData.oldPassword) {
            enqueueSnackbar(t('myProfile.updatePasswordTip'), { variant: 'error' })
            return
        }
        onOpenSimpleBackdrop()
        changePasswordPost.mutate({
            lang: language,
            newPassword: formData.newPassword,
            oldPassword: formData.oldPassword,
        })
    }

    return (
        <Box>
            <Typography variant='h5' className='mb-8 uppercase' gutterBottom>
                {t('myProfile.changePassword')}
            </Typography>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <TextField
                    {...register('oldPassword')}
                    error={!!errors?.oldPassword}
                    helperText={errors?.oldPassword?.message ?? null}
                    label={t('myProfile.oldPassword')}
                    variant='standard'
                    type={showOldPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('myProfile.enterOldassword')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowOldPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showOldPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    {...register('newPassword')}
                    error={!!errors?.newPassword}
                    helperText={errors?.newPassword?.message ?? null}
                    label={t('myProfile.newPassword')}
                    variant='standard'
                    type={showPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('myProfile.enterNewPassword')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {focus ? (
                    <Box>
                        {tips.map((tip, index) => {
                            return (
                                <Box key={index} sx={{ fontSize: 14, fontWeight: 500 }} component='li'>
                                    {tip}
                                </Box>
                            )
                        })}
                    </Box>
                ) : null}
                <TextField
                    {...register('confirmPassword')}
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message ?? null}
                    label={t('SignUpPage.ConfirmPasswordDisplay')}
                    variant='standard'
                    type={showConfirmPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('myProfile.reEnterPassword')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showConfirmPassword ? (
                                        <ImgIcon src={ViewOff} alt='' />
                                    ) : (
                                        <ImgIcon src={View} alt='' />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <DoubleBtn
                    leftText={t('myProfile.cancel')}
                    leftFun={onClose}
                    rightText={t('myProfile.save')}
                    rightDisable={changePasswordPost.isPending}
                />
            </Form>
        </Box>
    )
}
