import React from 'react'
import { useTranslation } from 'react-i18next'

import FerryEn_Tc from 'resources/images/FerryEn_Tc.png'
import FerrySc from 'resources/images/FerrySc.png'
import WebBtn from 'page/Transportation/components/WebBtn'
import WrapBox from 'page/Transportation/components/WrapBox'
import TaxiFerrryCrossCommon from 'page/Transportation/components/TaxiFerrryCrossCommon'
function Ferry() {
    const { i18n: {language}, t } = useTranslation()
    return (
        <WrapBox
            image={language === 'zh_CN' ? FerrySc : FerryEn_Tc}
            component={
                <div>
                    <TaxiFerrryCrossCommon firstTitle={t('transport.byFerry')} firstAbout={t('transport.ferryAbout')} />
                    <WebBtn text={t('transport.ferryBtn')} url={t('transport.ferryLink')} />
                </div>
            }
        />
    )
}

export default Ferry
