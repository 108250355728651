import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { fliterJobList } from 'utils/util'
import mask from 'resources/images/jobTypeMask.png'
const Wrap = tw.div`pt-8 mx-auto w-[90%] md:w-[68%]`

const Center = tw.div`flex justify-center`
export default function JobType({ getJobPositionList = [], getWorkWithKtsp = [] }) {
    const [datas, setDatas] = useState(null)
    const { t } = useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        getJobPositionList.length > 0 && setDatas(fliterJobList(getJobPositionList))
    }, [getJobPositionList])
    return (
        <Wrap>
            <div className='text-title text-center text-secondary-midnightBlue pb-[100px]'>
                {t('workWithUs.jobType')}
            </div>
            <div className='flex justify-between pb-16 max-w-[978px] mx-auto gap-4 md:gap-6'>
                {datas &&
                    datas?.jobList.length > 0 &&
                    datas?.jobList.map((item, index) => {
                        return (
                            <div
                                onClick={() =>
                                    item.identityUuid && navigate('/workWithUsDetails?id=' + item.identityUuid)
                                }
                                key={index}
                                className={
                                    'relative flex-1 cursor-pointer group overflow-hidden' +
                                    (index === 1 ? ' -translate-y-10' : '')
                                }
                            >
                                <img
                                    alt={item.altText}
                                    src={item.cardImage}
                                    className='w-full object-cover aspect-[3/4]'
                                />
                                <div className=' absolute bottom-2 z-20 space-y-[5px] left-2 break-words w-4/5 text-white group-hover:-translate-y-2 transition-all duration-500'>
                                    <div className='text-h5 line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>
                                        {item.title}
                                    </div>
                                    <div className='text-xs font-regular line-clamp-1 md:line-clamp-2 lg:line-clamp-5 textarea-content'>
                                        {item.cardDescription}
                                    </div>
                                </div>
                                <img
                                    alt=''
                                    src={mask}
                                    className='absolute z-10 bottom-0 object-cover w-full h-[50%] lg:h-[190px]'
                                />
                            </div>
                        )
                    })}
            </div>
            <Center>
                {getWorkWithKtsp?.[0] && (
                    <div className='text-secondary-midnightBlue text-center w-[300px] md:w-[500px] xl:w-[800px]'>
                        <div className='text-title pb-4'>{t('workWithUs.jobVacancies')}</div>
                        <div className='text-sm font-semiboldFamily pb-8 textarea-content'>
                            {getWorkWithKtsp[0].cardDescription}
                        </div>
                        <Center>
                            <div className='space-y-8'>
                                {getWorkWithKtsp[0].image && (
                                    <div className='w-[320px] h-[220px] md:w-[450px] md:h-[300px] xl:w-[696px] xl:h-[392px]'>
                                        <img
                                            alt={getWorkWithKtsp[0].altText}
                                            src={getWorkWithKtsp[0].image}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                )}
                                <Button
                                    onClick={() => window.open(getWorkWithKtsp[0].vacanciesButtonUrl)}
                                    className='mt-2 text-sm h-fit px-4 mb-4 md:w-[273px]'
                                    variant='primaryRed'
                                    size='large'
                                >
                                    {t('workWithUs.btnTitle')}
                                </Button>
                            </div>
                        </Center>
                    </div>
                )}
            </Center>
        </Wrap>
    )
}
