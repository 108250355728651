import React, { useState, useEffect, useSearchParams, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { information } from 'service/informationService'
import Banner from 'components/shopDine/Banner'
import ourPartnerBanner from 'resources/images/workwithUsBanner.jpg'
import NavigationPoint from 'components/NavigationPoint'
import PremiumFoundingPartner from 'page/ConnectWithKTSP/OurPartner/components/PremiumFoundingPartner'
import OfficialSection from 'page/ConnectWithKTSP/OurPartner/components/OfficialSection'
import BeOurPartner from 'page/ConnectWithKTSP/OurPartner/components/BeOurPartner'
function OurPartner() {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const searchParams = new URLSearchParams(url.search)
    const id = searchParams.get('id') + ''
    const targetRef = useRef(null)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const banner = {
        image: ourPartnerBanner,
        title: t('ourPartner.title'),
        about: t('ourPartner.bannerAbout'),
    }
    const [items, setItems] = useState(null)
    const getOurPartners = useMutation({
        mutationFn: information.getOurPartners,
        onSuccess: setItems,
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        getOurPartners.mutate({ lang: language })
    }, [language])
    useEffect(() => {
        if (targetRef) {
            targetRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [targetRef, items])
    return (
        <div className='bg-white pb-8 lg:pb-20'>
            <section>
                <Banner banner={banner} />
            </section>
            {items && (
                <div className='mx-auto '>
                    <div className='bg-container py-6'>
                        <NavigationPoint
                            firstNav={t('navigationBar.connectWithKTSP')}
                            lastNav={t('connectWithKtsp.ourPartners')}
                            firstNavLink={'/connectWithKTSP/aboutKTSP'}
                        />
                    </div>
                    <section className='bg-container'>
                        <PremiumFoundingPartner id={id} targetRef={targetRef} items={items} />
                        {items.officalPartner && items.officalPartner.length > 0 && (
                            <OfficialSection
                                id={id}
                                targetRef={targetRef}
                                brandAbouts={items.officalPartner}
                                title={t('ourPartner.officialPartner')}
                            />
                        )}
                        {items.officialSupplier && items.officialSupplier.length > 0 && (
                            <OfficialSection
                                id={id}
                                targetRef={targetRef}
                                brandAbouts={items.officialSupplier}
                                title={t('ourPartner.officialSupplier')}
                            />
                        )}
                        <BeOurPartner />
                    </section>
                </div>
            )}
        </div>
    )
}

export default OurPartner
