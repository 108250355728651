import { useEffect, useState } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@tanstack/react-query'
import tw from 'twin.macro'

import TitleSearchComponent from 'components/shopDine/allProductsComponents/TitleSearchComponent'
import Loading from 'components/Loading'
import VenueCard from 'page/Venue/components/VenueCard'
import { venueServices } from 'service/venueService'

const Wrap = tw.div`text-secondary-midnightBlue mx-auto py-16`

export default function AllVenue() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const [isProductsList, setIsProductsList] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)

    const {
        data: cards,
        refetch,
        isFetching,
        isPending,
    } = useQuery({
        queryKey: ['getVenueList', language],
        queryFn: () => venueServices.getVenueList({ lang: language, searchKey: searchVal.trim() }),
    })
    useEffect(() => {
        refetch()
    }, [])
    return (
        <Wrap className='bg-container'>
            <TitleSearchComponent
                name={t('venue.allVenue')}
                searchVal={searchVal}
                setIsSearch={setIsSearch}
                setSearchVal={setSearchVal}
                onBlurFun={() => refetch()}
            />
            <div className='text-lg text-secondary-grey cursor-pointer flex items-center justify-end gap-5 mb-8 mt-[30px]'>
                <div
                    onClick={() => setIsProductsList(false)}
                    className={!isProductsList ? 'text-secondary-midnightBlue' : ''}
                >
                    <GridOnOutlinedIcon />
                </div>
                <div
                    onClick={() => setIsProductsList(true)}
                    className={isProductsList ? 'text-secondary-midnightBlue' : ''}
                >
                    <FormatListBulletedIcon />
                </div>
            </div>
            {isPending ? (
                <Loading />
            ) : (
                <div
                    className={
                        isProductsList
                            ? ''
                            : 'grid gap-x-[10px] gap-y-8 grid-cols-2 md:gap-x-[27px] lg:grid-cols-4 lg:gap-y-16'
                    }
                >
                    {isSearch &&
                        cards &&
                        cards.length > 0 &&
                        cards.map((card) => {
                            return <VenueCard key={card.identityUuid} isProductsList={isProductsList} card={card} />
                        })}
                </div>
            )}
        </Wrap>
    )
}
