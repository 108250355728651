import { createRef } from 'react'
import ReactDOM from 'react-dom/client'
import { keepPreviousData, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import { Helmet } from 'react-helmet'
import { CssBaseline, Grow, IconButton } from '@mui/material'
import { red } from '@mui/material/colors'
import { zhCN } from '@mui/material/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider, MaterialDesignContent } from 'notistack'
import { Close } from '@mui/icons-material'
import { styled } from 'twin.macro'

import 'resources/i18n'
import './index.css'
import App from './App'
import theme from 'theme'
import ThemeProvider from 'theme'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            gcTime: 1000 * 60 * 60,
            retry: false,
            placeholderData: keepPreviousData,
        },
        mutations: {},
    },
})

const notistackRef = createRef()
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#231448',
        color: '#FFFFFF',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#FFE7EC',
        color: '#E20030',
        flexWrap: 'nowrap'
    },
}))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <div>
        {/*<Helmet>*/}
        {/*    <title>KTSP</title>*/}
        {/*    <meta name='description' content='Your page description' />*/}
        {/*    <meta name='keywords' content='keyword1, keyword2, keyword3' />*/}
        {/*</Helmet>*/}
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-right' />
            <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <SnackbarProvider
                        preventDuplicate
                        autoHideDuration={null}
                        Components={{
                            success: StyledMaterialDesignContent,
                            error: StyledMaterialDesignContent,
                        }}
                        TransitionComponent={Grow}
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        ref={notistackRef}
                        action={(key) => (
                            <IconButton onClick={onClickDismiss(key)} size='small' color='inherit'>
                                <Close></Close>
                            </IconButton>
                        )}
                    >
                        <App />
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
            <CssBaseline />
        </QueryClientProvider>
    </div>,
)
