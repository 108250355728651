import { useEffect, useMemo, useState } from 'react'
import { Outlet, ScrollRestoration, useMatch } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { setDayjsLocale } from 'utils/util'
import { decryptAes } from 'utils/filter'
import SimpleBackdrop from 'components/SimpleBackdrop'
import { GetCookie, RemoveCookie } from 'utils/cookieLombok'
import { myProfile } from 'service/crmService'
import { user } from 'service/userService'
import { configServices } from 'service/configService'
import { configDataStore, useUserInfoAtom } from 'store/authAtom'
import { useSpacialTrafficNews, useFooterShow } from 'store/authAtom'
import MainModal from 'components/MainModal'
import SpacialTrafficNews from 'components/SpacialTrafficNews'
import { event } from 'event/event'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ImageViewer from 'components/ImageViewer'
import BackToTop from 'components/BackToTop'
import CookieConsent from 'components/CookieConsent'
import { cn } from 'utils/cn'

export default function Main() {
    const {
        i18n: { language },
        i18n,
        t,
    } = useTranslation()
    const { setConfigDataValue } = configDataStore()
    const { isOpenSpacial } = useSpacialTrafficNews()
    const { isOpen } = useFooterShow()
    const [isBlur, setIsBlur] = useState(false)
    const navigate = useNavigate()
    const userSettingMatch = useMatch('/userSettings/*')
    const heritage = useMatch('/heritageMilestone')
    const landing = useMatch('/landing')
    const transportation = useMatch('/transportation')
    const carPark = useMatch('/carPark')
    const carParkDetail = useMatch('/carParkDetail')
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const [refreshToken, setRefreshToken] = useState(GetCookie('ktspSsoRefreshToken'))
    const setProfileInfo = useUserInfoAtom((state) => state.setProfileInfo)

    const updateRefreshToken = () => {
        setRefreshToken(GetCookie('ktspSsoRefreshToken'))
    }
    const { data: profileData, isPending: profileIsPending } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
        enabled: !!refreshToken,
    })
    const { data: configData } = useQuery({
        queryKey: ['getSystemConfig'],
        queryFn: configServices.getSystemConfig,
    })
    const logoutHandle = () => {
        RemoveCookie('ktspSsoAccessToken')
        RemoveCookie('session')
        RemoveCookie('sessionKey')
        RemoveCookie('ktspSsoRefreshToken')
        setProfileInfo()
        setIsBlur(false)
        window.location.pathname.startsWith('/userSettings') && navigate('/')
    }
    const LogoutApi = useMutation({
        mutationFn: user.logout,
        onSuccess: () => {
            console.log('logout success')
            logoutHandle()
        },
        onError: () => {
            console.log('logout failed')
        },
    })

    const logout = () => {
        LogoutApi.mutate(decryptAes(GetCookie('ktspSsoRefreshToken')))
    }
    useEffect(() => {
        event.on('logout', logout)
        event.on('logoutHandle', logoutHandle)
        return () => {
            event.off('logout', logout)
            event.off('logoutHandle', logoutHandle)
        }
    }, [])
    useEffect(() => {
        updateRefreshToken()
        if (GetCookie('ktspSsoRefreshToken') && !profileData) {
            queryClient.invalidateQueries(['getMyProfile'])
        }
    }, [GetCookie('ktspSsoRefreshToken')])
    useEffect(() => {
        if (GetCookie('lang')) {
            setDayjsLocale(GetCookie('lang'))
            i18n.changeLanguage(GetCookie('lang'))
            RemoveCookie('lang')
        }
    }, [GetCookie('lang')])
    useEffect(() => {
        if (configData) {
            setConfigDataValue(configData)
        } else {
            setConfigDataValue('')
        }
    }, [configData])
    console.log(language)

    useEffect(() => {
        setProfileInfo(profileData)
    }, [profileData])

    return (
        <div
            className={cn('flex flex-col text-body1', language === 'en_US' ? 'font-kt-en' : 'font-kt-zh')}
            id='main-container'
        >
            <SimpleBackdrop />
            <MainModal />
            <ImageViewer />
            <header className='sticky top-0 z-50' id='main-header'>
                {isOpenSpacial && (landing || transportation || carPark || carParkDetail) && <SpacialTrafficNews />}
                <Header profileData={profileData ?? null} profileIsPending={profileIsPending} setIsBlur={setIsBlur} />
            </header>
            <main
                id='main-content'
                onClick={() => isBlur && setIsBlur(false)}
                className={'w-full overflow-x-hidden overflow-y-auto h-full relative'}
            >
                {isBlur && (
                    <div className='absolute top-0 left-0 right-0 bottom-0 z-50 backdrop-blur-[10px] bg-opacity-20' />
                )}
                <Outlet />
            </main>
            <ScrollRestoration
            // getKey={(location, matches) => {
            //     return location.pathname
            // }}
            />
            {((!userSettingMatch && !heritage) || (heritage && isOpen)) && (
                <Footer profileData={profileData ?? null} profileIsPending={profileIsPending} />
            )}
            <BackToTop />
            <CookieConsent />
        </div>
    )
}
