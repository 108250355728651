import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useMediaQuery } from '@mui/material'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useNavigate } from 'react-router-dom'

import c1 from 'resources/images/card1.png'
import shadowBg from 'resources/images/featuredShopBg.png'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import Steps from 'components/swiperComponent/Steps'
import Title from 'components/shopDine/Title'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'

function FeaturedShop({ name, featuredList, path }) {
    const navigate = useNavigate()
    const [swiper, setSwiper] = useState(null)
    const isDesktop = useMediaQuery('(min-width:1024px)')
    const isPad = useMediaQuery('(min-width:500px)')
    const num = isDesktop ? 2 : isPad ? 1 : 0
    const [slideIndex, setSlideIndex] = useState(0)

    return (
        <>
            <div className='w-full bg-[#E9ECF3] bg-cover bg-center bg-no-repeat overflow-hidden pt-4 mt-8 md:pt-8 md:mt-16'>
                <div className='pb-4 pl-5 md:pl-10 md:pb-8 lg:pl-[60px] xl:pl-[150px]'>
                    <Title title={name} />
                </div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={24}
                    modules={[Navigation]}
                    onSwiper={(swiper) => {
                        setSwiper(swiper)
                    }}
                    onActiveIndexChange={(swiper) => {
                        setSlideIndex(swiper.realIndex)
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.3,
                        },
                        500: {
                            slidesPerView: 2.3,
                        },
                        768: {
                            slidesPerView: 2.5,
                        },
                        1024: {
                            slidesPerView: 3.3,
                        },
                    }}
                    className='mySwiper ml-5 pb-8 md:ml-10 lg:ml-[60px] xl:ml-[150px]'
                >
                    {featuredList.map((img, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div
                                    onClick={() => navigate(path + '?id=' + img.identityUuid)}
                                    className='relative cursor-pointer active:opacity-50'
                                >
                                    <img alt='' src={img.cardImage} className='w-full aspect-square object-cover' />
                                    <div className='absolute z-20 bottom-4 left-4 right-4 space-y-1 text-white md:bottom-[30px] md:left-[30px] md:right-[30px]'>
                                        <div className='line-clamp-2 break-words text-h5'>{img.name}</div>
                                        <div className='text-body3 flex items-start space-x-1'>
                                            <div className='icon-location mt-[2px]' />
                                            <div className='text-ellipsis whitespace-nowrap overflow-hidden'>{img.location}</div>
                                        </div>
                                    </div>
                                    <img src={shadowBg} alt='' className='w-full absolute bottom-0 h-[47%]' />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div
                    className={
                        'w-full flex justify-between items-center pb-8 px-5 md:px-10 lg:px-[60px] xl:px-[150px] xl:pb-16 ' +
                        (featuredList.length <= 3 && ' lg:hidden ') +
                        (featuredList.length <= 2 && ' md:hidden ') +
                        (featuredList.length <= 1 && ' hidden ')
                    }
                >
                    <Steps
                        swiperLength={featuredList}
                        slideIndex={slideIndex + num}
                        stepTextColor={'text-secondary-midnightBlue'}
                        stepActiveColor={'bg-secondary-midnightBlue'}
                    />
                    <IconButtonChange
                        slideIndex={slideIndex}
                        num={isDesktop ? 3 : isPad ? 2 : 1}
                        length={featuredList.length}
                        goToNextSlide={() => {
                            swiper.slideNext()
                        }}
                        goToPrevSlide={() => {
                            swiper.slidePrev()
                        }}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                    />
                </div>
            </div>
        </>
    )
}

export default FeaturedShop
