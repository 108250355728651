import { useState } from 'react'
import { Button } from '@mui/material'
import { cn } from 'utils/cn'

/**
 * @typedef {Object} ButtonItem
 * @property {React.ReactNode} text - The text to display on the button.
 * @property {boolean} selected - Indicates if the button is selected.
 * @property {Object} [otherProps] - Additional properties that are not fixed.
 */

/**
 * ButtonsTab component renders a list of buttons that can be dragged horizontally.
 *
 * @param {{ list: Array<ButtonItem>, onItemClick: function(ButtonItem): void, className?: string, itemClassName?: string }} props - The component props.
 * @returns {JSX.Element} The rendered ButtonsTab component.
 */
export default function ButtonsTab({ list, onItemClick, className, itemClassName }) {
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    /**
     * Handles the start of a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragStart = (e) => {
        setIsDragging(true)
        setStartX(e.pageX - e.currentTarget.offsetLeft)
        setScrollLeft(e.currentTarget.scrollLeft)
    }

    /**
     * Handles the end of a drag event.
     */
    const handleDragEnd = () => {
        setIsDragging(false)
    }

    /**
     * Handles the movement during a drag event.
     *
     * @param {MouseEvent} e - The mouse event.
     */
    const handleDragMove = (e) => {
        if (!isDragging) return
        e.preventDefault()
        const x = e.pageX - e.currentTarget.offsetLeft
        const walk = (x - startX) * 3
        e.currentTarget.scrollLeft = scrollLeft - walk
    }

    return (
        <div
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDragMove}
            onMouseLeave={handleDragEnd}
            className={cn('flex flex-grow overflow-x-auto gap-4 py-6 mb-6 lg:gap-6 bg-container', className)}
            style={{
                scrollbarWidth: 'none',
            }}
        >
            {list.map((item, index) => {
                return (
                    <TabButton
                        key={index}
                        className={itemClassName}
                        selected={item.selected}
                        onClick={() => onItemClick(item)}
                    >
                        {item.text}
                    </TabButton>
                )
            })}
        </div>
    )
}

export function TabButton({ className, children, selected, onClick }) {
    return (
        <Button
            className={cn(
                'py-3 px-5 h-fit w-fit min-w-[142px] text-nowrap shrink-0 text-[14px] leading-normal',
                selected ? 'font-semiboldFamily' : 'font-mediumFamily',
                className,
            )}
            onClick={onClick}
            variant={selected ? 'selected' : 'unselected'}
        >
            {children}
        </Button>
    )
}
