import React from 'react'
import HeaderCard from 'components/headerComponents/card/HeaderCard'
import { useTranslation } from 'react-i18next'
// import { Container } from './styles';
import { cn } from 'utils/cn'
function HeaderCards({ abouts, setIsFocus }) {
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <div className={cn('grid gap-5 ', abouts.length > 4 ? 'grid-cols-5' : 'grid-cols-4')}>
            {abouts.map((item, index) => {
                return (
                    <div key={index} className='space-y-9'>
                        {item.map((itemSecond, i) => {
                            return <HeaderCard key={i} setIsFocus={setIsFocus} abouts={itemSecond} />
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export default HeaderCards
