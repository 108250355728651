import * as React from 'react'
import { Card, CardMedia } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { cn } from 'utils/cn'

export default function VenueCard({ card, isProductsList }) {
    const navigate = useNavigate()
    return (
        <Card
            onClick={() => navigate('/venueDetail?id=' + card.identityUuid)}
            className={
                'relative w-full shadow-none cursor-pointer bg-transparent group rounded-none' +
                (isProductsList ? ' my-3' : '')
            }
        >
            <div className={isProductsList ? 'flex gap-4 md:gap-10' : ''}>
                <div
                    className={
                        CARD_BOX +
                        (!isProductsList
                            ? 'mb-3 w-full aspect-[4/3] xl:h-[194px]'
                            : ' aspect-[4/3] w-[150px] md:w-[180px] xl:w-[290px] xl:h-[210px]')
                    }
                >
                    {card.cardImage && (
                        <CardMedia
                            component='img'
                            className='object-cover w-full h-full '
                            image={card.cardImage}
                            alt='loading'
                        />
                    )}
                    <RedLine />
                </div>

                <div className={isProductsList ? 'flex-1 space-y-2' : ''}>
                    <div className='flex justify-between'>
                        {card?.venueName && (
                            <div
                                className={cn(
                                    ' text-secondary-midnightBlue w-full break-words font-semiboldFamily text-base md:text-xl',
                                    isProductsList ? 'pt-0' : 'pt-3',
                                )}
                            >
                                {card.venueName}
                            </div>
                        )}

                        {/* <IconButton className=' p-0 self-start text-base  text-secondary-midnightBlue'>
                            {card.bookMark ? <BeenhereOutlinedIcon /> : <BookmarkBorderIcon />}
                        </IconButton> */}
                    </div>
                    {card.description && isProductsList && <DescriptionLabel description={card.description} />}
                    {card.localhost && (
                        <div className='flex gap-1 items-center font-medium mt-2 text-xs text-secondary-midnightBlue md:text-sm '>
                            {/* <IconButton className='p-0 '> */}
                            <div className='icon-location text-base text-secondary-midnightBlue' />
                            {/* </IconButton> */}
                            <div className='line-clamp-2'>{card.localhost}</div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    )
}
