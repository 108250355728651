import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { information } from 'service/informationService'
import MothodLabel from 'components/MothodLabel'

function ContactMethod() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [contacts, setContacts] = useState([])
    const seeMoreContactUs = useMutation({
        mutationFn: information.seeMoreContactUs,
        onSuccess: setContacts,
        onError: (e) => console.log(e),
    })
    useEffect(() => {
        seeMoreContactUs.mutate({ lang: language })
    }, [language])
    return (
        <div className='px-5 w-full py-[38px] text-secondary-midnightBlue md:px-0 md:mx-auto md:w-[600px] xl:w-[729px]'>
            <div className='pb-[14px] text-h2 text-center'>{t('contactUs.contactMethod')}</div>
            <div>
                {contacts.map((item, index) => {
                    return <MothodLabel key={index} item={item} />
                })}
            </div>
        </div>
    )
}

export default ContactMethod
