import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { encrypt } from 'utils/filter'
dayjs.extend(utc)
export const SetCookie = (cookieName, val, expiresTime) => {
    const format = 'DD/MM/YYYY HH:mm:ss'
    const CookieConsent = GetCookie('CookieConsent')
    if (!CookieConsent) {
    
    }
    document.cookie = `${cookieName}=${encrypt(val)}; Secure; SameSite=None; domain=${
      process.env.REACT_APP_DOMAINS
    }; expires=${dayjs(expiresTime, format).utc().toString()}; path=/`
    // document.cookie = `${cookieName}=${encrypt(val)}; expires=${dayjs(expiresTime, format).utc().toString()}; path=/`
}

export const GetCookie = (cookieName) => {
    return Cookies.get(cookieName)
}

export const RemoveCookie = (cookieName) => {
    // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    document.cookie = `${cookieName}=; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    document.cookie = `${cookieName}=; Secure; SameSite=None; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}
