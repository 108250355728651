import React, { useState, useEffect, useMemo } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Drawer, useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Fade from '@mui/material/Fade'
import tw from 'twin.macro'
import { useQuery } from '@tanstack/react-query'
import { useMatch } from 'react-router-dom'
// import headerNav from 'constants/header.json'

import HeaderCards from 'components/headerComponents/HeaderCards'
import { registerBtnFun } from 'utils/util'
import { filterName } from 'utils/filter'
import { GetCookie } from 'utils/cookieLombok'
import SearchComponent from 'components/headerComponents/SearchComponent'
import UniversalSearth from 'components/headerComponents/UniversalSearth'
import SelectComponent from 'components/headerComponents/SelectComponent'
import Event from 'components/headerComponents/group/Event'
import PearlClub from 'components/headerComponents/group/PearlClub'
import ShopDine from 'components/headerComponents/group/ShopDine'
import BooksProgrammes from 'components/headerComponents/group/BooksProgrammes'
import VisitExperience from 'components/headerComponents/group/VisitExperience'
import ConnectWithKTSP from 'components/headerComponents/group/ConnectWithKTSP'
import MenuHeader from 'components/headerComponents/MenuHeader'
import ModalHeader from 'components/headerComponents/ModalHeader'
import headerLogo from 'resources/images/headerLogo.png'
import { useDisclosure } from 'utils/useDisclosure'
import { LogoutIcon, MyRecordIcon, MyPlayListIcon, SettingsIcon, Notification } from './Icons'
import { configDataStore } from 'store/authAtom'
import { configServices } from 'service/configService'
import FriendsComponent from './headerComponents/FriendsComponent'
import { cn } from 'utils/cn'

const Wrap = tw.div`flex justify-between items-center h-full px-[24px] md:gap-[72px] md:px-5 lg:px-[50px] 2xl:container 2xl:mx-auto`

const IconBox = tw.div`text-2xl leading-6`

function Header({ setIsBlur, profileData, profileIsPending }) {
    const { configDataValue } = configDataStore()
    // const headerNavs = JSON.stringify(headerNav)
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const navigate = useNavigate()
    const [refreshToken, setRefreshToken] = useState(GetCookie('ktspSsoRefreshToken'))
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isFocus, setIsFocus] = useState(false)
    const [index, setIndex] = useState(0)
    const open = Boolean(anchorEl)
    const lgScreen = useMediaQuery('(min-width:1085px)')
    const isEvent = useMatch('/eventsTickets')
    const updateRefreshToken = () => {
        setRefreshToken(GetCookie('ktspSsoRefreshToken'))
    }
    const handleClick = (event) => {
        if (GetCookie('ktspSsoRefreshToken')) {
            setIsBlur(true)
            setAnchorEl(event.currentTarget)
        } else {
            updateRefreshToken()
        }
    }
    const handleClose = () => {
        setIsBlur(false)
        setAnchorEl(null)
    }
    const { data: headerNavs } = useQuery({
        queryKey: ['getHeaderJson'],
        queryFn: configServices.getHeaderJson,
        placeholderData: null,
    })
    const useData = [
        // {
        //     icon: <div className='text-xl icon-bookmark text-secondary-midnightBlue' />,
        //     text: t('myProfile.myBookmark'),
        //     link: '/userSettings/myBookmark',
        // },
        // {
        //     icon: <Notification className='size-6 lg:text-secondary-midnightBlue text-white' />,
        //     text: t('myProfile.notification'),
        //     link: 'myProfile/personal?num=1',
        //     isExteralLink: true,
        // },
        ((configDataValue && configDataValue?.ttlSwitch) ||
            (configDataValue && configDataValue?.cchSwitch) ||
            (configDataValue && configDataValue?.cspSwitch)) && {
            icon: <MyRecordIcon className={cn('size-6 text-secondary-midnightBlue')} />,
            text: t('myProfile.myRecord'),
            link: '/userSettings/myRecord',
        },
        // configDataValue &&
        //     configDataValue?.cchSwitch && {
        //         icon: <div className='icon-teamup1 text-2xl leading-6 text-white lg:text-secondary-midnightBlue' />,
        //         text: t('myProfile.playerList'),
        //         link: 'myProfile/personal?num=5',
        //         isExteralLink: true,
        //     },
        // {
        //     icon: <div className='text-xl icon-fitness text-secondary-midnightBlue' />,
        //     text: t('myProfile.fitnessPlanner'),
        //     link: '',
        // },
        {
            icon: <SettingsIcon className={cn('size-6 text-white lg:text-secondary-midnightBlue')} />,
            text: t('myProfile.settings'),
            link: '/userSettings/setting',
        },
        {
            icon: <LogoutIcon className={cn('size-6 text-secondary-midnightBlue')} />,
            text: t('myProfile.logOut'),
            link: '/',
        },
    ].filter((i) => i)
    // const isEventFocus =
    //     isEvent && JSON.parse(headerNavs)?.length > 0 && JSON.parse(headerNavs)[0].webLinkUrl === '/eventsTickets'
    useEffect(() => {
        updateRefreshToken()
    }, [document.cookie])
    // useEffect(() => {
    //     if (!isEvent && index === 0) {
    //         setIsFocus(false)
    //     }
    // }, [isEvent])
    if (!headerNavs) {
        return (
            <div className='bg-website-gradation' id='main-header'>
                <Wrap>
                    <div className='my-[20.74px] w-[100px] h-[40px] md:w-[148px] md:h-[66.52px]'>
                        <img
                            onClick={() => navigate('/')}
                            alt=''
                            className='object-contain cursor-pointer w-full h-full'
                            src={headerLogo}
                        ></img>
                    </div>
                    <div className='hidden lg:flex-1 lg:flex-col lg:flex'>
                        <div className='flex self-end py-2'>
                            <SelectComponent color={'#fff'} />
                            {refreshToken && profileIsPending ? (
                                <CircularProgress />
                            ) : refreshToken && profileData ? (
                                <MenuHeader
                                    profileData={profileData}
                                    isPending={profileIsPending}
                                    handleClick={handleClick}
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                    open={open}
                                    useData={useData}
                                />
                            ) : (
                                <Button
                                    className='text-base normal-case text-[#fff] border-[#fff] hover:bg-white/10'
                                    size='small'
                                    variant='outlined'
                                    onClick={() => {
                                        registerBtnFun(language)
                                    }}
                                >
                                    {t('navigationBar.loginBtn')}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className='lg:hidden'>
                        {/* <SearchComponent size='middle' /> */}
                        <IconButton onClick={onOpen} aria-label='menu' size='middle'>
                            <MenuIcon sx={{ color: '#fff' }} fontSize='middle' />
                        </IconButton>
                    </div>
                </Wrap>
            </div>
        )
    }
    return (
        <div
            onMouseLeave={() => {
                setIsFocus(false)
            }}
            className='bg-website-gradation'
            id='main-header'
        >
            <Wrap>
                <Drawer anchor={'top'} open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
                    <UniversalSearth
                        close={() => {
                            setIsOpenDrawer(false)
                        }}
                    />
                </Drawer>
                {!lgScreen && (
                    <ModalHeader
                        isOpen={isOpen}
                        profileData={profileData}
                        isPending={profileIsPending}
                        onClose={onClose}
                        headerNavs={JSON.parse(headerNavs) ?? []}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        open={open}
                        useData={useData}
                        isTurn={JSON.parse(headerNavs).length > 4}
                    />
                )}
                <div className='my-[20.74px] w-[100px] h-[40px] md:w-[148px] md:h-[66.52px]'>
                    <img
                        onClick={() => navigate('/')}
                        alt=''
                        className='object-contain cursor-pointer w-full h-full'
                        src={headerLogo}
                    ></img>
                </div>
                <div
                    className={
                        'self-end ' +
                        (JSON.parse(headerNavs).length > 4
                            ? lgScreen
                                ? ' flex-1 flex-col flex '
                                : ' hidden '
                            : 'hidden lg:flex lg:flex-1 lg:flex-col')
                    }
                >
                    <div className='flex self-end py-2 items-center'>
                        <SelectComponent color={'#fff'} />
                        {/*<FriendsComponent />*/}
                        {refreshToken && profileIsPending ? (
                            <CircularProgress />
                        ) : refreshToken && profileData ? (
                            <MenuHeader
                                profileData={profileData}
                                isPending={profileIsPending}
                                handleClick={handleClick}
                                anchorEl={anchorEl}
                                handleClose={handleClose}
                                open={open}
                                useData={useData}
                            />
                        ) : (
                            <Button
                                className='text-base normal-case text-[#fff] border-[#fff] hover:bg-white/10'
                                size='small'
                                variant='outlined'
                                onClick={() => {
                                    registerBtnFun(language)
                                }}
                            >
                                {t('navigationBar.loginBtn')}
                            </Button>
                        )}
                    </div>
                    <div className='flex'>
                        <div className='hidden md:flex md:items-center md:gap-x-9 md:gap-y-4 md:flex-wrap'>
                            {JSON.parse(headerNavs).length > 0 &&
                                JSON.parse(headerNavs).map((item, i) => {
                                    return (
                                        <div
                                            key={i}
                                            onMouseEnter={() => {
                                                setIsFocus(true)
                                                setIndex(i)
                                            }}
                                            onClick={() => {
                                                setIsFocus(true)
                                                setIndex(i)
                                                item?.webLinkUrl && navigate(item.webLinkUrl)
                                            }}
                                            onMouseLeave={() => {}}
                                            className={
                                                'text-[#fff] text-base font-semiboldFamily cursor-pointer md:py-2 md:pb-2 md:border-b-[4px] md:border-transparent ' +
                                                (index === i &&
                                                    isFocus &&
                                                    item?.secondMenu?.length !== 0 &&
                                                    'md:border-b-white') +
                                                (!item?.secondMenu || item?.secondMenu?.length === 0
                                                    ? ' active:border-b-white hover:border-b-white'
                                                    : '')
                                            }
                                        >
                                            {filterName(item, language)}
                                        </div>
                                    )
                                })}
                        </div>
                        {/* <div className='flex justify-end flex-1 pl-2'>
                            <SearchComponent
                                size='middle'
                                fun={() => {
                                    console.log(3444)
                                    setIsOpenDrawer(true)
                                }}
                            />
                        </div> */}
                    </div>
                </div>
                <div
                    className={JSON.parse(headerNavs).length > 4 ? (lgScreen ? 'hidden' : 'block') : 'block lg:hidden'}
                >
                    {/* <SearchComponent size='middle' /> */}
                    <IconButton onClick={onOpen} aria-label='menu' size='middle'>
                        <MenuIcon sx={{ color: '#fff' }} fontSize='middle' />
                    </IconButton>
                </div>
            </Wrap>
            <Fade
                in={
                    isFocus &&
                    JSON.parse(headerNavs)?.length > 0 &&
                    JSON.parse(headerNavs)[index]?.secondMenu &&
                    JSON.parse(headerNavs)[index]?.secondMenu.length > 0
                }
            >
                <div
                    onWheel={() => setIsFocus(false)}
                    className='bg-gradient-to-t from-primaryRed-300 to-primaryRed-100 z-50 absolute w-full py-16 hidden lg:block'
                    onMouseLeave={() => {
                        setIsFocus(false)
                    }}
                >
                    <div className='bg-container'>
                        <HeaderCards setIsFocus={setIsFocus} abouts={JSON.parse(headerNavs)[index].secondMenu ?? []} />
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default Header
