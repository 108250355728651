import { TextField, Box, MenuItem, Typography } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import DoubleBtn from 'page/UserSettings/components/DoubleBtn'
import VerifyPhone from 'page/UserSettings/EditMyProfile/components/VerifyPhone'

import { useValidationI18n } from 'utils/validationI18n'
import { myProfile } from 'service/crmService'
import { loginReg } from 'service/authService'
import { useModalStore, useUserInfoAtom } from 'store/authAtom'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const Form = tw.form``
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

export default function UpdatePhone({ countryCodeList }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { validationSchema } = useValidationI18n()
    const { enqueueSnackbar } = useSnackbar()
    const { setModalComponent, onClose } = useModalStore()

    const profileInfo = useUserInfoAtom((state) => state.profileInfo)

    const {
        register,
        handleSubmit,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            countryCode: countryCodeList?.[0]?.code,
        },
        resolver: yupResolver(
            yup.object({
                countryCode: validationSchema.countryCode,
                mobileNo: validationSchema.mobileNo,
            }),
        ),
    })

    const sendOtpMutation = useMutation({
        mutationFn: loginReg.sendOtp,
        onSuccess: (v) => {
            // enqueueSnackbar(t('myProfile.verifyPhoneLast'), { variant: 'success' })
            setModalComponent(
                <VerifyPhone
                    countryCodeList={countryCodeList}
                    countryCode={getValues()?.countryCode}
                    mobileNo={getValues()?.mobileNo}
                />,
            )
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const checkProfileExistMutation = useMutation({
        mutationFn: myProfile.checkProfileExist,
        onSuccess: (v) => {
            const formData = getValues()
            sendOtpMutation.mutate({
                countryCode: formData?.countryCode,
                mobileNo: formData?.mobileNo,
                lang: profileInfo?.languagePreference,
                newCountryCode: '',
                newMobileNo: '',
                verifyType: 0,
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onSend = (formData) => {
        checkProfileExistMutation.mutate({
            countryCode: formData?.countryCode,
            mobileNo: formData?.mobileNo,
            lang: language,
        })
    }
    return (
        <Box>
            <Typography variant='h6' className='font-bold mb-8' gutterBottom>
                {t('myProfile.updatePhone')}
            </Typography>
            <Form onSubmit={handleSubmit(onSend)} autoComplete='off'>
                <Box className='flex space-x-3'>
                    <Controller
                        name='countryCode'
                        control={control}
                        shouldUnregister={true}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={
                                    <Typography className='font-bold text-black'>{t('myProfile.mobile')}</Typography>
                                }
                                variant='standard'
                                color='secondary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                }}
                                className='w-[150px] md:w-[200px]'
                            >
                                {countryCodeList.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        +{option.code}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />

                    <TextField
                        {...register('mobileNo', {
                            shouldUnregister: true,
                        })}
                        fullWidth
                        label=' '
                        variant='standard'
                        color='secondary'
                        placeholder={t('myProfile.phoneNo')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors?.mobileNo}
                        helperText={errors?.mobileNo?.message ?? null}
                    />
                </Box>
                <DoubleBtn
                    leftText={t('myProfile.updatePhoneBack')}
                    leftFun={onClose}
                    rightText={t('myProfile.update')}
                />
            </Form>
        </Box>
    )
}
