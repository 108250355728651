import React from 'react'
import Content from 'components/Content'

function TabAbout({ items }) {
    return (
        <div className='bg-container text-secondary-midnightBlue'>
            <div className='max-w-[750px] space-y-8 pt-8 md:space-y-[60px] md:pt-[60px]'>
                {items.description && <Content data={items.description} className='text-body1' />}
            </div>
        </div>
    )
}

export default TabAbout
