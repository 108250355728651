import React from 'react'

import Title from 'components/shopDine/Title'
import SearchInput from 'components/SearchInput'

function TitleSearchComponent({ name, searchVal, setSearchVal, onBlurFun, setIsSearch }) {
    return (
        <div className='w-full flex flex-col gap-4 md:flex-row md:justify-between md:items-center'>
            <Title title={name} />
            <SearchInput
                searchVal={searchVal}
                setIsSearch={setIsSearch}
                setSearchVal={setSearchVal}
                onBlurFun={onBlurFun}
            />
        </div>
    )
}

export default TitleSearchComponent
