import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import HeaderCard from 'components/headerComponents/card/HeaderCard'
import a from 'resources/images/aboutKTSP.jpg'
import { configDataStore } from 'store/authAtom'
const Title = tw.div`cursor-pointer active:opacity-50 text-3xl text-white pb-12`
function ConnectWithKTSP({ setIsFocus }) {
    const navigate = useNavigate()
    const { configDataValue } = configDataStore()
    const { t } = useTranslation()
    const datas = {
        title: t('connectWithKtsp.aboutKtsp'),
        link: '/connectWithKTSP/aboutKTSP',
        abouts: [],
        labels: [
            // { label: 'Our Story', link: '' },
            { label: t('connectWithKtsp.milestonesHeritage'), link: '/heritageMilestone' },
            // { label: 'Heritage', link: '/heritageMilestone?val=0' },
            // { label: t('connectWithKtsp.awards'), link: '' },
        ],
        imgSrc: a,
    }

    const titles1 =
        configDataValue && configDataValue?.ourPartnersSwitch
            ? [
                  // { label: t('connectWithKtsp.ktspMembership'), link: '' },
                  { label: t('connectWithKtsp.ourPartners'), link: '/ourPartner' },
                  { label: t('connectWithKtsp.newsroom'), link: '/newsRoom' },
              ]
            : [{ label: t('connectWithKtsp.newsroom'), link: '/newsRoom' }]
    const titles2 = [
        { label: t('connectWithKtsp.workWithUs'), link: '/connectWithKTSP/workWithUs' },
        { label: t('connectWithKtsp.contactUs'), link: '/contactUs' },
        // { label: t('connectWithKtsp.sustainability'), link: '' },
    ]
    return (
        <div className='grid grid-cols-3 gap-5'>
            <HeaderCard setIsFocus={setIsFocus} abouts={datas} />
            <div>
                {titles1.map((t, index) => {
                    return (
                        <Title
                            className='font-semiboldFamily'
                            onClick={() => {
                                t.link && navigate(t.link)
                                setIsFocus(false)
                            }}
                            key={index}
                        >
                            {t.label}
                        </Title>
                    )
                })}
            </div>
            <div>
                {titles2.map((t, index) => {
                    return (
                        <Title
                            className='font-semiboldFamily'
                            onClick={() => {
                                t.link && navigate(t.link)
                                setIsFocus(false)
                            }}
                            key={index}
                        >
                            {t.label}
                        </Title>
                    )
                })}
                {/* <div className='text-lg font-mediumcursor-pointer active:opacity-50 text-white'>
                    {t('connectWithKtsp.emAInfo')}
                    <OpenInNewIcon />
                </div> */}
            </div>
        </div>
    )
}

export default ConnectWithKTSP
