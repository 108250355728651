import React from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Wrap = tw.div`space-y-6 md:space-y-8`

export default function AmenitiesSeeAll({ amenitiesBindVenueRespDTO }) {
    const { t } = useTranslation()

    return (
        <Wrap className='text-secondary-midnightBlue'>
            <div className='text-h6 font-kt'>{t('venue.Popular')}</div>
            {amenitiesBindVenueRespDTO?.map((item, i) => (
                <div key={i} className='space-y-2'>
                    <Typography variant='h6' gutterBottom>
                        {item?.amenities?.name}
                    </Typography>
                    <div className='grid gap-y-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                        {item?.amenities?.['amenities'].map((item, index) => (
                            // <div key={index} className='text-sm font-kt font-mediumFamily'>
                            //     {item?.amenitiesName}
                            // </div>
                            <div className='flex px-5' key={index}>
                                <div className='icon-tickCarPark leading-6 font-bold mr-2.5' />
                                <div className='text-sm font-kt font-mediumFamily'>{item?.amenitiesName}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </Wrap>
    )
}
