import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import SwiperPromotionCards from 'components/detailComponents/SwiperPromotionCards'
function PromotionCards({ items, promotionRef, bgColor = 'bg-[rgb(233,236,243)]', title }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div ref={promotionRef} className={'w-full ' + bgColor}>
            <div className='py-[60px] bg-container'>
                <div className='flex justify-between items-center pb-4 md:pb-8'>
                    <div className='text-h3 text-secondary-midnightBlue font-semiboldFamily'>
                        {title ?? t('visitList.promotions')}
                    </div>
                    <div
                        className={'block ' + (items.length <= 3 && ' lg:hidden ') + (items.length <= 2 && ' hidden ')}
                    >
                        <IconButtonChange
                            slideIndex={slideIndex}
                            num={lgScreen ? 3 : 2}
                            length={items.length}
                            goToNextSlide={goToNextSlide}
                            goToPrevSlide={goToPrevSlide}
                            buttonColor={'#231448'}
                            borderColor={'border-secondary-midnightBlue'}
                        />
                    </div>
                </div>
                <SwiperPromotionCards
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    items={items}
                    setSwiper={setSwiper}
                />
            </div>
        </div>
    )
}

export default PromotionCards
