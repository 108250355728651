import React, { useEffect, useRef, useState } from 'react'
import { Tab, Box, Tabs } from '@mui/material'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import ViewMore from 'components/detailComponents/ViewMore'
import { createPortal } from 'react-dom'
import Content from 'components/Content'
const TableDetail = ({ abouts, titles }) => {
    const BorderRight = 'border-solid border-r-[1px] border-[#F9F9F9] text-center'
    return (
        <table className='w-4/5 border-solid border-[2px] border-primaryRed-lightRed'>
            <tr className='bg-primaryRed-lightRed'>
                <th className='w-[45%] text-base font-semiboldFamily py-2'>{titles[0]}</th>
                <th className='w-[10%]'></th>
                <th className='w-[45%] text-base font-regular'>{titles[1]}</th>
            </tr>
            {abouts.map((item, index) => {
                return (
                    <Tr key={index}>
                        <td className={'text-base font-regular  py-2 ' + BorderRight}>{item.left}</td>
                        <td className={BorderRight}>=</td>
                        <td className='text-base text-center font-regular'>{item.right}</td>
                    </Tr>
                )
            })}
        </table>
    )
}

const Tr = tw.tr`border-solid border-b-[1px] text-[#333333] border-[#F9F9F9]`
const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`
function AboutTabs({ items, recommendedClick, relatedClick }) {
    const { t } = useTranslation()
    const [isExpand, setIsExpand] = useState(false)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [isVisible, setIsVisible] = useState(false)
    const [refWidth, setRefWidth] = useState(0)
    const ref = useRef(null)

    const tabsTitles =
        items.eventRespDTOList.length > 0 && items.itemRespDTOList.length > 0
            ? [t('eventPage.about'), t('visitList.promotions'), t('eventPage.uMayAlsoLike')]
            : items.eventRespDTOList.length > 0 && items.itemRespDTOList.length === 0
            ? [t('eventPage.about'), t('visitList.promotions')]
            : items.eventRespDTOList.length === 0 && items.itemRespDTOList.length > 0
            ? [t('eventPage.about'), t('eventPage.uMayAlsoLike')]
            : [t('eventPage.about')]
    return (
        <>
            <Tabs
                textColor='secondary'
                indicatorColor='secondary'
                value={value}
                className='sticky top-0 left-0 z-40 border-solid border-b-[1px] border-secondary-darkGery'
                onChange={handleChange}
                aria-label='basic tabs example'
            >
                {tabsTitles.map((tabTitle, index) => {
                    return (
                        <Tab
                            key={index}
                            className={index === 0 ? 'pl-0' : ''}
                            label={<Box sx={{ fontSize: 16, fontWeight: 600, textTransform: 'none' }}>{tabTitle}</Box>}
                            onClick={() => {
                                tabTitle === t('visitList.promotions') && relatedClick()
                                tabTitle === t('eventPage.uMayAlsoLike') && recommendedClick()
                            }}
                        />
                    )
                })}
            </Tabs>

            <div className='py-8 text-secondary-midnightBlue'>
                <div className='pb-4 text-2xl font-semiboldFamily'>{t('eventPage.about')}</div>
                {isExpand && (
                    <>
                        <Content data={items.description} />
                    </>
                )}
                <ViewMore setIsExpand={setIsExpand} isExpand={isExpand} />
                <div className='flex flex-wrap gap-2 pt-4'>
                    {items.tagList.length > 0 &&
                        items.tagList.map((tag, index) => {
                            console.log('tag', tag)
                            return (
                                <Tag className='font-medium' key={index}>
                                    {tag.name}
                                </Tag>
                            )
                        })}
                </div>
            </div>
        </>
    )
}

export default AboutTabs
