import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

import { filterName } from 'utils/filter'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
const Title = tw.div`w-[116px] text-sm md:text-base`
const VenueComponent = ({ venue }) => {
    const [isExpand, setIsExpand] = useState(false)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const maoUrl = (longitude, latitude) => {
        return language === 'zh_CN'
            ? `https://uri.amap.com/marker?position=${longitude},${latitude}`
            : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    }

    return (
        <>
            <div className='flex gap-[10px]'>
                <Title className='text-body2'>{t('venue.Venue')}</Title>
                <div className='flex-1 text-body1 overflow-hidden break-words'>
                    {filterName({ nameEn: venue.enName, nameCn: venue.scName, nameZh: venue.tcName }, language) && (
                        <div>
                            {filterName({ nameEn: venue.enName, nameCn: venue.scName, nameZh: venue.tcName }, language)}
                        </div>
                    )}
                    {(venue.venueImage || venue.latitude) && (
                        <div className='flex items-center cursor-pointer gap-4' onClick={() => setIsExpand(!isExpand)}>
                            <div className='text-sm font-semiboldFamily underline text-[#0000EE]'>
                                {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                            </div>
                            <div className=' text-secondary-midnightBlue'>
                                {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {isExpand && (
                <>
                    {venue?.venueImage && <ImageMap venueImage={venue.venueImage} />}
                    {venue.latitude && venue.longitude && (
                        <MapBtn venueLocationUrl={maoUrl(venue.longitude, venue.latitude)} />
                    )}
                </>
            )}
        </>
    )
}

export default VenueComponent
