import SwiperPromotionCards from 'components/detailComponents/SwiperPromotionCards'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwiperProductCard from './SwiperProductCard'
import { useMediaQuery } from '@mui/material'

export default function RecommendProducts({ items, bgColor = 'bg-[rgb(233,236,243)]', title }) {
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const isMiddleMedia = useMediaQuery('(min-width:650px)')
    const isPhone = useMediaQuery('(min-width:500px)')

    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }

    return (
        <div className='bg-container'>
            <div className='max-w-[750px] mb-16 mt-8 md:mt-12'>
                <div className='text-secondary-midnightBlue'>
                    <div className='flex justify-between items-center pb-4 md:pb-6'>
                        <div className='text-h4'>
                            {title} ({items.length})
                        </div>
                        <div
                            className={
                                'block ' +
                                (items.length <= 4 && isMiddleMedia && ' hidden ') +
                                (items.length <= 3 && ' sm:hidden ') +
                                (items.length <= 2 && ' hidden ')
                            }
                        >
                            <IconButtonChange
                                slideIndex={slideIndex}
                                num={isMiddleMedia ? 4 : isPhone ? 3 : 2}
                                length={items.length}
                                goToNextSlide={goToNextSlide}
                                goToPrevSlide={goToPrevSlide}
                                buttonColor={'#231448'}
                                borderColor={'border-secondary-midnightBlue'}
                            />
                        </div>
                    </div>
                    <SwiperProductCard items={items} setSlideIndex={setSlideIndex} setSwiper={setSwiper} />
                </div>
            </div>
        </div>
    )
}
