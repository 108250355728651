import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import SwiperPromotionCards from 'components/detailComponents/SwiperPromotionCards'
function RecommendedCards({ items = [], youMayLikeRef }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)

    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div ref={youMayLikeRef} className='bg-container my-16'>
            <div className='flex justify-between items-center pb-4 md:pb-8'>
                <div className='text-h3 text-secondary-midnightBlue font-semiboldFamily'>
                    {t('eventPage.uMayAlsoLike')}
                </div>
                <div className={'block ' + (items.length <= 3 && ' lg:hidden ') + (items.length <= 2 && ' hidden ')}>
                    <IconButtonChange
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                    />
                </div>
            </div>
            <SwiperPromotionCards items={items} setSwiper={setSwiper} />
        </div>
    )
}

export default RecommendedCards
