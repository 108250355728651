import React from 'react'

export default function Banner({ banner, contactUs = '' }) {
    return (
        <div className='w-full lg:relative'>
            <div className='aspect-[18/5] w-full max-h-[320px] relative'>
                <img alt='' src={banner.image} className='left-0 top-0 w-full h-full object-cover' />
                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-25 z-10' />
            </div>
            <div className='bg-container text-secondary-midnightBlue pt-8 md:pt-10 lg:pt-0 lg:text-white lg:z-10 lg:absolute lg:top-1/2 lg:-translate-y-1/2'>
                <div className='text-h1 pb-3 pl-2'>
                    {banner.title}
                </div>
                {banner.about && (
                    <div
                        className={
                            'text-body2 pl-2 pb-3 border-solid border-b-[1px] border-[#B1B1B1] lg:line-clamp-4 lg:pb-0 lg:border-b-0 lg:max-w-[463px]' +
                            contactUs
                        }
                    >
                        {banner.about}
                    </div>
                )}
            </div>
        </div>
    )
}
