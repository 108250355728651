import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import PaginationButton from 'components/PaginationButton'
import { programmesService } from 'service/vbsService'
import BookingCard from 'page/UserSettings/MyRecord/components/BookingCard'
import NoDatas from 'page/UserSettings/MyRecord/components/NoDatas'
import Loading from 'components/Loading'

function MyProgrammes({ value, name }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [hasMoreData, setHasMoreData] = useState(true)
    const [cards, setCards] = useState([])
    const getEventsList = useMutation({
        mutationFn: programmesService.getMyProgramme,
        onSuccess: (res) => {
            setTotal(res.totalCount)
            setHasMoreData(res.totalCount > 10)
            setCards(res.vbsProgrammeList)
            setPage(1)
            // res.length > 0 && setTotal(res[0].count)
        },
        onError: (e) => {
            console.log(e)
            setHasMoreData(false)
        },
    })
    const getEventsListPagination = useMutation({
        mutationFn: programmesService.getMyProgramme,
        onSuccess: (res) => {
            setCards([...cards, ...res.vbsProgrammeList])
            const arr = [...cards, ...res.vbsProgrammeList]
            arr.length < res.totalCount ? setHasMoreData(true) : setHasMoreData(false)
        },
        onError: (e) => {
            console.log(e)
            setHasMoreData(false)
        },
    })
    const getEventListMethod = (pageNow) => {
        if (getEventsListPagination.isPending || !hasMoreData) {
            return
        }
        getEventsListPagination.mutate({
            pageNum: pageNow + 1,
            pageSize: 10,
            lang: language,
            period: name.val.toUpperCase(),
        })
    }
    useEffect(() => {
        setCards([])
        getEventsList.mutate({ pageNum: 1, pageSize: 10, lang: language, period: name.val.toUpperCase() })
    }, [value, language])
    return (
        <>
            {cards && cards.length > 0 ? (
                <div className='mt-10 space-y-6 mb-6 md:mb-[60px] md:space-y-[60px]'>
                    {cards.map((card, index) => {
                        return (
                            <BookingCard
                                detailUrl={`myProfile/personal?num=11&applId=${card?.id ?? ''}&lang=${language}`}
                                card={{ ...card, facilityName: card.programmeName }}
                                isBooking={false}
                                key={index}
                            />
                        )
                    })}
                    {getEventsListPagination.isPending && <Loading />}
                    {hasMoreData && (
                        <PaginationButton
                            isDisabled={!hasMoreData}
                            fun={() => {
                                getEventListMethod(page)
                            }}
                            nowCardsLength={cards.length}
                            allCardsLength={total}
                        />
                    )}
                </div>
            ) : getEventsList.isPending ? (
                <Loading />
            ) : (
                <NoDatas
                    text={t('myProfile.noProgrammesTickets')}
                    btnText={t('myProfile.exporeProgrammesEvent')}
                    path='/programmes'
                />
            )}
        </>
    )
}

export default MyProgrammes
