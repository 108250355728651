import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { Button, Grow } from '@mui/material'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { find, propEq, insert, indexOf } from 'ramda'
import { useSnackbar } from 'notistack'

import { useSimpleBackdropStore } from 'store/authAtom'
import { setDayjsLocale } from 'utils/util'
import { myProfile } from 'service/crmService'
import { loginReg } from 'service/authService'
import { user } from 'service/userService'
import Loading from 'components/Loading'
import AccountBoxs from 'page/UserSettings/components/AccountBoxs'
import MyInterest from 'page/UserSettings/components/MyInterest'
import { languageList } from 'resources/i18n'
import { monthList, IASMError, getNameByCode } from 'utils/filter'
import { Box, Wrap } from 'page/UserSettings/components/CommonBox'

const Label = ({ title, about }) => {
    return (
        <div className='pb-[26px]'>
            <div className='text-base pb-2 font-semiboldFamily'>{title}</div>
            <div className='text-base font-medium'>{about ? about : '-'}</div>
        </div>
    )
}

const findLanguageLabel = (list, langValue) => {
    const language = list.find((lang) => lang.value === langValue)
    return language ? language.label : null
}

export default function Main() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { emailUuid, sysLang, emailId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { i18n } = useTranslation()

    const [labels, setLabels] = useState([])

    const {
        data: profileData,
        isPending: profileIsPending,
        refetch,
    } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
    })

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', language],
        queryFn: () =>
            user.getCountryCodeList({
                lang: language,
            }),
    })
    const { data: getTitle } = useQuery({
        queryKey: ['getTitle', language],
        queryFn: () =>
            loginReg.getTitle({
                lang: language,
            }),
        placeholderData: [],
    })

    const updateEmailAndPhoneMutation = useMutation({
        mutationFn: myProfile.updateEmailAndPhone,
        onSuccess: () => {
            onCloseSimpleBackdrop()
            navigate('/userSettings/myProfile', {
                replace: true,
            })
            refetch()
            enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
        },
        onError: (e) => {
            onCloseSimpleBackdrop()
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const emailVerifiyMutation = useMutation({
        mutationFn: loginReg.emailVerifiy,
        onSuccess: (v) => {
            if (v.emailStatus === 'VERIFY') {
                onOpenSimpleBackdrop()
                updateEmailAndPhoneMutation.mutate({
                    emailId: v.emailId,
                    countryCode: '',
                    mobileNo: '',
                })
            } else {
                enqueueSnackbar(t('ErrorCode.92002'), { variant: 'warning' })
            }
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const findResidentialAreaValue = (iosCode, code) => {
        const area = find(propEq(iosCode, 'isoCode'))(countryCodeList?.countryCodeList)['area']

        return find(propEq(code, 'code'))?.(area)?.['name'] || ''
    }

    useEffect(() => {
        if (!!profileData && countryCodeList?.countryCodeList) {
            const DateOfBirth = [
                profileData.dayOfBirth,
                t(findLanguageLabel(monthList, profileData.monthOfBirth)),
                profileData.yearOfBirth,
            ]
            let labelsList = [
                { title: t('myProfile.emailAddress'), about: profileData.emailId },
                { title: t('myProfile.phoneNo'), about: `+${profileData.countryCode} ${profileData.mobileNo}` },
                { title: t('contactUs.title'), about: getNameByCode(profileData.title, getTitle) },
                { title: t('SignUpPage.LastDisplay'), about: profileData.lastname },
                { title: t('SignUpPage.FirstNameDisplay'), about: profileData.firstname },
                { title: t('SignUpPage.ChineseNameDisplay'), about: profileData.chineseName },
                { title: t('SignUpPage.NicknameDisplay'), about: profileData.nickname },
                {
                    title: t('SignUpPage.DateOfBirthDisplay'),
                    about: DateOfBirth.filter(Boolean).join(' / '),
                },
                {
                    title: t('SignUpPage.ResidentialAreaDisplay'),
                    about: find(propEq(profileData.residentialArea, 'isoCode'))(countryCodeList?.countryCodeList)[
                        'name'
                    ],
                },
                {
                    title: t('myProfile.languagePreference'),
                    about: findLanguageLabel(languageList, profileData.languagePreference),
                },
            ]

            if (!!profileData.province) {
                labelsList = insert(
                    9,
                    {
                        title: t('SignUpPage.ProvinceDisplay'),
                        about: findResidentialAreaValue(profileData.residentialArea, profileData.province),
                    },
                    labelsList,
                )
            }

            if (!!profileData.district) {
                labelsList = insert(
                    9,
                    {
                        title: t('SignUpPage.DistrictDisplay'),
                        about: findResidentialAreaValue(profileData.residentialArea, profileData.district),
                    },
                    labelsList,
                )
            }

            setLabels(labelsList)
        }
    }, [profileData, countryCodeList, language, getTitle])

    useEffect(() => {
        if (!!emailUuid && !!profileData && !!emailId) {
            emailVerifiyMutation.mutate({
                emailUuid,
                emailId: decodeURIComponent(emailId),
                lang: profileData.languagePreference,
            })
            i18n.changeLanguage(sysLang)
            setDayjsLocale(sysLang)
        }
    }, [profileData, emailUuid, sysLang])

    useEffect(() => {
        if (indexOf(searchParams.get('error_code'), IASMError) !== -1) {
            setSearchParams(undefined)
            const IASMProfileData = searchParams.get('error_code')
            if (IASMProfileData === 'D40000' || IASMProfileData === 'D40001' || IASMProfileData === 'D50001') {
                enqueueSnackbar(t('myProfile.canceIamsmart'), { variant: 'error' })
            }
            if (IASMProfileData === 'D40002' || IASMProfileData === 'D50002') {
                enqueueSnackbar(t('myProfile.rejectIamsmart'), { variant: 'error' })
            }
            if (IASMProfileData === 'D40003' || IASMProfileData === 'D50003' || IASMProfileData === 'D40004') {
                enqueueSnackbar(t('myProfile.expiredIamsmart'), { variant: 'error' })
            }
            // enqueueSnackbar(t(`ErrorCode.${searchParams.get('error_code')}`), { variant: 'error' })
        }
    }, [searchParams.get('error_code')])

    return (
        <Wrap>
            <div className='text-4xl font-semiboldFamily uppercase'>{t('myProfile.myProfile')}</div>
            {profileIsPending && <Loading />}
            <Grow
                in={!profileIsPending}
                style={{ transformOrigin: '0 0 0' }}
                {...(!profileIsPending ? { timeout: 1000 } : {})}
            >
                <Box>
                    {labels.length > 0 &&
                        labels.map((item, index) => {
                            return <Label key={index} title={item.title} about={item.about} />
                        })}
                    <Button
                        onClick={() => navigate('/userSettings/editMyProfile')}
                        className='bg-white mb-8 normal-case border-primaryRed-ktRed text-primaryRed-ktRed text-base px-3'
                        variant='outlined'
                        size='small'
                    >
                        {t('myProfile.editProfile')}
                    </Button>
                    <MyInterest profileData={profileData} />
                </Box>
            </Grow>
            <AccountBoxs />
        </Wrap>
    )
}
