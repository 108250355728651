import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TopLabelImgCard from 'components/headerComponents/card/TopLabelImgCard'
import Label from 'components/headerComponents/card/Label'
import { filterName } from 'utils/filter'
function HeaderCard({ abouts, setIsFocus, index }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
    } = useTranslation()
    if (!abouts.threeMenu && abouts.imageUrl) {
        return <TopLabelImgCard setIsFocus={setIsFocus} key={index} item={abouts} />
    }
    return (
        <div className='text-white flex flex-col justify-between w-full'>
            <div>
                <Label
                    index={index}
                    setIsFocus={setIsFocus}
                    label={filterName(abouts, language)}
                    link={abouts.webLinkUrl}
                    isThreeMenu={abouts?.threeMenu}
                />
                {abouts?.threeMenu?.length > 0 && (
                    <div className='space-y-[18px]'>
                        {abouts.threeMenu.map((about, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        about.webLinkUrl && navigate(about.webLinkUrl)
                                        setIsFocus(false)
                                    }}
                                    key={index}
                                    className='text-lg cursor-pointer active:opacity-50 leading-[18px] font-semiboldFamily hover:underline'
                                >
                                    {filterName(about, language)}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            {abouts.imageUrl && (
                <div
                    className={
                        'w-full h-[120px] group overflow-hidden hover:rounded-tr-[40px] transition-all duration-300 ' +
                        (abouts.threeMenu && abouts.threeMenu.length === 0 ? ' mt-10':' mt-5')
                    }
                >
                    <img
                        alt=''
                        src={abouts.imageUrl}
                        className='w-full h-full cursor-pointer active:opacity-50 object-cover group-hover:scale-110 transition-all duration-300'
                    />
                </div>
            )}
        </div>
    )
}

export default HeaderCard
