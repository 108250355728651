import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

function Tag({ text, fun, isLoading }) {
    return (
        <div className='flex items-center gap-2 rounded-[20px] bg-secondary-grey px-3 py-[5px] text-secondary-darkGery'>
            <div>{text}</div>
            <div
                onClick={() => {
                    !isLoading && fun()
                }}
            >
                <div className='icon-close cursor-pointer text-[10px]' />
            </div>
        </div>
    )
}

export default Tag
