import React from 'react'
import { useTranslation } from 'react-i18next'

import ExpandConponent from 'page/ConnectWithKTSP/FAQ/conponents/ExpandConponent'

export default function CommonLabels({ items, title, isAll = false }) {
    const { t } = useTranslation()
    if (!items || items.length === 0) {
        return null
    }
    if (isAll) {
        return (
            <div className='pt-10 text-secondary-midnightBlue'>
                <div className='font-semiboldFamily text-3xl pb-4 md:pb-5 md:text-4xl'>{title}</div>
                {items.map((data, index) => {
                    return <ExpandConponent key={index} itemOut={data} />
                })}
            </div>
        )
    }
    return (
        <div className='pt-10 text-secondary-midnightBlue'>
            <div className='font-semiboldFamily text-3xl pb-4 md:pb-5 md:text-4xl'>{title}</div>
            {items.map((item) => {
                return (
                    <div key={item}>
                        {item.faqList.map((data) => {
                            return <ExpandConponent key={data} itemOut={data} />
                        })}
                    </div>
                )
            })}
        </div>
    )
}
