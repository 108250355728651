import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import { useState, useRef, useEffect } from 'react'

export default function DescriptionComponent({ description = '', isNeedCk = true }) {
    const [isExpand, setIsExpand] = useState(false)
    const [isMoreThan, setIsMoreThan] = useState(false)
    // const [height, setHeight] = useState(false)
    const descriptionRef = useRef(null)

    //等ba回复确定是否需要将amenitiesList放在show more中
    const checkDescriptionHeight = () => {
        if (descriptionRef.current) {
            descriptionRef.current.style.display = 'block'
            const descriptionHeight = descriptionRef.current.offsetHeight
            const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10)
            const maxLines = 4
            setIsMoreThan(descriptionHeight > lineHeight * maxLines)
            // setHeight(lineHeight * maxLines)
            descriptionRef.current.style.display = 'none'
        }
    }
    useEffect(() => {
        checkDescriptionHeight()
        window.addEventListener('resize', checkDescriptionHeight)
        return () => {
            window.removeEventListener('resize', checkDescriptionHeight)
        }
    }, [description])

    return (
        <>
            <Content
                ref={descriptionRef}
                data={description}
                className={'text-base  font-barlow font-medium max-w-[750px]'}
            />
            <Content
                data={description}
                isNeedCk={isNeedCk}
                className={
                    'text-base  font-barlow font-medium max-w-[750px]' +
                    (isExpand ? ' ' : ` line-clamp-4 h-[100px] overflow-hidden`)
                }
            />
            {isMoreThan && (
                <div className='pt-4 md:pt-6'>
                    <ViewMore setIsExpand={setIsExpand} isExpand={isExpand} />
                </div>
            )}
        </>
    )
}
