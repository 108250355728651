import React from 'react'
import { Button, Box } from '@mui/material'

const style = {
    textTransform: 'none',
    fontSize: 18,
    lineHeight: '32px',
    fontWeight: 600,
    borderRadius: 8,
    paddingY: '8px',
    width: '45%',
    height: 'fit-content',
}
function DoubleBtn({ leftText, rightText, leftFun, rightFun, rightDisable }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '32px' }}>
            <Button
                onClick={leftFun && leftFun}
                sx={{ ...style}}
                variant='secondary'
                size='small'
            >
                {leftText}
            </Button>
            <Button
                onClick={rightFun && rightFun}
                type='submit'
                disabled={rightDisable}
                sx={{
                    ...style,
                }}
                variant={rightDisable ? 'contained' : 'primaryRed'}
                size='small'
            >
                {rightText}
            </Button>
        </Box>
    )
}

export default DoubleBtn
