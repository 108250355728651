import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Error() {
    const navigate = useNavigate();
    
    useEffect(() => {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          window.location.href = 'https://apps.apple.com/app/id6502622059'
        // window.location = ('xxx://');//schema链接或者universal link
        // window.setTimeout(() => { //如果没有安装app,便会执行setTimeout跳转下载页
        //   window.location.href = "xxx"; //ios下载地址3
        // }, 3000);
        //android
      } else if (navigator.userAgent.match(/android/i)) {
        try {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.ktsp.app'
          // window.location.href = ('app://xxx'); //schema链接或者universal link
          // window.setTimeout(() => {
          //   window.location = "xxx"; //android下载地址
          // }, 3000);
        } catch (e) {
        }
      } else {
        navigate('/');
      }
    }, [])

    return null
}
