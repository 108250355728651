import React from 'react'
function ConnectCard({ isReverse, image, title, about }) {
    return (
        <div
            className={
                'w-full my-8 md:h-[392px] md:flex shadow-card' + (isReverse ? ' md:flex-row-reverse' : '')
            }
        >
            <div className='flex-1 w-full h-[280px] md:h-auto md:w-3/5'>
                <img alt='' src={image} className='w-full h-full object-cover' />
            </div>
            <div className='flex flex-col justify-center p-[40px] text-secondary-midnightBlue w-full md:w-[500px]'>
                <div className='font-semiboldFamily pb-8 text-xl md:text-2xl lg:text-4xl '>{title}</div>
                <div className='font-medium text-sm md:text-base textarea-content'>{about}</div>
            </div>
        </div>
    )
}

export default ConnectCard
