import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { eventServices } from 'service/eventService'
import Banner from 'components/shopDine/Banner'
import bannerTop from 'resources/images/eventsBanner.png'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import AllProducts from 'page/EventsTickets/components/AllProducts'
function EventsTickets() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const banner = {
        image: bannerTop,
        title: t('eventPage.title'),
        about: t('eventPage.bannerAbout'),
    }
    const { data: bannerSwiperImgs } = useQuery({
        queryKey: ['getEventBanner', language],
        queryFn: () => eventServices.getEventBanner({ lang: language, size: 5 }),
        onError: (e) => {
            console.log(e)
        },
    })
    return (
        <div className='bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                {bannerSwiperImgs && bannerSwiperImgs.length > 0 && (
                    <BannerSwiper bannerSwiperImgs={bannerSwiperImgs} type='event' />
                )}
            </section>
            <section>
                <AllProducts
                    firstTypeName={t('eventPage.type')}
                    secondTypeName={t('eventPage.venue')}
                    name={t('eventPage.allEvent')}
                    isFilter={true}
                />
            </section>
        </div>
    )
}

export default EventsTickets
