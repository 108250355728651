import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import { Button, IconButton } from '@mui/material'
import { pipe, __, modulo, equals } from 'ramda'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { useTranslation } from 'react-i18next'
import { useWindowScroll } from 'react-use'

import EventCard from 'page/ConnectWithKTSP/WorkWithUsDetails/components/EventCard'
import NavigationPoint from 'components/NavigationPoint'

const Box = tw.div`bg-white mt-5 p-5 space-y-4 md:space-y-8 md:py-5 md:px-[30px] lg:px-10 `
const Wrap = tw.div`text-secondary-midnightBlue px-5 pt-[30px] pb-[60px] md:px-[30px] lg:px-[60px]`

function EventManagement({ onOpen, contenrDatas, keyLabel }) {
    const isEven = pipe(modulo(__, 2), equals(0))
    const { t } = useTranslation()
    const [menuIsShow, setMenuIsShow] = useState(false)
    const { y } = useWindowScroll()
    useEffect(() => {
        if (y > 200) {
            setMenuIsShow(true)
        } else {
            setMenuIsShow(false)
        }
    }, [y])

    return (
        <Wrap>
            <div className='flex justify-between items-center'>
                <NavigationPoint
                    firstNav={t('connectWithKtsp.workWithUs')}
                    lastNav={keyLabel.dataType === 1 ? t('workWithUs.teamIntroduction') : t('workWithUs.jobType')}
                    firstNavLink='/connectWithKTSP/workWithUs'
                />
                <IconButton
                    className={
                        (menuIsShow
                            ? 'fixed right-5 z-20 w-8 h-8 flex justify-center cursor-pointer items-center bg-black rounded-[5px] '
                            : '') + 'block lg:hidden'
                    }
                    onClick={onOpen}
                    aria-label='menu'
                    size='middle'
                >
                    <MenuOpenIcon
                        className={'text-secondary-midnightBlue ' + (menuIsShow && ' text-white')}
                        fontSize='middle'
                    />
                </IconButton>
            </div>
            <div className='pt-3 text-h3'>{keyLabel.title}</div>
            <Box className='pb-4'>
                {contenrDatas?.withKtspContentDTOS.length > 0 &&
                    contenrDatas.withKtspContentDTOS.map((card, index) => {
                        return <EventCard key={index} isReverse={!isEven(index)} card={card} />
                    })}
            </Box>
            <Box>
                <div className='flex justify-between gap-4 flex-col md:items-center md:flex-row'>
                    <div className='text-secondary-midnightBlue space-y-2 flex-1'>
                        <div className='text-h6'>{t('workWithUs.jobVacancies')}</div>
                        <div className='text-body1 font-medium'>{t('workWithUs.jobVacanciesAbout')}</div>
                    </div>
                    <Button
                        onClick={() => contenrDatas.webUrl && window.open(contenrDatas.webUrl)}
                        className='text-sm px-4 py-1 h-9 w-fit'
                        variant='primaryRed'
                        size='large'
                    >
                        {t('workWithUs.exploreCareers')}
                    </Button>
                </div>
            </Box>
        </Wrap>
    )
}

export default EventManagement
