import React, { useState } from 'react'
import tw from 'twin.macro'
import EastIcon from '@mui/icons-material/East'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import PrecinctHighlight from 'resources/images/precinctHighlights.png'

import { useDisclosure } from 'utils/useDisclosure'
import Content from 'components/Content'
import { Modal } from '@mui/material'

const Label = tw.div`py-6 md:py-12 w-full md:w-[261px] flex gap-2 justify-between items-center cursor-pointer active:opacity-50`

export default function MoreThanSports({ getMoreSports }) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { t } = useTranslation()
    const [currentItem, setCurrentItem] = useState({})

    const handleClick = (item) => {
        setCurrentItem(item)
        onOpen()
    }

    return (
        <div className='relative flex flex-col text-secondary-midnightBlue  md:bg-no-repeat md:bg-[length:100%_100%]'>
            {isOpen && (
                <Modal
                    sx={{
                        marginTop: { xs: '81.45px', md: '107.9px' },
                        // marginRight: { xs: '0', lg: '15px' },
                        ':focus-visible': {
                            outline: 'none',
                        },
                    }}
                    hideBackdrop
                    open={isOpen}
                    onClose={onClose}
                >
                    <div className='absolute flex justify-end top-0 bottom-0 right-0 z-30 left-0 xl:left-[-150px] focus-visible:outline-none'>
                        <div className='flex-1 bg-[#222222] opacity-50 relative' onClick={onClose}></div>
                        <div
                            className='h-full bg-website-gradation opacity-95 shadow-popup overflow-auto z-30 w-full md:w-1/2 focus-visible:outline-none'
                            style={{
                                scrollbarWidth: 'none',
                            }}
                        >
                            <div className=' p-5 md:p-10 lg:p-[120px] focus-visible:outline-none'>
                                <div className='w-full flex justify-end'>
                                    <CloseIcon onClick={onClose} className='cursor-pointer text-white' />
                                </div>
                                <div className='text-white h-full'>
                                    <div className='text-4xl font-semiboldFamily pb-4 uppercase break-words'>
                                        {currentItem?.webTitle}
                                    </div>
                                    <Content className='text-base pb-8 text-white' data={currentItem?.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <div className='bg-container h-full z-20 relative'>
                <div className='pt-20 md:pt-[100px] lg:pt-[140px] xl:pt-[181px] w-full lg:w-2/5 h-full xl:flex xl:flex-col xl:min-h-[700px] xl:w-[557px] z-10'>
                    <div className='pb-2 text-h3'>{t('aboutUs.moreThanSport')}</div>
                    {/* need to really copy right */}
                    <div className='md:mb-[60px] text-body1'>{t('aboutUs.moreThanSportAbout')}</div>
                    <div className='w-full flex-1 relative pb-9 divide-y divide-secondary-grey'>
                        {getMoreSports?.map((item, index) => {
                            return (
                                <Label className='text-h4' onClick={() => handleClick(item)} key={index}>
                                    <div>{item.webTitle}</div>
                                    <EastIcon />
                                </Label>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className='pt-2 md:pt-[100px] lg:pt-[140px] xl:pt-[181px] w-full md:w-2/3 max-w-[980px] md:absolute bottom-0 right-0'>
                <img className='w-full h-full object-cover aspect-[98/60]' src={PrecinctHighlight} alt='' />
                <div className='hidden md:block w-1/4 h-full absolute bottom-0 left-0 -translate-x-1 bg-gradient-to-r from-white  to-transparent' />
            </div>
        </div>
    )
}
