import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import DescriptionComponent from 'components/detailComponents/DescriptionComponent'
const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`

export const About = ({ items }) => {
    return (
        <div className='bg-container py-8 text-secondary-midnightBlue'>
            <div className='max-w-[750px]'>
                {/* <div className='pb-4 text-h3 font-semiboldFamily'>{t('eventPage.about')}</div> */}
                <DescriptionComponent description={items.description} />
                <div className='flex flex-wrap gap-2 pt-4'>
                    {items.tagList.length > 0 &&
                        items.tagList.map((tag, index) => {
                            return (
                                <Tag className='font-medium' key={index}>
                                    {tag.name}
                                </Tag>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
