import React, { useMemo } from 'react'
import tw from 'twin.macro'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { footer } from 'service/footerService'
import downloadPhone from 'resources/images/downloadPhone.png'
import downloadPhoneSc from 'resources/images/downloadPhoneSc.png'
import downloadPhoneTc from 'resources/images/downloadPhoneTc.png'
import ios from 'resources/images/iosBlack.png'
import aos from 'resources/images/aosBlack.png'

import { BookingsIcon, ProgrammesIcon, LoyaltyIcon, TicketIcon } from 'components/Icons'

const MainTitle = tw.div`text-[28px] pb-4 leading-[28px]`
function AppIntroduction() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const phoneImage = useMemo(() => {
        return language === 'en_US' ? downloadPhone : language === 'zh_HK' ? downloadPhoneTc : downloadPhoneSc
    }, [language])
    const iconsList = [
        { icon: <TicketIcon className='text-secondary-midnightBlue size-16' />, about: t('landing.ticketing') },
        {
            icon: <ProgrammesIcon className='text-secondary-midnightBlue size-16' />,
            about: t('landing.programmes'),
        },
        {
            icon: <BookingsIcon className='text-secondary-midnightBlue size-16' />,
            about: t('landing.facilitiesBooking'),
        },
        { icon: <LoyaltyIcon className='text-secondary-midnightBlue size-16' />, about: t('landing.loyalty') },
    ]
    const imgs = [
        { src: ios, i: 0 },
        { src: aos, i: 1 },
    ]
    const { data: menu } = useQuery({
        queryKey: ['getFooter'],
        queryFn: () =>
            footer.getFooter({
                lang: 'en_US',
                platForm: 'Web',
            }),
        select: (res) => res?.menu,
    })
    return (
        <div className='bg-container'>
            <div className='flex gap-6 pb-12 my-10 flex-col lg:flex-row'>
                <div className='h-[330px] w-[320px] mx-auto lg:mx-0 lg:w-[420px] lg:h-[430px] xl:w-[525px]  xl:h-[536px]'>
                    <img alt='' src={phoneImage} className='w-full h-full object-contain' />
                </div>
                <div className='flex-1 px-5 text-secondary-midnightBlue pt-8 mx-auto lg:mx-0 lg:pt-16 '>
                    <MainTitle className='font-semiboldFamily'>{t('visitorTip.downAppTitle')}</MainTitle>
                    <div className='pt-4 text-base font-medium'>{t('visitorTip.downAppAbout')}</div>
                    <div className='text-xl font-semiboldFamily pb-6 pt-8 lg:pt-12'>{t('visitorTip.appFeatures')}</div>
                    <div className='max-w-[504px] grid grid-cols-4 gap-4 pb-8 lg:pb-12 lg:gap-9'>
                        {iconsList.map((icon, index) => {
                            return (
                                <div className='flex flex-col items-center gap-2 w-full' key={index}>
                                    {icon.icon}
                                    <div className='text-center text-sm lg:text-lg '>{icon.about}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='flex gap-4'>
                        {imgs.map((img, index) => {
                            return (
                                <img
                                    onClick={() => window.open(menu.downloadApp[img.i].link)}
                                    key={index}
                                    alt=''
                                    src={img.src}
                                    className='object-contain cursor-pointer active:opacity-50 w-[142px] h-[42px] lg:w-[160px] lg:h-[46px]  '
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <div className='flex justify-center'>
                <div className='w-[83%]'>
                    <div className='text-3xl font-semiboldFamily text-center pb-4'>
                        {t('visitorTip.introductionVideo')}
                    </div>
                    <div className='text-base font-medium text-center pb-4'>{t('visitorTip.videoAbout')}</div>
                    <ReactPlayer
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 },
                            },
                            facebook: {
                                appId: '12345',
                            },
                        }}
                        controls={true}
                        width='100%'
                        height='533px'
                        url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                    />
                </div>
            </div> */}
        </div>
    )
}

export default AppIntroduction
