import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
const Title = tw.div`text-[42px] leading-[50px] text-center pb-8`

export default function RowList({ items, targetRef, id }) {
    return (
        <div className='space-y-12 w-full'>
            {items?.map((brand, index) => {
                return (
                    <div key={index} className='md:flex md:gap-[77px]'>
                        <div title={brand?.altText} className='flex-shrink-0 w-[100px] h-[100px] mx-auto md:mx-0'>
                            <img
                                ref={id === brand.uuid ? targetRef : null}
                                alt=''
                                src={brand.webVisual}
                                className='w-full h-full object-contain'
                            />
                        </div>
                        <div className='px-2 flex-1 textarea-content text-body1 pt-2 md:pt-0'>{brand.description}</div>
                    </div>
                )
            })}
        </div>
    )
}
