import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Button, CardMedia } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import tw from 'twin.macro'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import { TOTP } from 'totp-generator'

import TicketTransfer from 'page/UserSettings/TicketDetail/components/TicketTransfer'
import TicketDatasEdit from 'page/UserSettings/TicketDetail/components/TicketDatasEdit'
import { useModalStore } from 'store/authAtom'
import tranfer from 'resources/images/tranfer.png'
import defaultTicketCode from 'resources/images/defaultTicketCode.png'
import { user } from 'service/userService'
import dayjs from 'dayjs'

const ButtonEdit = ({ component, text, img, icon }) => {
    const { setModalComponent, onOpen } = useModalStore()
    return (
        <Button
            onClick={() => {
                setModalComponent(component)
                onOpen()
            }}
            className='bg-white  px-5 py-[6px] text-base font-semiboldFamily text-secondary-midnightBlue normal-case border-secondary-midnightBlue border-[1px] border-solid w-[142px]'
            variant='contained'
            startIcon={img ? <CardMedia component='img' alt='' className='object-contain' image={img} /> : icon}
            size='small'
        >
            {text}
        </Button>
    )
}
const TicketLabel = ({ title, labelComponent }) => {
    return (
        <div className='flex pb-[10px] flex-col mt-1 md:mt-0 md:flex-row'>
            <div className='w-[200px] font-semiboldFamily'>{title} </div>
            <div className='flex-1'>{labelComponent}</div>
        </div>
    )
}
function TicketCard({ item, length, index, ticketData, time }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { setModalComponent, onClose, onOpen } = useModalStore()
    const [countryCodeList, setCountryCodeList] = useState([])

    // const getCountryCodeListMutation = useMutation({
    //     mutationFn: user.getCountryCodeList,
    //     onSuccess: setCountryCodeList,
    // })
    const timeoutInSeconds = 30
    const secret = item.totpKey && /^[A-Z2-7]+=*$/.test(item.totpKey) ? item.totpKey : undefined

    const [qrCode, setQrcode] = useState('')
    const generateQR = useCallback(() => {
        if (!secret) {
            setQrcode(item.qrCode)
        } else {
            const { otp } = TOTP.generate(secret, {
                digits: 6,
                period: timeoutInSeconds,
                algorithm: 'SHA-1',
            })
            console.log(`PeterTesting, generateQR.generateOTP[${otp}]`)
            setQrcode(item.qrCode + otp)
        }
    }, [])
    const [hasReached, setHasReached] = useState(false)
    const targetDate = useMemo(() => {
        return dayjs(item?.ticketDownloadSuppressedUntil)
    }, [item])
    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentDate = dayjs()
            if (!item.ticketDownloadSuppressedUntil) {
                clearInterval(intervalId)
                setHasReached(true)
            }
            if (currentDate.isAfter(targetDate) || currentDate.isSame(targetDate)) {
                setHasReached(true)
                clearInterval(intervalId) // 清除定时器
            }
        }, 1000)
        const timer = setInterval(generateQR, timeoutInSeconds * 1000)

        return () => {
            clearInterval(intervalId)
            clearInterval(timer)
        }
    }, [])
    useEffect(() => {
        generateQR()
        // getCountryCodeListMutation?.mutate({
        //     lang: language,
        // })
    }, [])
    return (
        <div className='bg-white pt-8 pb-5 px-5 text-secondary-midnightBlue rounded-[12px]'>
            <div className='flex justify-between items-center'>
                <div className=' text-base font-semiboldFamily'>
                    {t('myProfile.ticket')} {index + 1 + '/' + length}
                </div>
                <div className='flex gap-4 flex-wrap'>
                    {/* <ButtonEdit
                        component={
                            <TicketTransfer
                                setModalComponent={setModalComponent}
                                countryCodeList={countryCodeList}
                                onClose={onClose}
                            />
                        }
                        text={'Transfer'}
                        img={tranfer}
                    />
                    <ButtonEdit
                        component={<TicketDatasEdit />}
                        text={'Edit'}
                        icon={<EditOutlinedIcon className='text-2xl' />}
                    /> */}
                </div>
            </div>
            <div className='pt-5 flex flex-col lg:pl-[55px] lg:flex-row'>
                <div className='mx-auto md:mr-0 md:ml-10 lg:mx-0'>
                    {item.qrCode && hasReached ? (
                        <div id={'qrcode' + index}>
                            <QRCode value={qrCode} size={180} />
                        </div>
                    ) : (
                        <div className='w-[180px] h-[180px] relative'>
                            <div className=' -translate-y-1/2 absolute font-medium top-1/2 left-1 right-1 text-center'>
                                {t('myProfile.qrCodeTip')}
                            </div>
                            <CardMedia
                                component='img'
                                alt=''
                                className='object-contain w-full h-full'
                                image={defaultTicketCode}
                            />
                        </div>
                    )}
                </div>

                <div className='flex-1 text-base mt-8 lg:mt-0 lg:pl-[95px]'>
                    <TicketLabel
                        title={t('Tickets.event')}
                        labelComponent={<div className='font-medium'>{ticketData.eventName}</div>}
                    />
                    <TicketLabel title={t('Tickets.date')} labelComponent={<div className='font-medium'>{time}</div>} />
                    <TicketLabel
                        title={t('Tickets.venue')}
                        labelComponent={
                            <div>
                                <div className='font-medium'>{ticketData.eventVenue.name}</div>
                                <div
                                    onClick={() =>
                                        window.open(
                                            language !== 'zh_CN'
                                                ? `https://maps.google.com/maps?daddr=${ticketData.eventVenue.address}`
                                                : `https://ditu.amap.com/search?query=${ticketData.eventVenue.address}`,
                                        )
                                    }
                                    className='text-[#0000EE] text-sm font-semiboldFamily cursor-pointer active:opacity-50'
                                >
                                    {t('myProfile.viewOnMap')}
                                </div>
                            </div>
                        }
                    />
                    <TicketLabel
                        title={t('Tickets.price')}
                        labelComponent={
                            <div className='font-medium'>{`${item.price?.currency} ${item.price?.amount}`}</div>
                        }
                    />
                    <TicketLabel
                        title={t('Tickets.ticketType')}
                        labelComponent={<div className='font-medium'>{item.ticketType?.name}</div>}
                    />
                    <TicketLabel
                        title={t('Tickets.category')}
                        labelComponent={<div className='font-medium'>{item.ticketCategories?.name}</div>}
                    />
                    {item?.fullNameWithLevel && (
                        <TicketLabel
                            title={t('Tickets.seat')}
                            labelComponent={<div className='font-medium'>{item.fullNameWithLevel}</div>}
                        />
                    )}
                    {item.entrace && (
                        <TicketLabel
                            title={t('myProfile.entrance')}
                            labelComponent={<div className='font-medium'>{item.entrace} </div>}
                        />
                    )}
                    {/* <TicketLabel
                        title='Ticket Details '
                        labelComponent={
                            <div>
                                <div className='font-medium'>Full Name:{item.fullNameWithLevel} </div>
                                <div className='font-medium'>Birthday: </div>
                                <div className='font-medium'>Gender: </div>
                            </div>
                        }
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default TicketCard
