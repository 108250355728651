import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { Drawer } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { fliterJobList } from 'utils/util'
import { information } from 'service/informationService'
import EventManagement from 'page/ConnectWithKTSP/WorkWithUsDetails/EventManagement'
import Aside from 'page/ConnectWithKTSP/WorkWithUsDetails/components/Aside'
import { useDisclosure } from 'utils/useDisclosure'
import Loading from 'components/Loading'
const Wrap = tw.div`w-full flex overflow-hidden min-h-[700px]`

export default function Main() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [datas, setDatas] = useState([])
    const [keyLabel, setKeyLabel] = useState(null)

    const getWorkWithKtspJobList = useMutation({
        mutationFn: information.getWorkWithKtspJobList,
        onSuccess: (res) => {
            setDatas(fliterJobList(res))
            setKeyLabel(res.find((item) => item.identityUuid === id))
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const { data: contenrDatas } = useQuery({
        queryKey: ['getWorkWithKtspContentList', language, keyLabel?.identityUuid],
        queryFn: () =>
            information.getWorkWithKtspContentList({
                lang: language,
                identityUuid: keyLabel.identityUuid,
            }),
        enabled: !!keyLabel?.identityUuid,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [keyLabel])

    useEffect(() => {
        getWorkWithKtspJobList.mutate({ lang: language })
    }, [language])

    useEffect(()=>{
        if (datas && datas.length > 0) {
            setKeyLabel(datas.find((item) => item.identityUuid === id))
        }
    }, [id])

    if (!keyLabel && !contenrDatas) {
        return <Loading />
    }

    return (
        <Wrap id='work-with-us-content'>
            <Drawer open={isOpen} onClose={onClose} anchor='left' className='max-w-3xl w-full'>
                <Aside keyLabel={keyLabel} setKeyLabel={setKeyLabel} datas={datas} onClose={onClose} />
            </Drawer>
            <Aside keyLabel={keyLabel} setKeyLabel={setKeyLabel} datas={datas} isHidden={true} />
            <div className='bg-slate-100 flex-1'>
                {contenrDatas ? (
                    <EventManagement keyLabel={keyLabel} contenrDatas={contenrDatas} onOpen={onOpen} />
                ) : (
                    <Loading />
                )}
            </div>
        </Wrap>
    )
}
