import React from 'react'
import { MenuItem, Box, ListItemIcon, ListItemText } from '@mui/material'
function Label({ icon, text, handleFun }) {
    return (
        <MenuItem
            onClick={handleFun}
            sx={{
                borderTopWidth: 1,
                borderColor: '#EFEFEF',
                borderBlockStyle: 'solid',
                paddingX: '20px',
                paddingY: '12px',
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <Box
                sx={{
                    color: '#231448',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontFamily: 'Barlow',
                    fontWeight: 500,
                }}
            >
                {text}
            </Box>
        </MenuItem>
    )
}

export default Label
