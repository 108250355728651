import { http } from 'utils/http'

export const facilitiesService = {
    getSportBanner: (payload) => http.post('/vbs/getSportBanner', payload),
    getSportFilter: (payload) => http.post('/vbs/getSportFilter', payload),
    getSportList: (payload) => http.post('/vbs/getSportList', payload),
    getSportDetail: (payload) => http.post('/vbs/getSportDetail', payload),
    getMyBooking: (payload) => http.post('/vbs/myBooking', payload),
    getFacilitiesStatus: (payload) => http.post('/vbs/getFacilitiesStatus', payload),
    getSportRemark: (payload) => http.post('/vbs/getSportRemark', payload),
}

export const programmesService = {
    getProgrammeBanner: (payload) => http.post('/vbs/getProgrammeBanner', payload),
    getProgrammeFilter: (payload) => http.post('/vbs/getProgrammeFilter', payload),
    getProgrammeList: (payload) => http.post('/vbs/getProgrammeList', payload),
    getProgrammeDetail: (payload) => http.post('/vbs/getProgrammeDetail', payload),
    getMyProgramme: (payload) => http.post('/vbs/myProgramme', payload),
    getActivitiesStatus: (payload) => http.post('/vbs/getActivitiesStatus', payload),
}
