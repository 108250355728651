import Content from 'components/Content'
import React from 'react'

function EventCard({ isReverse, card }) {
    return (
        <div
            className={
                'flex w-full flex-col gap-4 md:gap-[27px] md:flex-row md:h-fit' +
                (isReverse ? '  md:flex-row-reverse' : '')
            }
        >
            <div className='w-full h-[210px] md:h-auto md:aspect-4/3 md:w-[200px] lg:w-[300px]'>
                <img alt={card?.altText} src={card?.webVisual} className='w-full h-full object-cover' />
            </div>
            <div className='flex flex-col text-secondary-midnightBlue md:flex-1 md:h-fit'>
                <div className='pb-3 text-h6 '>{card?.title}</div>
                <Content className='text-body1 md:line-clamp-5' data={card?.webDescription} />
            </div>
        </div>
    )
}

export default EventCard
