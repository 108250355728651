import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Seemore({ handleClick, title }) {
    const { t } = useTranslation()
    const isPad = useMediaQuery('(min-width:500px)')
    return (
        <div
            className='text-primaryRed-ktRed cursor-pointer self-start active:opacity-5 pl-4 flex items-center gap-1 text-base md:text-2xl'
            onClick={handleClick}
        >
            {isPad && (
                <>
                    <div className='whitespace-nowrap font-semiboldFamily'>{title || t('landing.seeMore')}</div>
                    <ArrowForwardIcon />
                </>
            )}
        </div>
    )
}

export default Seemore
