import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import img from 'resources/images/noDatas.svg'
function NoDatas({ text, btnText = '', path = '/eventsTickets', buttonIsShow = true }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div className='w-full h-[500px] flex justify-center items-center'>
            <div className='flex flex-col space-y-4 items-center'>
                {/*<img alt='' src={img} className='w-[80px] h-[80px] object-contain' />*/}
                <div className='text-base font-semiboldFamily'>{text}</div>
                {buttonIsShow && (
                    <Button
                        onClick={() => navigate(path)}
                        className=' text-white normal-case bg-website-gradation text-base leading-4 font-semiboldFamily px-4 py-[10px] w-fit h-fit'
                        variant='contained'
                        size='large'
                    >
                        {btnText}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default NoDatas
