import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Content from 'components/Content'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'

const PAGE_SIZE = 4

function SeeAlso({ imgs }) {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)

    const currentList = useMemo(() => {
        return imgs.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
    }, [imgs, page])

    const allPage = useMemo(() => {
        return Math.ceil(imgs.length / PAGE_SIZE)
    }, [imgs])

    return (
        <div className='bg-container'>
            <div className='flex w-full justify-between pt-[64px] pb-8 items-center'>
                <div className='font-semiboldFamily text-[28px] md:text-4xl'>{t('visitorTip.seeAlso')}</div>
            </div>
            {imgs.length > 0 && (
                <div className='space-y-8'>
                    {currentList.map((img, index) => {
                        return (
                            <div key={index} className='flex w-full cursor-pointer space-x-5'>
                                <img alt={img.altText} src={img.image} className='w-2/5 aspect-[4/3] object-cover' />
                                <div className='text-secondary-midnightBlue'>
                                    <div className='text-h4'>{img.title}</div>
                                    <Content className='mt-3 text-body2' data={img.description} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            {allPage >1 && (
                <div className='pt-6 flex justify-between items-center mx-auto'>
                    <div>
                        <div className={'text-base font-medium pb-2 text-secondary-midnightBlue'}>
                            {(page + 1).toString()
                                       .padStart(2, '0') + '/' + allPage.toString()
                                                                        .padStart(2, '0')}
                        </div>
                        <div className={'relative flex items-center w-[100px] h-[2px] md:h-[4px] xl:w-[200px] '}>
                            <div
                              className={'absolute top-0 bottom-0 left-0 right-0 opacity-30 bg-secondary-midnightBlue '}
                            />
                            {Array.from({ length: allPage }, (_, index) => index)
                                  .map((step, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={
                                            'flex-1  ' +
                                            (page >= index && ' h-[3px] z-30 md:h-[6px] bg-secondary-midnightBlue')
                                          }
                                        />
                                      )
                                  })}
                        </div>
                    </div>
                    <IconButtonChange
                        goToNextSlide={() => setPage(page + 1)}
                        goToPrevSlide={() => setPage(page - 1)}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                        length={allPage - 1}
                        slideIndex={page}
                    />
                </div>
            )}
        </div>
    )
}

export default SeeAlso
