import { useRef, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'

import ShareAndBookMark from 'components/ShareAndBookMark'
import TopIntro from 'page/CarPark/components/TopIntro'
import ParkingFee from 'page/CarPark/components/ParkingFee'
import Payment from 'page/CarPark/components/Payment'
import ThingToNote from 'page/CarPark/components/ThingToNote'
import Tags from 'page/CarPark/components/Tags'
import { facilitiesService } from 'service/vbsService'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import { carParkService } from 'service/carParkService'
import { useSpacialTrafficNews } from 'store/authAtom'
import { filterName } from 'utils/filter'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import Loading from 'components/Loading'
import { TabContainer } from 'components/TabContainer'
import { cn } from 'utils/cn'

export default function Detail() {
    const { setData } = useSpacialTrafficNews()
    const {
        i18n: { language },
        t,
    } = useTranslation()

    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const { data: items, isPending: isCarParkDetailPending } = useQuery({
        queryKey: ['carParkDetail', language, id],
        queryFn: () =>
            carParkService.getDetail({
                lang: language,
                identityUuid: id,
                bannerSize: 5,
                promoSize: 5,
            }),
    })

    const { data: newsData } = useQuery({
        queryKey: ['getSpecialNews', language],
        queryFn: () =>
            carParkService.getSpecialNews({
                lang: language,
            }),
        placeholderData: null,
    })
    useEffect(() => {
        if (newsData) {
            setData({
                announcementTitle: newsData?.title ?? '',
                announcementMessage: newsData?.message ?? '',
                publishDate: newsData?.lastUpdatedTime ?? '',
            })
        }
    }, [newsData])
    if (isCarParkDetailPending) return <Loading />

    return (
        <div className='text-secondary-midnightBlue'>
            {/* <ShareAndBookMark /> */}
            {items && (
                <>
                    {items?.images?.length > 0 && <BannerSwiper items={items.images} />}
                    <section className='bg-container'>
                        <TopIntro items={items} />
                    </section>
                    <TabContainer
                        customDistance={250}
                        components={[
                            items?.parkingFeeDescription && {
                                key: 'parkingFee',
                                title: t('carPark.parkingFee'),
                                component: () => (
                                    <>
                                        <ParkingFee items={items} />
                                        {(items?.things.length === 0 || !items.isThings) &&
                                            !items?.paymentDescription && <Tags items={items} />}
                                    </>
                                ),
                            },
                            items?.paymentDescription && {
                                key: 'payment',
                                title: t('carPark.payment'),
                                component: () => (
                                    <>
                                        <Payment items={items} />
                                        {(items?.things.length === 0 || !items.isThings) && <Tags items={items} />}
                                    </>
                                ),
                            },
                            items?.things.length > 0 &&
                                items.isThings && {
                                    key: 'things',
                                    title: t('eventPage.thingsToNote'),
                                    component: () => <ThingToNote items={items} />,
                                },
                            items?.promotions &&
                                items?.promotions.length > 0 && {
                                    key: 'promotion',
                                    title: t('visitList.promotions'),
                                    component: () => (
                                        <>
                                            {(items?.things.length === 0 || !items.isThings) &&
                                                !items?.paymentDescription &&
                                                !items?.parkingFeeDescription && <Tags items={items} />}
                                            <div className={cn('pt-10 md:pt-[90px]')}>
                                                <PromotionCards items={items.promotions} />
                                            </div>
                                        </>
                                    ),
                                },
                        ].filter((i) => i)}
                    />
                    <section>
                        {(items?.things.length === 0 || !items.isThings) &&
                            !items?.paymentDescription &&
                            !items?.parkingFeeDescription &&
                            items?.promotions.length === 0 && (
                                <div className='pb-8'>
                                    <Tags items={items} />
                                </div>
                            )}
                    </section>
                </>
            )}
        </div>
    )
}
