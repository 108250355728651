import React, { Fragment } from 'react'
import { DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import { cn } from 'utils/cn'

import { enUS, zhCN, zhHK } from 'date-fns/locale'

/**
 * @typedef {Object & import('react-day-picker').DayPickerProps} CalendarProps
 * @property {string} [className] - Additional class names to apply to the calendar.
 * @property {Object} [classNames] - Custom class names for various parts of the calendar.
 * @property {boolean} [showOutsideDays=true] - Whether to show days from the previous and next months.
 * @property {Object} [props] - Additional props to pass to the DayPicker component.
 * @property {...import('react-day-picker').DayPickerProps} [dayPickerProps] - Props from react-day-picker to pass to the DayPicker component.
 */

/**
 * Calendar component that wraps the DayPicker component with custom styles and icons.
 *
 * @param {CalendarProps} props - The props for the Calendar component.
 * @returns {JSX.Element} The rendered Calendar component.
 */
function Calendar({ className, classNames, isRange, ...props }) {
    const { i18n } = useTranslation()

    return (
        <DayPicker
            className={cn('', className)}
            showOutsideDays={false}
            classNames={{
              
                months: 'flex flex-col sm:flex-row relative gap-4',
                month: 'space-y-4',
                month_caption: 'flex justify-center relative items-center',
                caption: 'flex justify-center relative items-center',
                caption_label: 'text-base font-semiboldFamily',
                nav: 'absolute h-[24px] pl-[1px] w-[280px] sm:w-[580px] space-x-1 flex items-center justify-between z-30',
                nav_button: cn('h-7 w-7 bg-transparent p-0 opacity-50 '),
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex',
                head_cell: 'rounded-md w-9 font-normal text-[0.8rem]',
                weeks: 'border-spacing-2',
                month_grid: 'border-separate border-spacing-y-1',
                row: 'flex w-full mt-2',
                cell: 'h-9 w-9 text-center text-sm p-0 m-2 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
                weekdays: 'hidden',
                week:'mt-2',
                day: 'h-10 w-10 font-normal text-[#283266] aria-selected:opacity-100 text-center font-mediumFamily',
                selected: 'bg-[#283266] text-white hover:bg-[#283266] hover:text-white focus:bg-[#283266]',
                range: 'bg-[#283266] text-white hover:bg-[#283266] hover:text-white focus:bg-[#283266] focus:text-white',
                range_start: cn(
                    'bg-[#283266] text-white hover:bg-[#283266] hover:text-white focus:bg-[#283266] focus:text-white',
                    isRange ? 'rounded-l-full rounded-r-none' : 'rounded-full',
                ),
                range_middle:
                    'bg-[#283266] text-white hover:bg-[#283266] hover:text-white focus:bg-[#283266] focus:text-white rounded-none',
                range_end: cn(
                    'bg-[#283266] text-white hover:bg-[#283266] hover:text-white focus:bg-[#283266] focus:text-white',
                    isRange ? 'rounded-r-full rounded-l-none' : 'rounded-full',
                ),
                day_hidden: 'invisible',
                outside: 'text-secondary-hint',
                ...classNames,
            }}
            locale={i18n.language === 'en_US' ? enUS : i18n.language === 'zh_CN' ? zhCN : zhHK}
            components={{
                Nav: ({children, ...props}) =>{ 
                 return(
                  <div {...props}>
                    {children.map((item, index) => (
                      <div {...item.props} key={index}>
                        <span className={
                          cn(
                            'cursor-pointer',
                            item.props.className==='rdp-button_previous'? 'icon-arrow_left' : 'icon-arrow_right'
                          )
                        } />
                      </div>
                    ))}
                  </div>
                 )
                  
                }
            }}
            {...props}
        />
    )
}

export { Calendar }
