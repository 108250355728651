import * as React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { IconButton, Button } from '@mui/material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ConvertDateRangeHelper, ConvertTimeRangeHelper, updateUrlLang } from 'utils/util'
function BookingCard({ card, item, detailUrl = false, isBooking = true }) {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigateToDetail = () => {
        if (detailUrl) {
            window.location.href = updateUrlLang(process.env.REACT_APP_VBS_URL + detailUrl)
        }
    }
    const dateFormat = (time) => {
        return dayjs(time).format('DD MMMM YYYY(ddd)')
    }
    return (
        <Card
            sx={{
                width: '100%',
                position: 'relative',
                boxShadow: 'none',
            }}
        >
            <div className='flex justify-between gap-4 flex-col-reverse md:flex-row'>
                <div className='flex-1'>
                    <div className=' text-secondary-midnightBlue font-semiboldFamily text-xl'>{card.facilityName}</div>
                    <div className='flex gap-1 items-start font-medium mt-2 text-sm text-secondary-midnightBlue'>
                        <div className='icon-date text-base' />
                        <div>
                            <span>
                                {isBooking
                                    ? dateFormat(card.playDate)
                                    : dateFormat(card.sessions[0].startTime) !==
                                      dateFormat(card.sessions[card.sessions.length - 1].endTime)
                                    ? dateFormat(card.sessions[0].startTime) +
                                      '-' +
                                      dateFormat(card.sessions[card.sessions.length - 1].endTime)
                                    : dateFormat(card.sessions[0].startTime)}
                            </span>
                            <span>&nbsp;|&nbsp;</span>
                            {card.sessions.length < 3 ? (
                                card.sessions.map((time, index) => {
                                    return (
                                        <span key={index}>
                                            {dayjs(time.startTime).format('hh:mm A') +
                                                '-' +
                                                dayjs(time.endTime).format('hh:mm A') +
                                                (index === card.sessions.length - 1 ? '' : ',')}
                                        </span>
                                    )
                                })
                            ) : (
                                <span>{t('myProfile.multipleSessionTime')}</span>
                            )}
                        </div>
                    </div>
                    <div className='flex gap-1 items-center font-medium mt-1 text-sm text-secondary-midnightBlue'>
                        <div className='icon-location text-base' />
                        {card.sessions.length < 3 ? (
                            <div>
                                {card.sessions.map((time, index) => {
                                    return (
                                        <span key={index}>
                                            {time.venueName + (index === card.sessions.length - 1 ? '' : ' | ')}
                                        </span>
                                    )
                                })}
                            </div>
                        ) : (
                            <div>{t('myProfile.multipleVenues')}</div>
                        )}
                    </div>

                    <Button
                        onClick={navigateToDetail}
                        className='mt-2 text-white normal-case bg-website-gradation text-base leading-[19.2px] py-[10px] h-fit w-fit font-semiboldFamily px-4'
                        variant='contained'
                        size='large'
                    >
                        {t('myProfile.viewDetail')}
                    </Button>

                    {/* <div
                        onClick={navigateToDetail}
                        className='mt-2 cursor-pointer text-base font-semiboldFamily text-secondary-midnightBlue underline active:opacity-50'
                    >
                        {t('myProfile.downloadTicket')}
                    </div> */}
                </div>
                <CardMedia
                    component='img'
                    className=' h-[216px] w-full md:w-[185px] md:h-[132px] '
                    height='267'
                    image={card.thumbnail}
                    alt='green iguana'
                />
            </div>
        </Card>
    )
}

export default BookingCard
