import * as React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { IconButton, Button } from '@mui/material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ConvertDateRangeHelper, ConvertTimeRangeHelper } from 'utils/util'
function MyRecordCard({ card, type, item, detailUrl = false }) {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigateToDetail = () => {
        detailUrl
            ? (window.location.href = detailUrl)
            : navigate(
                  '/userSettings/ticketDetail?id=' +
                      card.eventId +
                      '&type=' +
                      type +
                      '&image=' +
                      card.eventImageUrl +
                      '&sessionId=' +
                      item.session.id,
              )
    }
    const { tickets } = item
    let totalQty = 0
    for (let item of tickets) {
        totalQty += item.qty
    }

    const handleDate = (date) => {
        const dayjsObj = dayjs(date)
        const hour = dayjsObj.hour()
        console.log('handle date', date, `${dayjsObj.format('HH:mm')}${hour > 12 ? 'pm' : 'am'}`)
        return `${dayjsObj.format('HH:mm')}${hour > 12 ? 'pm' : 'am'}`
    }
    const handleDay = (startDate = '', endDate = '') => {
        const startDayjsObj = dayjs(startDate)
        const endDayObj = dayjs(endDate)
        if (startDayjsObj.format('DD MMM YYYY') === endDayObj.format('DD MMM YYYY')) {
            return `${startDayjsObj.format('DD MMM YYYY')} | ${handleDate(startDate)} - ${handleDate(endDate)}`
        } else
            return `${startDayjsObj.format('DD MMM YYYY')} | ${handleDate(startDate)} - ${endDayObj.format(
                'DD MMM YYYY',
            )} | ${handleDate(endDate)}`
    }
    return (
        <Card
            sx={{
                width: '100%',
                position: 'relative',
                boxShadow: 'none',
            }}
        >
            <div className='flex justify-between gap-4 flex-col-reverse md:flex-row'>
                <div className='flex-1'>
                    {card?.programmes && <div className='text-primaryRed-ktRed text-sm'>{card.programmes}</div>}
                    <div className=' text-secondary-midnightBlue font-semiboldFamily text-xl'>{card.eventName}</div>
                    {item.session.starts && item.session.ends && (
                        <div className='flex gap-1 items-start font-medium mt-2 text-sm text-secondary-midnightBlue'>
                            <div className='icon-date text-base' />
                            <div className='line-clamp-2'>{`${ConvertDateRangeHelper(
                                [item.session.starts, item.session.ends],
                                false,
                                language,
                            )} | ${ConvertTimeRangeHelper([item.session.starts, item.session.ends], language)}`}</div>
                        </div>
                    )}
                    {card?.eventVenue && (
                        <div className='flex gap-1 items-center font-medium mt-1 text-sm text-secondary-midnightBlue'>
                            <div className='icon-location text-base' />
                            <div className='line-clamp-2'>{card.eventVenue.name}</div>
                        </div>
                    )}

                    <Button
                        onClick={navigateToDetail}
                        className='mt-2 text-white normal-case bg-website-gradation text-base leading-[19.2px] py-[10px] h-fit w-fit font-semiboldFamily px-4'
                        variant='contained'
                        size='large'
                    >
                        {t('myProfile.viewTicket')}
                    </Button>

                    {/* <div
                        onClick={navigateToDetail}
                        className='mt-2 cursor-pointer text-base font-semiboldFamily text-secondary-midnightBlue underline active:opacity-50'
                    >
                        {t('myProfile.downloadTicket')}
                    </div> */}
                </div>
                <CardMedia
                    component='img'
                    className=' h-[216px] w-full md:w-[185px] md:h-[132px] '
                    height='267'
                    image={card.eventImageUrl}
                    alt='green iguana'
                />
            </div>
        </Card>
    )
}

export default MyRecordCard
