import React, { useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import PromotionCard from 'page/Promotions/components/PromotionCard'
import ShopCard from './ShopCard'
import ProductCard from './ProductCard'
import { useDisclosure } from 'utils/useDisclosure'
import ProductModal from './ProductModal'
function SwiperProductCard({ items, setSwiper,setSlideIndex}) {
    const [card, setCard] = useState({})
    const { onClose, onOpen, isOpen } = useDisclosure()
    // const imgs = [
    //     {
    //       "cardImage": "https://img1.baidu.com/it/u=3001934222,1791122926&fm=253&fmt=auto&app=120&f=PNG?w=500&h=733",
    //       "name": "demo"
    //     },
    //     {
    //         "cardImage": "https://img1.baidu.com/it/u=3001934222,1791122926&fm=253&fmt=auto&app=120&f=PNG?w=500&h=733",
    //         "name": "demo"
    //       },
    //       {
    //         "cardImage": "https://img1.baidu.com/it/u=3001934222,1791122926&fm=253&fmt=auto&app=120&f=PNG?w=500&h=733",
    //         "name": "demo"
    //       },{
    //         "cardImage": "https://img1.baidu.com/it/u=3001934222,1791122926&fm=253&fmt=auto&app=120&f=PNG?w=500&h=733",
    //         "name": "demo"
    //       },
        
    //   ]
    return (
        <>
            <Swiper
                spaceBetween={24}
                // loop={items.length > 2}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                breakpoints={{
                    0: { slidesPerView: 2 },
                    500: {
                        slidesPerView: 3,
                    },
                    650 : {
                        slidesPerView: 4,
                    },
                }}
                className='mySwiper'
            >
                {items.map((card, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <ProductCard
                                key={card + i}
                                card={card}
                                setCard={setCard}
                                onOpen={onOpen}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <ProductModal item={card} items={items} onClose={onClose} isOpen={isOpen} setCard={setCard} />
        </>
    )
}

export default SwiperProductCard
