import tw from 'twin.macro'
import ReactPlayer from 'react-player'

const Wrap = tw.div`w-full bg-black relative h-[281px] md:h-[432px] lg:h-[692px]`
const AltText = tw.div`absolute z-20 top-[20%] left-[10%] text-white text-2xl md:w-[500px] md:text-[90px] md:leading-[90px]`

export default function Banner({ url, description = '' }) {
    return (
        <Wrap>
            <ReactPlayer
                playing={true}
                playsinline={true}
                muted
                controls={false}
                loop
                config={{
                    file: {
                        attributes: {
                            style: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            },
                        },
                    },
                }}
                width='100%'
                height='100%'
                url={url}
                // wrapper={({ children }) => (
                //     <div className='relative'>
                //         {children}
                //         <AltText>{description}</AltText>
                //     </div>
                // )}
            />
            <AltText className='font-semiboldFamily'>{description}</AltText>
        </Wrap>
    )
}
