import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// 导入语言资源文件
import { setDayjsLocale } from 'utils/util'
import translationEN from './en.json'
import translationZHCN from './zhCN.json'
import translationZHHK from './zhHK.json'
import { SetCookie } from 'utils/cookieLombok'

export const languageList = [
    {
        value: 'en_US',
        label: 'English',
    },
    {
        value: 'zh_CN',
        label: '简体中文',
    },
    {
        value: 'zh_HK',
        label: '繁體中文',
    },
]

const urlParams = new URLSearchParams(window.location.search)
const lang = urlParams.get('lang')
const langs = ['en_US', 'zh_CN', 'zh_HK']
const localLang = window.localStorage.getItem('lang')
const currentLang = lang ? lang : localLang ? localLang : 'en_US'
setDayjsLocale(langs.includes(currentLang) ? currentLang : 'en_US')
// 创建 i18next 实例
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en_US: { translation: translationEN },
            zh_CN: { translation: translationZHCN },
            zh_HK: { translation: translationZHHK },
        },
        lng: langs.includes(currentLang) ? currentLang : 'en_US', ///动态改语言
        fallbackLng: 'en_US', // 如果没有加载语言的资源则使用此语言
        interpolation: { escapeValue: false }, // React 已经安全地对值进行编码
    })

export default i18n
