import React from 'react'
import { useTranslation } from 'react-i18next'

import TextContainer from 'page/Transportation/components/TextContainer'
function TaxiFerrryCrossCommon({ firstTitle, firstAbout, routeAbouts }) {
    const { t } = useTranslation()
    return (
        <div className='space-y-8'>
            <TextContainer title={firstTitle} about={firstAbout} />
            {routeAbouts && (
                <div className='space-y-2'>
                    {/* <div className='text-base font-semiboldFamily'>{t('transport.route')}</div> */}
                    {routeAbouts.map((route, index) => {
                        return (
                            <div key={index} className='text-base font-medium'>
                                {route}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default TaxiFerrryCrossCommon
