import React from 'react'
import { cn } from 'utils/cn'
function Steps({ swiperLength, slideIndex, stepTextColor, stepActiveColor, isLanding }) {
    return (
        <>
            {swiperLength.length > 1 && (
                <div>
                    <div className={'text-base font-medium pb-2 ' + stepTextColor}>
                        {(slideIndex + 1).toString().padStart(2, '0') +
                            '/' +
                            swiperLength.length.toString().padStart(2, '0')}
                    </div>
                    <div className={'relative flex items-center w-[120px] h-[2px] md:h-[4px] md:w-[200px] '}>
                        <div
                            className={
                                'absolute top-0 bottom-0 left-0 right-0 opacity-30 bg-secondary-midnightBlue ' +
                                (isLanding && ' bg-white')
                            }
                        ></div>
                        {swiperLength.map((step, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cn(
                                        'flex-1',
                                        slideIndex >= index && ' h-[3px] z-30 md:h-[6px] ',
                                        stepActiveColor,
                                    )}
                                ></div>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

export default Steps
