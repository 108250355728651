import React, { useState, useEffect } from 'react'

import Lottie from 'react-lottie'
import * as animationData from 'constants/white_dot.json'
function AnUrbanCilcle({ position = '', text = '' }) {
    const [isVisible, setIsVisible] = useState(false)
    const [lottieOptions, setLottieOptions] = useState({
        loop: true,
        autoplay: true,
        isStopped: false,
        animationData: animationData,
    })

    useEffect(() => {
        setLottieOptions({
            loop: true,
            autoplay: true,
            isStopped: false,
            animationData: animationData,
        })
    }, [position])

    return (
        <div className={'absolute cursor-pointer mx-[100px] pt-3 ' + position}>
            <div className='relative'>
                <div
                    className={
                        'absolute left-1/2 -translate-x-1/2 whitespace-nowrap transition duration-500 ease-in-out bg-white px-5 py-[9px] text-secondary-midnightBlue font-semiboldFamily text-base leading-[22.4px] rounded-[50px] ' +
                        (isVisible ? 'opacity-100 -translate-y-12 z-30' : 'opacity-0 translate-y-0  z-10')
                    }
                >
                    {text}
                </div>
                {lottieOptions && (
                    <div
                        onMouseEnter={() => setIsVisible(true)}
                        onMouseLeave={() => setIsVisible(false)}
                        onClick={() => setIsVisible(true)}
                        className={'size-10 absolute -translate-x-1/2 left-1/2 z-30'}
                    >
                        <Lottie options={lottieOptions} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AnUrbanCilcle
