import { useQuery } from '@tanstack/react-query'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import Loading from 'components/Loading'
import Banner from 'components/shopDine/Banner'
import { TabContainer } from 'components/TabContainer'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import bannerTop from 'resources/images/friends1.jpg'
import friends2 from 'resources/images/friends2.png'
import friends3 from 'resources/images/friends3.png'
import friends4 from 'resources/images/friends4.png'
import friends_icon1 from 'resources/images/friends_icon1.png'
import friends_icon2 from 'resources/images/friends_icon2.png'
import { shopDineService } from 'service/restaurantService'
import { useModalStore } from 'store/authAtom'

export default function Friends() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const { setModalComponent, onOpen, setColorCloseIcon } = useModalStore()
    const [isLoading, setIsLoading] = useState(true)

    const banner = {
        image: bannerTop,
        title: t('friends.title'),
        about: t('friends.bannerAbout'),
    }
    const TermsOfCondition = () => {
        return (
            <div className='flex flex-col'>
                <div className='text-h6 text-secondary-midnightBlue break-words'>
                    {t('friends.tncTitle')}
                </div>
                <div className='flex-1'>
                    <div className='text-body4 text-secondary-darkGery break-words mt-8 h-[360px] sm:h-[500px] overflow-y-scroll'>
                        Lorem ipsum dolor sit amet consectetur. Nibh lobortis maecenas adipiscing nisi sit lectus turpis
                        interdum. Consectetur eget a varius ut libero nisi est felis. Netus enim aliquet sed nunc
                        penatibus. Felis enim velit quis diam lobortis eros massa. Egestas proin sodales dictumst
                        malesuada. Vestibulum bibendum sit mauris lobortis eget. Est accumsan lectus a sit commodo sem
                        mi. Aenean elementum dolor elit dolor. Tincidunt gravida mattis vulputate leo amet. Ipsum metus
                        felis rutrum vitae diam tellus cras lectus. Suspendisse et semper nibh libero elit enim nunc
                        leo. Vitae enim proin at massa quam id dictum. Commodo diam rhoncus a pulvinar. Eu mauris enim
                        sem nibh donec erat adipiscing sed arcu. Massa risus semper consectetur sapien nec egestas. Sed
                        nec quis sollicitudin pharetra arcu. Vulputate sed commodo sed tortor vel. Orci donec faucibus
                        egestas proin laoreet sodales vivamus nibh. Id amet cursus morbi eu turpis proin semper
                        facilisis a. Etiam vel ultricies tristique sed nisi turpis urna elementum. Urna sagittis non
                        eleifend ipsum ut. Non odio sit ut porta blandit ac. Adipiscing consectetur eget hac molestie
                        tortor pellentesque nunc consequat. Sem quam porttitor venenatis non nunc id eu. Feugiat natoque
                        metus id consectetur volutpat. Suspendisse eget mi vel diam morbi. Nulla faucibus auctor sem
                        dolor est fermentum. Sapien auctor hendrerit tellus gravida sed vitae rhoncus quisque in. Dui
                        egestas imperdiet feugiat ornare tempus sed. Cursus sit in rhoncus purus et non at in aliquam.
                        Augue cras turpis hac vivamus mauris orci morbi. Id id vestibulum porttitor gravida ut non
                        fringilla tempor dui. Donec aliquam iaculis sapien elementum ut. Urna tempor sem odio vivamus.
                        Faucibus nisl tempor neque aliquet fringilla diam praesent et curabitur. Consequat pulvinar
                        neque ante quam scelerisque aliquam amet et tincidunt. Massa massa quis neque dignissim vitae
                        ornare. Pulvinar turpis diam sagittis a odio. Ornare dignissim risus suspendisse fermentum velit
                        leo ut. Vitae est morbi facilisi ac adipiscing tortor egestas nulla egestas. Morbi quisque
                        suspendisse eu sem mattis urna sed. Natoque montes pellentesque orci pellentesque dictum amet id
                        lacus. Scelerisque egestas felis aliquet faucibus. Malesuada turpis urna tincidunt justo purus.
                        Tincidunt ac velit a diam. Nisl bibendum ultrices nulla aenean diam etiam duis elementum. Sit
                        aliquam purus ut et egestas porta viverra eu justo. Enim ut lectus sagittis velit sit faucibus
                        aliquet odio. Lobortis tempor dui habitant sollicitudin vulputate. Lacus at euismod at pretium.
                        Aliquam in vel sapien est consequat convallis lacus leo. Adipiscing commodo orci sed erat amet
                        dolor enim neque. Egestas mauris nascetur suspendisse sollicitudin ultricies. Laoreet congue
                        scelerisque euismod magna in. Eu ut odio diam massa etiam feugiat. Urna velit dolor eget
                        scelerisque fusce ornare. Lacus tortor diam nisl ut aliquet sit sagittis eget. Accumsan id
                        condimentum sit tortor purus viverra amet sit blandit. Cras neque et odio lobortis integer
                        pulvinar tempus turpis. Et amet tincidunt malesuada integer. Commodo nullam diam fermentum morbi
                        volutpat sed mauris neque molestie. Gravida ipsum urna mi nibh. Sed egestas iaculis vitae sit ut
                        nisi ipsum. Est feugiat sit senectus euismod ac. Netus id sollicitudin posuere a non.
                        Ullamcorper nibh sapien nunc integer. Id est vitae morbi ultrices nibh imperdiet euismod
                        scelerisque a. Justo lorem in tellus cursus nullam in purus. At nunc ornare sit amet nibh non
                        turpis mi sed. Molestie pharetra neque integer massa sit quam potenti. Lectus consequat velit
                        accumsan ac blandit ut ullamcorper. Ullamcorper risus tristique est vitae feugiat. Maecenas{' '}
                    </div>
                </div>
            </div>
        )
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 10)
    }, [])

    if (isLoading) return <Loading />

    return (
        <div>
            <Banner banner={banner} />
            <div className='my-12 bg-container whitespace-pre text-wrap'>
                <div className='whitespace-pre text-wrap'>{t('friends.desc')}</div>
                <br />
                <div>{t('friends.sub1')}</div>
                {/* <br />
                <div>
                    <Trans
                        i18nKey='friends.desc2'
                        components={[
                            <span
                                className='text-primaryRed-ktRed cursor-pointer'
                                onClick={() => {
                                    // setModalComponent(<TermsOfCondition />)
                                    // setColorCloseIcon('white')
                                    // onOpen()
                                }}
                            />,
                        ]}
                    />
                </div> */}
            </div>

            {/* <section className='mt-12'>
                <TabContainer
                    fullWidth={true}
                    components={[
                        {
                            key: 'Overview',
                            title: 'Overview',
                            component: () => (
                                <div className='bg-white bg-container text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6 mt-10'>OVERVIEW</div>
                                    <img src={friends2} alt='loading' className='mb-6' />
                                    <div className='mb-[60px] text-body1'>
                                        Elevate your visiting experience by signing up as a Friend of KTSP! Managed and
                                        operated by Kai Tak Sports Park Limited, Friends of KTSP is a membership
                                        programme that comes with fabulous member perks and allows you to earn points on
                                        eligible purchases made within the precinct.  
                                    </div>

                                    <div className='text-h3 pb-4 text-secondary-midnightBlue md:pb-5'>HOW TO JOIN?</div>
                                    <div className='mb-5 text-body1'>
                                        Signing up is easy. All you need to do is to create an account via Kai Tak
                                        Sports Park’s official website or mobile app.
                                    </div>
                                    <img src={friends3} alt='laoding' className='mb-[60px]' />
                                    <div className='h-[1px] w-full bg-[#b1b1b1] mb-10' />
                                </div>
                            ),
                        },
                        {
                            key: 'points',
                            title: 'KTSP POINTS',
                            component: () => (
                                <div className='bg-white bg-container  text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6'>KTSP POINTS</div>
                                    <img src={friends4} alt='loading' className='mb-6' />
                                    <div className='text-body1 mb-10 lg:mb-[60px]'>
                                        <div className='mb-6'>
                                            KTSP Points are the rewards that Friends of KTSP can earn on eligible
                                            purchases made at Kai Tak Sports Park. You will earn 1 KTSP Point for every
                                            HK$1 spent at a designated merchant or service provider. The earned KTSP
                                            Points can be used for future purchases.
                                        </div>

                                        <div className='mb-6'>
                                            You can choose to bind your account with K Dollar Programme in Kai Tak
                                            Sports Park mobile app after you become a Friend of KTSP. The KTSP Points
                                            that you earned will be automatically converted into K Dollars upon the
                                            binding.
                                        </div>

                                        <div>
                                            For the full Terms of Conditions of Friends of KTSP Programme, please{' '}
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    setModalComponent(<TermsOfCondition />)
                                                    setColorCloseIcon('white')
                                                    onOpen()
                                                }}
                                                className='text-primaryRed-ktRed underline'
                                            >
                                                click here.
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex lg:flex-row flex-col mb-20 gap-8 text-secondary-midnightBlue'>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon1} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem as Cash</div>
                                                <div className='text-body1'>
                                                    Earned KTSP Points can be used for future purchases
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon2} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem a Reward</div>
                                                <div className='text-body1'>
                                                    Redeem your earned points for exclusive rewards, discounts or
                                                    experiences.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ),
                        },
                    ].filter((i) => i)}
                />
            </section> */}
        </div>
    )
}
