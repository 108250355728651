import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import tw from 'twin.macro'

import { filterName } from 'utils/filter'
import TabSearch from 'page/Programmes/componets/TabSearch'
import Card from './Card'
import { facilitiesService } from 'service/vbsService'
import PaginationButton from 'components/PaginationButton'

const Wrap = tw.div`mt-4`
const Title = tw.div`pb-3 pl-2 text-[28px] leading-[28px] lg:text-5xl lg:leading-[57.5px]`

export default function TabsChange({ tabsTitles = [] }) {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const queryClient = useQueryClient()
    const allTabsTitle = [
        {
            id: '',
            nameEn: 'All',
            nameCn: '全部',
            nameZh: '全部',
        },
        ...tabsTitles,
    ]
    const [value, setValue] = useState('')
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const getSportsConditions = {
        lang: language,
        searchKey: searchVal.trim(),
        sportsType: value,
        maxRecord: 12,
    }
    const { data, fetchNextPage, refetch, isFetching } = useInfiniteQuery({
        queryKey: ['getSportList', language, value],
        queryFn: ({ pageParam = 0 }) =>
            facilitiesService.getSportList({
                ...getSportsConditions,
                startRow: pageParam,
            }),
        getNextPageParam: (lastPage, pages) => {
            // if (!lastPage.hasMoreData) {
            //     return null
            // }
            return pages.length * 12
        },
        onError: (e) => {
            console.log(e)
        },
    })
    useEffect(() => {
        refetch()
    }, [])
    return (
        <Wrap className='bg-container'>
            <Title className='font-semiboldFamily text-secondary-midnightBlue'>{t('facilitiesBooking.title')}</Title>
            <TabSearch
                tabVal={value}
                setTabVal={setValue}
                tabTitles={allTabsTitle.map((i) => ({ ...i, vbsRefId: i.id }))}
                searchVal={searchVal}
                setIsSearch={setIsSearch}
                setSearchVal={setSearchVal}
                onBlurFun={() => {
                    !isFetching && refetch()
                }}
            />
            {/* {isSearch && (
                <div className='text-h4 mt-6 text-secondary-midnightBlue'>
                    {isSearch ? data?.pages[0]?.allDataSize ?? 0 : 0}
                    {t('eventPage.resultsFound')}
                </div>
            )} */}
            <div className='grid gap-x-[10px] gap-y-4 grid-cols-2 pt-8 md:gap-x-6 lg:grid-cols-3 lg:gap-y-8 lg:pt-[64px]'>
                {isSearch &&
                    data &&
                    data?.pages.map((page) => {
                        return page.data.map((card, index) => {
                            return (
                                <Card
                                    key={index}
                                    card={{
                                        ...card,
                                        cardImage: filterName(
                                            {
                                                nameEn: card.cardImageEn,
                                                nameZh: card.cardImageTc,
                                                nameCn: card.cardImageCn,
                                            },
                                            language,
                                        ),
                                        facilityName: filterName(card, language),
                                        label: filterName(
                                            { nameEn: card.labelEn, nameZh: card.labelTc, nameCn: card.labelCn },
                                            language,
                                        ),
                                    }}
                                />
                            )
                        })
                    })}
            </div>

            {isSearch && (
                <PaginationButton
                    isDisabled={
                        data?.pages.reduce((acc, page) => acc + page.data.length, 0) === data?.pages[0]?.allDataSize
                    }
                    fun={() => {
                        fetchNextPage()
                    }}
                    nowCardsLength={data?.pages?.reduce((acc, page) => acc + page.data.length, 0) ?? 0}
                    allCardsLength={data?.pages?.[0]?.allDataSize ?? 0}
                />
            )}
        </Wrap>
    )
}
