import { Chip } from '@mui/material'
import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from 'twin.macro'
import { cn } from 'utils/cn'

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
`

export default function About({ items }) {
    const { about: description, tags, dynamicContent, interiorDesign } = items
    const { t } = useTranslation()

    const [isExpand, setIsExpand] = useState(false)
    const [isMoreThan, setIsMoreThan] = useState(false)

    const descriptionRef = useRef(null)

    //等ba回复确定是否需要将amenitiesList放在show more中
    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.style.display = 'block'
            const descriptionHeight = descriptionRef.current.offsetHeight
            const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10)
            const maxLines = 4
            setIsMoreThan(descriptionHeight > lineHeight * maxLines)
            descriptionRef.current.style.display = 'none'
        }
    }, [description])

    return (
        <div className='bg-container'>
            <div className='max-w-[750px]'>
                <div className='pt-8 space-y-4 text-secondary-midnightBlue md:pt-12'>
                    {/* <div className='text-h3 pb-4 md:pb-6'>{t('eventPage.about')}</div> */}
                    <Content ref={descriptionRef} data={description} className={'text-base font-medium pb-6'} />
                    <Content
                        data={description}
                        className={'text-base font-medium' + (isMoreThan && !isExpand ? ' line-clamp-4' : '')}
                    />
                    {interiorDesign && (isMoreThan ? (isExpand ? true : false) : true) && (
                        <div className='my-8 md:my-12'>
                            <img alt='loading' loading='lazy' src={interiorDesign} className='w-full h-full' />
                        </div>
                    )}
                    {isMoreThan && (
                        <div className='my-4'>
                            <ViewMore setIsExpand={setIsExpand} isExpand={isExpand} />
                        </div>
                    )}

                    {tags && tags.length > 0 && (
                        <div className='flex flex-wrap gap-4'>
                            {tags?.map((item) => (
                                <ChipWrap
                                    className='bg-transparent'
                                    key={item.identityUuid}
                                    label={item.name}
                                    variant='outlined'
                                />
                            ))}
                        </div>
                    )}

                    <div className='flex flex-col mt-12'>
                        {dynamicContent &&
                            dynamicContent.length > 0 &&
                            dynamicContent.map((item, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={item.content}
                                        className={cn(
                                            index !== dynamicContent.length - 1 ? 'mb-[11px]' : '',
                                            index === dynamicContent.length - 1 ? 'mb-8 md:mb-12' : '',
                                            'text-lg font-boldFamily underline cursor-pointer active:opacity-50',
                                        )}
                                    >
                                        {item.description}
                                    </a>
                                )
                            })}
                    </div>
                    {items?.floorMap && (
                        <img alt='loading' loading='lazy' src={items.floorMap} className='w-full h-full' />
                    )}
                </div>
            </div>
        </div>
    )
}
