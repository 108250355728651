import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import tw from 'twin.macro'
import Tags from 'page/EventsTickets/components/Tags'
import { useTranslation } from 'react-i18next'
import Content from 'components/Content'

const Label = tw.div`pt-[18px] pb-4 md:pb-[30px] pr-5 flex justify-between cursor-pointer items-center text-secondary-midnightBlue text-2xl active:opacity-50`
function ThingsLabel({ item }) {
    const [isExpand, setExpand] = useState(false)

    return (
        <div className='border-solid border-secondary-grey border-b-[1px]'>
            <Label className='text-body2' onClick={() => setExpand(!isExpand)}>
                <div>{item.title}</div>
                {isExpand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            </Label>
            {isExpand && <Content data={item.content} className='text-body1 pb-6 md:pb-[30px]' />}
        </div>
    )
}

function ThingTonoteSection({ items }) {
    const { t } = useTranslation()
    const { things, tags } = items
    // const { hideThingsToNote, thingsToNote } = items
    return (
        <div className='text-secondary-midnightBlue bg-container'>
            {things && things.length > 0 && (
                <div className=' pt-8 md:pt-[60px]'>
                    <div className='text-h4'>{t('eventPage.thingsToNote')}</div>
                    <div className='max-w-[752px]'>
                        <div className='mt-4 md:mt-[22px]'>
                            {things.map((item, index) => {
                                return <ThingsLabel item={item} index={item} key={index} />
                            })}
                        </div>
                    </div>
                </div>
            )}
            {tags && tags.length > 0 && (
                <div className='pt-8 md:pt-[60px]'>
                    <Tags items={{ tags }} />
                </div>
            )}
        </div>
    )
}

export default ThingTonoteSection
