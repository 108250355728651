import React from 'react'
import { useWindowScroll } from 'react-use'
import tw, { styled } from 'twin.macro'

const Wrap = styled.div`
    ${tw`fixed top-[75vh] right-0 size-[60px] flex items-center justify-center bg-[#8F774E] z-50 cursor-pointer rounded-l-full`}
    ${({ display }) => (display === 'block' ? tw`flex` : tw`hidden`)}
    box-shadow: 0px 2px 12px 0px #0000006B;
`

export default function BackToTopButton() {
    const { y: scrollY } = useWindowScroll()

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <Wrap display={scrollY > 100 ? 'block' : 'none'} onClick={handleScrollToTop}>
            <div className='icon-back_top text-white text-2xl leading-[100%]' />
        </Wrap>
    )
}
