import { createTheme, SvgIcon, ThemeProvider as MuiThemeProvider } from '@mui/material'

import CheckIcon from 'resources/images/checkbox_unselected.svg'
import CheckedIcon from 'resources/images/checkbox_selected.svg'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {import('@mui/material').ThemeOptions} options
 * @returns
 */
function createThemeOptions(options) {
    return options
}

/**
 *
 * @param {import('@mui/material').ThemeOptions[]} args[]
 * @returns
 */
function mergeTheme(...args) {
    return createTheme(args.reduce((acc, arg) => ({ ...acc, ...arg }), {}))
}


const ThemeProvider = ({ children }) => {
    const {
        i18n: { language },
    } = useTranslation()

    const fontFamily = language === 'en_US' ? 'Barlow, sans-serif' : 'MicrosoftJhengHei, sans-serif'

    const token = createThemeOptions({
        status: {},
        palette: {
            primary: {
                main: '#D6093B',
            },
            secondary: {
                main: '#231448',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 640,
                // => @media (min-width: 640px) { ... }
                md: 768,
                // => @media (min-width: 768px) { ... }
                lg: 1024,
                // => @media (min-width: 1024px) { ... }
                xl: 1280, //適用 1560*1600 13.3英寸
                // => @media (min-width: 1280px) { ... }
                '2xl': 1536,
                '3xl': 1667,
                '4xl': 1900, //適用1920*1080 23.5英寸
            },
        },
    })

    const typography = createThemeOptions({
        typography: {
            h5: {
                fontFamily,
                fontSize: '28px',
                lineHeight: '28px',
                fontWeight: 600,
            },
            h6: {
                fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '21px',
            },
            subtitle2: {
                fontFamily,
                fontSize: '12px',
                lineHeight: '18px',
            },
            button: {
                fontFamily,
                fontSize: '16px',
            },

            fontFamily,
        },
    })

    const components = createThemeOptions({
        components: {
            MuiCssBaseline: {
                styleOverrides: (themeParam) => `
              body {
                  fontFamily: ${themeParam.typography.fontFamily};
              }
            `,
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        fontSize: '22px',
                        fontFamily,
                        color: '#231448',
                        fontWeight: 600,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '16px',
                        fontFamily,
                        color: '#4A4B59',
                        fontWeight: 500,
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    IconComponent: (props) => {
                        return (
                            <SvgIcon {...props}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    viewBox='0 0 16 16'
                                    fill='none'
                                >
                                    <path
                                        d='M12.6667 6.46667L8.00008 11.3333L3.33342 6.46667L3.7809 6L8.00008 10.4L12.2193 6L12.6667 6.46667Z'
                                        fill='currentColor'
                                        stroke='currentColor'
                                        strokeWidth='0.4'
                                        strokeMiterlimit='10'
                                    />
                                </svg>
                            </SvgIcon>
                        )
                    },
                },
                styleOverrides: {
                    root: {
                        fontFamily,
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'primary' },
                        style: {
                            backgroundColor: 'white',
                            color: '#D6093B',
                            borderStyle: 'solid',
                            borderColor: 'transparent',
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'transparent',
                                color: 'white',
                            },
                        },
                    },
                    {
                        props: { variant: 'primaryRed' },
                        style: {
                            background:
                                'linear-gradient(45deg, #8f0044, #bc003c, #e20030, #ff1636), linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%)',
                            color: 'white',
                            '&:hover': {
                                background:
                                    'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%)',
                            },
                        },
                    },
                    {
                        props: { variant: 'secondary' },
                        style: {
                            backgroundColor: 'white',
                            color: '#D6093B',
                            borderStyle: 'solid',
                            borderColor: '#D6093B',
                            '&:hover': {
                                backgroundColor: 'rgba(236, 0, 50, 0.1)',
                            },
                        },
                    },
                    {
                        props: { variant: 'secondaryBlue' },
                        style: {
                            backgroundColor: 'white',
                            color: '#283266',
                            borderStyle: 'solid',
                            borderColor: '#283266',
                            '&:hover': {
                                backgroundColor: 'rgba(40, 50, 102, 0.1)',
                            },
                        },
                    },
                    {
                        props: { variant: 'selected' },
                        style: {
                            backgroundColor: '#283266',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(40, 50, 102, 0.9)',
                            },
                        },
                    },
                    {
                        props: { variant: 'unselected' },
                        style: {
                            backgroundColor: '#D4D6D9',
                            fontWeight: 'normal',
                            '&:hover': {
                                backgroundColor: 'rgba(212, 214, 217, 0.9)',
                            },
                        },
                    },
                ],
                defaultProps: {
                    disableElevation: true, // remove shadow
                },
                styleOverrides: {
                    root: {
                        fontFamily,
                        borderRadius: '9999px',
                        boxShadow: '0px 10px 30px 0px rgba(164, 152, 200, 0.05)',
                        textTransform: 'none',
                    },
                    sizeSmall: {
                        height: '32px',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '100%',
                        padding: '4px 12px',
                        borderWidth: '1px',
                        '@media (max-width: 768px)': {
                            height: '28px',
                            fontSize: '12px',
                            lineHeight: '100%',
                            fontWeight: '600',
                            borderWidth: '1px',
                        },
                    },
                    sizeMedium: {
                        height: '48px',
                        fontSize: '18px',
                        lineHeight: '32px',
                        fontWeight: '600',
                        borderWidth: '1px',
                        '@media (max-width: 768px)': {
                            height: '32px',
                            fontSize: '14px',
                            lineHeight: '24px',
                            fontWeight: '600',
                            borderWidth: '1px',
                        },
                    },
                    sizeLarge: {
                        height: '60px',
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: '600',
                        padding: '8px 16px',
                        borderWidth: '2px',
                        '@media (max-width: 768px)': {
                            height: '48px',
                            fontSize: '18px',
                            lineHeight: '32px',
                            fontWeight: '600',
                            borderWidth: '1px',
                        },
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    className: 'size-5 p-0',
                    disableRipple: true,
                    icon: <img className='size-full' src={CheckIcon} alt='check' />,
                    checkedIcon: <img className='size-full' src={CheckedIcon} alt='check' />,
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontFamily,
                        fontSize: '14px',
                        lineHeight: '21px',
                        fontWeight: 500,
                        padding: '10px 20px',
                        borderRadius: '9999px',
                        backgroundColor: '#E9ECF3',
                        color: '#283266',
                        '&:hover': {
                            backgroundColor: 'rgba(233, 236, 243, 0.8)',
                        },
                        '.MuiChip-label': {
                            padding: '0px !important',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiSvgIcon-root.Mui-disabled': {
                            display: 'none',
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-focused': {
                            backgroundColor: 'transparent',
                        },
                        '&::before, &::after': {
                            borderBottom: '1px solid #231448',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '1px solid #231448',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '1px solid #231448',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled:before': {
                            borderBottomStyle: 'solid !important',
                        },
                    },
                },
            },
        },
    })

    const theme = mergeTheme(token, typography, components)

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider