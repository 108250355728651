import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useMediaQuery } from '@mui/material'

import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import Card from 'page/ConnectWithKTSP/NewsRoom/components/Card'
function AllCard({ items }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div className='py-4 bg-container'>
            <div className='flex justify-between items-center pb-4 md:pb-8'>
                <div className='text-h3 text-secondary-midnightBlue font-semiboldFamily'>{t('aboutUs.moreNews')}</div>
                <div className={'block ' + (items.length <= 3 && ' lg:hidden ') + (items.length <= 2 && ' hidden ')}>
                    <IconButtonChange
                        slideIndex={slideIndex}
                        num={lgScreen ? 3 : 2}
                        length={items.length}
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                    />
                </div>
            </div>
            <Swiper
                spaceBetween={26}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                breakpoints={{
                    0: { slidesPerView: 2 },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                className='mySwiper'
            >
                {items.map((card, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Card card={card} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default AllCard
