import React, { useEffect, useState } from 'react'
import { Button, CardMedia } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { useTranslation } from 'react-i18next'
import JSZip from 'jszip'

import tw from 'twin.macro'
import dayjs from 'dayjs'

const Label = tw.div`text-sm flex gap-1 items-center pb-1`
function RecordCard({ item, time, image, filteredData }) {
    const { t } = useTranslation()
    const downloadTicket = () => {
        const zip = new JSZip()
        for (let i = 0; i < filteredData.length; i++) {
            if (!filteredData[i].isAllowDownload || filteredData[i].totpKey) {
                return
            }
            const canvas = document.getElementById('qrcode' + i).querySelector('canvas')
            const dataURL = canvas.toDataURL('image/png')
            zip.file(`ticket${i + 1}.png`, dataURL.split(',')[1], { base64: true })
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            const downloadLink = document.createElement('a')
            downloadLink.setAttribute('href', window.URL.createObjectURL(content))
            downloadLink.setAttribute('download', 'tickets.zip')
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        })
    }
    const ticketTypeCount = Object.values(
        filteredData.reduce((acc, ticket) => {
            const { name } = ticket.ticketType
            if (acc[name]) {
                acc[name].num++
            } else {
                acc[name] = { name, num: 1 }
            }
            return acc
        }, {}),
    )

    console.log(ticketTypeCount)
    const [adultCount, setAdultCount] = useState(0)
    const [childCount, setChildCount] = useState(0)
    const DownloadBtn = ({ playText }) => {
        return (
            <>
                {filteredData.length > 0 &&
                    filteredData.some((ticket) => ticket.isAllowDownload && !ticket.totpKey) && (
                        <Button
                            onClick={downloadTicket}
                            className={
                                'bg-white pl-5 pr-6 py-[5.5px] h-fit w-fit self-start text-base leading-4 font-semiboldFamily text-secondary-midnightBlue normal-case border-secondary-midnightBlue border-[1px] border-solid ' +
                                playText
                            }
                            variant='contained'
                            startIcon={<div className='text-2xl leading-6 icon-download' />}
                            size='small'
                        >
                            {t('myProfile.downloadBtn')}
                        </Button>
                    )}
            </>
        )
    }
    useEffect(() => {
        for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i].ticketType.name === 'Adult') {
                setAdultCount((adultCount) => adultCount + 1)
            } else {
                setChildCount((childCount) => childCount + 1)
            }
        }
    }, [])
    return (
        <div className='bg-white pt-8 pb-5 px-5 rounded-[12px]'>
            <div
                onClick={() => window.history.back()}
                className='flex items-center text-secondary-midnightBlue cursor-pointer pb-5'
            >
                <div className='icon-arrow_left text-[12px] mr-1' />
                <div className='text-sm font-semiboldFamily underline'>{t('myProfile.backToMyRecord')}</div>
            </div>
            <div>
                <div className='flex gap-9 flex-col lg:flex-row lg:gap-10'>
                    <CardMedia
                        component='img'
                        className='h-[218px] w-full md:h-[210px] md:w-[290px]'
                        height='267'
                        image={image}
                        alt=''
                    />
                    <div className='flex-1 text-secondary-midnightBlue'>
                        <div className='flex justify-between gap-2'>
                            <div className='text-xl font-semiboldFamily pb-3 flex-1'>{item.eventName} </div>
                            <DownloadBtn playText='hidden lg:flex' />
                        </div>
                        <Label className='font-medium'>
                            <div className='icon-date text-base' />
                            <div>{time}</div>
                        </Label>
                        <Label className='font-medium'>
                            <div className='icon-location text-base' />
                            <div>{item?.eventVenue?.name}</div>
                        </Label>
                        <Label className='font-medium'>
                            <div className='icon-ticket text-base' />
                            <div className='flex gap-1'>
                                {ticketTypeCount.length > 0 &&
                                    ticketTypeCount.map((type, index) => {
                                        return <div key={index}>{type.name + 'x' + type.num}</div>
                                    })}
                            </div>
                        </Label>
                        <div className='pt-3 text-base font-semiboldFamily'>
                            <div className='pb-1 flex gap-1'>
                                {t('myProfile.transactionDate')}:
                                <div className='font-medium'>{dayjs(item.completeAt).format('dddd DD MMM YYYY')}</div>
                            </div>
                            <div className='pb-1 flex gap-1'>
                                {t('myProfile.bookingNumber')}: <div className='font-medium'>{item.reference}</div>
                            </div>
                        </div>
                        <DownloadBtn playText='mt-6 flex lg:hidden' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordCard
