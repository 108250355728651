import * as React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { IconButton } from '@mui/material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { formatStartDateEndDate } from 'utils/filter'
import { cn } from 'utils/cn'
function PromotionCard({ card, isProductsList, index }) {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <Card
            onClick={() => navigate('/promotionsDetail?id=' + card.identityUuid)}
            className={
                'relative w-full shadow-none cursor-pointer bg-transparent group rounded-none' +
                (isProductsList && index !== 0 ? ' mt-[35px]' : '')
            }
        >
            <div className={isProductsList ? 'flex justify-between' : ''}>
                <div
                    className={
                        CARD_BOX + (isProductsList ? ' aspect-[4/3] h-full w-[40%] md:w-[27%]' : ' w-full aspect-[4/3]')
                    }
                >
                    {card.label && (
                        <div className='absolute z-10 text-white top-0 left-0 py-[6px] px-[15px] bg-primaryRed-ktRed  text-xs md:text-sm'>
                            {card.label}
                        </div>
                    )}
                    <CardMedia
                        component='img'
                        className='w-full h-full object-cover hover:scale-105 transition-all duration-300'
                        image={card.cardImage}
                        alt='Loading'
                    />
                    <RedLine />
                </div>
                <div className={cn('space-y-2', isProductsList ? 'w-[54%] md:w-[70%]' : 'flex-1')}>
                    <div className={cn('flex justify-between w-full', isProductsList ? 'pt-0' : 'pt-3')}>
                        {/* {card?.programmes && (
                                <div className=' text-primaryRed-ktRed text-xs md:text-sm'>{card.programmes}</div>
                            )} */}
                        {card?.promotionTitle && (
                            <div className=' text-secondary-midnightBlue w-full break-words text-h5'>
                                {card.promotionTitle}
                            </div>
                        )}
                        {/* <IconButton
                            className={
                                'p-0 self-start text-base text-secondary-midnightBlue' +
                                (isProductsList && ' absolute top-0 right-0')
                            }
                        >
                            {card.bookMark ? <BeenhereOutlinedIcon /> : <BookmarkBorderIcon />}
                        </IconButton> */}
                    </div>
                    {card?.description && isProductsList && <DescriptionLabel description={card.description} />}
                    {card.startDate && card.endDate && (
                        <div className='flex gap-1 items-center font-medium text-xs text-secondary-midnightBlue md:text-sm '>
                            {/* <IconButton className='p-0 '> */}
                            <div className='icon-date text-base text-secondary-midnightBlue' />
                            {/* </IconButton> */}
                            <div className='line-clamp-2'>
                                {formatStartDateEndDate(card.startDate, card.endDate, language)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    )
}

export default PromotionCard
