import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Title = tw.div`text-[42px] leading-[50px] text-center pb-8`

function BeOurPartner() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className='flex justify-center mt-20'>
            <div className='text-center text-secondary-midnightBlue w-full md:w-2/3 normal-case'>
                <div className='text-h2 pb-4 uppercase'>{t('ourPartner.beOurPartner')}</div>
                <div className='text-body1 pb-8'>{t('ourPartner.beOurPartnerAbout')}</div>
                <Button
                    className='px-3 py-2 w-[200px]'
                    onClick={() => navigate('/contactUs')}
                    variant='primaryRed'
                >
                    {t('ourPartner.joinUs')}
                </Button>
            </div>
        </div>
    )
}

export default BeOurPartner
