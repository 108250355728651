import React from 'react'
import tw, { styled } from 'twin.macro'
import { Typography, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Content from 'components/Content'

const Wrap = tw.div``
const ListWrap = tw.div``

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
`

const PeakDay = ({ title, weekdayHourly, weeklyHourly }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <>
            <Typography variant='h5' className='text-[16px] text-[#0A173D] cursor-pointer underline'>
                {title}
            </Typography>
            <div>
                <div className='flex gap-4 md:gap-10'>
                    <li className='w-[271px]'>{t('facilitiesBooking.weekdayHourly')}</li>
                    <div className='font-semiboldFamily text-lg leading-6'>{weekdayHourly}</div>
                </div>
                <div className='flex gap-4 md:gap-10'>
                    <li className='w-[271px]'>{t('facilitiesBooking.weeklyHourly')}</li>
                    <div className='font-semiboldFamily text-lg leading-6'>{weeklyHourly}</div>
                </div>
            </div>
        </>
    )
}
export default function BookingFee({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <Wrap className='bg-container mt-12'>
            {/* <Typography variant='h5' className='text-[20px] text-[#0A173D]'>
                {t('facilitiesBooking.parkingFee')}
            </Typography> */}
            {/* <ListWrap className='space-y-5 mt-5'>
                <PeakDay
                    title={t('facilitiesBooking.nonPeakDay')}
                    weekdayHourly={items.parkingFee.nonPeakDay.weekdayHourly}
                    weeklyHourly={items.parkingFee.nonPeakDay.weeklyHourly}
                />
                <PeakDay
                    title={t('facilitiesBooking.peakDay')}
                    weekdayHourly={items.parkingFee.peakDay.weekdayHourly}
                    weeklyHourly={items.parkingFee.peakDay.weeklyHourly}
                />
            </ListWrap> */}
            <Content data={items.bookingFee} className={'text-base font-medium pb-6 mt-5'} />
            <div className='mt-[48px] mb-[64px] space-x-3'>
                <div className='mt-12 space-x-3'>
                    {items?.tags?.map((item) => (
                        <ChipWrap
                            className='bg-transparent'
                            key={item.identityUuid}
                            label={item.name}
                            variant='outlined'
                        />
                    ))}
                </div>
            </div>
        </Wrap>
    )
}
