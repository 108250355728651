import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import EventCard from 'page/EventsTickets/components/EventCard'

function SwiperEventCards({ items = [], setSwiper, CardType = EventCard, setSlideIndex }) {
    const { t } = useTranslation()
    return (
        <Swiper
            spaceBetween={26}
            modules={[Navigation]}
            onActiveIndexChange={(swiper) => {
                setSlideIndex(swiper.realIndex)
            }}
            onSwiper={(swiper) => {
                setSwiper(swiper)
            }}
            breakpoints={{
                0: { slidesPerView: 2 },
                1024: {
                    slidesPerView: 3,
                },
                1440: {
                    slidesPerView: 4,
                },
            }}
            className='mySwiper'
        >
            {items.map((card, index) => {
                return (
                    <SwiperSlide key={index}>
                        <CardType card={card} listType='grid' />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default SwiperEventCards
