import { useEffect, useState } from 'react'
import { FormatListBulleted, GridOnOutlined } from '@mui/icons-material'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { filter, unnest, pluck, length } from 'ramda'

import CardNewsRoom from 'page/ConnectWithKTSP/NewsRoom/components/Card'
import Tag from 'components/shopDine/allProductsComponents/Tag'
import TypeMenu from 'components/shopDine/allProductsComponents/TypeMenu'
import Loading from 'components/Loading'
import SearchInput from 'components/SearchInput'
import PaginationButton from 'components/PaginationButton'
import { filterName, newIdArray } from 'utils/filter'
import { newsroomService } from 'service/informationService'

export default function AllNewsroom() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const [categorySelected, setCategorySelected] = useState([])
    const [isProductsList, setIsProductsList] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const [date, setDate] = useState([])

    const [noChangeLangage, setNoChangeLangage] = useState(true)

    const [query, setQuery] = useState({
        cate: newIdArray(categorySelected),
        lang: language,
        year: date,
        searchText: searchVal.trim(),
        limit: 15,
        offset: 0,
    })

    const {
        data: { category, year: dates },
    } = useQuery({
        queryKey: ['newsroomGetFilterInfo', language],
        queryFn: () => newsroomService.getFilterInfo({ lang: language }),
        select: (res) => {
            return {
                category: res.category,
                year: filter((item) => !!item, res.year),
            }
        },
        placeholderData: {
            category: [],
            year: [],
        },
    })

    const { data, fetchNextPage, isFetching, hasNextPage, isFetchingNextPage, isSuccess } = useInfiniteQuery({
        queryKey: ['newsroomgetList', query],
        queryFn: ({ pageParam }) => {
            if (noChangeLangage) {
                return newsroomService.getList({ ...query, limit: 15, offset: pageParam * 15 })
            } else {
                return newsroomService.getList({ ...query })
            }
        },
        select: (data) => {
            return {
                pageParams: data.pageParams,
                pages: unnest(pluck('list', data.pages)),
                total: data.pages[0]?.total,
            }
        },

        getNextPageParam: (lastPage, pages) => {
            return length(unnest(pluck('list', pages))) < parseInt(lastPage?.total)
                ? length(unnest(pluck('list', pages))) / 15
                : undefined
        },
        initialPageParam: 0,
        placeholderData: [],
    })

    useEffect(() => {
        setQuery({
            cate: newIdArray(categorySelected),
            lang: language,
            limit: 15,
            offset: 0,
            year: date,
            searchText: searchVal.trim(),
        })
    }, [date, categorySelected])

    useEffect(() => {
        if (query.lang !== language) {
            setNoChangeLangage(false)
            setQuery((item) => ({ ...item, lang: language, limit: length(data?.pages), offset: 0 }))
        }
    }, [language, query.lang])

    useEffect(() => {
        if (isSuccess) {
            setNoChangeLangage(true)
        }
    }, [isSuccess])

    return (
        <div className='pt-8 bg-container'>
            <div className='w-full flex flex-col md:flex-row pb-2 justify-start md:justify-end'>
                <SearchInput
                    setIsSearch={setIsSearch}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    onBlurFun={() => setQuery((item) => ({ ...item, searchText: searchVal, offset: 0 }))}
                />
            </div>
            <div className='flex justify-between pb-4 gap-2 md:items-center'>
                <div className='py-2 flex flex-wrap items-center gap-[10px]'>
                    <TypeMenu
                        num={date?.length}
                        name={t('aboutUs.newsDate')}
                        labels={dates}
                        typesSelected={date}
                        setTypesSelected={setDate}
                        isNoFilter={true}
                    />
                    <TypeMenu
                        name={t('aboutUs.newsCategory')}
                        num={categorySelected?.length}
                        labels={category}
                        typesSelected={categorySelected}
                        setTypesSelected={setCategorySelected}
                    />
                    {(date?.length > 0 || categorySelected?.length > 0) && (
                        <div
                            onClick={() => {
                                setCategorySelected([])
                                setDate([])
                            }}
                            className='text-base font-semiboldFamily text-[#0A173D] cursor-pointer underline'
                        >
                            {t('eventPage.reset')}
                        </div>
                    )}
                </div>
                <div className='text-lg text-secondary-grey cursor-pointer flex items-center gap-2'>
                    <div
                        onClick={() => setIsProductsList(false)}
                        className={!isProductsList ? 'text-secondary-midnightBlue' : ''}
                    >
                        <GridOnOutlined />
                    </div>
                    <div
                        onClick={() => setIsProductsList(true)}
                        className={isProductsList ? 'text-secondary-midnightBlue' : ''}
                    >
                        <FormatListBulleted />
                    </div>
                </div>
            </div>
            {
                <div className='flex gap-2 flex-wrap pb-4'>
                    {date.map((item, index) => {
                        return (
                            <Tag
                                key={index}
                                text={item}
                                fun={() => {
                                    const arr = date.filter((val) => item !== val)
                                    setDate([...arr])
                                }}
                            />
                        )
                    })}
                    {categorySelected.map((item, index) => {
                        return (
                            <Tag
                                key={index}
                                text={filterName(item, language)}
                                fun={() => {
                                    const arr = categorySelected.filter((val) => item !== val)
                                    setCategorySelected([...arr])
                                }}
                            />
                        )
                    })}
                </div>
            }
            <div className='text-h4 text-secondary-midnightBlue pb-8'>
                {isSearch ? data?.total : 0}&nbsp;
                {t('eventPage.resultsFound')}
            </div>
            <div
                className={
                    isProductsList
                        ? 'space-y-4 lg:space-y-8'
                        : 'grid gap-x-[10px]  gap-y-8 grid-cols-2 md:gap-x-[20px] lg:grid-cols-3 lg:gap-y-16'
                }
            >
                {isSearch &&
                    data?.pages?.map((card, i) => {
                        return <CardNewsRoom card={card} key={card?.identityUuid} isProductsList={isProductsList} />
                    })}
            </div>
            {isFetching || (isFetchingNextPage && <Loading />)}
            {isSearch && (
                <PaginationButton
                    isDisabled={!hasNextPage}
                    fun={fetchNextPage}
                    nowCardsLength={length(data?.pages)}
                    allCardsLength={data?.total}
                />
            )}
        </div>
    )
}
